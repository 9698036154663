import '../../../Estilos/StyleTableDatos.css'; //Estilo de la tabla
import '../../../Estilos/StyleSelector.css'; //Estilo para el selector
import axios from "axios";
import React, { useState, useEffect, useCallback } from "react";
import {useNavigate} from 'react-router-dom';
import { Link } from "react-router-dom";
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css'; // Estilos de DataTables
import $ from 'jquery';
import 'datatables.net-bs5'; // Importa DataTables

const URI_ACCESO = 'https://cisrubenandino.com/ap/ad_accps/by-ro/'; // URI para obtener permisos por rol
const URI_ROLES = 'https://cisrubenandino.com/ap/ad_rol/'; // URI para obtener los roles

const VALIDAR_MODULO_URI = 'https://cisrubenandino.com/ap/validar-mod-Ap';
const VALIDAR_PERMISO_URI = 'https://cisrubenandino.com/ap/validar-Sa-';


const CompShowAccesos = () => {
    const [roles, setRoles] = useState([]);
    const [selectedRol, setSelectedRol] = useState("");
    const [permisos, setPermisos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    // Función para validar que se tenga acceso al modulo
    const validarAccesoModulo = useCallback(async () => {
        try {
            const res = await axios.get(VALIDAR_MODULO_URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (res.status !== 200 || !res.data.acceso) {
                throw new Error(res.data.mensaje || 'Acceso denegado.');
            }
        } catch (error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                setError('No tienes autorizacion para acceder a este apartado. Si crees que se trata de un error, comunicaselo al administrador.');
            } else {
                setError('No ha sido posible validar el acceso. Contacta con el administrador.');
            }
            setLoading(false);
            throw error;
        }
    }, []);

    // Función para validar el permiso necesario
    const validarPermiso = useCallback(async () => {
        try {
            const res = await axios.get(VALIDAR_PERMISO_URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (res.status !== 200 || !res.data.acceso) {
                setError('No tienes autorización para acceder a este apartado. Si crees que se trata de un error, comunícaselo al administrador.');
            }
        } catch (error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                setError('No se cuenta con el acceso completo para acceder a este apartado.');
            } else {
                setError('No ha sido posible validar el acceso. Contacta con el administrador.');
            }
            setLoading(false);
            throw error;
        }
    }, []);

    const getRoles = useCallback(async () => {
        try {
            const res = await axios.get(URI_ROLES, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            setRoles(res.data);
        } catch (error) {
            setError('No ha sido posible encontrar los roles. Por favor, vuelve a intentar, si el problema persiste, contacta con el administrador.');
        }
    }, []);

    const getPermisos = useCallback(async (ID_Rol) => {
        if (ID_Rol) {
            try {
                setLoading(true);
                const res = await axios.get(`${URI_ACCESO}${ID_Rol}`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });
                if (Array.isArray(res.data)) {
                    setPermisos(res.data);
                } else {
                    setPermisos([]); // No hay permisos del rol para mostrar
                }
            } catch (error) {
                setError('No ha sido posible encontrar los datos. Por favor, vuelve a intentar, si el problema persiste, contacta con el administrador.');
            } finally {
                setLoading(false);
            }
        } else {
            setPermisos([]);
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await validarAccesoModulo(); // Valida el acceso al módulo
                await validarPermiso(); // Valida el permiso del usuario
                await getRoles(); // Obtiene los datos de los usuarios si se tiene acceso
                await getPermisos(); // Obtiene los datos de los usuarios si se tiene acceso

            } catch (error) {
                console.error("Ha ocurrido un problema", error);
            }
        };
        fetchData();
    }, [validarAccesoModulo, validarPermiso, getRoles, getPermisos]);

    useEffect(() => {
        if ($.fn.DataTable.isDataTable('#dataTableAccesos')) {
            $('#dataTableAccesos').DataTable().destroy();
        }

        if (permisos.length > 0) {
            $('#dataTableAccesos').DataTable();
        } else {
            // Limpiar la tabla y mostrar el mensaje de "Seleccione un rol" o "No hay accesos habilitados"
            $('#dataTableAccesos').html(
                permisos.length === 0 && selectedRol === "" ? 
                '<tr><td colspan="6"><b><i><h3>Seleccione un rol para mostrar los datos.</i></h3></b></td></tr>' : 
                '<tr><td colspan="6"><b><i><h3>No hay permisos habilitados para el rol seleccionado. Agregue un nuevo acceso y permiso.</i></h3></b></td></tr>'
            );
        }
    }, [permisos, selectedRol]);

    const handleRolChange = (event) => {
        const selectedRolId = event.target.value;
        setSelectedRol(selectedRolId);
        getPermisos(selectedRolId);
    };

    const deleteAcceso = async (ID_Acceso) => {
        try {
            // Validar el permiso del usuario para la acción de "eliminación"
            const res = await axios.get('https://cisrubenandino.com/ap/validar-Ea-', {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
    
            if (res.status !== 200 || !res.data.acceso) {
                window.alert('No tienes el permiso necesario para realizar esta acción.');
                return;
            }
    
            // Si el usuario tiene permiso, preguntar si desea eliminar
            const confirmDelete = window.confirm("¿Está seguro de que desea eliminar/desactivar este Acceso y permiso?");
            
            if (confirmDelete) {
                try {
                    await axios.delete(`${URI_ACCESO}${ID_Acceso}`, {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        }
                    });
                    window.location.reload();
                } catch (error) {
                    console.error('Error al eliminar/desactivar el acceso:', error);
                    window.alert('Hubo un problema al intentar eliminar el acceso y permiso Por favor, inténtelo de nuevo.');
                }
            }
        } catch (error) {
            // Manejo específico del error 403
            if (error.response && error.response.status === 403) {
                window.alert('No tienes el permiso necesario para realizar esta acción.');
            } else {
                console.error('Error al validar permiso:', error);
                window.alert('Hubo un problema al verificar los permisos. Por favor, inténtelo de nuevo más tarde.');
            }
        }
    };

    // Función para cancelar y regresar a la página anterior
    const cancel = () => {
        navigate('/Inicio');
    };

    // Muestra un indicador de carga mientras se obtienen los datos
if (loading) {
    return (
        <div className="loading-container">
            <p>Cargando datos... Por favor, espera.</p>
        </div>
    );
}

// Muestra un mensaje de error en caso de que ocurra
if (error) {
    return (
        <div className="error-container">
            <p>{error}</p>
            <button className="btn btn-secondary" onClick={cancel}>Volver a inicio</button>
        </div>
    );
}

    return (
        <div className="main-container">
            {loading && !error ? (
                <p>Cargando... Por favor espere...</p>
            ) : error ? (
                <p>{error}</p>
            ) : (
                <>
                    <h2 className="table-title">Administración De Accesos y Permisos</h2>
                    <div className="btn-container">
                        <div className="role-select-container">
                            <label htmlFor="rolSelector"><h4>Seleccione un Rol:</h4></label>
                            <select id="rolSelector" className="form-control" value={selectedRol} onChange={handleRolChange}>
                                <option value="">Seleccionar Rol</option>
                                {roles.map((rol) => (
                                    <option key={rol.ID_Rol} value={rol.ID_Rol}>{rol.NOM_Rol}</option>
                                ))}
                            </select>
                        </div>  
                        <div className="btn-group">
                            <Link 
                                to="/AdmAPSC" 
                                className={'btn btn-primary mb-3'}
                            >
                                Agregar un nuevo Acceso y Permisos a un rol <i className="fa-solid fa-user-pen"></i>
                            </Link>
                        </div>
                    </div>
                    <div className="table-container">
                        <table id="dataTableAccesos" className="table table-striped">
                            <thead className="table">
                                <tr>
                                    <th className="text-center">Modulo</th>
                                    <th className="text-center">Seleccionar</th>
                                    <th className="text-center">Insertar</th>
                                    <th className="text-center">Actualizar</th>
                                    <th className="text-center">Eliminar</th>
                                    <th className="text-center">Opciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {permisos.length === 0 ? (
                                    selectedRol === "" ? (
                                        <tr>
                                            <td colSpan="6">Seleccione un rol para mostrar los datos.</td>
                                        </tr>
                                    ) : (
                                        <tr>
                                            <td colSpan="6">No hay accesos habilitados para el rol seleccionado. Agregue nuevos accesos.</td>
                                        </tr>
                                    )
                                ) : (
                                    permisos.map((permiso) => (
                                        <tr key={permiso.ID_Acceso}>
                                            <td className="text-center">{permiso.NOM_Modulo}</td>
                                            <td className="text-center">
                                                {permiso.Seleccionar === "1" ? "Habilitado" : "Deshabilitado"}
                                            </td>
                                            <td className="text-center">
                                                {permiso.Insertar === "1" ? "Habilitado" : "Deshabilitado"}
                                            </td>
                                            <td className="text-center">
                                                {permiso.Actualizar === "1" ? "Habilitado" : "Deshabilitado"}
                                            </td>
                                            <td className="text-center">
                                                {permiso.Eliminar === "1" ? "Habilitado" : "Deshabilitado"}
                                        </td>
                                            <td className="text-center">
                                                <Link to={`/AdmEdiAPC/${permiso.ID_Acceso}`} className="btn btn-info">
                                                    Editar <i className="fa-solid fa-pen-to-square"></i>
                                                </Link>
                                                <button onClick={() => deleteAcceso(permiso.ID_Acceso)} className="btn btn-danger">
                                                    Desactivar <i className="fa-solid fa-user-large-slash"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>
                </>
            )}
        </div>
    );
};

export default CompShowAccesos;



import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import { useNavigate, useParams } from 'react-router-dom';
import '../../../Estilos/StyleFormulario.css';

const URI = 'https://cisrubenandino.com/ap/control_personal/';
const VALIDAR_MODULO_URI = 'https://cisrubenandino.com/ap/validar-mod-E';
const VALIDAR_PERMISO_URI = 'https://cisrubenandino.com/ap/validar-AE-';



const CompEditPersonal = () => {  // Recibe el nombre de usuario como una prop
    const [NUM_EMP_CONTROL_PERSONAL, setNUM_EMP_CONTROL_PERSONAL] = useState('');
    const [PRI_NOM_CONTROL_PERSONAL, setPRI_NOM_CONTROL_PERSONAL] = useState('');
    const [SEG_NOM_CONTROL_PERSONAL, setSEG_NOM_CONTROL_PERSONAL] = useState('');
    const [PRI_APE_CONTROL_PERSONAL, setPRI_APE_CONTROL_PERSONAL] = useState('');
    const [SEG_APE_CONTROL_PERSONAL, setSEG_APE_CONTROL_PERSONAL] = useState('');
    const [COR_ELEC_CONTROL_PERSONAL, setCOR_ELEC_CONTROL_PERSONAL] = useState('');
    const [DIRECCION, setDIRECCION] = useState('');
    const [TELEFONO, setTELEFONO] = useState('');
    const [FEC_NAC_CONTROL_PERSONAL, setFEC_NAC_CONTROL_PERSONAL] = useState('');
    const [VACACIONES, setVACACIONES] = useState('');
    const [CATEGORIA, setCATEGORIA] = useState('');
    const [FEC_INGRESO, setFEC_INGRESO] = useState('');
    const [USR_REGISTRO] = useState(localStorage.getItem('username') || '');
    const [NOM_PUESTO, setNOM_PUESTO] = useState('');
    const [TIP_SANGRE, setTIP_SANGRE] = useState('');
       const navigate = useNavigate();
    const { ID_CONTROL_PERSONAL } = useParams();
    const [message, setMessage] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
    const [originalData, setOriginalData] = useState(null);
    // Estados para manejo de carga y errores
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    
    // Funciones para el control de los modales
    const openModal = () => {
        setModalIsOpen(true);
    };
    const closeModal = () => {
        setModalIsOpen(false);
    };
    const openConfirmModal = () => {
        setConfirmModalIsOpen(true);
    };
    const closeConfirmModal = () => {
        setConfirmModalIsOpen(false);
    };
    const handleModalClose = () => {
        closeModal();
    };


     // Función para validar que se tenga acceso al módulo
     const validarAccesoModulo = useCallback(async () => {
        try {
            const res = await axios.get(VALIDAR_MODULO_URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (res.status !== 200 || !res.data.acceso) {
                throw new Error(res.data.mensaje || 'Acceso denegado.');
            }
        } catch (error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                setError('No tienes autorización para acceder a este apartado. Si crees que se trata de un error, comunícaselo al administrador.');
            } else {
                setError('No ha sido posible validar el acceso. Contacta con el administrador.');
            }
            setLoading(false);
            throw error;
        }
    }, []);

    


    // Función para validar el permiso necesario
    const validarPermisoUsuario = useCallback(async () => {
        try {
            const res = await axios.get(VALIDAR_PERMISO_URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (res.status !== 200 || !res.data.acceso) {
                throw new Error('No tienes autorización para acceder a este apartado.');
            }
        } catch (error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                setError('No se cuenta con el acceso completo para acceder a este apartado.');
            } else {
                setError('No ha sido posible validar el acceso. Contacta con el administrador.');
            }
            setLoading(false);
            throw error;
        }
    }, []);




     //Comprobar si se ha realizado al menos un cambio en un campo
    const handleSubmit = (e) => {
        e.preventDefault();
        const hasChanges =
            NUM_EMP_CONTROL_PERSONAL !== originalData.NUM_EMP_CONTROL_PERSONAL ||
            PRI_NOM_CONTROL_PERSONAL !== originalData.PRI_NOM_CONTROL_PERSONAL ||
            SEG_NOM_CONTROL_PERSONAL !== originalData.SEG_NOM_CONTROL_PERSONAL ||
            PRI_APE_CONTROL_PERSONAL !== originalData.PRI_APE_CONTROL_PERSONAL ||
            SEG_APE_CONTROL_PERSONAL !== originalData.SEG_APE_CONTROL_PERSONAL ||
            COR_ELEC_CONTROL_PERSONAL !== originalData.COR_ELEC_CONTROL_PERSONAL ||
            DIRECCION !== originalData.DIRECCION ||
            TELEFONO !== originalData.TELEFONO ||
            FEC_NAC_CONTROL_PERSONAL !== originalData.FEC_NAC_CONTROL_PERSONAL ||
            VACACIONES !== originalData.VACACIONES ||
            CATEGORIA !== originalData.CATEGORIA ||
            FEC_INGRESO !== originalData.FEC_INGRESO ||
            NOM_PUESTO !== originalData.NOM_PUESTO ||
            TIP_SANGRE !== originalData.TIP_SANGRE;

        if (hasChanges) {
            //Si se ha cambiado al menos un dato se procede a realizar el cambio
            openConfirmModal(); 
        } else {
            //Si no se ha detectado al menos un cambio se muestra un mensaje
            setMessage('No se han realizado cambios en el personal.');
            openModal();
        }
    };

    
    useEffect(() => {
        const getPersonalByID = async () => {
            try {
                const userResponse = await axios.get(URI + ID_CONTROL_PERSONAL, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });
                const userData = userResponse.data;
                setNUM_EMP_CONTROL_PERSONAL(userData.NUM_EMP_CONTROL_PERSONAL);
                setPRI_NOM_CONTROL_PERSONAL(userData.PRI_NOM_CONTROL_PERSONAL);
                setSEG_NOM_CONTROL_PERSONAL(userData.SEG_NOM_CONTROL_PERSONAL);
                setPRI_APE_CONTROL_PERSONAL(userData.PRI_APE_CONTROL_PERSONAL);
                setSEG_APE_CONTROL_PERSONAL(userData.SEG_APE_CONTROL_PERSONAL);
                setCOR_ELEC_CONTROL_PERSONAL(userData.COR_ELEC_CONTROL_PERSONAL);
                setDIRECCION(userData.DIRECCION);
                setTELEFONO(userData.TELEFONO);
                setFEC_NAC_CONTROL_PERSONAL(new Date(userData.FEC_NAC_CONTROL_PERSONAL).toISOString().split('T')[0]);
                setVACACIONES(userData.VACACIONES);
                setCATEGORIA(userData.CATEGORIA);
                setFEC_INGRESO(new Date(userData.FEC_INGRESO).toISOString().split('T')[0]);
                setNOM_PUESTO(userData.NOM_PUESTO);
                setTIP_SANGRE(userData.TIP_SANGRE);
                setOriginalData(userData);
            } catch (error) {
                setMessage('¡Ocurrió un error y no se pudo actualizar el expediente del personal!, intentalo de nuevo');
            }
        };
        getPersonalByID();
    }, [ID_CONTROL_PERSONAL, navigate]);


    // Efecto para ejecutar las validaciones y cargar datos al montar el componente
    useEffect(() => {
        const fetchData = async () => {
            try {
                await validarAccesoModulo(); // Valida el acceso al módulo
                await validarPermisoUsuario(); // Valida el permiso del usuario
                
                setLoading(false);
            } catch (error) {
                console.error('Error durante la carga de datos:', error);
            }
        };
        fetchData();
    }, [validarAccesoModulo, validarPermisoUsuario]);

    
    const update = async (e) => {
        e.preventDefault();
        closeConfirmModal(); // Cerrar el modal de confirmación

        // Formateo de fechas
        const formattedFEC_NAC_CONTROL_PERSONAL = new Date(FEC_NAC_CONTROL_PERSONAL).toISOString().split('T')[0];
        const formattedFEC_INGRESO = new Date(FEC_INGRESO).toISOString().split('T')[0];
    
        try {
            await axios.put(URI + ID_CONTROL_PERSONAL, {
                NUM_EMP_CONTROL_PERSONAL,
                PRI_NOM_CONTROL_PERSONAL,
                SEG_NOM_CONTROL_PERSONAL,
                PRI_APE_CONTROL_PERSONAL,
                SEG_APE_CONTROL_PERSONAL,
                COR_ELEC_CONTROL_PERSONAL,
                DIRECCION,
                TELEFONO,
                FEC_NAC_CONTROL_PERSONAL: formattedFEC_NAC_CONTROL_PERSONAL,
                VACACIONES,
                CATEGORIA,
                FEC_INGRESO: formattedFEC_INGRESO,
                USR_REGISTRO,
                NOM_PUESTO,
                TIP_SANGRE,

            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }

            });
            setMessage('¡Personal actualizado exitosamente!'); // Establecer el mensaje de éxito
            openModal(); // Abrir el modal de mensaje
        } catch (error) {
            setMessage('¡Ocurrió un error y no se pudo actualizar el expediente del personal!, intentalo de nuevo'); // Establecer el mensaje de error
            openModal(); // Abrir el modal de mensaje
        }
    };
  
     //Procedimiento para cancelar
    const cancel = () => {
        closeModal();
        navigate('/MostrarP');
    };


     // Restaurar un campo especifico al valor original, si el usuario se equivoco y quiere regresar al valor existente
     const restoreField = (fieldName) => {
        switch (fieldName) {
            case 'NUM_EMP_CONTROL_PERSONAL':
                setNUM_EMP_CONTROL_PERSONAL(originalData.NUM_EMP_CONTROL_PERSONAL);
                break;
            case 'PRI_NOM_CONTROL_PERSONAL':
                setPRI_NOM_CONTROL_PERSONAL(originalData.PRI_NOM_CONTROL_PERSONAL);
                break;
            case 'SEG_NOM_CONTROL_PERSONAL':
                setSEG_NOM_CONTROL_PERSONAL(originalData.SEG_NOM_CONTROL_PERSONAL);
                break;
            case 'PRI_APE_CONTROL_PERSONAL':
                setPRI_APE_CONTROL_PERSONAL(originalData.PRI_APE_CONTROL_PERSONAL);
                break;
            case 'SEG_APE_CONTROL_PERSONAL':
                setSEG_APE_CONTROL_PERSONAL(originalData.SEG_APE_CONTROL_PERSONAL);
                break;
            case 'COR_ELEC_CONTROL_PERSONAL':
               setCOR_ELEC_CONTROL_PERSONAL(originalData.COR_ELEC_CONTROL_PERSONAL);
                break;
            case 'DIRECCION':
                setDIRECCION(originalData.DIRECCION);
                break;
            case 'TELEFONO':
                setTELEFONO(originalData.TELEFONO);
                break;
            case 'FEC_NAC_CONTROL_PERSONAL':
                setFEC_NAC_CONTROL_PERSONAL(originalData.FEC_NAC_CONTROL_PERSONAL);
                break;
            case 'VACACIONES':
                setVACACIONES(originalData.VACACIONES);
                break;
            case 'CATEGORIA':
                setCATEGORIA(originalData.CATEGORIA);
                break;
            case 'FEC_INGRESO':
                setFEC_INGRESO(originalData.FEC_INGRESO);
                break;
            case 'NOM_PUESTO':
                setNOM_PUESTO(originalData.NOM_PUESTO);
                break;
            case 'TIP_SANGRE':
                setTIP_SANGRE(originalData.TIP_SANGRE);
                break;
            default:
                break;
        }
    };

    // Restaurar todos los campos al valor original
    const restoreAllFields = () => {
        setNUM_EMP_CONTROL_PERSONAL(originalData.NUM_EMP_CONTROL_PERSONAL);
        setPRI_NOM_CONTROL_PERSONAL(originalData.PRI_NOM_CONTROL_PERSONAL);
        setSEG_NOM_CONTROL_PERSONAL(originalData.SEG_NOM_CONTROL_PERSONAL);
        setPRI_APE_CONTROL_PERSONAL(originalData.PRI_APE_CONTROL_PERSONAL);
        setSEG_APE_CONTROL_PERSONAL(originalData.SEG_APE_CONTROL_PERSONAL);
        setCOR_ELEC_CONTROL_PERSONAL(originalData.COR_ELEC_CONTROL_PERSONAL);
        setDIRECCION(originalData.DIRECCION);
        setTELEFONO(originalData.TELEFONO);
        setFEC_NAC_CONTROL_PERSONAL(originalData.FEC_NAC_CONTROL_PERSONAL);
        setVACACIONES(originalData.VACACIONES);
        setCATEGORIA(originalData.CATEGORIA);
        setFEC_INGRESO(originalData.FEC_INGRESO);
        setNOM_PUESTO(originalData.NOM_PUESTO);
        setTIP_SANGRE(originalData.TIP_SANGRE);
    };


    // Muestra un indicador de carga mientras se obtienen los datos
    if (loading) {
        return (
            <div className="loading-container">
                <p>Cargando datos... Por favor, espera.</p>
            </div>
        );
    }
    // Muestra un mensaje de error en caso de que ocurra
    if (error) {
        return (
            <div className="error-container">
                <p>{error}</p>
                <button className="btn btn-secondary" onClick={cancel}>Volver</button>
            </div>
        );
    }

    return (
        <div>
        <div className="titulo-container">
            <h3>EDITAR UN EXPEDIENTE DEL PERSONAL</h3>
    </div>
        <div className="formulario-container">
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label className="form-label">Numero de empleado:</label>
                    <input
                       value={NUM_EMP_CONTROL_PERSONAL}
                       onChange={(e)=> setNUM_EMP_CONTROL_PERSONAL(e.target.value)}
                       type="bigint"
                       className="form-control"
                    />
                    <button type="button" className="btn btn-info" onClick={() => restoreField('NUM_EMP_CONTROL_PERSONAL')}>
                        Limpiar
                    </button>
                </div>
                <div className="mb-3">
                    <label className="form-label">Primer nombre del empleado:</label>
                 <input
                       value={PRI_NOM_CONTROL_PERSONAL}
                       onChange={(e)=> setPRI_NOM_CONTROL_PERSONAL(e.target.value)}
                       type="text"
                       className="form-control"

                    />
                   <button type="button" className="btn btn-info" onClick={() => restoreField('PRI_NOM_CONTROL_PERSONAL')}>
                        Limpiar
                    </button>
                </div>
                <div className="mb-3">
                    <label className="form-label">Segundo nombre del empleado:</label>
                    <input
                       value={SEG_NOM_CONTROL_PERSONAL}
                       onChange={(e)=> setSEG_NOM_CONTROL_PERSONAL(e.target.value)}
                       type="text"
                       className="form-control"

                    />
                    <button type="button" className="btn btn-info" onClick={() => restoreField('SEG_NOM_CONTROL_PERSONAL')}>
                        Limpiar
                    </button>
                </div>
                <div className="mb-3">
                    <label className="form-label">Primer apellido del empleado:</label>
                    <input
                       value={PRI_APE_CONTROL_PERSONAL}
                       onChange={(e)=> setPRI_APE_CONTROL_PERSONAL(e.target.value)}
                       type="text"
                       className="form-control"

                    />
                    <button type="button" className="btn btn-info" onClick={() => restoreField('PRI_APE_CONTROL_PERSONAL')}>
                        Limpiar
                    </button>
                </div>
                <div className="mb-3">
                    <label className="form-label">Segundo apellido del empleado:</label>
                    <input
                       value={SEG_APE_CONTROL_PERSONAL}
                       onChange={(e)=> setSEG_APE_CONTROL_PERSONAL(e.target.value)}
                       type="text"
                       className="form-control"

                    />
                    <button type="button" className="btn btn-info" onClick={() => restoreField('SEG_APE_CONTROL_PERSONAL')}>
                        Limpiar
                    </button>
                </div>
                 <div className="mb-3">
                    <label className="form-label">Correo electronico:</label>
                    <textarea
                       value={COR_ELEC_CONTROL_PERSONAL}
                       onChange={(e)=> setCOR_ELEC_CONTROL_PERSONAL(e.target.value)}
                       type="text"
                       className="form-control"

                    />
                 <button type="button" className="btn btn-info" onClick={() => restoreField('COR_ELEC_CONTROL_PERSONAL')}>
                        Limpiar
                    </button>
                </div>
                <div className="mb-3">
                    <label className="form-label">Dirección del domicilio del empleado:</label>
                    <input
                       value={DIRECCION}
                       onChange={(e)=> setDIRECCION(e.target.value)}
                       type="text"
                       className="form-control"

                    />
                    <button type="button" className="btn btn-info" onClick={() => restoreField('DIRECCION')}>
                        Limpiar
                    </button>
                </div>
                <div className="mb-3">
                    <label className="form-label">Numero de telefono del empleado:</label>
                    <input
                       value={TELEFONO}
                       onChange={(e)=> setTELEFONO(e.target.value)}
                       type="text"
                       className="form-control"

                    />
                    <button type="button" className="btn btn-info" onClick={() => restoreField('TELEFONO')}>
                        Limpiar
                    </button>
                </div>
                <div className="mb-3">
                    <label className="form-label">Fecha de nacimiento del empleado:</label>
                    <input
                       value={FEC_NAC_CONTROL_PERSONAL}
                       onChange={(e)=> setFEC_NAC_CONTROL_PERSONAL(e.target.value)}
                       type="date"
                       className="form-control"

                    />
                    <button type="button" className="btn btn-info" onClick={() => restoreField('FEC_NAC_CONTROL_PERSONAL')}>
                        Limpiar
                    </button>
                </div>
                <div className="mb-3">
                    <label className="form-label">Vacaciones del empleado:</label>
                    <textarea
                       value={VACACIONES}
                       onChange={(e)=> setVACACIONES(e.target.value)}
                       type="text"
                       className="form-control"

                    />
                 <button type="button" className="btn btn-info" onClick={() => restoreField('VACACIONES')}>
                        Limpiar
                    </button>
                </div>
                <div className="mb-3">
                    <label className="form-label">Categoria de trabajo:</label>
                    <textarea
                       value={CATEGORIA}
                       onChange={(e)=> setCATEGORIA(e.target.value)}
                       type="text"
                       className="form-control"

                    />
                 <button type="button" className="btn btn-info" onClick={() => restoreField('CATEGORIA')}>
                        Limpiar
                    </button>
                </div>
                <div className="mb-3">
                    <label className="form-label">Fecha de ingreso del empleado:</label>
                    <input
                       value={FEC_INGRESO}
                       onChange={(e)=> setFEC_INGRESO(e.target.value)}
                       type="date"
                       className="form-control"

                    />
                    <button type="button" className="btn btn-info" onClick={() => restoreField('FEC_INGRESO')}>
                        Limpiar
                    </button>
                </div>
                 <div className="mb-3">
                    <label className="form-label">nombre de puesto de trabajo:</label>
                    <textarea
                       value={NOM_PUESTO}
                       onChange={(e)=> setNOM_PUESTO(e.target.value)}
                       type="text"
                       className="form-control"

                    />
                 <button type="button" className="btn btn-info" onClick={() => restoreField('NOM_PUESTO')}>
                        Limpiar
                    </button>
                </div>
                 <div className="mb-3">
                    <label className="form-label">Tipo de sangre:</label>
                    <textarea
                       value={TIP_SANGRE}
                       onChange={(e)=> setTIP_SANGRE(e.target.value)}
                       type="text"
                       className="form-control"

                    />
                 <button type="button" className="btn btn-info" onClick={() => restoreField('TIP_SANGRE')}>
                        Limpiar
                    </button>
                </div>
                <button type="button" className="btn btn-success" onClick={handleSubmit} > Guardar</button>
                <button type="button" className="btn btn-primary" onClick={restoreAllFields}> Restaurar</button>
                <button type="button" className="btn btn-danger" onClick={cancel}>Cancelar</button>
            </form>
            <Modal
                 isOpen={confirmModalIsOpen}
                 onRequestClose={closeConfirmModal}
                 contentLabel="Confirmación Modal"
            >
                <h2>¿Estás seguro de que deseas actualizar el Expediente del personal?</h2>
                <button onClick={update}>Si, Actualizar</button>
                <button onClick={closeConfirmModal}>No, regresar</button>
            </Modal>
            {/* Modal de mensaje */}
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={cancel}
                contentLabel="Mensaje Modal"
            >
                <h2>{message}</h2>
                <button onClick={handleModalClose}>Aceptar</button>
            </Modal>
        </div>
    </div>
    )
}
export default CompEditPersonal
import '../../../Estilos/StyleTableDatos.css';
import axios from "axios";
import React, { useState, useEffect, useCallback } from "react";
import {useNavigate} from 'react-router-dom';
import { Link } from "react-router-dom";
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css'; // Estilos de DataTables
import $ from 'jquery';
import 'datatables.net-bs5'; // Importa DataTables

const URI = 'https://cisrubenandino.com/ap/usuarios/';
const VALIDAR_MODULO_URI = 'https://cisrubenandino.com/ap/validar-mod-U';
const VALIDAR_PERMISO_URI = 'https://cisrubenandino.com/ap/validar-Su-';

const CompShowUsuarios = () => {
    const [usuarios, setUsuarios] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    // Formateo para la fecha y hora
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString('es-HN', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true // Para formato 12 horas, false = 24 
        });
    };
    
    // Función para validar que se tenga acceso al modulo
    const validarAccesoModulo = useCallback(async () => {
        try {
            const res = await axios.get(VALIDAR_MODULO_URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (res.status !== 200 || !res.data.acceso) {
                throw new Error(res.data.mensaje || 'Acceso denegado.');
            }
        } catch (error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                setError('No tienes autorizacion para acceder a este apartado. Si crees que se trata de un error, comunicaselo al administrador.');
            } else {
                setError('No ha sido posible validar el acceso. Contacta con el administrador.');
            }
            setLoading(false);
            throw error;
        }
    }, []);

    // Función para validar el permiso necesario
    const validarPermiso = useCallback(async () => {
        try {
            const res = await axios.get(VALIDAR_PERMISO_URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (res.status !== 200 || !res.data.acceso) {
                setError('No tienes autorización para acceder a este apartado. Si crees que se trata de un error, comunícaselo al administrador.');
            }
        } catch (error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                setError('No se cuenta con el acceso completo para acceder a este apartado.');
            } else {
                setError('No ha sido posible validar el acceso. Contacta con el administrador.');
            }
            setLoading(false);
            throw error;
        }
    }, []);

    const getUsuarios = useCallback(async () => {

        try {
        const res = await axios.get(URI, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        });
            if (Array.isArray(res.data)) {
                const usuariosFormatted = res.data.map((usuario) => ({
                    ...usuario,
                    ULTI_Sesion: formatDate(usuario.ULTI_Sesion),
                }));
                setUsuarios(usuariosFormatted);
            } else {
                setUsuarios([]); // No hay usuarios para mostrar
            }
        } catch (error) {
            // Manejo de error para los códigos de estado 401 y 403
        if (error.response) {
            if (error.response.status === 401 || error.response.status === 403) {
                // Redirige a la página de InicioNoAutorizado
                localStorage.removeItem('token'); // Elimina el token
                localStorage.removeItem('username'); // Elimina el nombre de usuario
                setError('No ha sido posible validar tus credenciales, vuelve a iniciar sesión.');
                navigate(`/InicioNoAutorizado?error=${encodeURIComponent(error.response.data.message)}`);
            } else {
                // Otro tipo de error
                setError('No ha sido posible encontrar los datos. Por favor vuelve a intentar, si el problema persiste, contacta con el administrador.');
            }
        } else {
            // Error sin respuesta del servidor (puede ser de red o otro detalle)
            setError('No ha sido posible verificar los datos en este momento. Por favor, contacta con el administrador.');
        }
        } finally {
            setLoading(false);
        }
    }, [navigate]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await validarAccesoModulo(); // Valida el acceso al módulo
                await validarPermiso(); // Valida el permiso del usuario
                await getUsuarios(); // Obtiene los datos de los usuarios si se tiene acceso
            } catch (error) {
                console.error("Ha ocurrido un problema", error);
            }
        };
        fetchData();
    }, [validarAccesoModulo, validarPermiso, getUsuarios]);

    useEffect(() => {
        if ($.fn.DataTable.isDataTable('#dataTableUsuario')) {
            $('#dataTableUsuario').DataTable().destroy();
        }

        if (usuarios.length > 0) {
            $('#dataTableUsuario').DataTable();
        }
    }, [usuarios]); // Vuelve a inicializar DataTables cuando cambian los parámetros


    const deleteUsuario = async (ID_Usuario) => {
        try {
            // Validar el permiso del usuario para la acción de "eliminación"
            const res = await axios.get('https://cisrubenandino.com/ap/validar-Eu-', {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
    
            if (res.status !== 200 || !res.data.acceso) {
                window.alert('No tienes el permiso necesario para realizar esta acción.');
                return;
            }
    
            // Si el usuario tiene permiso, preguntar si desea eliminar
            const confirmDelete = window.confirm("¿Está seguro de que desea Eliminar este usuario?");
            
            if (confirmDelete) {
                try {
                    await axios.delete(`${URI}${ID_Usuario}`, {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        }
                    });
                    getUsuarios(); // Refresca la lista de usuarios después de la eliminación
                } catch (error) {
                    console.error('Error al Eliminar al usuario:', error);
                    window.alert('Hubo un problema al intentar Eliminar al usuario. Por favor, inténtelo de nuevo.');
                }
            }
        } catch (error) {
            // Manejo específico del error 403
            if (error.response && error.response.status === 403) {
                window.alert('No tienes el permiso necesario para realizar esta acción.');
            } else {
                console.error('Error al validar permiso:', error);
                window.alert('Hubo un problema al verificar los permisos. Por favor, inténtelo de nuevo más tarde.');
            }
        }
    };

    // Función para cancelar y regresar a la página anterior
    const cancel = () => {
        navigate('/Inicio');
    };

    // Muestra un indicador de carga mientras se obtienen los datos
if (loading) {
    return (
        <div className="loading-container">
            <p>Cargando datos... Por favor, espera.</p>
        </div>
    );
}

// Muestra un mensaje de error en caso de que ocurra
if (error) {
    return (
        <div className="error-container">
            <p>{error}</p>
            <button className="btn btn-secondary" onClick={cancel}>Volver a inicio</button>
        </div>
    );
}

return (
    <div className="main-container">
        <h2 className="text-center mb-4">Administración De Usuarios</h2> {/* Título de la página */}
        <div className="btn-container">
            <Link to="/AdmCreatUser" className="btn btn-primary mb-3">
                Crear un nuevo Usuario <i className="fa-solid fa-address-card"></i>
            </Link>
        </div>
        <div className="table-container">
            <table id="dataTableUsuario" className="table table-striped">
                <thead>
                    <tr>
                        <th className="text-center">Usuario</th>
                        <th className="text-center">Persona</th>
                        <th className="text-center">Rol</th>
                        <th className="text-center">Correo</th>
                        <th className="text-center">Intentos Realizados</th>
                        <th className="text-center">Última Sesión</th>
                        <th className="text-center">Estado</th>
                        <th className="text-center">Opciones</th>
                    </tr>
                </thead>
                <tbody>
                    {usuarios.length === 0 ? (
                        <tr>
                            <td colSpan="8">No hay datos para mostrar. Cree o registre un nuevo usuario.</td>
                        </tr>
                    ) : (
                        usuarios.map((usuario) => (
                            <tr key={usuario.ID_Usuario}>
                                <td className="text-center">{usuario.NOM_Usuario}</td>
                                <td className="text-center">{usuario.NOM_Persona}</td>
                                <td className="text-center">{usuario.NOM_Rol}</td>
                                <td className="text-center">{usuario.Correo}</td>
                                <td className="text-center">{usuario.Intentos}</td>
                                <td className="text-center">{usuario.ULTI_Sesion}</td>
                                <td className="text-center">{usuario.Estado === 1 ? "Activo" : "Inactivo"}</td>
                                <td className="text-center">
                                    <Link to={`/AdmEdiUser/${usuario.ID_Usuario}`} className="btn btn-info">
                                        Editar <i className="fa-solid fa-pen-to-square"></i>
                                    </Link>
                                    <button onClick={() => deleteUsuario(usuario.ID_Usuario)} className="btn btn-danger">
                                        Desactivar <i className="fa-solid fa-user-large-slash"></i>
                                    </button>
                                </td> 
                            </tr>
                        ))
                    )}
                </tbody>
            </table>
        </div>
    </div>
  );
};

export default CompShowUsuarios;

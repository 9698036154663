import React, { useState } from "react";
import { Button, Spinner } from "reactstrap";
import * as XLSX from "xlsx";

const BotonExcelRE = ({ productos }) => {
  const [loading, setLoading] = useState(false);

  const titulo = [{ A: "Registro de Rango de edades del mes AT2R" }, {}];
  
  const longitudes = [35, 30, 30, 30];

  const handleDownload = () => {
    setLoading(true);

    let tabla = [
      {
        A: "Concepto",
        B: "Atenciones con medico general",
        C: "Atenciones con especialista",
        D: "Total de atenciones del mes",
      },
    ];

    productos.forEach((producto) => {
      tabla.push({
        A: producto.Desc_Ran_Edad,
        B: producto.General,
        C: producto.Especialista,
        D: producto.Num_pac,
      });
    });

    const dataFinal = [...titulo, ...tabla];

    setTimeout(() => {
      creandoArchivo(dataFinal);
      setLoading(false);
    }, 1000);
  };

  const creandoArchivo = (dataFinal) => {
    const libro = XLSX.utils.book_new();

    const hoja = XLSX.utils.json_to_sheet(dataFinal, { skipHeader: true });

    hoja["!merges"] = [
      XLSX.utils.decode_range("A1:G1"),
      XLSX.utils.decode_range("A2:G2"),
      XLSX.utils.decode_range("A34:G34"),
    ];

    let propiedades = [];

    longitudes.forEach((col) => {
      propiedades.push({
        width: col,
      });
    });

    hoja["!cols"] = propiedades;

    XLSX.utils.book_append_sheet(libro, hoja, "Registro de AT2R del mes");

    XLSX.writeFile(libro, "RRangoEdad.xlsx");
  };

  return (
    <>
      {!loading ? (
        <Button color="success" onClick={handleDownload}>
          Imprimir reporte
        </Button>
      ) : (
        <Button color="success" disabled>
          <Spinner size="sm">Loading...</Spinner>
          <span> Generando...</span>
        </Button>
      )}
    </>
  );
};

export default BotonExcelRE;

import axios from "axios";

const VALIDAR_PERMISOD_URI = 'https://cisrubenandino.com/ap/validar-Eat2r-';

export const validarPermisoEliminar = async (token) => {
    try {
        const res = await axios.get(VALIDAR_PERMISOD_URI, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        return res.status === 200 && res.data.acceso;
    } catch (error) {
        console.error('Error validando el permiso de eliminación:', error);
        return false;
    }
};

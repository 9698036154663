import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CardHeader } from 'react-bootstrap';
import BotonExcelRE from "../ModuloEstadistica/BotonReporteREXLSX.js";

const URI = 'https://cisrubenandino.com/ap/ran_edad_paciente/';
const VALIDAR_MODULO_URI = 'https://cisrubenandino.com/ap/validar-mod-red';
const VALIDAR_PERMISO_URI = 'https://cisrubenandino.com/ap/validar-Sred-';

const CompShowedad = () => {
    const [Redad, setRedad] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState('');
    const [selectedYear, setSelectedYear] = useState('');

    const navigate = useNavigate(); // Importa useNavigate para redireccionar

    // Función para manejar errores comunes en la validación
    const manejarErroresDeValidacion = (error) => {
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
            localStorage.removeItem('token');
            localStorage.removeItem('username');
            setError('No ha sido posible validar tus credenciales, vuelve a iniciar sesión.');
            navigate(`/InicioNoAutorizado?error=${encodeURIComponent(error.response.data.message)}`);
        } else {
            setError('No ha sido posible validar el acceso. Contacta con el administrador.');
        }
        setLoading(false);
    };

    // Función para validar el acceso al módulo
    const validarAccesoModulo = useCallback(async () => {
        try {
            const res = await axios.get(VALIDAR_MODULO_URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (res.status !== 200 || !res.data.acceso) {
                throw new Error(res.data.mensaje || 'Acceso denegado.');
            }
        } catch (error) {
            manejarErroresDeValidacion(error);
            throw error;
        }
    }, []);

    // Función para validar el permiso del usuario
    const validarPermisoUsuario = useCallback(async () => {
        try {
            const res = await axios.get(VALIDAR_PERMISO_URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (res.status !== 200 || !res.data.acceso) {
                setError('No tienes autorización para acceder a este apartado. Si crees que se trata de un error, comunícaselo al administrador.');
            }
        } catch (error) {
            manejarErroresDeValidacion(error);
            throw error;
        }
    }, []);

    // Función para obtener los datos
    const getRedad = async (selectedMonth, selectedYear) => {
        try {
            const selectedMonthNumeric = months.indexOf(selectedMonth) + 1;
            const response = await axios.get(`${URI}?month=${selectedMonthNumeric}&year=${selectedYear}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            setRedad(response.data);
            setLoading(false);
        } catch (error) {
            setError('Error al obtener los datos. Por favor, inténtalo de nuevo más tarde.');
            setLoading(false);
        }
    };

    // Uso de useEffect para validar acceso y permisos al cargar el componente
    useEffect(() => {
        const verificarAccesoYPermiso = async () => {
            try {
                await validarAccesoModulo();
                await validarPermisoUsuario();
                if (selectedMonth && selectedYear) {
                    getRedad(selectedMonth, selectedYear);
                }
            } catch (error) {
                console.error('Error de validación:', error);
            }
        };

        verificarAccesoYPermiso();
    }, [selectedMonth, selectedYear, validarAccesoModulo, validarPermisoUsuario]);

    const months = [
        'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
        'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
    ];

    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 11 }, (_, i) => currentYear - 5 + i);

    return (
        <div className="container" style={{ maxWidth: '90%', padding: '0' }}>
            <div className="mb-3">
                <label htmlFor="selectMonth" className="form-label" style={{ marginRight: '30px', fontSize: '20px !important' }}>Seleccione un mes:</label>
                <select className="form-select" id="selectMonth" value={selectedMonth} onChange={(e) => setSelectedMonth(e.target.value)}>
                    <option value="">Seleccione un mes</option>
                    {months.map((month, index) => (
                        <option key={index} value={month}>{month}</option>
                    ))}
                </select>
            </div>

            <div className="mb-3">
                <label htmlFor="selectYear" className="form-label" style={{ marginRight: '30px', fontSize: '20px !important' }}>Seleccione un año:</label>
                <select className="form-select" id="selectYear" value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)}>
                    <option value="">Seleccione un año</option>
                    {years.map((year) => (
                        <option key={year} value={year}>{year}</option>
                    ))}
                </select>
            </div>

            {loading && <p>Seleccione un mes y el año en el que desee obtener los datos</p>}
            {error && <p>{error}</p>}
            {!loading && !error && (
                <div className="row">
                    <div className="col">
                        <CardHeader>
                            <div>
                                <BotonExcelRE productos={Redad} />
                            </div>
                        </CardHeader>
                        <div className="mb-3">
                            <Link to="/CreateA" className="btn btn-primary mt-3 mb-3">
                                Crear una nueva consulta
                                <i className="fa-solid fa-file-circle-plus" style={{ marginRight: '10 px', fontSize: '20px' }}></i>
                            </Link>
                        </div>
                        <div className="text-center">
                            <h1 className="mt-4 mb-4">Informe Mensual de Atenciones AT2R</h1>
                        </div>
                        <table className="table table-striped">
                            <thead className="table-primary">
                                <tr>
                                    <th>Concepto</th>
                                    <th>General</th>
                                    <th>Especialista</th>
                                    <th>TOTAL</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Redad.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.Desc_Ran_Edad}</td>
                                        <td>{item.General}</td>
                                        <td>{item.Especialista}</td>
                                        <td>{item.Num_pac}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CompShowedad;

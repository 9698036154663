import React, { useState } from 'react';
import '../Estilos/StyleLogin.css';
import axios from 'axios';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';

Modal.setAppElement('#root');

const Login = () => {
  const [NOM_Usuario, setUsuario] = useState('');
  const [Contrasena, setContrasena] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setUsuario('');
    setContrasena('');
    setError('');
    setSuccessMessage('');
    if (successMessage) {
      navigate('/Inicio');
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async () => {
    if (!NOM_Usuario || !Contrasena) {
      setError('Por favor, completa todos los campos');
      openModal();
      return;
    }
  
    try {
      const response = await axios.post('https://cisrubenandino.com/ap/login', {
        NOM_Usuario,
        Contrasena
      });
  
      if (response.data.success) {
        // Almacenar el token JWT en el localStorage
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('username', response.data.username);
        setSuccessMessage('¡Credenciales correctas, iniciando sesión!');
        openModal();
      } else {
        setError('El usuario o contraseña no son correctos. Por favor, inténtalo de nuevo.');
        openModal();
      }
    } catch (error) {
      console.error('Error de inicio de sesión:', error);
      if (error.response && error.response.status === 401) {
        setError('El usuario o contraseña no son correctos. Por favor, inténtalo de nuevo.');
      } else if (error.response && error.response.status === 402) {
        setError('El usuario o contraseña no son correctos. Por favor, inténtalo de nuevo.');
      } else {
        setError('Ocurrió un error durante el inicio de sesión. Por favor, inténtalo de nuevo. Si el problema persiste, por favor contacta con el administrador.');
      }
      openModal();
    }
  };

  return (
    <div className="login login-background-image">
      <div className="login-form">
        <div className="login-header">
          <h2>Iniciar Sesión</h2>
        </div>
        <label className="login-label">
          Usuario:
          <input
            type="text"
            value={NOM_Usuario}
            onChange={(e) => setUsuario(e.target.value)}
            placeholder="Ingrese su usuario"
            className="login-input"
          />
        </label>
        <br />
        <div className="login-label">
          Contraseña:
          <div className="password-input-container">
            <input
              type={showPassword ? 'text' : 'password'}
              value={Contrasena}
              onChange={(e) => setContrasena(e.target.value)}
              placeholder="Ingrese su contraseña"
              className="login-input"
            />
            <button
              onClick={togglePasswordVisibility}
              style={{ marginBottom: '12px' }} // Margen negativo para mover el botón hacia arriba
              className="password-toggle-button"
            >
              {showPassword ? (
                <i className="fa-sharp fa-solid fa-eye"></i>
              ) : (
                <i className="fa-sharp fa-solid fa-eye-slash"></i>
              )}
            </button>
          </div>
        </div>
        <br />
        <button onClick={handleLogin} className="login-button">
          Iniciar Sesión
        </button>
      </div>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
          content: {
            width: 'auto',
            height: 'auto',
            maxWidth: '50%',
            maxHeight: '50%',
            margin: 'auto',
            textAlign: 'center',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          },
        }}
      >
        <div className='modal-content'>
          <h2>{successMessage ? '¡Inicio de sesión exitoso!' : 'Ups... ¡Ah ocurrido un error!'}</h2>
          <p style={{ color: successMessage ? 'green' : 'red', marginBottom: '25px' }}>
            {successMessage || error}
          </p>

          <button
            className="modal-close-button"
            onClick={closeModal}
            style={{ width: '150px', padding: '15px', fontSize: '25px', display: 'block', margin: '0 auto' }}
          >
            Aceptar
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default Login;
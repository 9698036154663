import '../../Estilos/StyleTableDatos.css';
import axios from "axios";
import React, { useState, useEffect, useCallback } from "react";
import {useNavigate} from 'react-router-dom';
import { Link } from "react-router-dom";
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css'; // Estilos de DataTables para Bootstrap 5
import $ from 'jquery'; // Importa jQuery
import 'datatables.net-bs5'; // Importa DataTables

const URI = 'https://cisrubenandino.com/ap/parametros_empresa/';
const VALIDAR_MODULO_URI = 'https://cisrubenandino.com/ap/validar-mod-PE';
const VALIDAR_PERMISO_URI = 'https://cisrubenandino.com/ap/validar-PES-';


const CompShowEmpresaParametros = () => {
    const [empresas, setEmpresaParametros] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    // Formateo para la fecha y hora
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString('es-HN', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true // Para formato 12 horas, false = 24 
        });
    };
    
    // Función para validar que se tenga acceso al modulo
    const validarAccesoModulo = useCallback(async () => {
        try {
            const res = await axios.get(VALIDAR_MODULO_URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (res.status !== 200 || !res.data.acceso) {
                throw new Error(res.data.mensaje || 'Acceso denegado.');
            }
        } catch (error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                setError('No tienes autorizacion para acceder a este apartado. Si crees que se trata de un error, comunicaselo al administrador.');
            } else {
                setError('No ha sido posible validar el acceso. Contacta con el administrador.');
            }
            setLoading(false);
            throw error;
        }
    }, []);

    // Función para validar el permiso necesario
    const validarPermisoParametrosEmpresa = useCallback(async () => {
        try {
            const res = await axios.get(VALIDAR_PERMISO_URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (res.status !== 200 || !res.data.acceso) {
                setError('No tienes autorización para acceder a este apartado. Si crees que se trata de un error, comunícaselo al administrador.');
            }
        } catch (error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                setError('No se cuenta con el acceso completo para acceder a este apartado.');
            } else {
                setError('No ha sido posible validar el acceso. Contacta con el administrador.');
            }
            setLoading(false);
            throw error;
        }
    }, []);

    const getEmpresaParametros = useCallback(async () => {
        try {
            const res = await axios.get(URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
                if (Array.isArray(res.data)) {
                    const parametros_empresaFormatted = res.data.map((parametros_empresa) => ({
                        ...parametros_empresa,
                        ULTI_Sesion: formatDate(parametros_empresa.ULTI_Sesion),
                    }));
                    setEmpresaParametros(parametros_empresaFormatted);
                } else {
                    setEmpresaParametros([]); // No hay parametros para mostrar
                }
            } catch (error) {
                // Manejo de error para los códigos de estado 401 y 403
            if (error.response) {
                if (error.response.status === 401 || error.response.status === 403) {
                    // Redirige a la página de InicioNoAutorizado
                    localStorage.removeItem('token'); // Elimina el token
                    localStorage.removeItem('username'); // Elimina el nombre de usuario
                    setError('No ha sido posible validar tus credenciales, vuelve a iniciar sesión.');
                    navigate(`/InicioNoAutorizado?error=${encodeURIComponent(error.response.data.message)}`);
                } else {
                    // Otro tipo de error
                    setError('No ha sido posible encontrar los datos. Por favor vuelve a intentar, si el problema persiste, contacta con el administrador.');
                }
            } else {
                // Error sin respuesta del servidor (puede ser de red o otro detalle)
                setError('No ha sido posible verificar los datos en este momento. Por favor, contacta con el administrador.');
            }
            } finally {
                setLoading(false);
            }
    }, [navigate]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await validarAccesoModulo(); // Valida el acceso al módulo
                await validarPermisoParametrosEmpresa(); // Valida el permiso del parametro
                await getEmpresaParametros(); // Obtiene los datos de los usuarios si se tiene acceso
            } catch (error) {
                console.error('Error durante la carga de datos:', error);
            }
        };
        fetchData();
    }, [validarAccesoModulo, validarPermisoParametrosEmpresa, getEmpresaParametros]);

    useEffect(() => {
        if ($.fn.DataTable.isDataTable('#dataTableEmpresa')) {
            $('#dataTableEmpresa').DataTable().destroy();
        }
        $('#dataTableEmpresa').DataTable();
    }, [empresas]);

    const deleteEmpresaParametros = async (ID_PRTS_Empresa) => {
        const confirmDelete = window.confirm("¿Está seguro de que desea eliminar este parametro?");
        
        if (confirmDelete) {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    throw new Error('Token no disponible. Por favor, vuelve a iniciar sesión.');
                }
                await axios.delete(`${URI}${ID_PRTS_Empresa}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                // Actualiza el estado sin recargar la página
                setEmpresaParametros(prev => prev.filter(empresa => empresa.ID_PRTS_Empresa !== ID_PRTS_Empresa));
            } catch (error) {
                console.error('Error al eliminar parametro:', error);
                setError('Error al eliminar parametro: ' + (error.response?.data?.message || error.message));
            }
        }
    };

    return (
        <div className="main-container">
            <h1 className="text-center mb-4">Parámetros de Empresa</h1>
            {loading ? (
                <p>Cargando...</p>
            ) : error ? (
                <p>{error}</p>
            ) : (
                <div className="row">
                    <div className="text-start">
                        <Link to="/createPE" className="btn btn-primary mb-3">Crear un nuevo Parametro <i className="fa-solid fa-file-circle-plus"></i></Link>
                        <div className="table-container">  {/* Contenedor con scroll */}
                            <table id="dataTableEmpresa" className="table table-striped">
                                <thead className="table">
                                    <tr>
                                        <th className="text-center">Instituciones</th>
                                        <th className="text-center">Razón Social</th>
                                        <th className="text-center">RTN</th>
                                        <th className="text-center">Dirección</th>
                                        <th className="text-center">Sucursales</th>
                                        <th className="text-center">Número Teléfono Principal</th>
                                        <th className="text-center">Red Social</th>
                                        <th className="text-center">URL Social</th>
                                        <th className="text-center">Correo Electrónico Principal</th>
                                        <th className="text-center">Opciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {empresas.length === 0 ? (
                                        <tr>
                                            <td colSpan="10">No hay parametros para mostrar, no existe o no está creado.</td>
                                        </tr>
                                    ) : (
                                        empresas.map((empresa) => (
                                            <tr key={empresa.ID_PRTS_Empresa}>
                                                <td className="text-center"> {empresa.Nombre} </td>
                                                <td className="text-center"> {empresa.RZN_Social} </td>
                                                <td className="text-center"> {empresa.RTN} </td>
                                                <td className="text-center"> {empresa.Direccion} </td>
                                                <td className="text-center"> {empresa.Sucursales || <i>ND</i>} </td>
                                                <td className="text-center"> {empresa.Num_Telefonico} </td>
                                                <td className="text-center"> {empresa.RED_SOCIAL || <i>ND</i>} </td>
                                                <td className="text-center"> {empresa.URL_RED_SOCIAL || <i>ND</i>} </td>
                                                <td className="text-center"> {empresa.CRO_ELTO_Principal || <i>ND</i>} </td>
                                                <td className="text-center">
                                                    <Link to={`/EditPE/${empresa.ID_PRTS_Empresa}`} className="btn btn-info">Editar <i className="fa-solid fa-pen-to-square"></i></Link>
                                                    <button onClick={() => deleteEmpresaParametros(empresa.ID_PRTS_Empresa)} className="btn btn-danger">Borrar <i className="fa-solid fa-trash"></i></button>
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
    
};

export default CompShowEmpresaParametros;

import React, { useState } from "react";
import { Button, Spinner } from "reactstrap";
import * as XLSX from "xlsx";

const BotonExcel = ({ productos, columns }) => {
  const [loading, setLoading] = useState(false);

  const titulo = [{ A: "Reporte de libro diario del mes" }, {}];

  const longitudes = [25, ...columns.map(() => 10)]; // Ajusta el ancho de las columnas de los días según sea necesario

  const handleDownload = () => {
    setLoading(true);

    // Define las cabeceras, incluyendo las columnas de los días
    let tabla = [
      {
        A: "Descripción",
        ...columns.reduce((acc, col, index) => {
          acc[String.fromCharCode(66 + index)] = `Día ${index + 1}`;
          return acc;
        }, {}),
        [String.fromCharCode(66 + columns.length)]: "Total del mes",
      },
    ];

    productos.forEach((producto) => {
      let fila = {
        A: producto.Desc_Ran_Edad,
        ...columns.reduce((acc, col, index) => {
          acc[String.fromCharCode(66 + index)] = producto[col] || 0;
          return acc;
        }, {}),
        [String.fromCharCode(66 + columns.length)]: producto.Total_Mes,
      };
      tabla.push(fila);
    });

    const dataFinal = [...titulo, ...tabla];

    setTimeout(() => {
      creandoArchivo(dataFinal);
      setLoading(false);
    }, 1000);
  };

  const creandoArchivo = (dataFinal) => {
    const libro = XLSX.utils.book_new();

    const hoja = XLSX.utils.json_to_sheet(dataFinal, { skipHeader: true });

    hoja["!merges"] = [
      XLSX.utils.decode_range("A1:G1"),
      XLSX.utils.decode_range("A2:G2"),
      XLSX.utils.decode_range("A34:G34"),
    ];

    let propiedades = [];

    longitudes.forEach((col) => {
      propiedades.push({
        width: col,
      });
    });

    hoja["!cols"] = propiedades;

    XLSX.utils.book_append_sheet(libro, hoja, "Reporte de libro diario");

    XLSX.writeFile(libro, "RLibroDiario.xlsx");
  };

  return (
    <>
      {!loading ? (
        <Button color="success" onClick={handleDownload}>
          Imprimir reporte
        </Button>
      ) : (
        <Button color="success" disabled>
          <Spinner size="sm">Loading...</Spinner>
          <span> Generando...</span>
        </Button>
      )}
    </>
  );
};

export default BotonExcel;

import '../../Estilos/StyleTableDatos.css';
import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import {useNavigate} from 'react-router-dom';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css'; // Estilos de DataTables
import $ from 'jquery'; // Importa jQuery
import 'datatables.net-bs5'; // Importa DataTables

const URI = 'https://cisrubenandino.com/ap/enfermerias/';
const VALIDAR_MODULO_URI = 'https://cisrubenandino.com/ap/validar-mod-e';
const VALIDAR_PERMISO_URI = 'https://cisrubenandino.com/ap/validar-ens-';

const CompShowEnfer = () => {
     
    const [enfermerias, setEnfermeria] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
 
    // Función para validar el acceso al módulo
    const validarAccesoModulo = useCallback(async () => {
        try {
            const res = await axios.get(VALIDAR_MODULO_URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (res.status !== 200 || !res.data.acceso) {
                throw new Error(res.data.mensaje || 'Acceso denegado.');
            }
        } catch (error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                setError('No tienes autorizacion para acceder a este apartado. Si crees que se trata de un error, comunicaselo al administrador.');
            } else {
                setError('2No ha sido posible validar el acceso. Contacta con el administrador.');
            }
            setLoading(false);
            throw error;
        }
    }, []);

   
     // Función para validar el permiso necesario
     const validarPermisoEnfermeria = useCallback(async () => {
        try {
            const res = await axios.get(VALIDAR_PERMISO_URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (res.status !== 200 || !res.data.acceso) {
                setError('No tienes autorización para acceder a este apartado. Si crees que se trata de un error, comunícaselo al administrador.');
            }
        } catch (error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                setError('No se cuenta con el acceso completo para acceder a este apartado.');
            } else {
                setError('No ha sido posible validar el acceso. Contacta con el administrador.');
            }
            setLoading(false);
            throw error;
        } 
    }, []);


    const getEnfermeria = useCallback(async () => {
        try {
            const res = await axios.get(URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (Array.isArray(res.data)) {
            const enfermeriaFormatted = res.data.map((enfermerias) => ({
                ...enfermerias,
                FEC_Vencimiento: formatDate(enfermerias.FEC_Vencimiento),
            }));
            setEnfermeria(enfermeriaFormatted); 
        } else {
            setEnfermeria([]); // No hay usuarios para mostrar
        }
    } catch (error) {
        // Manejo de error para los códigos de estado 401 y 403
    if (error.response) {
        if (error.response.status === 401 || error.response.status === 403) {
            // Redirige a la página de InicioNoAutorizado
            localStorage.removeItem('token'); // Elimina el token
            localStorage.removeItem('username'); // Elimina el nombre de usuario
            setError('No ha sido posible validar tus credenciales, vuelve a iniciar sesión.');
            navigate(`/InicioNoAutorizado?error=${encodeURIComponent(error.response.data.message)}`);
        } else {
            // Otro tipo de error
            setError('No ha sido posible encontrar los datos. Por favor vuelve a intentar, si el problema persiste, contacta con el administrador.');
        }
    } else {
        // Error sin respuesta del servidor (puede ser de red o otro detalle)
        setError('No ha sido posible verificar los datos en este momento. Por favor, contacta con el administrador.');
    }
    } finally {
        setLoading(false);
    }
}, [navigate]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await validarAccesoModulo(); // Valida el acceso al módulo
                await validarPermisoEnfermeria(); 
                await getEnfermeria(); 
            } catch (error) {
                console.error("Error durante la carga de datos", error);
            }
        };
        fetchData();
    }, [validarAccesoModulo, validarPermisoEnfermeria, getEnfermeria]);

    useEffect(() => {
        if ($.fn.DataTable.isDataTable('#dataTableEnfer')) {
            $('#dataTableEnfer').DataTable().destroy();
        }
        $('#dataTableEnfer').DataTable();
    }, [enfermerias]); // Vuelve a inicializar DataTables cuando cambian los datos

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        date.setDate(date.getDate() + 1);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };



    const deleteRegis = async (ID_Producto) => {
        // Mensaje de confirmación antes de eliminar el expediente
        const confirmDelete = window.confirm("¿Está seguro de que desea eliminar este producto?");
        
        if (confirmDelete) {
            try {
                // Realizar una petición DELETE a la API para desactivar el expediente utilizando el procedimiento almacenado
                await axios.delete(`${URI}${ID_Producto}` , {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });
                
                // Volver a obtener los expedientes para actualizar la lista después de la eliminación
                window.location.reload();
            } catch (error) {
                if (error.response && error.response.status === 403) {
                    setError('No tienes permiso para eliminar este parámetro. Contacta con el administrador.');
                } else {
                    console.error('Error al eliminar parametro:', error);
                    setError('Error al eliminar el parámetro. Por favor, intenta nuevamente.');
                }
            }
        }
    };

    return (
        <div className="main-container">
            {loading ? (
                <p>Cargando...</p>
            ) : error ? (
                <p>{error}</p>
            ) : (
                <div className="row">
                    <div className="col">
                        <Link to="/CreateEN" className="btn btn-primary mb-3">Crear un nuevo registro <i className="fa-solid fa-file-circle-plus"></i></Link>
                        <table id="dataTableEnfer" className="table table-striped">
                            <thead className="table-primary">
                                <tr>
                                
                                    <th className="text-center">Nombre producto</th>
                                    <th className="text-center">Especificacion</th>
                                    <th className="text-center">Tamaño</th>
                                    <th className="text-center">Capacidad</th>
                                    <th className="text-center">Tipo</th>
                                    <th className="text-center">Cantidad</th>
                                    <th className="text-center">Precio</th>
                                    <th className="text-center">Dosificacion_1</th>
                                    <th className="text-center">Dosificacion_2</th>
                                    <th className="text-center">Dosificacion_3</th>
                                    <th className="text-center">Lote</th>
                                    <th className="text-center">Fecha de vencimiento</th>
                                    <th className="text-center">Aplicadas</th>
                                    <th className="text-center">Perdidas</th>
                                    <th className="text-center">Descartadas</th>
                                    <th className="text-center">Cantidad Usada</th>
                                    <th className="text-center">Opciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {enfermerias.length === 0 ? (
                                    <tr>
                                        <td colSpan="17">No hay expedientes para mostrar, no existe o no está creado.</td>
                                    </tr>
                                ) : (
                                    enfermerias.map((enfermeria) => (
                                        <tr key={enfermeria.ID_Producto}>
                                            <td className="text-center"> {enfermeria.NOM_Producto} </td>
                                            <td className="text-center"> {enfermeria.Especificacion} </td>
                                            <td className="text-center"> {enfermeria.Tamano} </td>
                                            <td className="text-center"> {enfermeria.Capacidad} </td>
                                            <td className="text-center"> {enfermeria.Tipo} </td>
                                            <td className="text-center"> {enfermeria.Cantidad} </td>
                                            <td className="text-center"> {enfermeria.Precio} </td>
                                            <td className="text-center"> {enfermeria.Dosificacion_1} </td>
                                            <td className="text-center"> {enfermeria.Dosificacion_2} </td>
                                            <td className="text-center"> {enfermeria.Dosificacion_3} </td>
                                            <td className="text-center"> {enfermeria.Lote} </td>
                                            <td className="text-center"> {enfermeria.FEC_Vencimiento} </td>
                                            <td className="text-center"> {enfermeria.Aplicadas} </td>
                                            <td className="text-center"> {enfermeria.Perdidas} </td>
                                            <td className="text-center"> {enfermeria.Descartadas} </td>
                                            <td className="text-center"> {enfermeria.CAN_Usada} </td>
                                            <td className="text-center">
                                                <Link to={`/EditEN/${enfermeria.ID_Producto}`} className="btn btn-info">Editar <i className="fa-solid fa-pen-to-square"></i></Link>
                                                <button onClick={() => deleteRegis(enfermeria.ID_Producto)} className="btn btn-danger">Borrar <i className="fa-solid fa-trash"></i></button>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CompShowEnfer;

import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Modal from 'react-modal';
import '../../../Estilos/StyleFormulario.css';

const URI = 'https://cisrubenandino.com/ap/usuarios/';
const URI_ROLES = 'https://cisrubenandino.com/ap/ad_rol/';
const URI_PERSONAS = 'https://cisrubenandino.com/ap/ad_persona/';
const URI_PREGUNTAS = 'https://cisrubenandino.com/ap/preg/';
const VALIDAR_PERMISO_URI = 'https://cisrubenandino.com/ap/validar-Iu-';
const VALIDAR_MODULO_URI = 'https://cisrubenandino.com/ap/validar-mod-U';

const CompCreateUsuario = () => {
  const Navigate = useNavigate()
  const [roles, setRoles] = useState([]);
  const [personas, setPersonas] = useState([]);
  const [preguntas, setPreguntas] = useState([]);
  const [formData, setFormData] = useState({
    ID_Persona: '',
    NOM_Usuario: '',
    Contrasena: '',
    ID_Rol: '',
    Correo: '',
    ID_Pregunta_1: '',
    RESP_Seguridad_1: '',
    ID_Pregunta_2: '',
    RESP_Seguridad_2: '',
    ID_Pregunta_3: '',
    RESP_Seguridad_3: '',
    Usr_Registro: localStorage.getItem('username') || '', //Usuario logeado
});
// Estado para errores
const [errors, setErrors] = useState({});
const [warnings, setWarnings] = useState({});
const [error, setError] = useState(null);
const [loading, setLoading] = useState(true);
// Estado para mensajes de modal
const [modalIsOpen, setModalIsOpen] = useState(false);
const [modalMessage, setModalMessage] = useState('');
const [redirectAfterClose, setRedirectAfterClose] = useState(false);

// Funciónes del modal
const openModal = (message, redirect = false) => {
  setModalMessage(message);
  setModalIsOpen(true);
  setRedirectAfterClose(redirect);
};
const closeModal = () => {
  setModalIsOpen(false);
  if (redirectAfterClose) {
    Navigate('/AdmUsers');
}
};

// Función para validar que se tenga acceso al modulo
const validarAccesoModulo = useCallback(async () => {
    try {
        const res = await axios.get(VALIDAR_MODULO_URI, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        });

        if (res.status !== 200 || !res.data.acceso) {
            throw new Error(res.data.mensaje || 'Acceso denegado.');
        }
    } catch (error) {
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
            setError('No tienes autorizacion para acceder a este apartado. Si crees que se trata de un error, comunicaselo al administrador.');
        } else {
            setError('No ha sido posible validar el acceso. Contacta con el administrador.');
        }
        setLoading(false);
        throw error;
    }
}, []);

// Función para validar permisos del usuario
const validarPermiso = useCallback(async () => {
    try {
      const res = await axios.get(VALIDAR_PERMISO_URI, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (res.status !== 200 || !res.data.acceso) {
        throw new Error(res.data.mensaje || 'Permiso denegado.');
      }
    } catch (error) {
      setError('No tienes autorización para realizar esta acción. Si crees que se trata de un error, comunícalo al administrador.');
      setLoading(false);
      throw error;
    }
  }, []);

// Función para cargar roles, personas y preguntas
const cargarDatosIniciales = useCallback(async () => {
    try {
      const rolesResponse = await axios.get(URI_ROLES, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
      });

      const personasResponse = await axios.get(`${URI_PERSONAS}nd-usuario`, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
      });

      const preguntasResponse = await axios.get(URI_PREGUNTAS, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
      });

      setRoles(rolesResponse.data);
      if (personasResponse.data.message) {
        setPersonas([]);
      } else {
        setPersonas(personasResponse.data);
      }
      setPreguntas(preguntasResponse.data);
    } catch (error) {
      console.error('Error al cargar los datos:', error);
    }
  }, []);

// UseEffect para validar acceso, permisos y cargar datos
useEffect(() => {
    const fetchData = async () => {
      try {
        await validarAccesoModulo(); // Valida el acceso al módulo
        await validarPermiso(); // Valida el permiso del usuario
        await cargarDatosIniciales(); // Carga roles, personas y preguntas si se tiene acceso
      } catch (error) {
        console.error('Error durante la carga de datos:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [validarAccesoModulo, validarPermiso, cargarDatosIniciales]);

  // Filtra las preguntas ya seleccionadas
  const getFilteredPreguntas = (currentField) => {
    // Obtiene los IDs de las preguntas seleccionadas
    const selectedIds = [
        formData.ID_Pregunta_1,
        formData.ID_Pregunta_2,
        formData.ID_Pregunta_3
    ].filter(id => id && id !== currentField); // Excluye el ID del campo actual


    // Filtra las preguntas disponibles excluyendo las seleccionadas
    return preguntas.filter(pregunta => !selectedIds.includes(pregunta.ID_Pregunta.toString()));


};
// Función para manejar los cambios en los campos de entrada
const handleChange = (e) => {
    const { name, value } = e.target;
    // Actualizar formData con el valor del campo que ha cambiado
    setFormData((prevData) => ({
        ...prevData,
        [name]: value
    }));

    // Llamar a la función validateField para validar el campo que acaba de cambiar
    validateField(name, value);
};

//valida que el nombre de usuario no exista
const validateUniqueUsername = async (username) => {
    try {
        const token = localStorage.getItem('token'); // Obtén el token del almacenamiento local
        const response = await fetch(`${URI}?username=${encodeURIComponent(username)}`, {
            headers: {
                'Authorization': `Bearer ${token}`, // Agrega el token en los headers
            }
        });

        if (!response.ok) {
            throw new Error('Error en la respuesta del servidor');
        }
        const data = await response.json();
        
        return !data.some(user => user.NOM_Usuario === username);
    } catch (error) {
        console.error('Error checking username:', error);
        // Propagar el error para manejarlo en la función que llama a esta función
        throw new Error('Un problema impide validar el nombre de usuario. impidiendo continuar. Contacta con el administrador y notificale el problema.');
    }
};

// Función para validar un campo específico
 const validateField = async (name, value) => {
    let error = [];
    let warning = [];
    // Expresiones regulares comunes
    const noLeadingOrTrailingSpaces = /^(?!\s).*.*(?<!\s)$/; //no permite los valores tengan espacios iniciales o finales
    const noMultipleSpaces = /^(?!.*\s{2}).*$/; // Permite asegurar que no haya múltiples espacios consecutivos
    // Verificación de la variedad de caracteres
    const hasLetters = /[a-zA-Z]/.test(value);
    const hasNumbers = /\d/.test(value);
    const repetitivePattern = /^(.)\1{4,}$/; // 5 o más caracteres repetidos  Verifica secuencias repetitivas
    const sequentialNumbers = /01234|12345|23456|34567|45678|56789/; // Verifica secuencias numéricas repetitivas
    // Verifica secuencias repetitivas de caracteres especiales
    const repetitiveSpecialChars = /^(.)\1{4,}$/; // 5 o más caracteres especiales repetidos
    const specialChars = /[!@#$%^&*(),.?":{}|<>]/;
    const sequentialLetters = /aaaa|bbbb|cccc|dddd|eeee|ffff|gggg|hhhh|iiii|jjjj|kkkk|llll|mmmm|nnnn|oooo|pppp|qqqq|rrrr|ssss|tttt|uuuu|vvvv|wwww|xxxx|yyyy|zzzz/;     // Verifica secuencias de letras repetitivas
    const hasSpecialChars = /[!@#$%^&*(),.?":{}|<>]/.test(value);
    const commonPasswords = ['123456', '12345', 'password', '123456789', 'contraseña', 'CONTRASEÑA', 'qwerty']; //verifica algunas contraseñas demasiado comunes
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;  // Expresión regular para validar el formato del correo electrónico

    switch (name) {
        case 'ID_Persona':
            if (!value.trim()) {
                error.push('La persona a asignar el usuario es obligatorio.');
            }
            break;
        case 'NOM_Usuario':
            if (!value.trim()) {
                error.push ('El Nombre de usuario es obligatorio, no puede estar vacio.');
            } else {
                if (value.length > 20) {
                error.push ('El Nombre de usuario es muy largo, no puede tener mas de 20 letras.');
            } if (value.length < 4) {
                error.push ('El Nombre de usuario es muy corto.');
            } if (!noLeadingOrTrailingSpaces.test(value)) {
                error.push ('El Nombre de usuario no debe comenzar o terminar con espacios.');
            } if (!noMultipleSpaces.test(value)) {
                error.push ('No puede haber múltiples espacios consecutivos.');
            } if (/([a-zA-ZáéíóúÁÉÍÓÚñÑ])\1{2,}/.test(value)) {
                error.push ('No puede haber más de 2 caracteres repetidos consecutivamente.');
            } if (/[^a-zA-ZáéíóúÁÉÍÓÚñÑ\s]/.test(value)) {
                error.push('El Nombre de usuario solo puede contener letras.');
            }
            try {
                // Validar si el nombre de usuario esta disponible
                const isUnique = await validateUniqueUsername(value.trim());
                if (!isUnique) {
                    error.push('El Nombre de usuario ya está en uso.');
                }
            } catch (error) {
                // Si ocurre un error en la validación del nombre de usuario
                error.push(error.message); // Mensaje de error propaga desde la función validateUniqueUsername
            }
        }
            break;
        case 'Contrasena':
            if (!value.trim()) {
                error.push ('La contraseña es obligatoria, no puede quedar vacio.');
            } else {
                if (value.length > 30) {
                error.push ('la contraseña es muy larga y sera dicifil de recordar, usa solo 30 caracteres.');
            } if (value.length < 8) {
                error.push ('La contraseña es muy corta minimo 8 caracteres.');
            } if (!noLeadingOrTrailingSpaces.test(value)) {
                error.push ('La contraseña no debe comenzar o terminar con espacios.');
            } if (!noMultipleSpaces.test(value)) {
                error.push ('No puede haber múltiples espacios consecutivos.');
            } if (/([a-zA-ZáéíóúÁÉÍÓÚñÑ])\1{2,}/.test(value)) {
                warning.push('Se ha detectado que tu contraseña contiene más de 2 caracteres repetidos consecutivamente. Para una mayor seguridad, evita repetir el mismo caracter muchas veces.');
            } if (!hasLetters || !hasNumbers || !hasSpecialChars) {
                warning.push('Para una contraseña mas segura, usa una combinación variada y no muy repetitiva de letras mayusculas y minusculas, números y símbolos en tu contraseña.');
            } if (commonPasswords.includes(value)) {
                error.push('La contraseña es demasiado común, por favor elige una más segura y evita usar contraseñas comunes.');
            } if (repetitivePattern.test(value)) {
                error.push('La contraseña no debe consistir en un solo carácter repetido, esto provoca que la contraseña sea demasiado predecible.');
            } if (sequentialNumbers.test(value)) {
                error.push('La contraseña no debe contener secuencias numéricas consecutivas, esto provoca que la contraseña sea demasiado predecible.');
            } if (sequentialLetters.test(value)) {
                error.push('La contraseña no debe contener secuencias de letras repetitivas, esto provoca que la contraseña sea demasiado predecible.');
            } if (specialChars.test(value) && repetitiveSpecialChars.test(value)) {
                error.push('La contraseña no debe contener solo caracteres especiales repetidos.');
            } if (specialChars.test(value) && /(\W)\1{4,}/.test(value)) {
                error.push('La contraseña no debe contener solo caracteres especiales repetidos.');
            }
        }
            break;
        case 'ID_Rol':
            if (!value.trim()) {
                warning.push ('No se ha asignado un rol, esto limitara a este usuario. Verifica si es correcto que el usuario no debe tener rol en estos momentos.');
            }
            break;
        case 'Correo':
            if (!value.trim()) {
                error.push ('El correo electronico es obligatorio.');
            } else {
                if (!emailRegex.test(value)) {
                error.push('El correo electrónico no tiene un formato válido.');
            } if (!noMultipleSpaces.test(value)) {
                error.push('No puede haber múltiples espacios consecutivos.');
            }
        }
            break;
        case 'ID_Pregunta_1':
        case 'ID_Pregunta_2':
        case 'ID_Pregunta_3':
            if (!value.trim()) {
                error.push ('La pregunta de seguridad es obligatoria.');
            }
            break;
        case 'RESP_Seguridad_1':
        case 'RESP_Seguridad_2':
        case 'RESP_Seguridad_3':
            if (!value.trim()) {
                error.push ('La respuesta de seguridad es obligatoria.');
            } else {
                if (value.length > 100) {
                error.push ('La respuesta es muy larga, no puede tener mas de 100 caracteres.');
            } if (value.length < 4) {
                error.push ('La respuesta es muy corta');
            } if (!noLeadingOrTrailingSpaces.test(value)) {
                error.push ('La respuesta no debe comenzar o terminar con espacios.');
            } if (!noMultipleSpaces.test(value)) {
                error.push ('No puede haber múltiples espacios consecutivos.');
            } if (/([a-zA-ZáéíóúÁÉÍÓÚñÑ])\1{2,}/.test(value)) {
                warning.push ('No es recomendable tener más de 2 caracteres repetidos consecutivamente.');
            } if (!/[a-zA-Z]/.test(value) || !/\d/.test(value) || !/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
                warning.push('Para mayor seguridad y si es posible, usa una combinación de letras, números y símbolos en tu respuesta para hacerla mas unica.');
            } if (/^(password|123456|qwerty|abc123)$/i.test(value)) {
                error.push('La respuesta de seguridad es demasiado común. Elige una respuesta más segura.');
            } if (repetitivePattern.test(value)) {
                error.push('La respuesta de seguridad no debe consistir en un solo carácter repetido, esto provoca que sea demasiado predecible.');
            } if (sequentialNumbers.test(value)) {
                error.push('La respuesta de seguridad no debe contener secuencias numéricas consecutivas, esto provoca que sea demasiado predecible.');
            } if (sequentialLetters.test(value)) {
                error.push('La respuesta de seguridad no debe contener secuencias de letras repetitivas, esto provoca que sea demasiado predecible.');
            } if (specialChars.test(value) && repetitiveSpecialChars.test(value)) {
                error.push('La respuesta de seguridad no debe contener solo caracteres especiales repetidos.');
            } if (specialChars.test(value) && /(\W)\1{4,}/.test(value)) {
                error.push('La respuesta de seguridad no debe contener solo caracteres especiales repetidos.');
            }
        }
            break;
        default:
            break;
    }
  // Actualiza el objeto de estado de errores y advertencia con el resultado de la validación
  setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error.length > 0 ? error.join(', ') : '', // Concatenar todos los mensajes de error
  }));
  setWarnings((prevWarnings) => ({
    ...prevWarnings,
    [name]: warning.length > 0 ? warning.join(', ') : '', // Concatenar todas las advertencias
}));
};

// Función para validar todos los campos antes de enviar el formulario
const validateAllFields = () => {
  Object.keys(formData).forEach((field) => validateField(field, formData[field]));
  return !Object.values(errors).some((error) => error !== '');
};

// Función para limpiar todos los campos y errores
const clearInputs = () => {
  setFormData({
    NOM_Usuario: '',
    ID_Persona: '',
    ID_Rol: '',
    Contrasena: '',
    Correo: '',
    ID_Pregunta_1: '',
    ID_Pregunta_2: '',
    ID_Pregunta_3: '',
    RESP_Seguridad_1: '',
    RESP_Seguridad_2: '',
    RESP_Seguridad_3: '',
    Usr_Registro: localStorage.getItem('username') || '',
  });
  setErrors({});
  setWarnings({});
};

// Función para cancelar y volver a la vista anterior
const handleCancel = () => {
  clearInputs();
  Navigate('/AdmUsers');
};

// Lista de campos obligatorios
const requiredFields = [
    'ID_Persona',
    'NOM_Usuario',
    'Contrasena',
    'ID_Rol',
    'Correo',
    'ID_Pregunta_1',
    'ID_Pregunta_2',
    'ID_Pregunta_3',
    'RESP_Seguridad_1',
    'RESP_Seguridad_2',
    'RESP_Seguridad_3'
];

// Función para validar los campos obligatorios
const validateRequiredFields = () => {
    let hasError = false;
    const newErrors = {};

    requiredFields.forEach((field) => {
        const value = formData[field];
        if (!value.trim()) {
            // Si el campo está vacío, actualiza el estado de errores con un mensaje de error
            newErrors[field] = `El campo es obligatorio.`;
            hasError = true;
        }
    });
    setErrors((prevErrors) => ({
        ...prevErrors,
        ...newErrors
    }));
    return !hasError;
};

// Procedimiento para guardar o registrar los datos del formulario
const crear = async (e) => {
  e.preventDefault();

try {

// Validar el acceso al módulo
await validarAccesoModulo();

// Validar el permiso del usuario para realizar la acción de creación
await validarPermiso();
  
// Validar los campos obligatorios
const areRequiredFieldsValid = validateRequiredFields();

const allFieldsFilled = requiredFields.every(
    (field) => formData[field] && formData[field].trim() !== ''
);

// Verificar que los campos obligatorios estén llenos
if (!allFieldsFilled) {
    openModal('Los datos son insuficientes. Por favor, completa todos los campos obligatorios para continuar.');
    return;
}
if (!areRequiredFieldsValid) {
    openModal('Los campos obligatorios deben completarse antes de poder continuar.');
    return;
}

  // Crear el usuario pero antes Validar que no haya errores en todos los campos
  const isValid = validateAllFields();
  if (!isValid) {
      openModal('Se han detectado uno o mas errores, por favor corrigelos antes de poder continuar.');
      return;
  }

  // Enviar los datos si todas la validaciones fueron correctas
  await axios.post(URI, formData, {
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
});

// Mostrar mensaje de éxito y limpiar el formulario
openModal('¡Se ha creado el usuario correctamente!', true);
clearInputs();

} catch (error) {
  console.error('Error:', error);
  openModal(error.message || '¡Ocurrió un error y no se han podido registrar los datos, inténtalo de nuevo! Si el problema persiste, por favor contacta con el administrador.');
}
};

// Muestra un indicador de carga mientras se obtienen los datos
if (loading) {
    return (
        <div className="loading-container">
            <p>Cargando datos... Por favor, espera.</p>
        </div>
    );
}

// Muestra un mensaje de error en caso de que ocurra
if (error) {
    return (
        <div className="error-container">
            <p>{error}</p>
            <button className="btn btn-secondary" onClick={handleCancel}>Volver a inicio</button>
        </div>
    );
}

return (
    <div>
      <div className="titulo-container">
        <h3>CREAR NUEVO USUARIO</h3>
      </div>
      <div className="formulario-container">
        <form onSubmit={crear}>
    <div className="mb-3">
        <label className="form-label">Persona a asignar el usuario</label>
                {personas.length === 0 ? (
                    <p>Todas las personas ya tienen un usuario asignado por lo que no se podra continuar. Por favor, cree una nueva persona primero.</p>
                ) : (
                    <select
                        name="ID_Persona"
                        value={formData.ID_Persona}
                        onChange={handleChange}
                        className={`form-control ${errors.ID_Persona ? 'is-invalid' : warnings.ID_Persona ? 'is-warning' : ''}`}
                    >
                        <option value="">Selecciona una persona →</option>
                        {personas.map((persona) => (
                            <option key={persona.ID_Persona} value={persona.ID_Persona}>
                                {persona.NOM_Persona}
                            </option>
                        ))}
                    </select>
                )}
                {errors.ID_Persona && <div className="error-feedback">{errors.ID_Persona}</div>}
                {warnings.ID_Persona && <div className="warning-feedback">{warnings.ID_Persona}</div>}
            </div>
        <div className="mb-3">
              <label className="form-label">Nombre de Usuario</label>
              <input
                    name="NOM_Usuario" // Añade el atributo name
                    value={formData.NOM_Usuario}
                    onChange={handleChange}
                    type="text"
                    placeholder="Ejemplo: MLopez, ClaudiaAP, JTorres, se distinguen mayusculas y minusculas ej: Jlopez, no es lo mismo a jlopez (Este campo es obligatorio)"
                    className={`form-control ${errors.NOM_Usuario ? 'is-invalid' : warnings.NOM_Usuario ? 'is-warning' : ''}`}
              />
              {errors.NOM_Usuario && (
                  <div className="error-feedback">{errors.NOM_Usuario}</div>
              )}
              {warnings.NOM_Usuario && (
                  <div className="warning-feedback">{warnings.NOM_Usuario}</div>
                )}
          </div>
          <div className="mb-3">
              <label className="form-label">Contraseña</label>
              <input
                    name="Contrasena" // Añade el atributo name
                    value={formData.Contrasena}
                    onChange={handleChange}
                    type="text"
                    placeholder="Ejemplo: jkoplñ90@, 01_@djfoeoj. Evita usar contraseñas comunes y erroneas: 1234, contraseña, 123456789 (Este campo es obligatorio)"
                    className={`form-control ${errors.Contrasena ? 'is-invalid' : warnings.Contrasena ? 'is-warning' : ''}`}
              />
              {errors.Contrasena && (<div className="error-feedback">{errors.Contrasena}</div>)}
              {warnings.Contrasena && (<div className="warning-feedback">{warnings.Contrasena}</div>)}
          </div>
          <div className="mb-3">
            <label className="form-label">Rol del usuario</label>
            <select
                name="ID_Rol"
                value={formData.ID_Rol}
                onChange={handleChange}
                className={`form-control ${errors.ID_Rol ? 'is-invalid' : warnings.ID_Rol ? 'is-warning' : ''}`}
            >
            <option value="">Selecciona un rol →</option>
                {roles.map((rol) => (
                    <option key={rol.ID_Rol} value={rol.ID_Rol}>
                        {rol.NOM_Rol}  {/* Mostrar el nombre del rol */}
                    </option>
                ))}
            </select>
                {errors.ID_Rol && <div className="error-feedback">{errors.ID_Rol}</div>}
                {warnings.ID_Rol && <div className="warning-feedback">{warnings.ID_Rol}</div>}
        </div>
        <div className="mb-3">
              <label className="form-label">Correo electrónico</label>
              <input
                    name="Correo" // Añade el atributo name
                    value={formData.Correo}
                    onChange={handleChange}
                    type="text"
                    placeholder="Ingresa un correo valido y operativo ejemplo: JuanLopez89@gmail.com (Este campo es obligatorio)"
                    className={`form-control ${errors.Correo ? 'is-invalid' : warnings.Correo ? 'is-warning' : ''}`}
              />
              {errors.Correo && (
                  <div className="error-feedback">{errors.Correo}</div>
              )}
              {warnings.Correo && (
                  <div className="warning-feedback">{warnings.Correo}</div>
                )}
          </div>
          <div className="mb-3">
            <label className="form-label">Pregunta de Seguridad 1</label>
            <select
                name="ID_Pregunta_1"
                value={formData.ID_Pregunta_1}
                onChange={handleChange}
                className={`form-control ${errors.ID_Pregunta_1 ? 'is-invalid' : warnings.ID_Pregunta_1 ? 'is-warning' : ''}`}
            >
            <option value="">Selecciona una pregunta →</option>
                {getFilteredPreguntas(formData.ID_Pregunta_1).map(pregunta => (
                    <option key={pregunta.ID_Pregunta} value={pregunta.ID_Pregunta}>
                        {pregunta.PREG_Seguridad}  {/* Mostrar la pregunta */}
                    </option>
                ))}
            </select>
                {errors.ID_Pregunta_1 && <div className="error-feedback">{errors.ID_Pregunta_1}</div>}
                {warnings.ID_Pregunta_1 && <div className="warning-feedback">{warnings.ID_Pregunta_1}</div>}
        </div>
          <div className="mb-3">
              <label className="form-label">Respuesta de Seguridad 1</label>
              <input
                    name="RESP_Seguridad_1" // Añade el atributo name
                    value={formData.RESP_Seguridad_1}
                    onChange={handleChange}
                    type="text"
                    placeholder="Ingresa la respuesta a la pregunta seleccionada (Primero selecciona la pregunta, este campo es obligatorio)"
                    className={`form-control ${errors.RESP_Seguridad_1 ? 'is-invalid' : warnings.RESP_Seguridad_1 ? 'is-warning' : ''}`}
                    disabled={!formData.ID_Pregunta_1} // Deshabilita el input si no hay pregunta seleccionada
              />
              {errors.RESP_Seguridad_1 && (
                  <div className="error-feedback">{errors.RESP_Seguridad_1}</div>
              )}
              {warnings.RESP_Seguridad_1 && (
                  <div className="warning-feedback">{warnings.RESP_Seguridad_1}</div>
                )}
          </div>
          <div className="mb-3">
            <label className="form-label">Pregunta de Seguridad 2</label>
            <select
                name="ID_Pregunta_2"
                value={formData.ID_Pregunta_2}
                onChange={handleChange}
                className={`form-control ${errors.ID_Pregunta_2 ? 'is-invalid' : warnings.ID_Pregunta_2 ? 'is-warning' : ''}`}
            >
            <option value="">Selecciona una pregunta →</option>
                {getFilteredPreguntas(formData.ID_Pregunta_2).map(pregunta => (
                    <option key={pregunta.ID_Pregunta} value={pregunta.ID_Pregunta}>
                        {pregunta.PREG_Seguridad}  {/* Mostrar la pregunta */}
                    </option>
                ))}
            </select>
                {errors.ID_Pregunta_2 && <div className="error-feedback">{errors.ID_Pregunta_2}</div>}
                {warnings.ID_Pregunta_2 && <div className="warning-feedback">{warnings.ID_Pregunta_2}</div>}
        </div>
          <div className="mb-3">
              <label className="form-label">Respuesta de Seguridad 2</label>
              <input
                    name="RESP_Seguridad_2" // Añade el atributo name
                    value={formData.RESP_Seguridad_2}
                    onChange={handleChange}
                    type="text"
                    placeholder="Ingresa la respuesta a la pregunta seleccionada (Primero selecciona la pregunta, este campo es obligatorio)"
                    className={`form-control ${errors.RESP_Seguridad_2 ? 'is-invalid' : warnings.RESP_Seguridad_2 ? 'is-warning' : ''}`}
                    disabled={!formData.ID_Pregunta_2} // Deshabilita el input si no hay pregunta seleccionada
              />
              {errors.RESP_Seguridad_2 && (
                  <div className="error-feedback">{errors.RESP_Seguridad_2}</div>
              )}
              {warnings.RESP_Seguridad_2 && (
                  <div className="warning-feedback">{warnings.RESP_Seguridad_2}</div>
                )}
          </div>
          <div className="mb-3">
            <label className="form-label">Pregunta de Seguridad 3</label>
            <select
                name="ID_Pregunta_3"
                value={formData.ID_Pregunta_3}
                onChange={handleChange}
                className={`form-control ${errors.ID_Pregunta_3 ? 'is-invalid' : warnings.ID_Pregunta_3 ? 'is-warning' : ''}`}
            >
            <option value="">Selecciona una pregunta →</option>
                {getFilteredPreguntas(formData.ID_Pregunta_3).map(pregunta => (
                    <option key={pregunta.ID_Pregunta} value={pregunta.ID_Pregunta}>
                        {pregunta.PREG_Seguridad}  {/* Mostrar la pregunta */}
                    </option>
                ))}
            </select>
                {errors.ID_Pregunta_3 && <div className="error-feedback">{errors.ID_Pregunta_3}</div>}
                {warnings.ID_Pregunta_3 && <div className="warning-feedback">{warnings.ID_Pregunta_3}</div>}
        </div>
          <div className="mb-3">
              <label className="form-label">Respuesta de Seguridad 3</label>
              <input
                    name="RESP_Seguridad_3" // Añade el atributo name
                    value={formData.RESP_Seguridad_3}
                    onChange={handleChange}
                    type="text"
                    placeholder="Ingresa la respuesta a la pregunta seleccionada (Primero selecciona la pregunta, este campo es obligatorio)"
                    className={`form-control ${errors.RESP_Seguridad_3 ? 'is-invalid' : warnings.RESP_Seguridad_3 ? 'is-warning' : ''}`}
                    disabled={!formData.ID_Pregunta_3} // Deshabilita el input si no hay pregunta seleccionada
              />
              {errors.RESP_Seguridad_3 && (
                  <div className="error-feedback">{errors.RESP_Seguridad_3}</div>
              )}
              {warnings.RESP_Seguridad_3 && (
                  <div className="warning-feedback">{warnings.RESP_Seguridad_3}</div>
                )}
          </div>
            <button type="submit" className="btn btn-success"> Guardar Usuario <i className="fa-solid fa-user-plus"></i> </button>
            <button type="button" className="btn btn-primary" onClick={clearInputs}>Limpiar los datos <i className="fa-solid fa-paint-roller"></i></button>
            <button type="button" className="btn btn-danger" onClick={handleCancel}>Cancelar y regresar <i className="fa-solid fa-rotate-left"></i></button>
        </form>
      </div>

      {/* Modal para mostrar mensajes */}
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} className="custom-modal" overlayClassName="custom-modal-overlay">
        <div className="modal-content">
          <h3>{modalMessage}</h3>
          <button className="btn btn-primary" onClick={closeModal}>Cerrar</button>
        </div>
      </Modal>
    </div>
  );
};
                
export default CompCreateUsuario
import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Modal from 'react-modal';
import '../../Estilos/StyleFormulario.css';

const URI = 'https://cisrubenandino.com/ap/control_inventario/'
const VALIDAR_PERMISO_URI = 'https://cisrubenandino.com/ap/validar-II-';
const VALIDAR_MODULO_URI = 'https://cisrubenandino.com/ap/validar-mod-I';

const CompCreateInventario = () => {
  const Navigate = useNavigate()
  const [formData, setFormData] = useState({
    NUM_FIC_CONTROL_INVENTARIO: '',
    DESCRIPCION: '',
    MARCA: '',
    NUM_SER_CONTROL_INVENTARIO: '',
    NUM_INV_ANT_CONTROL_INVENTARIO: '',
    COLOR: '',
    COST_ADQ_CONTROL_INVENTARIO: '',
   
    USR_REGISTRO: localStorage.getItem('username') || '' //Usuario logeado
});
// Estado para errores
const [errors, setErrors] = useState({});
const [warnings, setWarnings] = useState({});
const [error, setError] = useState(null);
const [loading, setLoading] = useState(true);
// Estado para mensajes de modal
const [modalIsOpen, setModalIsOpen] = useState(false);
const [modalMessage, setModalMessage] = useState('');

// Funciónes del modal
const openModal = (message) => {
  setModalMessage(message);
  setModalIsOpen(true);
};
const closeModal = () => {
  setModalIsOpen(false);
};

// Función para validar que se tenga acceso al modulo
const validarAccesoModulo = useCallback(async () => {
    try {
        const res = await axios.get(VALIDAR_MODULO_URI, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        });

        if (res.status !== 200 || !res.data.acceso) {
            throw new Error(res.data.mensaje || 'Acceso denegado.');
        }
    } catch (error) {
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
            setError('No tienes autorizacion para acceder a este apartado. Si crees que se trata de un error, comunicaselo al administrador.');
        } else {
            setError('No ha sido posible validar el acceso. Contacta con el administrador.');
        }
        setLoading(false);
        throw error;
    }
}, []);

// Función para validar permisos del usuario
const validarPermisoControlInventarioRoutes = useCallback(async () => {
    try {
      const res = await axios.get(VALIDAR_PERMISO_URI, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (res.status !== 200 || !res.data.acceso) {
        throw new Error(res.data.mensaje || 'Permiso denegado.');
      }
    } catch (error) {
      setError('No tienes autorización para realizar esta acción. Si crees que se trata de un error, comunícalo al administrador.');
      setLoading(false);
      throw error;
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await validarAccesoModulo(); // Valida el acceso al módulo
        await validarPermisoControlInventarioRoutes(); // Valida el permiso del usuario
      } catch (error) {
        console.error('Error durante la carga de datos:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [validarAccesoModulo, validarPermisoControlInventarioRoutes]);



// Función para manejar los cambios en los campos de entrada
const handleChange = (e) => {
    const { name, value } = e.target;
    // Actualizar formData con el valor del campo que ha cambiado
    setFormData((prevData) => ({
        ...prevData,
        [name]: value
    }));
    // Llamar a la función validateField para validar el campo que acaba de cambiar
    validateField(name, value);
};
// Función para validar un campo específico
const validateField = (name, value) => {
    let error = ''; // Inicializa el mensaje de error
    let warning = ''; // Inicializa el mensaje de advertencia

    // Expresiones regulares comunes
    const onlyNumbersPattern = /^\d+$/;
  
    switch (name) {
        case 'NUM_FIC_CONTROL_INVENTARIO':
            if (!value.trim()) {
                error = 'El número de ficha es obligatorio.';
            } else if (!onlyNumbersPattern.test(value)) {
                error = 'El número de ficha solo puede contener números.';
            } else if (parseInt(value, 10) < 1) {
                error = 'El número de ficha debe ser mayor o igual a 1.';
            } else if (value.length > 20) {
                error = 'El número de ficha no debe exceder los 20 dígitos.';
            }
            break;
        case 'DESCRIPCION':
            if (!value.trim()) {
                error = 'No se han detectado datos, la descripcion es obligatorio.';
          } else {
              // Verificar si hay un espacio al principio o al final
              if (/^\s/.test(value) || /\s$/.test(value)) {
                  error = 'El campo no puede comenzar o terminar con un espacio. Por favor, elimina los espacios.';
              } else if (/\s{2,}/.test(value)) {
                  error = 'El campo no puede contener múltiples espacios consecutivos. Por favor, elimina los espacios extra.';
              } else if (value.length > 255) {
                  error = 'El campo no debe superar los 255 caracteres. Reduce la cantidad de palabras.';
              }
          }
            break;
       case 'MARCA':
            if (!value.trim()) {
                warning = 'No se han detectado datos, verificar si este campo debe estar vacío.';
          } else {
              // Verificar si hay un espacio al principio o al final
              if (/^\s/.test(value) || /\s$/.test(value)) {
                  error = 'El campo no puede comenzar o terminar con un espacio. Por favor, elimina los espacios.';
              } else if (/\s{2,}/.test(value)) {
                  error = 'El campo no puede contener múltiples espacios consecutivos. Por favor, elimina los espacios extra.';
              } else if (value.length > 255) {
                  error = 'El campo no debe superar los 255 caracteres. Reduce la cantidad de palabras.';
              }
          }
            break;
       case 'NUM_SER_CONTROL_INVENTARIO':
            if (!value.trim()) {
                error = 'El número de serie es obligatorio.';
            } else if (!onlyNumbersPattern.test(value)) {
                error = 'El número de serie solo puede contener números.';
            } else if (parseInt(value, 10) < 1) {
                error = 'El número de serie debe ser mayor o igual a 1.';
            } else if (value.length > 20) {
                error = 'El número de serie no debe exceder los 20 dígitos.';
            }
            break;
       case 'NUM_INV_ANT_CONTROL_INVENTARIO':
            if (!value.trim()) {
                error = 'El número de inventario anterior es obligatorio.';
            } else if (!onlyNumbersPattern.test(value)) {
                error = 'El número de inventario anterior solo puede contener números.';
            } else if (parseInt(value, 10) < 1) {
                error = 'El número de inventario anterior debe ser mayor o igual a 1.';
            } else if (value.length > 20) {
                error = 'El número de inventario anterior no debe exceder los 20 dígitos.';
            }
            break;
       case 'COLOR':
            if (!value.trim()) {
                warning = 'No se han detectado datos, verificar si este campo debe estar vacío.';
          } else {
              // Verificar si hay un espacio al principio o al final
              if (/^\s/.test(value) || /\s$/.test(value)) {
                  error = 'El campo no puede comenzar o terminar con un espacio. Por favor, elimina los espacios.';
              } else if (/\s{2,}/.test(value)) {
                  error = 'El campo no puede contener múltiples espacios consecutivos. Por favor, elimina los espacios extra.';
              } else if (value.length > 255) {
                  error = 'El campo no debe superar los 255 caracteres. Reduce la cantidad de palabras.';
              }
          }
            break;
       case 'COST_ADQ_CONTROL_INVENTARIO':
            if (!value.trim()) {
                error = 'El costo adquirido es obligatorio.';
            } else if (!onlyNumbersPattern.test(value)) {
                error = 'El costo adquirido solo puede contener números.';
            } else if (parseInt(value, 10) < 1) {
                error = 'El costo adquirido debe ser mayor o igual a 1.';
            } else if (value.length > 20) {
                error = 'El costo adquirido no debe exceder los 20 dígitos.';
            }
            break;
      
      default:
            break;
    }
 // Actualiza el objeto de estado de errores y advertencia con el resultado de la validación
  setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
  }));
  setWarnings((prevWarnings) => ({
    ...prevWarnings,
    [name]: warning,
}));
};

// Función para validar todos los campos antes de enviar el formulario
const validateAllFields = () => {
  Object.keys(formData).forEach((field) => validateField(field, formData[field]));
  return !Object.values(errors).some((error) => error !== '');
};

// Función para limpiar todos los campos y errores
const clearInputs = () => {
  setFormData({
      NUM_FIC_CONTROL_INVENTARIO: '',
      DESCRIPCION: '',
      MARCA: '',
      NUM_SER_CONTROL_INVENTARIO: '',
      NUM_INV_ANT_CONTROL_INVENTARIO: '',
      COLOR: '',
      COST_ADQ_CONTROL_INVENTARIO: '',
      USR_REGISTRO: localStorage.getItem('username') || ''
  });
  setErrors({});
  setWarnings({});
};

// Función para cancelar y volver a la vista anterior
const handleCancel = () => {
  clearInputs();
  Navigate(-1);
};

// Lista de campos obligatorios
const requiredFields = [
    'NUM_FIC_CONTROL_INVENTARIO',
    'DESCRIPCION',
    'NUM_SER_CONTROL_INVENTARIO',
    'NUM_INV_ANT_CONTROL_INVENTARIO',
    'COST_ADQ_CONTROL_INVENTARIO'
];

// Función para validar los campos obligatorios
const validateRequiredFields = () => {
    let hasError = false;
    const newErrors = {};

    requiredFields.forEach((field) => {
        const value = formData[field];

        if (!value.trim()) {
            // Si el campo está vacío, actualiza el estado de errores con un mensaje de error
            newErrors[field] = `El campo es obligatorio.`;
            hasError = true;
        }
    });

    setErrors((prevErrors) => ({
        ...prevErrors,
        ...newErrors
    }));

    return !hasError;
};

// Procedimiento para guardar los datos del formulario
const crear = async (e) => {
  e.preventDefault();
  
  // Validar el acceso al módulo
  await validarAccesoModulo();

  // Validar el permiso del usuario para realizar la acción de creación
   await validarPermisoControlInventarioRoutes();
   

// Validar los campos obligatorios
const areRequiredFieldsValid = validateRequiredFields();

const allFieldsFilled = requiredFields.every(
    (field)=> formData[field] && formData[field].trim() !==''
 
 );

// Verificar que los campos obligatorios estén llenos
if (!allFieldsFilled) {
    openModal('Los datos son insuficientes o no existen. Por favor, completa al menos todos los campos obligatorios para continuar.');
    return;
}
if (!areRequiredFieldsValid) {
    openModal('Los campos obligatorios deben completarse antes de poder guardar el inventario.');
    return;
}
// Validar todos los campos
  const isValid = validateAllFields();
  if (!isValid) {
      openModal('Se han detectado uno o mas errores, por favor corrigelos antes de poder continuar.');
      return;
  }
  try {
      // Convertir fechas a formato "yyyy-MM-dd"
      //const formattedFEC_REGISTRO = formData.FEC_REGISTRO;
     // Realizar la petición POST a la API
      await axios.post(URI, formData, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        
          NUM_FIC_CONTROL_INVENTARIO: formData.NUM_FIC_CONTROL_INVENTARIO,
          DESCRIPCION: formData.DESCRIPCION,
          MARCA: formData.MARCA,
          NUM_SER_CONTROL_INVENTARIO: formData.NUM_SER_CONTROL_INVENTARIO,
          NUM_INV_ANT_CONTROL_INVENTARIO: formData.NUM_INV_ANT_CONTROL_INVENTARIO,
          COLOR: formData.COLOR,
          COST_ADQ_CONTROL_INVENTARIO: formData.COST_ADQ_CONTROL_INVENTARIO,
          //FEC_REGISTRO: formattedFEC_REGISTRO,
          USR_REGISTRO: formData.USR_REGISTRO,
        }
      });
      openModal('¡Inventario creado exitosamente!');
      clearInputs();
  } catch (error) {
      console.error('Error:', error);
      openModal('¡Ocurrió un error y no se ha podido crear el inventario, intentalo de nuevo!. Si el problema persiste, por favor contacta con el administrador');
  }
};

// Muestra un indicador de carga mientras se obtienen los datos
if (loading) {
    return (
        <div className="loading-container">
            <p>Cargando datos... Por favor, espera.</p>
        </div>
    );
}

// Muestra un mensaje de error en caso de que ocurra
if (error) {
    return (
        <div className="error-container">
            <p>{error}</p>
            <button className="btn btn-secondary" onClick={handleCancel}>Volver a inicio</button>
        </div>
    );
}


return (
    <div>
        <div className="titulo-container">
            <h3>REGISTRAR UN NUEVO INVENTARIO</h3>
    </div>
  <div className="formulario-container">
      <form onSubmit={crear}>
          <div className="mb-3">
              <label className="form-label">Numero de fichas</label>
              <input
                    name="NUM_FIC_CONTROL_INVENTARIO" // Añade el atributo name
                    value={formData.NUM_FIC_CONTROL_INVENTARIO}
                    onChange={handleChange}
                    type="number"
                    placeholder="Ejemplo: 1, 5, 6, 10. (Este campo es obligatorio)"
                    className={`form-control ${errors.NUM_FIC_CONTROL_INVENTARIO ? 'is-invalid' : ''}`}
              />
              {errors.NUM_FIC_CONTROL_INVENTARIO && (
                  <div className="error-feedback">{errors.NUM_FIC_CONTROL_INVENTARIO}</div>
              )}
          </div>
          <div className="mb-3">
              <label className="form-label">Descripcion del objeto</label>
              <textarea
                  name="DESCRIPCION" // Añade el atributo name
                  value={formData.DESCRIPCION}
                  onChange={handleChange}
                  className={`form-control ${errors.DESCRIPCION ? 'is-invalid' : warnings.DESCRIPCION ? 'is-warning' : ''}`}
                  placeholder="Escribe aquí la descripción del objeto o producto... (Este campo es obligatorio)"
                  rows={4} // se puede ajustar el número de filas según preferencias
              />
              {errors.DESCRIPCION && (
                  <div className="error-feedback">{errors.DESCRIPCION}</div>
              )}
              {warnings.DESCRIPCION && (
                  <div className="warning-feedback">{warnings.DESCRIPCION}</div>
                )}
          </div>
          <div className="mb-3">
              <label className="form-label">Marca del objeto</label>
              <textarea
                  name="MARCA" // Añade el atributo name
                  value={formData.MARCA}
                  onChange={handleChange}
                  className={`form-control ${errors.MARCA ? 'is-invalid' : warnings.MARCA ? 'is-warning' : ''}`}
                  placeholder="Escribe aquí la marca de objeto o producto"
                  rows={4} // se puede ajustar el número de filas según preferencias
              />
              {errors.MARCA && (
                  <div className="error-feedback">{errors.MARCA}</div>
              )}
              {warnings.MARCA && (
                  <div className="warning-feedback">{warnings.MARCA}</div>
                )}
          </div>
          <div className="mb-3">
              <label className="form-label">Numero de serie</label>
              <input
                    name="NUM_SER_CONTROL_INVENTARIO" // Añade el atributo name
                    value={formData.NUM_SER_CONTROL_INVENTARIO}
                    onChange={handleChange}
                    type="number"
                    placeholder="Ejemplo: 1, 5, 6, 10. (Este campo es obligatorio)"
                    className={`form-control ${errors.NUM_SER_CONTROL_INVENTARIO ? 'is-invalid' : ''}`}
              />
              {errors.NUM_SER_CONTROL_INVENTARIO && (
                  <div className="error-feedback">{errors.NUM_SER_CONTROL_INVENTARIO}</div>
              )}
          </div>
          <div className="mb-3">
              <label className="form-label">Numero de inventario anterior</label>
              <input
                    name="NUM_INV_ANT_CONTROL_INVENTARIO" // Añade el atributo name
                    value={formData.NUM_INV_ANT_CONTROL_INVENTARIO}
                    onChange={handleChange}
                    type="number"
                    placeholder="Ejemplo: 1, 5, 6, 10. (Este campo es obligatorio)"
                    className={`form-control ${errors.NUM_INV_ANT_CONTROL_INVENTARIO ? 'is-invalid' : ''}`}
              />
              {errors.NUM_INV_ANT_CONTROL_INVENTARIO && (
                  <div className="error-feedback">{errors.NUM_INV_ANT_CONTROL_INVENTARIO}</div>
              )}
          </div>
          <div className="mb-3">
              <label className="form-label">Color del objeto</label>
              <textarea
                  name="COLOR" // Añade el atributo name
                  value={formData.COLOR}
                  onChange={handleChange}
                  className={`form-control ${errors.COLOR ? 'is-invalid' : warnings.COLOR ? 'is-warning' : ''}`}
                  placeholder="Escribe aquí el color de objeto o producto"
                  rows={4} // se puede ajustar el número de filas según preferencias
              />
              {errors.COLOR && (
                  <div className="error-feedback">{errors.COLOR}</div>
              )}
              {warnings.COLOR && (
                  <div className="warning-feedback">{warnings.COLOR}</div>
                )}
          </div>
          <div className="mb-3">
              <label className="form-label">Costo adquirido el objeto</label>
              <input
                    name="COST_ADQ_CONTROL_INVENTARIO" // Añade el atributo name
                    value={formData.COST_ADQ_CONTROL_INVENTARIO}
                    onChange={handleChange}
                    type="number"
                    placeholder="Ejemplo: 100, 500, 1000. (Este campo es obligatorio)"
                    className={`form-control ${errors.COST_ADQ_CONTROL_INVENTARIO ? 'is-invalid' : ''}`}
              />
              {errors.COST_ADQ_CONTROL_INVENTARIO && (
                  <div className="error-feedback">{errors.COST_ADQ_CONTROL_INVENTARIO}</div>
              )}
          </div>

       
           <button type="submit" className="btn btn-success"> Guardar</button>
          <button type="button" className="btn btn-primary" onClick={clearInputs}>Limpiar</button>
          <button type="button" className="btn btn-danger" onClick={handleCancel}>Cancelar</button>
      </form>
      <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Mensaje Modal"
      >
          <h2>{modalMessage}</h2>
          <button onClick={closeModal}>Cerrar</button>
      </Modal>
  </div>
  </div>
);
};
                
export default CompCreateInventario

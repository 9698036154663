import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Modal from 'react-modal';
import '../../Estilos/StyleFormulario.css';

const URI = 'https://cisrubenandino.com/ap/parametros_software/';
const VALIDAR_MODULO_URI = 'https://cisrubenandino.com/ap/validar-mod-PS';
const VALIDAR_PERMISO_URI = 'https://cisrubenandino.com/ap/validar-PSI-';

const CompCreateParametrosSoftware = () => {
    const [NOM_Producto, setNOM_Producto] = useState('');
    const [Descripcion, setDescripcion] = useState('');
    const [Version, setVersion] = useState('');
    const [REQ_Sistema, setREQ_Sistema] = useState('');
    const [Licencia, setLicencia] = useState('');
    const [Plataforma, setPlataforma] = useState('');
    const [Categoria, setCategoria] = useState('');
    const [NOM_Desarrollador, setNOM_Desarrollador] = useState('');
    const [Usr_Registro, setUsr_Registro] = useState('');
    const Navigate = useNavigate();
    const [message, setMessage] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [loading, setLoading] = useState(true);

    // Función para validar que se tenga acceso al modulo
    const validarAccesoModulo = useCallback(async () => {
        try {
            const res = await axios.get(VALIDAR_MODULO_URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (res.status !== 200 || !res.data.acceso) {
                throw new Error(res.data.mensaje || 'Acceso denegado.');
            }
        } catch (error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                setErrors('No tienes autorizacion para acceder a este apartado. Si crees que se trata de un error, comunicaselo al administrador.');
            } else {
                setErrors('No ha sido posible validar el acceso. Contacta con el administrador.');
            }
            setLoading(false);
            throw error;
        }
    }, []);
// Función para validar permisos del usuario
    const validarPermisoUsuario = useCallback(async () => {
        try {
        const res = await axios.get(VALIDAR_PERMISO_URI, {
            headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        });
        if (res.status !== 200 || !res.data.acceso) {
            throw new Error(res.data.mensaje || 'Permiso denegado.');
        }
        } catch (error) {
        setErrors('No tienes autorización para realizar esta acción. Si crees que se trata de un error, comunícalo al administrador.');
        setLoading(false);
        throw error;
        }
    }, []);

  // UseEffect para validar acceso, permisos y cargar datos
  useEffect(() => {
    const fetchData = async () => {
      try {
        await validarAccesoModulo(); // Valida el acceso al módulo
        await validarPermisoUsuario(); // Valida el permiso del usuario
      } catch (error) {
        console.error('Error durante la carga de datos:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [validarAccesoModulo, validarPermisoUsuario]);


    // Validaciones en tiempo real
    const [errors, setErrors] = useState({});

    const validateField = (name, value) => {
        let errorsObj = { ...errors };

        // Expresión regular para solo permitir letras, números y espacios
        const alphanumericRegex = /^[a-zA-Z0-9\s]*$/;

        switch (name) {
            case 'NOM_Producto':
                if (!value.trim()) {
                    errorsObj.NOM_Producto = 'El nombre del producto es requerido';
                } else if (!alphanumericRegex.test(value)) {
                    errorsObj.NOM_Producto = 'El nombre del producto no debe contener caracteres especiales';
                } else if (value.trim().length > 30) {
                    errorsObj.NOM_Producto = 'El nombre del producto no puede exceder los 30 caracteres';
                } else {
                    delete errorsObj.NOM_Producto;
                }
                break;
            case 'Descripcion':
                if (!value.trim()) {
                    errorsObj.Descripcion = 'La descripción del producto es requerida';
                } else if (value.trim().length > 100) {
                    errorsObj.Descripcion = 'La descripción no puede exceder los 100 caracteres';
                } else {
                    delete errorsObj.Descripcion;
                }
                break;
            case 'Version':
                if (!value.trim()) {
                    errorsObj.Version = 'La versión del producto es requerida';
                } else if (!/^\d+(\.\d+){2}$/.test(value.trim())) {
                    errorsObj.Version = 'El formato de la versión debe ser X.X.X (por ejemplo, 1.2.3)';
                } else if (value.trim().length > 20) {
                    errorsObj.Version = 'La versión del producto no puede exceder los 20 caracteres';
                } else {
                    delete errorsObj.Version;
                }
                break;
            case 'REQ_Sistema':
                if (!value.trim()) {
                    errorsObj.REQ_Sistema = 'Los requisitos del sistema son requeridos';
                } else if (value.trim().length > 200) {
                    errorsObj.REQ_Sistema = 'Los requisitos del sistema no pueden exceder los 200 caracteres';
                } else {
                    delete errorsObj.REQ_Sistema;
                }
                break;
            case 'Licencia':
                if (!value.trim()) {
                    errorsObj.Licencia = 'La licencia del producto es requerida';
                } else if (value.trim().length > 20) {
                    errorsObj.Licencia = 'La licencia no puede exceder los 20 caracteres';
                } else {
                    delete errorsObj.Licencia;
                }
                break;
            case 'Plataforma':
                if (!value.trim()) {
                    errorsObj.Plataforma = 'La plataforma del producto es requerida';
                } else if (value.trim().length > 50) {
                    errorsObj.Plataforma = 'La plataforma no puede exceder los 50 caracteres';
                } else {
                    delete errorsObj.Plataforma;
                }
                break;
            case 'Categoria':
                if (!value.trim()) {
                    errorsObj.Categoria = 'La categoría del producto es requerida';
                } else if (value.trim().length > 50) {
                    errorsObj.Categoria = 'La categoría no puede exceder los 50 caracteres';
                } else {
                    delete errorsObj.Categoria;
                }
                break;
            case 'NOM_Desarrollador':
                if (!value.trim()) {
                    errorsObj.NOM_Desarrollador = 'El nombre del desarrollador es requerido';
                } else if (!/^[a-zA-Z\s]*$/.test(value)) {
                    errorsObj.NOM_Desarrollador = 'El nombre del desarrollador solo debe contener letras y espacios';
                } else if (value.trim().length > 50) {
                    errorsObj.NOM_Desarrollador = 'El nombre del desarrollador no puede exceder los 50 caracteres';
                } else {
                    delete errorsObj.NOM_Desarrollador;
                }
                break;
            case 'Usr_Registro':
                if (!value.trim()) {
                    errorsObj.Usr_Registro = 'El usuario del producto es requerido';
                } else if (value.trim().length > 50) {
                    errorsObj.Usr_Registro = 'El usuario no puede exceder los 50 caracteres';
                } else {
                    delete errorsObj.Usr_Registro;
                }
                break;
            default:
                break;
        }

        setErrors(errorsObj);
        return Object.keys(errorsObj).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        validateField(name, value);
        switch (name) {
            case 'NOM_Producto':
                setNOM_Producto(value);
                break;
            case 'Descripcion':
                setDescripcion(value);
                break;
            case 'Version':
                setVersion(value);
                break;
            case 'REQ_Sistema':
                setREQ_Sistema(value);
                break;
            case 'Licencia':
                setLicencia(value);
                break;
            case 'Plataforma':
                setPlataforma(value);
                break;
            case 'Categoria':
                setCategoria(value);
                break;
            case 'NOM_Desarrollador':
                setNOM_Desarrollador(value);
                break;
            case 'Usr_Registro':
                setUsr_Registro(value);
                break;
            default:
                break;
        }
    };

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const clearInputs = () => {
        setNOM_Producto('');
        setDescripcion('');
        setVersion('');
        setREQ_Sistema('');
        setLicencia('');
        setPlataforma('');
        setCategoria('');
        setNOM_Desarrollador('');
        setUsr_Registro('');
        setErrors({});
    };

    const handleCancel = () => {
        clearInputs();
        Navigate(-1);
    };

    const store = async (e) => {
        e.preventDefault();

        if (Object.keys(errors).length === 0) {
            try {
                const response = await axios.post(URI, {
                    NOM_Producto, 
                    Descripcion, 
                    Version, 
                    REQ_Sistema,
                    Licencia,
                    Plataforma,
                    Categoria, 
                    NOM_Desarrollador, 
                    Usr_Registro,
                }, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });
                setMessage('¡Parametro creado exitosamente!');
                openModal();
                clearInputs();
            } catch (error) {
                setMessage('¡Ocurrio un error y no se ha podido crear el parametro!');
                console.error('Error:', error.response ? error.response.data : error.message);
            }
        }
    };

    return (
        <div className="main-container d-flex justify-content-center align-items-center" style={{ minHeight: "100vh" }}>
            <div className="card p-4 w-75 shadow-lg border-radius">
                <h3 className="text-center mb-4">Registrar Parámetro</h3>
                <form onSubmit={store}>
                    <div className="form-group mb-3">
                        <label htmlFor="NOM_Producto" style={{ fontSize: '1.25rem', fontWeight: 'bold', color: '#333' }}>Nombre del Producto</label>
                        <input
                            type="text"
                            id="NOM_Producto"
                            name="NOM_Producto"
                            className={`form-control ${errors.NOM_Producto ? 'is-invalid' : ''}`}
                            value={NOM_Producto}
                            onChange={handleInputChange}
                            placeholder="Ingrese el nombre del producto"
                        />
                        {errors.NOM_Producto && <div className="invalid-feedback">{errors.NOM_Producto}</div>}
                    </div>

                    <div className="form-group mb-3">
                        <label htmlFor="Descripcion" style={{ fontSize: '1.25rem', fontWeight: 'bold', color: '#333' }}>Descripción</label>
                        <textarea
                            id="Descripcion"
                            name="Descripcion"
                            className={`form-control ${errors.Descripcion ? 'is-invalid' : ''}`}
                            value={Descripcion}
                            onChange={handleInputChange}
                            placeholder="Ingrese una descripción del producto"
                        />
                        {errors.Descripcion && <div className="invalid-feedback">{errors.Descripcion}</div>}
                    </div>

                    <div className="form-group mb-3">
                        <label htmlFor="Version" style={{ fontSize: '1.25rem', fontWeight: 'bold', color: '#333' }}>Versión</label>
                        <input
                            type="text"
                            id="Version"
                            name="Version"
                            className={`form-control ${errors.Version ? 'is-invalid' : ''}`}
                            value={Version}
                            onChange={handleInputChange}
                            placeholder="Ingrese la versión del producto"
                        />
                        {errors.Version && <div className="invalid-feedback">{errors.Version}</div>}
                    </div>

                    <div className="form-group mb-3">
                        <label htmlFor="REQ_Sistema" style={{ fontSize: '1.25rem', fontWeight: 'bold', color: '#333' }}>Requisitos del Sistema</label>
                        <textarea
                            id="REQ_Sistema"
                            name="REQ_Sistema"
                            className={`form-control ${errors.REQ_Sistema ? 'is-invalid' : ''}`}
                            value={REQ_Sistema}
                            onChange={handleInputChange}
                            placeholder="Ingrese los requisitos del sistema"
                        />
                        {errors.REQ_Sistema && <div className="invalid-feedback">{errors.REQ_Sistema}</div>}
                    </div>

                    <div className="form-group mb-3">
                        <label htmlFor="Licencia" style={{ fontSize: '1.25rem', fontWeight: 'bold', color: '#333' }}>Licencia</label>
                        <input
                            type="text"
                            id="Licencia"
                            name="Licencia"
                            className={`form-control ${errors.Licencia ? 'is-invalid' : ''}`}
                            value={Licencia}
                            onChange={handleInputChange}
                            placeholder="Ingrese la licencia del producto"
                        />
                        {errors.Licencia && <div className="invalid-feedback">{errors.Licencia}</div>}
                    </div>

                    <div className="form-group mb-3">
                        <label htmlFor="Plataforma" style={{ fontSize: '1.25rem', fontWeight: 'bold', color: '#333' }}>Plataforma</label>
                        <input
                            type="text"
                            id="Plataforma"
                            name="Plataforma"
                            className={`form-control ${errors.Plataforma ? 'is-invalid' : ''}`}
                            value={Plataforma}
                            onChange={handleInputChange}
                            placeholder="Ingrese la plataforma del producto"
                        />
                        {errors.Plataforma && <div className="invalid-feedback">{errors.Plataforma}</div>}
                    </div>

                    <div className="form-group mb-3">
                        <label htmlFor="Categoria" style={{ fontSize: '1.25rem', fontWeight: 'bold', color: '#333' }}>Categoría</label>
                        <input
                            type="text"
                            id="Categoria"
                            name="Categoria"
                            className={`form-control ${errors.Categoria ? 'is-invalid' : ''}`}
                            value={Categoria}
                            onChange={handleInputChange}
                            placeholder="Ingrese la categoría del producto"
                        />
                        {errors.Categoria && <div className="invalid-feedback">{errors.Categoria}</div>}
                    </div>

                    <div className="form-group mb-3">
                        <label htmlFor="NOM_Desarrollador" style={{ fontSize: '1.25rem', fontWeight: 'bold', color: '#333' }}>Nombre del Desarrollador</label>
                        <input
                            type="text"
                            id="NOM_Desarrollador"
                            name="NOM_Desarrollador"
                            className={`form-control ${errors.NOM_Desarrollador ? 'is-invalid' : ''}`}
                            value={NOM_Desarrollador}
                            onChange={handleInputChange}
                            placeholder="Ingrese el nombre del desarrollador"
                        />
                        {errors.NOM_Desarrollador && <div className="invalid-feedback">{errors.NOM_Desarrollador}</div>}
                    </div>

                    <div className="text-center">
                        <button type="submit" className="btn" style={{ backgroundColor: "#28a745", color: "white", border: "none" }}>Guardar</button>
                        <button type="button" className="btn" style={{ backgroundColor: "#dc3545", color: "white", border: "none" }} onClick={handleCancel}>Cancelar</button>
                        <button type="button" className="btn" style={{ backgroundColor: "#007bff", color: "white", border: "none" }} onClick={clearInputs}>Limpiar</button>
                    </div>
                </form>

                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    contentLabel="Registro exitoso"
                    className="modal-content"
                    overlayClassName="modal-overlay"
                >
                    <h2>Registro exitoso</h2>
                    <p>{message}</p>
                    <button onClick={closeModal}>Cerrar</button>
                </Modal>
            </div>
        </div>
    );
}

export default CompCreateParametrosSoftware;






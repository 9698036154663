import '../../../Estilos/StyleTableDatos.css';
import axios from "axios";
import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import {useNavigate} from 'react-router-dom';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css'; // Estilos de DataTables
import $ from 'jquery';
import 'datatables.net-bs5'; // Importa DataTables

const URI = 'https://cisrubenandino.com/ap/ad_rol/';
const VALIDAR_MODULO_URI = 'https://cisrubenandino.com/ap/validar-mod-R';
const VALIDAR_PERMISO_URI = 'https://cisrubenandino.com/ap/validar-Sr-';

const CompShowRoles = () => {
    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    // Función para validar que se tenga acceso al modulo
    const validarAccesoModulo = useCallback(async () => {
        try {
            const res = await axios.get(VALIDAR_MODULO_URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (res.status !== 200 || !res.data.acceso) {
                throw new Error(res.data.mensaje || 'Acceso denegado.');
            }
        } catch (error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                setError('No tienes autorizacion para acceder a este apartado. Si crees que se trata de un error, comunicaselo al administrador.');
            } else {
                setError('No ha sido posible validar el acceso. Contacta con el administrador.');
            }
            setLoading(false);
            throw error;
        }
    }, []);

    // Función para validar el permiso necesario
    const validarPermiso = useCallback(async () => {
        try {
            const res = await axios.get(VALIDAR_PERMISO_URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (res.status !== 200 || !res.data.acceso) {
                setError('No tienes autorización para acceder a este apartado. Si crees que se trata de un error, comunícaselo al administrador.');
            }
        } catch (error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                setError('No se cuenta con el acceso completo para acceder a este apartado.');
            } else {
                setError('No ha sido posible validar el acceso. Contacta con el administrador.');
            }
            setLoading(false);
            throw error;
        }
    }, []);

    const getRoles = useCallback(async () => {
        try {
            const res = await axios.get(URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (Array.isArray(res.data)) {
                setRoles(res.data);
            } else {
                setRoles([]); // No hay roles para mostrar
            }
        } catch (error) {
            // Manejo de error para los códigos de estado 401 y 403
        if (error.response) {
            if (error.response.status === 401 || error.response.status === 403) {
                // Redirige a la página de InicioNoAutorizado
                localStorage.removeItem('token'); // Elimina el token
                localStorage.removeItem('username'); // Elimina el nombre de usuario
                setError('No ha sido posible validar tus credenciales, vuelve a iniciar sesión.');
                navigate(`/InicioNoAutorizado?error=${encodeURIComponent(error.response.data.message)}`);
            } else {
                // Otro tipo de error
                setError('No ha sido posible encontrar los datos. Por favor vuelve a intentar, si el problema persiste, contacta con el administrador.');
            }
        } else {
            // Error sin respuesta del servidor (puede ser de red o otro detalle)
            setError('No ha sido posible verificar los datos en este momento. Por favor, contacta con el administrador.');
        }
        } finally {
            setLoading(false);
        }
    }, [navigate]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await validarAccesoModulo(); // Valida el acceso al módulo
                await validarPermiso(); // Valida el permiso del usuario
                await getRoles(); // Obtiene los datos de los roles si se tiene acceso
            } catch (error) {
                console.error("Ha ocurrido un problema intenta de nuevo", error);
            }
        };
        fetchData();
    }, [validarAccesoModulo, validarPermiso, getRoles]);


    useEffect(() => {
        if ($.fn.DataTable.isDataTable('#dataTableRoles')) {
            $('#dataTableRoles').DataTable().destroy();
        }

        if (roles.length > 0) {
            $('#dataTableRoles').DataTable();
        }
    }, [roles]); // Vuelve a inicializar DataTables cuando cambian los parámetros

    /*const AlterRol = async (ID_Rol, Estado) => {
        const action = Estado === 1 ? 'desactivar' : 'activar';
        const confirmMessage = `¿Está seguro de que desea ${action} este rol?`;

        const confirm = window.confirm(confirmMessage);

        if (confirm) {
            try {
                await axios.delete(`${URI}${ID_Rol}`);
                getRoles();
            } catch (error) {
                console.error(`Error al ${action} el rol:`, error);
            }
        }
    };

    */

    const deleteRol = async (ID_Rol) => {
        try {
            // Validar el permiso del usuario para la acción de "eliminación"
            const res = await axios.get('https://cisrubenandino.com/ap/validar-Er-', {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
    
            if (res.status !== 200 || !res.data.acceso) {
                window.alert('No tienes el permiso necesario para realizar esta acción.');
                return;
            }
    
            // Si el usuario tiene permiso, preguntar si desea eliminar
            const confirmDelete = window.confirm("¿Está seguro de que desea eliminar este Rol?");
            
            if (confirmDelete) {
                try {
                    await axios.delete(`${URI}${ID_Rol}`, {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        }
                    });
                    getRoles(); // Refresca la lista después de la eliminación
                } catch (error) {
                    console.error('Error al Eliminar el rol:', error);
                    window.alert('Hubo un problema al intentar eliminar el rol. Por favor, inténtelo de nuevo.');
                }
            }
        } catch (error) {
            // Manejo específico del error 403
            if (error.response && error.response.status === 403) {
                window.alert('No tienes el permiso necesario para realizar esta acción.');
            } else {
                console.error('Error al validar permiso:', error);
                window.alert('Hubo un problema al verificar los permisos. Por favor, inténtelo de nuevo más tarde.');
            }
        }
    };

    // Función para cancelar y regresar
    const cancel = () => {
        navigate('/Inicio');
    };

    // Muestra un indicador de carga mientras se obtienen los datos
if (loading) {
    return (
        <div className="loading-container">
            <p>Cargando datos... Por favor, espera.</p>
        </div>
    );
}

// Muestra un mensaje de error en caso de que ocurra
if (error) {
    return (
        <div className="error-container">
            <p>{error}</p>
            <button className="btn btn-secondary" onClick={cancel}>Volver a inicio</button>
        </div>
    );
}

    return (
        <div className="main-container">
                <h2 className="text-center mb-4">Administración De Roles</h2> {/* Título de la página */}
                    <div className="btn-container">
                        <Link to="/AdmAgRL" className="btn btn-primary mb-3">
                            Crear un Nuevo Rol <i className="fa-solid fa-user-tag"></i>
                        </Link>
                    </div>
                    <div className="table-container">
                        <table id="dataTableRoles" className="table table-striped">
                            <thead className="table">
                                <tr>
                                    <th className="text-center">Rol</th>
                                    <th className="text-center">Descripción</th>
                                    <th className="text-center">Estado</th>
                                    <th className="text-center">Modulos</th>
                                    <th className="text-center">Opciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {roles.length === 0 ? (
                                    <tr>
                                        <td colSpan="8">No hay datos para mostrar. Cree o registre un nuevo rol.</td>
                                    </tr>
                                ) : (
                                    roles.map((rol) => (
                                        <tr key={rol.ID_Rol}>
                                            <td className="text-center">{rol.NOM_Rol}</td>
                                            <td className="text-center">{rol.Descripcion}</td>
                                            <td className="text-center">{rol.Estado === 1 ? "Activo" : "Inactivo"}</td>
                                            <td className="text-center">{rol.Modulos}</td>
                                            <td className="text-center">
                                                <Link to={`/AdmEditRl/${rol.ID_Rol}`} className="btn btn-info">
                                                    Editar <i className="fa-solid fa-pen-to-square"></i>
                                                </Link>
                                                <button onClick={() => deleteRol(rol.ID_Rol)} className="btn btn-danger">
                                                    Eliminar <i className="fa-solid fa-user-large-slash"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>
             </div>
        );
    }; 

export default CompShowRoles;





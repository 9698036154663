import '../../../Estilos/StyleFormulario.css';
import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import { useNavigate, useParams } from 'react-router-dom';

// Configuración del elemento raíz para los modales
Modal.setAppElement('#root');

const URI = 'https://cisrubenandino.com/ap/ad_rol/';
const VALIDAR_MODULO_URI = 'https://cisrubenandino.com/ap/validar-mod-R';
const VALIDAR_PERMISO_URI = 'https://cisrubenandino.com/ap/validar-Ar-';

const CompEditRoles = () => {
    // Estados para los datos
    const [NOM_Rol, setNOM_Rol] = useState('');
    const [Descripcion, setDescripcion] = useState('');
    const [Usr_Registro] = useState(localStorage.getItem('username') || '');
    // Estados para mensajes y modales
    const [message, setMessage] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
    const [originalData, setOriginalData] = useState(null);
    // Estados para manejo de carga y errores
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    // Navegación y parámetros de la URL
    const navigate = useNavigate();
    const { ID_Rol } = useParams();

    // Funciones para controlar los modales
    const openModal = () => {
        setModalIsOpen(true);
    };
    const closeModal = () => {
        setModalIsOpen(false);
    };
    const openConfirmModal = () => {
        setConfirmModalIsOpen(true);
    };
    const closeConfirmModal = () => {
        setConfirmModalIsOpen(false);
    };
    const handleModalClose = () => {
        closeModal();
        if (message === '¡Datos actualizados exitosamente!') {
            navigate('/AdmRl'); // Regresa a la página de roles después de una actualización exitosa
        }
    };

    // Función para cancelar y regresar a la página anterior
    const cancel = () => {
        navigate('/AdmRl');
    };

    // Función para validar que se tenga acceso al módulo
    const validarAccesoModulo = useCallback(async () => {
        try {
            const res = await axios.get(VALIDAR_MODULO_URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (res.status !== 200 || !res.data.acceso) {
                throw new Error(res.data.mensaje || 'Acceso denegado.');
            }
        } catch (error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                setError('No tienes autorización para acceder a este apartado. Si crees que se trata de un error, comunícaselo al administrador.');
            } else {
                setError('No ha sido posible validar el acceso. Contacta con el administrador.');
            }
            setLoading(false);
            throw error;
        }
    }, []);

    // Función para validar el permiso necesario
    const validarPermisoModulo = useCallback(async () => {
        try {
            const res = await axios.get(VALIDAR_PERMISO_URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (res.status !== 200 || !res.data.acceso) {
                throw new Error('No tienes autorización para acceder a este apartado.');
            }
        } catch (error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                setError('No se cuenta con el acceso completo para acceder a este apartado.');
            } else {
                setError('No ha sido posible validar el acceso. Contacta con el administrador.');
            }
            setLoading(false);
            throw error;
        }
    }, []);

    // Función para obtener los datos del usuario por ID
    const getRolByID = useCallback(async () => {
        try {
            const rolResponse = await axios.get(URI + ID_Rol, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            const rolData = rolResponse.data;

            setNOM_Rol(rolData.NOM_Rol);
            setDescripcion(rolData.Descripcion); 
            setOriginalData(rolData);

            setLoading(false);

        } catch (error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                localStorage.removeItem('token');
                setError('No ha sido posible validar tus credenciales, vuelve a iniciar sesión.');
                navigate(`/InicioNoAutorizado?error=${encodeURIComponent(error.response.data.message)}`);
            } else {
                setError('¡Ocurrió un error y no se pudo recuperar los datos! Inténtalo de nuevo.');
            }
            setLoading(false);
            throw error;
        }
    }, [ID_Rol, navigate]);


    useEffect(() => {
        const getRol = async () => {
            try {
                const rolResponse = await axios.get(URI + ID_Rol, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });
                const rolData = rolResponse.data;
                setNOM_Rol(rolData.NOM_Rol);
                setDescripcion(rolData.Descripcion);
                setOriginalData(rolData);
            } catch (error) {
                setError('¡Ocurrió un error y no se pudo obtener el Rol!, intentalo de nuevo');
            }
        };

        getRol();
    }, [ID_Rol]);

    // Efecto para ejecutar las validaciones y cargar datos al montar el componente
    useEffect(() => {
        const fetchData = async () => {
            try {
                await validarAccesoModulo(); // Valida el acceso al módulo
                await validarPermisoModulo(); // Valida el permiso del usuario
                await getRolByID(); // Obtiene los datos del usuario si se tiene acceso
                setLoading(false);
            } catch (error) {
                console.error('Error durante la carga de datos:', error);
            }
        };
        fetchData();
    }, [validarAccesoModulo, validarPermisoModulo, getRolByID]);

    // Comprobar si se ha realizado al menos un cambio en un campo
    const handleSubmit = (e) => {
        e.preventDefault();
        const hasChanges =
            NOM_Rol !== originalData.NOM_Rol ||
            Descripcion !== originalData.Descripcion;

        if (hasChanges) {
            if (!NOM_Rol.trim() || !Descripcion.trim()) {
                setMessage('Por favor, complete todos los campos.');
                openModal();
            } else {
                openConfirmModal();
            }
        } else {
            setMessage('No se han realizado cambios, no se puede realizar una actualización.');
            openModal();
        }
    };
    // Restaurar un campo especifico al valor original, si el usuario se equivoco y quiere regresar al valor existente
    const restoreField = (fieldName) => {
        switch (fieldName) {
            case 'NOM_Rol':
                setNOM_Rol(originalData.NOM_Rol);
                break;
            case 'Descripcion':
                setDescripcion(originalData.Descripcion);
                break;
            default:
                break;
        }
    };

    // Restaurar todos los campos al valor original
    const restoreAllFields = () => {
        setNOM_Rol(originalData.NOM_Rol);
        setDescripcion(originalData.Descripcion);
    };

    const update = async () => {
        closeConfirmModal(); // Cerrar el modal de confirmación
        try {
            await axios.put(URI + ID_Rol, {
                NOM_Rol,
                Descripcion,
                Usr_Registro
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            setMessage('¡Datos actualizados exitosamente!'); // Establecer el mensaje de éxito
            openModal(); // Abrir el modal de mensaje
        } catch (error) {
            setMessage('¡Ocurrió un problema y no se ha podido actualizar los datos del rol!, intentalo de nuevo'); // Establecer el mensaje de error
            openModal(); // Abrir el modal de mensaje
        }
    };

    // Muestra un indicador de carga mientras se obtienen los datos
    if (loading) {
        return (
            <div className="loading-container">
                <p>Cargando datos... Por favor, espera.</p>
            </div>
        );
    }
    // Muestra un mensaje de error en caso de que ocurra
    if (error) {
        return (
            <div className="error-container">
                <p>{error}</p>
                <button className="btn btn-secondary" onClick={cancel}>Volver</button>
            </div>
        );
    }

    return (
        <div>
            <div className="titulo-container">
                <h3>EDITAR UN ROL</h3>
            </div>
            <div className="formulario-container">
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label className="form-label">Nombre del Rol</label>
                        <input
                            value={NOM_Rol}
                            onChange={(e) => setNOM_Rol(e.target.value)}
                            type="text"
                            className="form-control"
                        />
                        <button type="button" className="btn btn-info" onClick={() => restoreField('NOM_Rol')}>
                            Limpiar
                        </button>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Descripción del Rol</label>
                        <input
                            value={Descripcion}
                            onChange={(e) => setDescripcion(e.target.value)}
                            type="text"
                            className="form-control"
                        />
                        <button type="button" className="btn btn-info" onClick={() => restoreField('Descripcion')}>
                            Limpiar
                        </button>
                    </div>
                    {/* Botones de acción */}
                    <button type="submit" className="btn btn-success">Guardar Cambios <i className="fa-solid fa-pen"></i></button>
                    <button type="button" className="btn btn-primary" onClick={restoreAllFields}>Restaurar Todo<i className="fa-solid fa-rotate-right"></i></button>
                    <button type="button" className="btn btn-danger" onClick={cancel}>Cancelar y regresar <i className="fa-solid fa-rotate-left"></i> </button>
                </form>
                {/* Modal de confirmación */}
            <Modal
                isOpen={confirmModalIsOpen}
                onRequestClose={closeConfirmModal}
                contentLabel="Confirmación Modal"
                className="custom-modal"
                overlayClassName="custom-overlay"
            >
                <div className="modal-content">
                  <h2>¿Estás seguro de que deseas guardar los cambios?</h2>
                  <div className="btn-container">
                        <button onClick={update} className="btn btn-success">Sí, Guardar <i className="fa-solid fa-check"></i> </button>
                        <button onClick={closeConfirmModal} className="btn btn-danger">No, Regresar <i className="fa-solid fa-xmark"></i> </button>
                    </div>
                </div>
            </Modal>

            {/* Modal de mensaje */}
            <Modal
                 isOpen={modalIsOpen}
                 onRequestClose={handleModalClose}
                 contentLabel="Mensaje Modal"
                 className="custom-modal"
                 overlayClassName="custom-overlay"
            >
                   <div className="modal-content">
                      <h2>{message}</h2>
                        <div className="btn-container">
                            <button onClick={handleModalClose} className="btn btn-primary">Aceptar</button>
                        </div>
                    </div>
                 </Modal>
            </div>
        </div>
    );
};

export default CompEditRoles;

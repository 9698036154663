import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import {
    FaBars,
    FaAddressBook,
    FaHome,
    FaIndustry,
    FaCogs,
    FaUserAlt,
    FaUserCog,
    FaWarehouse,
    FaChartBar,
    FaBookMedical,
    FaSyringe,
    FaSignOutAlt,
    FaAddressCard,
    FaClipboardList,
    FaUsers,
    FaPaste,
    FaArchive,
    FaPoll
} from "react-icons/fa";
import '../Estilos/SideBar.css'; // Importa el archivo CSS para estilos adicionales
import logoImage from '../imagenes/mini_logo.png';

const SideBar = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [activeSubMenuIndex, setActiveSubMenuIndex] = useState(null);
    const [activeMenuIndex, setActiveMenuIndex] = useState(null); // Estado para el menú activo
    const navigate = useNavigate(); // Usa useNavigate para la navegación
    const sidebarRef = useRef(null); // Ref para el sidebar

    const handleCerrarSesion = () => {
        // Remueve el token actual
        localStorage.removeItem('token');
        // Limpia el usuario guardado
        localStorage.removeItem('username');
        // Redirige a la página de inicio de sesión
        navigate('/login');
        toggle(); // Cierra el sidebar al cerrar sesión
    };

    const toggle = () => setIsOpen(!isOpen);

    const handleSubMenuClick = (index) => {
        if (!isOpen) {
            setIsOpen(true); // Abre el sidebar si está cerrado
        }
        // Toggle the clicked sub-menu, close others
        setActiveSubMenuIndex(prevIndex => prevIndex === index ? null : index);
        setActiveMenuIndex(index); // Resalta el menú activo
    };

    const handleClickOutside = (event) => {
        if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
            setIsOpen(false);
            setActiveSubMenuIndex(null); // Cierra todos los submenús
            setActiveMenuIndex(null); // Quita el resaltado del menú
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleMenuItemClick = () => {
        setIsOpen(false);
        setActiveSubMenuIndex(null);
        setActiveMenuIndex(null);
    };

    const menuItems = [
        {
            path: "/Inicio",
            name: "Inicio",
            icon: <FaHome />
        },
        {
            path: "#",
            name: "Opciones Administrativas",
            icon: <FaUserCog />,
            subMenu: [
                {
                    path: "/AdmUsers",
                    name: "Usuarios",
                    icon: <FaUserAlt />
                },
                {
                    path: "/AdmPers",
                    name: "Personas",
                    icon: <FaUsers />
                },
                {
                    path: "/AdmRl",
                    name: "Roles",
                    icon: <FaAddressCard />
                },
                {
                    path: "/AdmAPS",
                    name: "Accesos y Permisos",
                    icon: <FaClipboardList />
                },
            ]
        },
        {
            path: "#",
            name: "Area de Administración",
            icon: <FaArchive />,
            subMenu: [
                {
                    path: "/MostrarP",
                    name: "Empleados",
                    icon: <FaPaste />
                },
                {
                    path: "/MostrarI",
                    name: "Administracion Inventario",
                    icon: <FaWarehouse />
                },
            ]
        },
        {
            path: "#",
            name: "Area de Estadistica",
            icon: <FaPoll />,
            subMenu: [
                {
                    path: "/MostrarA",
                    name: "Registro de Consultas",
                    icon: <FaBookMedical />
                },
                {
                    path: "/MostrarE",
                    name: "Estadisticas AT2R",
                    icon: <FaChartBar />
                },
                {
                    path: "/MostrarLB",
                    name: "Libro Diario",
                    icon: <FaChartBar />
                },
                {
                    path: "/MostrarHM",
                    name: "Hora Medico",
                    icon: <FaChartBar />
                },
            ]
        },
        {
            path: "/MosArExp",
            name: "Area de Archivo",
            icon: <FaAddressBook />
        },
        {
            path: "/MostrarPS",
            name: "Parametros De Software",
            icon: <FaCogs />
        },
        {
            path: "/MostrarPE",
            name: "Parametros Empresas",
            icon: <FaIndustry />
        },
        {
            path: "/MostrarEN",
            name: "Enfermeria",
            icon: <FaSyringe />
        },
        {
            path: "/login",
            name: <span className="red-text">Cerrar Sesión</span>,
            icon: <FaSignOutAlt className="logout-icon" />,
            onClick: handleCerrarSesion,
        }
    ];

    return (
        <div style={{ display: 'flex' }}>
            <div ref={sidebarRef} className="sidebar" style={{
                background: '#24648a',
                color: 'white',
                height: '100vh',
                width: isOpen ? "250px" : "50px",
                transition: "width 0.5s",
                position: 'fixed',
                zIndex: 1000,
            }}>
                <div className="top_section">
                    <img src={logoImage} alt="Logo" style={{ display: isOpen ? "block" : "none", width: isOpen ? "150px" : "50px", height: isOpen ? "150px" : "50px" }} className="logo" />
                    <div style={{ marginLeft: isOpen ? "50px" : "0px" }} className="bars">
                        <FaBars className={isOpen ? "bars-icon active" : "bars-icon"} onClick={toggle} />
                    </div>
                </div>
                {
                    menuItems.map((item, index) => (
                        <div key={index}>
                            {item.subMenu ? (
                                <>
                                    <div
                                        className={`link ${activeMenuIndex === index ? "active" : ""}`}
                                        onClick={() => handleSubMenuClick(index)}>
                                        <div className="icon">{item.icon}</div>
                                        <div style={{ display: isOpen ? "block" : "none" }} className="link_text">{item.name}</div>
                                    </div>
                                    {activeSubMenuIndex === index && isOpen && item.subMenu.map((subItem, subIndex) => (
                                        <NavLink to={subItem.path} key={subIndex} className="link sub-link"
                                            activeclassname="active" onClick={handleMenuItemClick}>
                                            <div className="icon">{subItem.icon}</div>
                                            <div style={{ display: isOpen ? "block" : "none" }} className="link_text">{subItem.name}</div>
                                        </NavLink>
                                    ))}
                                </>
                            ) : (
                                <NavLink to={item.path} key={index} className="link"
                                    activeclassname="active" onClick={item.onClick || handleMenuItemClick}>
                                    <div className="icon">{item.icon}</div>
                                    <div style={{ display: isOpen ? "block" : "none" }} className="link_text">{item.name}</div>
                                </NavLink>
                            )}
                        </div>
                    ))
                }
            </div>
            <div className={`main-container ${isOpen ? "expanded" : ""}`}>
                {children}
            </div>
        </div>
    );
};

export default SideBar;

import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import { useNavigate, useParams } from 'react-router-dom';
import '../../Estilos/StyleFormulario.css';


const URI = 'https://cisrubenandino.com/ap/enfermerias/';
const VALIDAR_PERMISO_URI = 'https://cisrubenandino.com/ap/validar-ena-';
const VALIDAR_MODULO_URI = 'https://cisrubenandino.com/ap/validar-mod-e';

const CompEditEnfer = () => {  // Recibe el nombre de producto como una prop
    
    const [NOM_Producto, setNOM_Producto] = useState('');
    const [Especificacion, setEspecificacion] = useState('');
    const [Tamano, setTamano] = useState('');
    const [Capacidad, setCapacidad] = useState('');
    const [Tipo, setTipo] = useState('');
    const [Cantidad, setCantidad] = useState('');
    const [Precio, setPrecio] = useState('');
    const [Dosificacion_1, setDosificacion_1] = useState('');
    const [Dosificacion_2, setDosificacion_2] = useState('');
    const [Dosificacion_3, setDosificacion_3] = useState('');
    const [Lote, setLote] = useState('');
    const [FEC_Vencimiento, setFEC_Vencimiento] = useState('');
    const [Aplicadas, setAplicadas] = useState('');
    const [Perdidas, setPerdidas] = useState('');
    const [Descartadas, setDescartadas] = useState('');
    const [CAN_Usada, setCAN_Usada] = useState('');
    const [Usr_Registro,  setUsr_Registro] = useState('');

    const Navigate = useNavigate();
    const {ID_Producto} = useParams();
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [ConfirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
    const [originalData, setOriginalData] = useState(null);

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };
    const openConfirmModal = () => {
        setConfirmModalIsOpen(true);
    };
    const closeConfirmModal = () => {
        setConfirmModalIsOpen(false);
    };
    const handleModalClose = () => {
        closeModal();
        if (message === '¡Datos actualizados exitosamente!') {
            Navigate('/MostrarEN'); // Regresa después de una actualización exitosa
        }
    };


     // Validar acceso al módulo
     const validarAccesoModulo = useCallback(async () => {
        try {
            const res = await axios.get(VALIDAR_MODULO_URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
    
            if (res.status !== 200 || !res.data.acceso) {
                throw new Error(res.data.mensaje || 'Acceso denegado.');
            }
        } catch (error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                setError('No tienes autorización para acceder a este apartado. Si crees que se trata de un error, comunícaselo al administrador.');
            } else {
                setError('No ha sido posible validar el acceso. Contacta con el administrador.');
            }
            setLoading(false);
            throw error;
        }
    }, []);
    

    // Validar permisos del usuario
    const validarPermisoUsuario = useCallback(async () => {
        try {
            const res = await axios.get(VALIDAR_PERMISO_URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (res.status !== 200 || !res.data.acceso) {
                throw new Error(res.data.mensaje || 'Permiso denegado.');
            }
        } catch (error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                setError('No se cuenta con el acceso completo para acceder a este apartado.');
            } else {
                setError('No ha sido posible validar el acceso. Contacta con el administrador.');
            }
            setLoading(false);
            throw error;
        }
    }, []);
   

    useEffect(() => {
        const getProductoByID = async () => {
            try {
                const response = await axios.get(URI + ID_Producto);
                const data = response.data;
                setNOM_Producto(data.NOM_Producto);
                setEspecificacion(data.Especificacion);
                setTamano(data.Tamano);
                setCapacidad(data.Capacidad);
                setTipo(data.Tipo);
                setCantidad(data.Cantidad);
                setPrecio(data.Precio);
                setDosificacion_1(data.Dosificacion_1);
                setDosificacion_2(data.Dosificacion_2);
                setDosificacion_3(data.Dosificacion_3);
                setLote(data.Lote);
                setFEC_Vencimiento(data.FEC_Vencimiento);
                setAplicadas(data.Aplicadas);
                setPerdidas(data.Perdidas);
                setDescartadas(data.Descartadas);
                setCAN_Usada(data.CAN_Usada);
                setOriginalData(data);
            } catch (error) {
                setMessage('¡Ocurrió un error y no se pudo actualizar el producto!, intentalo de nuevo');
                
            }
        };

        getProductoByID();
    }, [ID_Producto, Navigate]);
   
    useEffect(() => {
        const validarAccesos = async () => {
            try {
                await validarAccesoModulo();
                await validarPermisoUsuario();
                setLoading(false);
            } catch (err) {
                setLoading(false);
            }
        };

        validarAccesos();
    }, [validarAccesoModulo, validarPermisoUsuario]);


    //Comprobar si se ha realizado al menos un cambio en un campo
    const handleSubmit = (e) => {
        e.preventDefault();
        const hasChanges =
            NOM_Producto !== originalData.NOM_Producto ||
            Especificacion !== originalData.PA_Primer_Nombre ||
            Tamano !== originalData.PA_Segundo_Nombre ||
            Capacidad !== originalData.PA_Primer_Apellido ||
            Tipo !== originalData.PA_Segundo_Apellido ||
            Cantidad !== originalData.DESC_Consulta ||
            Precio !== originalData.FEC_Nacimiento ||
            Dosificacion_1 !== originalData.PA_Excento ||
            Dosificacion_2 !== originalData.Identidad ||
            Dosificacion_3 !== originalData.FEC_Creacion ||
            Lote !== originalData.ULTI_Visita ||
            FEC_Vencimiento !== originalData.Receta ||
            Aplicadas !== originalData.NUM_Integrantes ||
            Perdidas !== originalData.Direccion ||
            Descartadas !== originalData.NUM_Telefono ||
            CAN_Usada !== originalData.CAN_Usada;

        if (hasChanges) {
            //Si se ha cambiado al menos un dato se procede a realizar el cambio
            openConfirmModal(); 
        } else {
            //Si no se ha detectado al menos un cambio se muestra un mensaje
            setMessage('No se han realizado cambios en el Producto.');
            openModal();
        }
    };


     //Procedimiento para editar y/o insertar
     const update = async (e) => {
        e.preventDefault();
        closeConfirmModal();


        const formattedFEC_Vencimiento = new Date(FEC_Vencimiento).toISOString().split('T')[0];

        try {
        await axios.put(URI + ID_Producto, {

            NOM_Producto, 
            Especificacion, 
            Tamano,
            Capacidad, 
            Tipo,
            Cantidad,
            Precio,
            Dosificacion_1,
            Dosificacion_2,
            Dosificacion_3,
            Lote, 
            FEC_Vencimiento: formattedFEC_Vencimiento, 
            Aplicadas,
            Perdidas,
            Descartadas,
            CAN_Usada,
            Usr_Registro,
        }, {
        headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
    });
    setMessage('¡Datos actualizados exitosamente!'); 
    openModal();
} catch (error) {
    setMessage('¡Ocurrió un error y no se pudo actualizar el expediente!, intentalo de nuevo'); // Establecer el mensaje de error
    openModal(); 
}
};

    //Procedimiento para cancelar
    const cancel = () => {
        closeModal();
        Navigate('/MostrarEN');
    };


    // Restaurar un campo especifico al valor original, si el usuario se equivoco y quiere regresar al valor existente
    const restoreField = (fieldName) => {
        switch (fieldName) {
            case 'NOM_Producto':
                setNOM_Producto(originalData.NOM_Producto);
                break;
            case 'Especificacion':
                setEspecificacion(originalData.Especificacion);
                break;
            case 'Tamano':
                setTamano(originalData.Tamano);
                break;
            case 'Capacidad':
                setCapacidad(originalData.Capacidad);
                break;
            case 'Tipo':
                setTipo(originalData.Tipo);
                break;
            case 'Cantidad':
                setCantidad(originalData.Cantidad);
                break;
            case 'Precio':
                setPrecio(originalData.Precio);
                break;
            case 'Dosificacion_1':
                setDosificacion_1(originalData.Dosificacion_1);
                break;
            case 'Dosificacion_2':
                setDosificacion_2(originalData.Dosificacion_2);
                break;
            case 'Dosificacion_3':
                setDosificacion_3(originalData.Dosificacion_3);
                break;
            case 'Lote':
                setLote(originalData.Lote);
                break;
            case 'FEC_Vencimiento':
                setFEC_Vencimiento(originalData.FEC_Vencimiento);
                break;
            case 'Aplicadas':
                setAplicadas(originalData.Aplicadas);
                break;
            case 'Perdidas':
                setPerdidas(originalData.Perdidas);
                break;
            case 'Descartadas':
                setDescartadas(originalData.Descartadas);
                break;
            case 'CAN_Usada':
                setCAN_Usada(originalData.CAN_Usada);
                break;    
            default:
                break;
        }
    };

     // Restaurar todos los campos al valor original
     const restoreAllFields = () => {
        setNOM_Producto(originalData.NOM_Producto);
        setEspecificacion(originalData.Especificacion);
        setTamano(originalData.Tamano);
        setCapacidad(originalData.Capacidad);
        setTipo(originalData.Tipo);
        setCantidad(originalData.Cantidad);
        setPrecio(originalData.Precio);
        setDosificacion_1(originalData.Dosificacion_1);
        setDosificacion_2(originalData.Dosificacion_2);
        setDosificacion_3(originalData.Dosificacion_3);
        setLote(originalData.Lote);
        setFEC_Vencimiento(originalData.FEC_Vencimiento);
        setAplicadas(originalData.Aplicadas);
        setPerdidas(originalData.Perdidas);
        setDescartadas(originalData.Descartadas);
        setCAN_Usada(originalData.CAN_Usada);
    };

    

    if (loading) {
        return <div>Cargando...</div>;
    }

    if (error) {
        return <div style={{ color: 'red' }}>{error}</div>;
    }

    return (
        <div> 
            <div className="main-container">
            <h3>Editar registro</h3>
            </div>
            <div className='formulario-container'>
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label className="form-label">Nombre de Producto</label>
                    <input
                       value={NOM_Producto}
                       onChange={(e)=> setNOM_Producto(e.target.value)}
                       type="text"
                       className="form-control"
                    />
                    <button type="button" className="btn btn-info" onClick={() => restoreField('NOM_Producto')}>
                    Restaurar
                    </button>
                </div>
                <div className="mb-3">
                    <label className="form-label">Especificacion</label>
                    <input
                       value={Especificacion}
                       onChange={(e)=> setEspecificacion(e.target.value)}
                       type="text"
                       className="form-control"

                    />
                    <button type="button" className="btn btn-info" onClick={() => restoreField('Especificacion')}>
                    Restaurar
                    </button>
                </div>
                <div className="mb-3">
                    <label className="form-label">Tamaño</label>
                    <input
                       value={Tamano}
                       onChange={(e)=> setTamano(e.target.value)}
                       type="text"
                       className="form-control"

                    />
                    <button type="button" className="btn btn-info" onClick={() => restoreField('Tamano')}>
                    Restaurar
                    </button>
                </div>
                <div className="mb-3">
                    <label className="form-label">Capacidad</label>
                      <input
                       value={Capacidad}
                       onChange={(e) => setCapacidad(e.target.value)}
                       type="text"
                       className="form-control"
                        />
                        <button type="button" className="btn btn-info" onClick={() => restoreField('Capacidad')}>
                        Restaurar
                    </button>                
                </div>
                <div className="mb-3">
                    <label className="form-label">Tipo</label>
                      <input
                       value={Tipo}
                       onChange={(e) => setTipo(e.target.value)}
                       className="form-control"
                       type="text"
                      />
                      <button type="button" className="btn btn-info" onClick={() => restoreField('Tipo')}>
                      Restaurar
                    </button>
                    </div>
                <div className="mb-3">
                    <label className="form-label">Cantidad</label>
                       <input
                       value={Cantidad}
                       onChange={(e) => setCantidad(e.target.value)}
                       className="form-control"
                       type="tinyint"
                    />
                    <button type="button" className="btn btn-info" onClick={() => restoreField('Cantidad')}>
                    Restaurar
                    </button>
                </div>
                <div className="mb-3">
                    <label className="form-label">Precio</label>
                    <input
                       value={Precio}
                       onChange={(e)=> setPrecio(e.target.value)}
                       type="tinyint"
                       className="form-control"

                    />
                    <button type="button" className="btn btn-info" onClick={() => restoreField('Precio')}>
                    Restaurar
                    </button>
                </div>
                <div className="mb-3">
                    <label className="form-label">Dosificacion_1</label>
                    <input
                       value={Dosificacion_1}
                       onChange={(e)=> setDosificacion_1(e.target.value)}
                       type="tinyint"
                       className="form-control"

                    />
                    <button type="button" className="btn btn-info" onClick={() => restoreField('Dosificacion_1')}>
                    Restaurar
                    </button>
                </div>
                <div className="mb-3">
                    <label className="form-label">Dosificacion_2</label>
                    <input
                       value={Dosificacion_2}
                       onChange={(e)=> setDosificacion_2(e.target.value)}
                       type="tinyint"
                       className="form-control"

                    />
                    <button type="button" className="btn btn-info" onClick={() => restoreField('Dosificacion_2')}>
                    Restaurar
                    </button>
                </div>
                <div className="mb-3">
                    <label className="form-label">Dosificacion_3</label>
                    <input
                       value={Dosificacion_3}
                       onChange={(e) => setDosificacion_3(e.target.value)}
                       className="form-control"
                       type="tinyint"
                    />
                    <button type="button" className="btn btn-info" onClick={() => restoreField('Dosificacion_3')}>
                    Restaurar
                    </button>
                </div>
                <div className="mb-3">
                    <label className="form-label">Lote</label>
                    <input
                       value={Lote}
                       onChange={(e) => setLote(e.target.value)}
                       className="form-control"
                       type="text"
                    
                    />
                    <button type="button" className="btn btn-info" onClick={() => restoreField('Lote')}>
                    Restaurar
                    </button>
                </div>
                <div className="mb-3">
                    <label className="form-label">Fecha de Vencimiento</label>
                    <input
                       value={FEC_Vencimiento}
                       onChange={(e)=> setFEC_Vencimiento(e.target.value)}
                       type="date"
                       className="form-control"

                    />
                    <button type="button" className="btn btn-info" onClick={() => restoreField('FEC_Vencimiento')}>
                    Restaurar
                    </button>
                </div>
                <div className="mb-3">
                    <label className="form-label">Aplicadas</label>
                    <input
                       value={Aplicadas}
                       onChange={(e) => setAplicadas(e.target.value)}
                       className="form-control"
                       type="tinyint"

                    />
                    <button type="button" className="btn btn-info" onClick={() => restoreField('Aplicadas')}>
                    Restaurar
                    </button>
                </div>
                <div className="mb-3">
                    <label className="form-label">Perdidas</label>
                    <input
                       value={Perdidas}
                       onChange={(e) => setPerdidas(e.target.value)}
                       className="form-control"
                       type="tinyint"

                    />
                    <button type="button" className="btn btn-info" onClick={() => restoreField('Perdidas')}>
                        Restaurar
                    </button>
                </div>
                <div className="mb-3">
                    <label className="form-label">Descartadas</label>
                    <input
                       value={Descartadas}
                       onChange={(e) => setDescartadas(e.target.value)}
                       className="form-control"
                       type="tinyint"

                    />
                    <button type="button" className="btn btn-info" onClick={() => restoreField('Descartadas')}>
                        Restaurar
                    </button>
                </div>
                <div className="mb-3">
                    <label className="form-label">Cantidad Usada</label>
                    <input
                       value={CAN_Usada}
                       onChange={(e) => setCAN_Usada(e.target.value)}
                       className="form-control"
                       type="tinyint"
                    
                    />
                    <button type="button" className="btn btn-info" onClick={() => restoreField('Can_Usada')}>
                        Restaurar
                    </button>
                </div>
                
                <button type="button" className="btn btn-success" onClick={handleSubmit} > Guardar</button>
                <button type="button" className="btn btn-primary" onClick={restoreAllFields}> Restaurar</button>
                <button type="button" className="btn btn-danger" onClick={cancel}>Cancelar</button>
            </form>
           
            <Modal
                isOpen={ConfirmModalIsOpen}
                onRequestClose={closeConfirmModal}
                contentLabel="Confirmacion de modal"
            >
                 <h2>¿Estás seguro de que deseas actualizar el Producto?</h2>
                <button onClick={update}>Si, Actualizar</button>
                <button onClick={closeConfirmModal}>No, regresar</button>
            </Modal>
            {/* Modal de mensaje */}
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={cancel}
                contentLabel="Mensaje Modal"
            >
                <h2>{message}</h2>
                <button onClick={handleModalClose}>Aceptar</button>
            </Modal>
        </div>
      </div>  
    )
}
export default CompEditEnfer

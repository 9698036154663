import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Modal from 'react-modal';
import '../../Estilos/StyleFormulario.css';

const URI = 'https://cisrubenandino.com/ap/parametros_empresa/';
const VALIDAR_MODULO_URI = 'https://cisrubenandino.com/ap/validar-mod-PE';
const VALIDAR_PERMISO_URI = 'https://cisrubenandino.com/ap/validar-PEI-';

const CompCreateEmpresaParametros = () => {
    const [Nombre, setNombre] = useState('');
    const [RZN_Social, setRZN_Social] = useState('');
    const [RTN, setRTN] = useState('');
    const [Direccion, setDireccion] = useState('');
    const [Sucursales, setSucursales] = useState('');
    const [Usr_Registro, setUsr_Registro] = useState('');
    const [Num_Telefonico, setNum_Telefonico] = useState('');
    const [RED_SOCIAL, setRED_SOCIAL] = useState('');
    const [URL_RED_SOCIAL, setURL_RED_SOCIAL] = useState('');
    const [CRO_ELTO_Principal, setCRO_ELTO_Principal] = useState('');
    const Navigate = useNavigate();
    const [message, setMessage] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [direccionLength, setDireccionLength] = useState(0);
    const maxLength = 100;
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState({});
    const [touched, setTouched] = useState({});

    // Función para validar que se tenga acceso al modulo
    const validarAccesoModulo = useCallback(async () => {
        try {
            const res = await axios.get(VALIDAR_MODULO_URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (res.status !== 200 || !res.data.acceso) {
                throw new Error(res.data.mensaje || 'Acceso denegado.');
            }
        } catch (error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                setErrors('No tienes autorizacion para acceder a este apartado. Si crees que se trata de un error, comunicaselo al administrador.');
            } else {
                setErrors('No ha sido posible validar el acceso. Contacta con el administrador.');
            }
            setLoading(false);
            throw error;
        }
    }, []);

    // Función para validar permisos del usuario
    const validarPermisoUsuario = useCallback(async () => {
        try {
        const res = await axios.get(VALIDAR_PERMISO_URI, {
            headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        });
        if (res.status !== 200 || !res.data.acceso) {
            throw new Error(res.data.mensaje || 'Permiso denegado.');
        }
        } catch (error) {
        setErrors('No tienes autorización para realizar esta acción. Si crees que se trata de un error, comunícalo al administrador.');
        setLoading(false);
        throw error;
        }
    }, []);

    // UseEffect para validar acceso, permisos y cargar datos
    useEffect(() => {
        const fetchData = async () => {
          try {
            await validarAccesoModulo(); // Valida el acceso al módulo
            await validarPermisoUsuario(); // Valida el permiso del usuario
          } catch (error) {
            console.error('Error durante la carga de datos:', error);
          } finally {
            setLoading(false);
          }
        };
        fetchData();
      }, [validarAccesoModulo, validarPermisoUsuario]);    

    const handleBlur = (field) => {
        setTouched({
            ...touched,
            [field]: true,
        });
        validateInputs();
    };

    const validateInputs = () => {
        let errorsObj = {};
        
        // Validación del nombre de la empresa
        if (!Nombre.trim()) {
            errorsObj.Nombre = 'El nombre de la empresa es requerido';
        } else if (!/^[a-zA-Z0-9\sáéíóúñÁÉÍÓÚÑ]+$/.test(Nombre)) {
            errorsObj.Nombre = 'El nombre de la empresa no debe contener caracteres especiales';
        } else if (Nombre.trim().length > 50) {
            errorsObj.Nombre = 'El nombre de la empresa no puede exceder los 50 caracteres';
        }
    
        // Validación de la razón social
        if (!RZN_Social.trim()) {
            errorsObj.RZN_Social = 'La razón social de la empresa es requerida';
        } else if (RZN_Social.trim().length > 50) {
            errorsObj.RZN_Social = 'La razón social de la empresa no puede exceder los 50 caracteres';
        }
    
        // Validación del RTN
        if (!RTN.trim()) {
            errorsObj.RTN = 'El RTN de la empresa es requerido';
        } else if (!/^\d+$/.test(RTN)) {
            errorsObj.RTN = 'El RTN solo puede contener dígitos';
        } else if (RTN.trim().length !== 14) {
            errorsObj.RTN = 'El RTN debe tener exactamente 14 dígitos';
        }
    
        // Validación de la dirección
        if (!Direccion.trim()) {
            errorsObj.Direccion = 'La dirección es requerida';
        } else if (Direccion.trim().length > maxLength) {
            errorsObj.Direccion = `La dirección no puede exceder los ${maxLength} caracteres`;
        }
    
        // Validación de las sucursales
        if (Sucursales.trim().length > 100) {
            errorsObj.Sucursales = 'Las sucursales no pueden exceder los 100 caracteres';
        }
    
        // Validación del número telefónico
        if (!Num_Telefonico.trim()) {
            errorsObj.Num_Telefonico = 'El número telefónico es requerido';
        } else if (!/^\d{8}$/.test(Num_Telefonico)) {
            errorsObj.Num_Telefonico = 'El número telefónico debe tener exactamente 8 dígitos';
        }
    
        // Validación de la red social
        if (!/^[a-zA-Z0-9\sáéíóúñÁÉÍÓÚÑ]+$/.test(RED_SOCIAL)) {
            errorsObj.RED_SOCIAL = 'La red social no debe contener caracteres especiales';
        } else if (RED_SOCIAL.trim().length > 50) {
            errorsObj.RED_SOCIAL = 'La red social no puede exceder los 50 caracteres';
        }
    
        // Validación de la URL de la red social
        const urlRegex = /^(https?:\/\/)?([\w\d-]+\.)+[\w\d-]+(\/[\w\d-]*)*$/i;
        if (URL_RED_SOCIAL.trim().length > 100) {
            errorsObj.URL_RED_SOCIAL = 'La URL de la red social no puede exceder los 100 caracteres';
        } else if (!urlRegex.test(URL_RED_SOCIAL)) {
            errorsObj.URL_RED_SOCIAL = 'Debe ingresar una URL válida';
        }
    
        // Validación del correo electrónico principal
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(CRO_ELTO_Principal)) {
            errorsObj.CRO_ELTO_Principal = 'Debe ingresar un correo electrónico válido';
        } else if (CRO_ELTO_Principal.trim().length > 100) {
            errorsObj.CRO_ELTO_Principal = 'El correo electrónico principal no puede exceder los 100 caracteres';
        }
    
        setErrors(errorsObj);
        return Object.keys(errorsObj).length === 0;
    };    

    const handleInputChange = (e, setFieldValue, fieldName) => {
        setFieldValue(e.target.value);
        setTouched({
            ...touched,
            [fieldName]: true,
        });
        validateInputs();
    };

    const handleDireccionChange = (e) => {
        const value = e.target.value;
        if (value.length <= maxLength) {
            setDireccion(value);
            setDireccionLength(value.length);
            setTouched({
                ...touched,
                Direccion: true,
            });
            validateInputs();
        }
    };
    
    const handleSucursalesChange = (e) => {
        const value = e.target.value;
        setSucursales(value);
        setTouched({
            ...touched,
            Sucursales: true,
        });
        validateInputs();
    };

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const clearInputs = () => {
        setNombre('');
        setRZN_Social('');
        setRTN('');
        setDireccion('');
        setSucursales('');
        setUsr_Registro('');
        setNum_Telefonico('');
        setRED_SOCIAL('');
        setURL_RED_SOCIAL('');
        setCRO_ELTO_Principal('');
        setTouched({});
        setErrors({});
    };

    const handleCancel = () => {
        clearInputs();
        Navigate(-1);
    };

    const store = async (e) => {
        e.preventDefault();
        if (validateInputs()) {
            try {
                const token = localStorage.getItem('token');
                console.log('Token:', token); // Debugging token retrieval
    
                const res = await axios.post(URI, {
                    Nombre: Nombre,
                    RZN_Social: RZN_Social,
                    RTN: RTN,
                    Direccion: Direccion,
                    Sucursales: Sucursales,
                    Usr_Registro: Usr_Registro,
                    Num_Telefonico: Num_Telefonico,
                    RED_SOCIAL: RED_SOCIAL,
                    URL_RED_SOCIAL: URL_RED_SOCIAL,
                    CRO_ELTO_Principal: CRO_ELTO_Principal,
                }, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                setMessage('¡Parametro creado exitosamente!');
                openModal();
                clearInputs();
            } catch (error) {
                console.error('Error:', error.response ? error.response.data : error.message);
                setMessage('¡Ocurrió un error y no se ha podido crear el parámetro!');
            }
        } else {
            setMessage('Por favor, complete todos los campos requeridos.');
            openModal();
        }
    };
    

    return (
        <div className="main-container d-flex justify-content-center align-items-center" style={{ minHeight: "100vh" }}>
            <div className="card p-4 w-75 shadow-lg rounded">
                <h3 className="text-center mb-4">Registrar un nuevo parámetro</h3>
                <form onSubmit={store}>
                    <div className="mb-3">
                        <label className="form-label">Nombre de la Empresa</label>
                        <input
                            value={Nombre}
                            onChange={(e) => handleInputChange(e, setNombre, 'Nombre')}
                            onBlur={() => handleBlur('Nombre')}
                            type="text"
                            className={`form-control ${errors.Nombre ? 'is-invalid' : ''}`}
                            placeholder="Ingrese aquí el nombre de la empresa"
                        />
                        {errors.Nombre && <div className="invalid-feedback">{errors.Nombre}</div>}
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Razón Social</label>
                        <textarea
                            value={RZN_Social}
                            onChange={(e) => handleInputChange(e, setRZN_Social, 'RZN_Social')}
                            onBlur={() => handleBlur('RZN_Social')}
                            className={`form-control ${errors.RZN_Social ? 'is-invalid' : ''}`}
                            placeholder="Ingrese aquí la razón social"
                            rows="3"
                        />
                        {errors.RZN_Social && <div className="invalid-feedback">{errors.RZN_Social}</div>}
                    </div>
                    <div className="mb-3">
                        <label className="form-label">RTN</label>
                        <input
                            value={RTN}
                            onChange={(e) => handleInputChange(e, setRTN, 'RTN')}
                            onBlur={() => handleBlur('RTN')}
                            type="text"
                            className={`form-control ${errors.RTN ? 'is-invalid' : ''}`}
                            placeholder="Ingrese aquí el RTN de la empresa"
                        />
                        {errors.RTN && <div className="invalid-feedback">{errors.RTN}</div>}
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Dirección</label>
                        <textarea
                            value={Direccion}
                            onChange={handleDireccionChange}
                            onBlur={() => handleBlur('Direccion')}
                            className={`form-control ${errors.Direccion ? 'is-invalid' : ''}`}
                            placeholder="Ingrese aquí la dirección de la empresa"
                            rows="3"
                        />
                        <div className="text-end">{direccionLength}/{maxLength}</div>
                        {errors.Direccion && <div className="invalid-feedback">{errors.Direccion}</div>}
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Sucursales</label>
                        <input
                            value={Sucursales}
                            onChange={handleSucursalesChange}
                            onBlur={() => handleBlur('Sucursales')}
                            type="text"
                            className={`form-control ${errors.Sucursales ? 'is-invalid' : ''}`}
                            placeholder="Ingrese aquí las sucursales"
                        />
                        {errors.Sucursales && <div className="invalid-feedback">{errors.Sucursales}</div>}
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Número Telefónico</label>
                        <input
                            value={Num_Telefonico}
                            onChange={(e) => handleInputChange(e, setNum_Telefonico, 'Num_Telefonico')}
                            onBlur={() => handleBlur('Num_Telefonico')}
                            type="text"
                            className={`form-control ${errors.Num_Telefonico ? 'is-invalid' : ''}`}
                            placeholder="Ingrese aquí el número telefónico"
                        />
                        {errors.Num_Telefonico && <div className="invalid-feedback">{errors.Num_Telefonico}</div>}
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Red Social</label>
                        <input
                            value={RED_SOCIAL}
                            onChange={(e) => handleInputChange(e, setRED_SOCIAL, 'RED_SOCIAL')}
                            onBlur={() => handleBlur('RED_SOCIAL')}
                            type="text"
                            className={`form-control ${errors.RED_SOCIAL ? 'is-invalid' : ''}`}
                            placeholder="Ingrese aquí la red social"
                        />
                        {errors.RED_SOCIAL && <div className="invalid-feedback">{errors.RED_SOCIAL}</div>}
                    </div>
                    <div className="mb-3">
                        <label className="form-label">URL Red Social</label>
                        <input
                            value={URL_RED_SOCIAL}
                            onChange={(e) => handleInputChange(e, setURL_RED_SOCIAL, 'URL_RED_SOCIAL')}
                            onBlur={() => handleBlur('URL_RED_SOCIAL')}
                            type="text"
                            className={`form-control ${errors.URL_RED_SOCIAL ? 'is-invalid' : ''}`}
                            placeholder="Ingrese aquí la URL de la red social"
                        />
                        {errors.URL_RED_SOCIAL && <div className="invalid-feedback">{errors.URL_RED_SOCIAL}</div>}
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Correo Electrónico Principal</label>
                        <input
                            value={CRO_ELTO_Principal}
                            onChange={(e) => handleInputChange(e, setCRO_ELTO_Principal, 'CRO_ELTO_Principal')}
                            onBlur={() => handleBlur('CRO_ELTO_Principal')}
                            type="email"
                            className={`form-control ${errors.CRO_ELTO_Principal ? 'is-invalid' : ''}`}
                            placeholder="Ingrese aquí el correo electrónico principal"
                        />
                        {errors.CRO_ELTO_Principal && <div className="invalid-feedback">{errors.CRO_ELTO_Principal}</div>}
                    </div>
                    <div className="d-flex justify-content-center mt-4">
                        <button type="submit" className="btn btn-primary me-2">Guardar</button>
                        <button type="button" onClick={handleCancel} className="btn btn-secondary">Cancelar</button>
                    </div>
                </form>
            </div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Registro exitoso"
                className="modal-content"
                overlayClassName="modal-overlay"
            >
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                    width: '100%',
                    textAlign: 'center'
                }}>
                    <h2>Registro exitoso</h2>
                    <p>{message}</p>
                    <button onClick={closeModal} style={{ marginTop: '20px' }}>Cerrar</button>
                </div>
            </Modal>
        </div>
    );
};

export default CompCreateEmpresaParametros;




import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import { useNavigate, useParams } from 'react-router-dom';
import '../../Estilos/StyleFormulario.css';

const URI = 'https://cisrubenandino.com/ap/parametros_empresa/'
const VALIDAR_MODULO_URI = 'https://cisrubenandino.com/ap/validar-mod-PE';
const VALIDAR_PERMISO_URI = 'https://cisrubenandino.com/ap/validar-PEA-';

const CompEditEmpresaParametros = () => {
    const [Nombre, setNombre] = useState('');
    const [RZN_Social, setRZN_Social] = useState('');
    const [RTN, setRTN] = useState('');
    const [Direccion, setDireccion] = useState('');
    const [Sucursales, setSucursales] = useState('');
    const [Usr_Registro, setUsr_Registro] = useState('');
    const [Num_Telefonico, setNum_Telefonico] = useState('');
    const [RED_SOCIAL, setRED_SOCIAL] = useState('');
    const [URL_RED_SOCIAL, setURL_RED_SOCIAL] = useState('');
    const [CRO_ELTO_Principal, setCRO_ELTO_Principal] = useState('');
    const navigate = useNavigate();
    const { ID_PRTS_Empresa } = useParams();
    const [message, setMessage] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
    const [originalData, setOriginalData] = useState(null);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(true);

    // Función para validar que se tenga acceso al módulo
    const validarAccesoModulo = useCallback(async () => {
        try {
            const res = await axios.get(VALIDAR_MODULO_URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (res.status !== 200 || !res.data.acceso) {
                throw new Error(res.data.mensaje || 'Acceso denegado.');
            }
        } catch (error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                setErrors('No tienes autorización para acceder a este apartado. Si crees que se trata de un error, comunícaselo al administrador.');
            } else {
                setErrors('No ha sido posible validar el acceso. Contacta con el administrador.');
            }
            setLoading(false);
            throw error;
        }
    }, []);

    // Función para validar el permiso necesario
    const validarPermisoUsuario = useCallback(async () => {
        try {
            const res = await axios.get(VALIDAR_PERMISO_URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (res.status !== 200 || !res.data.acceso) {
                throw new Error('No tienes autorización para acceder a este apartado.');
            }
        } catch (error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                setErrors('No se cuenta con el acceso completo para acceder a este apartado.');
            } else {
                setErrors('No ha sido posible validar el acceso. Contacta con el administrador.');
            }
            setLoading(false);
            throw error;
        }
    }, []);

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const openConfirmModal = () => {
        setConfirmModalIsOpen(true);
    };
    
    const closeConfirmModal = () => {
        setConfirmModalIsOpen(false);
    };

    const handleModalClose = () => {
        closeModal();
    };

    const validateForm = () => {
        const newErrors = {};
        if (Nombre.trim() === '') newErrors.Nombre = 'Nombre es requerido';
        if (RZN_Social.trim() === '') newErrors.RZN_Social = 'Razón Social es requerida';
        if (RTN.trim() === '') newErrors.RTN = 'RTN es requerido';
        if (Direccion.trim() === '') newErrors.Direccion = 'Dirección es requerida';
        if (Sucursales.trim() === '') newErrors.Sucursales = 'Sucursales son requeridas';
        if (Num_Telefonico === '') newErrors.Num_Telefonico = 'Número de Teléfono es requerido';
        // Validación específica para RTN (ejemplo)
        if (!/^\d+$/.test(RTN)) newErrors.RTN = 'RTN debe contener solo dígitos';
        if (RTN.length !== 14) newErrors.RTN = 'RTN debe tener exactamente 14 dígitos';
        // Validación adicional para cada campo si es necesario
        
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            const hasChanges =
                Nombre !== originalData.Nombre ||
                RZN_Social !== originalData.RZN_Social ||
                RTN !== originalData.RTN ||
                Direccion !== originalData.Direccion ||
                Sucursales !== originalData.Sucursales ||
                Usr_Registro !== originalData.Usr_Registro ||
                Num_Telefonico !== originalData.Num_Telefonico ||
                RED_SOCIAL !== originalData.RED_SOCIAL ||
                URL_RED_SOCIAL !== originalData.URL_RED_SOCIAL ||
                CRO_ELTO_Principal !== originalData.CRO_ELTO_Principal;
    
            if (hasChanges) {
                openConfirmModal();  // Aquí puedes utilizar la función
            } else {
                setMessage('No se han realizado cambios en el Parámetro.');
                openModal();
            }
        } else {
            openModal();
        }
    };

    const update = async (e) => {
        e.preventDefault();
        closeConfirmModal();
    
        try {
            const token = localStorage.getItem('token');
            if (!token) throw new Error('No token found');
    
            await axios.put(URI + ID_PRTS_Empresa, {
                Nombre,
                RZN_Social,
                RTN,
                Direccion,
                Sucursales,
                Usr_Registro,
                Num_Telefonico,
                RED_SOCIAL,
                URL_RED_SOCIAL,
                CRO_ELTO_Principal,
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
    
            setMessage('¡Parámetro actualizado exitosamente!');
            openModal();
        } catch (error) {
            console.error('Error updating parametro:', error.response?.data || error.message);
            setMessage('¡Ocurrió un error y no se pudo actualizar el parámetro!, inténtalo de nuevo');
            openModal();
        }
    };

    const cancel = () => {
        navigate('/MostrarPE');
    };

    const restoreField = (fieldName) => {
        switch (fieldName) {
            case 'Nombre':
                setNombre(originalData.Nombre);
                break;
            case 'RZN_Social':
                setRZN_Social(originalData.RZN_Social);
                break;
            case 'RTN':
                setRTN(originalData.RTN);
                break;
            case 'Direccion':
                setDireccion(originalData.Direccion);
                break;
            case 'Sucursales':
                setSucursales(originalData.Sucursales);
                break;
            case 'Usr_Registro':
                setUsr_Registro(originalData.Usr_Registro);
                break;
            case 'Num_Telefonico':
                setNum_Telefonico(originalData.Num_Telefonico);
                break;
            case 'RED_SOCIAL':
                setRED_SOCIAL(originalData.RED_SOCIAL);
                break;
            case 'URL_RED_SOCIAL':
                setURL_RED_SOCIAL(originalData.URL_RED_SOCIAL);
                break;
            case 'CRO_ELTO_Principal':
                setCRO_ELTO_Principal(originalData.CRO_ELTO_Principal);
                break;
            default:
                break;
        }
    };

    const restoreAllFields = () => {
        setNombre(originalData.Nombre);
        setRZN_Social(originalData.RZN_Social);
        setRTN(originalData.RTN);
        setDireccion(originalData.Direccion);
        setSucursales(originalData.Sucursales);
        setUsr_Registro(originalData.Usr_Registro);
        setNum_Telefonico(originalData.Num_Telefonico);
        setRED_SOCIAL(originalData.RED_SOCIAL);
        setURL_RED_SOCIAL(originalData.URL_RED_SOCIAL);
        setCRO_ELTO_Principal(originalData.CRO_ELTO_Principal);
    };

    useEffect(() => {
        const getEmpresaParametrosByID = async () => {
            try {
                const response = await axios.get(URI + ID_PRTS_Empresa, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });
                const data = response.data;
                setNombre(data.Nombre);
                setRZN_Social(data.RZN_Social);
                setRTN(data.RTN);
                setDireccion(data.Direccion);
                setSucursales(data.Sucursales);
                setUsr_Registro(data.Usr_Registro);
                setNum_Telefonico(data.Num_Telefonico);
                setRED_SOCIAL(data.RED_SOCIAL);
                setURL_RED_SOCIAL(data.URL_RED_SOCIAL);
                setCRO_ELTO_Principal(data.CRO_ELTO_Principal);
                setOriginalData(data);
            } catch (error) {
                setMessage('¡Ocurrió un error al cargar los datos!');
                console.error('Error fetching parametros:', error);
            }
        };
        getEmpresaParametrosByID();
    }, [ID_PRTS_Empresa]);

    // Efecto para ejecutar las validaciones y cargar datos al montar el componente
    useEffect(() => {
        const fetchData = async () => {
            try {
                await validarAccesoModulo(); // Valida el acceso al módulo
                await validarPermisoUsuario(); // Valida el permiso del usuario
                
                setLoading(false);
            } catch (error) {
                console.error('Error durante la carga de datos:', error);
            }
        };
        fetchData();
    }, [validarAccesoModulo, validarPermisoUsuario]);

    return (
        <div className="main-container d-flex justify-content-center align-items-center" style={{ minHeight: "100vh" }}>
            <div className="card p-4 w-75 shadow-lg rounded">
                <h3 className="text-center mb-4">Actualizar Parámetro del Empresa</h3>
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label className="form-label">Nombre de la Empresa</label>
                        <input
                            value={Nombre}
                            onChange={(e) => {
                                setNombre(e.target.value);
                                validateForm();  // Validación en tiempo real
                            }}
                            type="text"
                            className={`form-control ${errors.Nombre ? 'is-invalid' : ''}`}
                        />
                        {errors.Nombre && <div className="invalid-feedback">{errors.Nombre}</div>}
                        <button type="button" className="btn btn-info" onClick={() => restoreField('Nombre')}>
                            Limpiar
                        </button>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Razón Social</label>
                        <input
                            value={RZN_Social}
                            onChange={(e) => {
                                setRZN_Social(e.target.value);
                                validateForm();  // Validación en tiempo real
                            }}
                            type="text"
                            className={`form-control ${errors.RZN_Social ? 'is-invalid' : ''}`}
                        />
                        {errors.RZN_Social && <div className="invalid-feedback">{errors.RZN_Social}</div>}
                        <button type="button" className="btn btn-info" onClick={() => restoreField('RZN_Social')}>
                            Limpiar
                        </button>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">RTN</label>
                        <input
                            value={RTN}
                            onChange={(e) => {
                                setRTN(e.target.value);
                                validateForm();  // Validación en tiempo real
                            }}
                            type="text"
                            className={`form-control ${errors.RTN ? 'is-invalid' : ''}`}
                        />
                        {errors.RTN && <div className="invalid-feedback">{errors.RTN}</div>}
                        <button type="button" className="btn btn-info" onClick={() => restoreField('RTN')}>
                            Limpiar
                        </button>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Dirección</label>
                        <input
                            value={Direccion}
                            onChange={(e) => {
                                setDireccion(e.target.value);
                                validateForm();  // Validación en tiempo real
                            }}
                            type="text"
                            className={`form-control ${errors.Direccion ? 'is-invalid' : ''}`}
                        />
                        {errors.Direccion && <div className="invalid-feedback">{errors.Direccion}</div>}
                        <button type="button" className="btn btn-info" onClick={() => restoreField('Direccion')}>
                            Limpiar
                        </button>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Sucursales</label>
                        <input
                            value={Sucursales}
                            onChange={(e) => {
                                setSucursales(e.target.value);
                                validateForm();  // Validación en tiempo real
                            }}
                            type="text"
                            className={`form-control ${errors.Sucursales ? 'is-invalid' : ''}`}
                        />
                        {errors.Sucursales && <div className="invalid-feedback">{errors.Sucursales}</div>}
                        <button type="button" className="btn btn-info" onClick={() => restoreField('Sucursales')}>
                            Limpiar
                        </button>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Número Telefónico</label>
                        <input
                            value={Num_Telefonico}
                            onChange={(e) => {
                                setNum_Telefonico(e.target.value);
                                validateForm();  // Validación en tiempo real
                            }}
                            type="text"
                            className={`form-control ${errors.Num_Telefonico ? 'is-invalid' : ''}`}
                        />
                        {errors.Num_Telefonico && <div className="invalid-feedback">{errors.Num_Telefonico}</div>}
                        <button type="button" className="btn btn-info" onClick={() => restoreField('Num_Telefonico')}>
                            Limpiar
                        </button>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Red Social</label>
                        <input
                            value={RED_SOCIAL}
                            onChange={(e) => {
                                setRED_SOCIAL(e.target.value);
                                validateForm();  // Validación en tiempo real
                            }}
                            type="text"
                            className={`form-control ${errors.RED_SOCIAL ? 'is-invalid' : ''}`}
                        />
                        {errors.RED_SOCIAL && <div className="invalid-feedback">{errors.RED_SOCIAL}</div>}
                        <button type="button" className="btn btn-info" onClick={() => restoreField('RED_SOCIAL')}>
                            Limpiar
                        </button>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">URL Red Social</label>
                        <input
                            value={URL_RED_SOCIAL}
                            onChange={(e) => {
                                setURL_RED_SOCIAL(e.target.value);
                                validateForm();  // Validación en tiempo real
                            }}
                            type="text"
                            className={`form-control ${errors.URL_RED_SOCIAL ? 'is-invalid' : ''}`}
                        />
                        {errors.URL_RED_SOCIAL && <div className="invalid-feedback">{errors.URL_RED_SOCIAL}</div>}
                        <button type="button" className="btn btn-info" onClick={() => restoreField('URL_RED_SOCIAL')}>
                            Limpiar
                        </button>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Correo Electrónico Principal</label>
                        <input
                            value={CRO_ELTO_Principal}
                            onChange={(e) => {
                                setCRO_ELTO_Principal(e.target.value);
                                validateForm();  // Validación en tiempo real
                            }}
                            type="email"
                            className={`form-control ${errors.CRO_ELTO_Principal ? 'is-invalid' : ''}`}
                        />
                        {errors.CRO_ELTO_Principal && <div className="invalid-feedback">{errors.CRO_ELTO_Principal}</div>}
                        <button type="button" className="btn btn-info" onClick={() => restoreField('CRO_ELTO_Principal')}>
                            Limpiar
                        </button>
                    </div>
                    <div className="text-center">
                        <button type="submit" className="btn btn-primary">Actualizar</button>
                        <button type="button" className="btn btn-secondary ms-2" onClick={cancel}>Cancelar</button>
                        <button type="button" className="btn btn-warning ms-2" onClick={restoreAllFields}>Restaurar Todo</button>
                    </div>
                </form>
            </div>

            {/* Modal para mensajes */}
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={handleModalClose}
                contentLabel="Mensaje"
                ariaHideApp={false}
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                    },
                }}
            >
                <h2>Mensaje</h2>
                <p>{message}</p>
                <button onClick={handleModalClose}>Cerrar</button>
            </Modal>

            {/* Modal de confirmación */}
            <Modal
                isOpen={confirmModalIsOpen}
                onRequestClose={closeConfirmModal}
                contentLabel="Confirmación"
                ariaHideApp={false}
                style={{
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                    },
                }}
            >
                <h2>Confirmación</h2>
                <p>¿Estás seguro de que deseas actualizar el parámetro?</p>
                <button onClick={update}>Sí</button>
                <button onClick={closeConfirmModal}>No</button>
            </Modal>
        </div>
    );
};

export default CompEditEmpresaParametros;


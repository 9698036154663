import React, { useState } from "react";
import { Button, Spinner } from "reactstrap";
import * as XLSX from "xlsx";

const BotonExcel = ({ productos }) => {
  const [loading, setLoading] = useState(false);

  const titulo = [{ A: "Registro de consultas medicas" }, {}];
  
  const longitudes = [25, 35, 25, 20, 20, 15, 25, 40, 20];

  const handleDownload = () => {
    setLoading(true);

    let tabla = [
      {
        A: "Medico",
        B: "Fecha de la ultima atencion anterior",
        C: "Fecha ultima atencion",
        D: "Tipo de atencion",
        E: "Tipo de jornada",
        F: "Genero",
        G: "Fecha de nacimiento",
        H: "Rango de edad",
        I: "Tipo de medico",
      },
    ];

    productos.forEach((producto) => {
      tabla.push({
        A: producto.Id_Medico,
        B: producto.Fecha,
        C: producto.Fec_Atencion,
        D: producto.Id_Tip_Atencion,
        E: producto.Id_Jornada,
        F: producto.Genero,
        G: producto.Fec_Nacimiento,
        H: producto.Id_Ran_Edad_Pac,
        I: producto.Id_Tip_Medico,
      });
    });

    const dataFinal = [...titulo, ...tabla];

    setTimeout(() => {
      creandoArchivo(dataFinal);
      setLoading(false);
    }, 1000);
  };

  const creandoArchivo = (dataFinal) => {
    const libro = XLSX.utils.book_new();

    const hoja = XLSX.utils.json_to_sheet(dataFinal, { skipHeader: true });

    hoja["!merges"] = [
      XLSX.utils.decode_range("A1:G1"),
      XLSX.utils.decode_range("A2:G2"),
      XLSX.utils.decode_range("A34:G34"),
    ];

    let propiedades = [];

    longitudes.forEach((col) => {
      propiedades.push({
        width: col,
      });
    });

    hoja["!cols"] = propiedades;

    XLSX.utils.book_append_sheet(libro, hoja, "Registro de consultas medicas");

    XLSX.writeFile(libro, "RConsultasMedicas.xlsx");
  };

  return (
    <>
      {!loading ? (
        <Button color="success" onClick={handleDownload}>
          Imprimir reporte
        </Button>
      ) : (
        <Button color="success" disabled>
          <Spinner size="sm">Loading...</Spinner>
          <span> Generando...</span>
        </Button>
      )}
    </>
  );
};

export default BotonExcel;

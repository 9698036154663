import '../../../Estilos/StyleTableDatos.css';
import React, { useState, useEffect, useCallback } from "react";
import {useNavigate} from 'react-router-dom';
import axios from "axios";
import { Link } from "react-router-dom";
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css'; // Estilos de DataTables para Bootstrap 5
import $ from 'jquery'; // Importa jQuery
import 'datatables.net-bs5'; // Importa DataTables para Bootstrap 5

const URI = 'https://cisrubenandino.com/ap/control_personal/';
const VALIDAR_MODULO_URI = 'https://cisrubenandino.com/ap/validar-mod-E';
const VALIDAR_PERMISO_URI = 'https://cisrubenandino.com/ap/validar-SE-';

const CompShowPersonal = () => {
    const [personal, setPersonal] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    // Función para validar que se tenga acceso al modulo
    const validarAccesoModulo = useCallback(async () => {
        try {
            const res = await axios.get(VALIDAR_MODULO_URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (res.status !== 200 || !res.data.acceso) {
                throw new Error(res.data.mensaje || 'Acceso denegado.');
            }
        } catch (error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                setError('No tienes autorizacion para acceder a este apartado. Si crees que se trata de un error, comunicaselo al administrador.');
            } else {
                setError('No ha sido posible validar el acceso. Contacta con el administrador.');
            }
            setLoading(false);
            throw error;
        }
    }, []);

    // Función para validar el permiso necesario
    const validarPermisoControlPersonalRoutes = useCallback(async () => {
        try {
            const res = await axios.get(VALIDAR_PERMISO_URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (res.status !== 200 || !res.data.acceso) {
                setError('No tienes autorización para acceder a este apartado. Si crees que se trata de un error, comunícaselo al administrador.');
            }
        } catch (error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                setError('No se cuenta con el acceso completo para acceder a este apartado.');
            } else {
                setError('No ha sido posible validar el acceso. Contacta con el administrador.');
            }
            setLoading(false);
            throw error;
        }
    }, []);







    const getPersonal = useCallback(async () => {
        try {
            const res = await axios.get(URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (Array.isArray(res.data) && res.data.length > 0) {
                const personalFormatted = res.data.map((personal) => ({
                    ...personal,
                    FEC_NAC_CONTROL_PERSONAL: formatDate(personal.FEC_NAC_CONTROL_PERSONAL),
                    FEC_INGRESO: formatDate(personal.FEC_INGRESO),
                }));
                setPersonal(personalFormatted);
            } else {
                setPersonal([]);
            }
        } catch (error) {
             // Manejo de error para los códigos de estado 401 y 403
        if (error.response) {
            if (error.response.status === 401 || error.response.status === 403) {
                // Redirige a la página de InicioNoAutorizado
                localStorage.removeItem('token'); // Elimina el token
                localStorage.removeItem('username'); // Elimina el nombre de usuario
                setError('No ha sido posible validar tus credenciales, vuelve a iniciar sesión.');
                navigate(`/InicioNoAutorizado?error=${encodeURIComponent(error.response.data.message)}`);
            } else {
                // Otro tipo de error
                setError('No ha sido posible encontrar los datos. Por favor vuelve a intentar, si el problema persiste, contacta con el administrador.');
            }
        } else {
            // Error sin respuesta del servidor (puede ser de red o otro detalle)
            setError('No ha sido posible verificar los datos en este momento. Por favor, contacta con el administrador.');
        }
        } finally {
            setLoading(false);
        }
    }, [navigate]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await validarAccesoModulo(); // Valida el acceso al módulo
                await validarPermisoControlPersonalRoutes(); // Valida el permiso del usuario
                await getPersonal(); // Obtiene los datos de los usuarios si se tiene acceso
            } catch (error) {
                console.error("Ha ocurrido un problema", error);
            }
        };
        fetchData();
    }, [validarAccesoModulo, validarPermisoControlPersonalRoutes, getPersonal]);




    useEffect(() => {
        getPersonal();
    }, [getPersonal]);

    useEffect(() => {
        if ($.fn.DataTable.isDataTable('#dataTablePersonal')) {
            $('#dataTablePersonal').DataTable().destroy();
        }
        
        if (personal.length > 0) {
            $('#dataTablePersonal').DataTable();
        }
    }, [personal]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        date.setDate(date.getDate() + 1);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const deletePersonal = async (ID_CONTROL_PERSONAL) => {
        const confirmDelete = window.confirm("¿Está seguro de que desea eliminar este expediente de personal?");
        
        if (confirmDelete) {
            try {
                await axios.delete(`${URI}${ID_CONTROL_PERSONAL}`);
                window.location.reload();
            } catch (error) {
                console.error('Error al eliminar expediente del personal:', error);
            }
        }
    };

    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };

    // Función para cancelar y regresar a la página anterior
    const cancel = () => {
        navigate('/Inicio');
    };

    // Muestra un indicador de carga mientras se obtienen los datos
if (loading) {
    return (
        <div className="loading-container">
            <p>Cargando datos... Por favor, espera.</p>
        </div>
    );
}

// Muestra un mensaje de error en caso de que ocurra
if (error) {
    return (
        <div className="error-container">
            <p>{error}</p>
            <button className="btn btn-secondary" onClick={cancel}>Volver a inicio</button>
        </div>
    );
}

    return (
        <div className="main-container">
            <div className="row">
                <div className="col">
                    <Link to="/createP" className="btn btn-primary mb-3">Crear un nuevo expediente del personal <i className="fa-solid fa-file-circle-plus"></i></Link>
                    <div className="table-container">
                        <table id="dataTablePersonal" className="table table-striped" style={{ width: "100%" }}>
                            <thead>
                                <tr>
                                    <th className="text-center">N# EMP</th>
                                    <th className="text-center">Primer Nombre</th>
                                    <th className="text-center">Segundo Nombre</th>
                                    <th className="text-center">Primer Apellido</th>
                                    <th className="text-center">Segundo Apellido</th>
                                    <th className="text-center">E-mail</th>
                                    <th className="text-center">Dirección</th>
                                    <th className="text-center">Teléfono</th>
                                    <th className="text-center">Nacimiento</th>
                                    <th className="text-center">Vacaciones</th>
                                    <th className="text-center">Categoría</th>
                                    <th className="text-center">Ingreso</th>
                                    <th className="text-center">Puesto</th>
                                    <th className="text-center">Sangre</th>
                                    <th className="text-center">Opciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {loading ? (
                                    <tr>
                                        <td colSpan="15">Cargando...</td>
                                    </tr>
                                ) : error ? (
                                    <tr>
                                        <td colSpan="15">{error}</td>
                                    </tr>
                                ) : personal.length === 0 ? (
                                    <tr>
                                        <td colSpan="15">No hay datos del personal para mostrar, no existe o no está creado.</td>
                                    </tr>
                                ) : (
                                    personal.map((personal) => (
                                        <tr key={personal.ID_CONTROL_PERSONAL}>
                                            <td className="text-center"> {personal.NUM_EMP_CONTROL_PERSONAL} </td>
                                            <td className="text-center"> {personal.PRI_NOM_CONTROL_PERSONAL} </td>
                                            <td className="text-center"> {personal.SEG_NOM_CONTROL_PERSONAL || <i>ND</i>} </td>
                                            <td className="text-center"> {personal.PRI_APE_CONTROL_PERSONAL} </td>
                                            <td className="text-center"> {personal.SEG_APE_CONTROL_PERSONAL || <i>ND</i>} </td>
                                            <td title={personal.COR_ELEC_CONTROL_PERSONAL} className="text-center text-truncated">
                                                {truncateText(personal.COR_ELEC_CONTROL_PERSONAL, 25)}
                                            </td>
                                            <td className="text-center"> {personal.DIRECCION || <i>ND</i>} </td>
                                            <td className="text-center"> {personal.TELEFONO || <i>ND</i>} </td>
                                            <td className="text-center"> {personal.FEC_NAC_CONTROL_PERSONAL} </td>
                                            <td className="text-center"> {personal.VACACIONES || <i>ND</i>} </td>
                                            <td className="text-center"> {personal.CATEGORIA || <i>ND</i>} </td>
                                            <td className="text-center"> {personal.FEC_INGRESO} </td>
                                            <td className="text-center"> {personal.NOM_PUESTO} </td>
                                            <td className="text-center"> {personal.TIP_SANGRE} </td>
                                            <td className="text-center">
                                                <Link to={`/EditP/${personal.ID_CONTROL_PERSONAL}`} className="btn btn-info">Editar <i className="fa-solid fa-pen-to-square"></i></Link>
                                                <button onClick={() => deletePersonal(personal.ID_CONTROL_PERSONAL)} className="btn btn-danger">Borrar <i className="fa-solid fa-trash"></i></button>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CompShowPersonal;
     
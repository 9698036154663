import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import '../Estilos/AccesoDenegado.css';

const InicioNoAutorizado = () => {
  const navigate = useNavigate();
  const location = useLocation();
  
  // Obtiene el mensaje de error de la query string
  const queryParams = new URLSearchParams(location.search);
  // Mensaje de error predeterminado si no se pasa ninguno
  const errorMessage = queryParams.get('error') || 'No se ha podido continuar, por favor vuelve a intentar iniciar sesión mas tarde y comunica el problema a los administradores.';

  const handleRedirect = () => {
    navigate('/Login');
  };

  return (
    <div className="redirect-container">
      <span className="material-icons error-icon"> error</span>
      <h1>Acceso Denegado</h1>
      <p>{errorMessage}</p>
      <button onClick={handleRedirect}>Ir al Login</button>
    </div>
  );
};

export default InicioNoAutorizado;
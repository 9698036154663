import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import { useNavigate, useParams } from 'react-router-dom';
import '../../Estilos/StyleFormulario.css';

// Configuración del elemento raíz para los modales
Modal.setAppElement('#root');

const URI = 'https://cisrubenandino.com/ap/control_expedientes/';
const VALIDAR_MODULO_URI = 'https://cisrubenandino.com/ap/validar-mod-Arc';
const VALIDAR_PERMISO_URI = 'https://cisrubenandino.com/ap/validar-Aar-';

const CompEditExpediente = () => {  // Recibe el nombre de usuario como una prop
    const [NUM_Expediente, setNUM_Expediente] = useState('');
    const [PA_Primer_Nombre, setPA_Primer_Nombre] = useState('');
    const [PA_Segundo_Nombre, setPA_Segundo_Nombre] = useState('');
    const [PA_Primer_Apellido, setPA_Primer_Apellido] = useState('');
    const [PA_Segundo_Apellido, setPA_Segundo_Apellido] = useState('');
    const [DESC_Consulta, setDESC_Consulta] = useState('');
    const [FEC_Nacimiento, setFEC_Nacimiento] = useState('');
    const [PA_Excento, setPA_Excento] = useState('');
    const [Identidad, setIdentidad] = useState('');
    const [FEC_Creacion, setFEC_Creacion] = useState('');
    const [ULTI_Visita, setULTI_Visita] = useState('');
    const [Usr_Registro] = useState(localStorage.getItem('username') || '');
    const [Receta, setReceta] = useState('');
    const [NUM_Integrantes, setNUM_Integrantes] = useState('');
    const [Direccion, setDireccion] = useState('');
    const [NUM_Telefono, setNUM_Telefono] = useState('');
    
    const navigate = useNavigate();
    const { ID_Expediente } = useParams();
    const [message, setMessage] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
    const [originalData, setOriginalData] = useState(null);
    // Estados para manejo de carga y errores
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Funciones para el control de los modales
    const openModal = () => {
        setModalIsOpen(true);
    };
    const closeModal = () => {
        setModalIsOpen(false);
    };
    const openConfirmModal = () => {
        setConfirmModalIsOpen(true);
    };
    const closeConfirmModal = () => {
        setConfirmModalIsOpen(false);
    };
    const handleModalClose = () => {
        closeModal();
        if (message === '¡Datos actualizados exitosamente!') {
            navigate('/MosArExp'); // Regresa después de una actualización exitosa
        }
    };

    // Función para validar que se tenga acceso al módulo
    const validarAccesoModulo = useCallback(async () => {
        try {
            const res = await axios.get(VALIDAR_MODULO_URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (res.status !== 200 || !res.data.acceso) {
                throw new Error(res.data.mensaje || 'Acceso denegado.');
            }
        } catch (error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                setError('No tienes autorización para acceder a este apartado. Si crees que se trata de un error, comunícaselo al administrador.');
            } else {
                setError('No ha sido posible validar el acceso. Contacta con el administrador.');
            }
            setLoading(false);
            throw error;
        }
    }, []);

    // Función para validar el permiso necesario
    const validarPermisoModulo = useCallback(async () => {
        try {
            const res = await axios.get(VALIDAR_PERMISO_URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (res.status !== 200 || !res.data.acceso) {
                throw new Error('No tienes autorización para acceder a este apartado.');
            }
        } catch (error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                setError('No se cuenta con el acceso completo para acceder a este apartado.');
            } else {
                setError('No ha sido posible validar el acceso. Contacta con el administrador.');
            }
            setLoading(false);
            throw error;
        }
    }, []);

    //Comprobar si se ha realizado al menos un cambio en un campo
    const handleSubmit = (e) => {
        e.preventDefault();
        const hasChanges =
            NUM_Expediente !== originalData.NUM_Expediente ||
            PA_Primer_Nombre !== originalData.PA_Primer_Nombre ||
            PA_Segundo_Nombre !== originalData.PA_Segundo_Nombre ||
            PA_Primer_Apellido !== originalData.PA_Primer_Apellido ||
            PA_Segundo_Apellido !== originalData.PA_Segundo_Apellido ||
            DESC_Consulta !== originalData.DESC_Consulta ||
            FEC_Nacimiento !== originalData.FEC_Nacimiento ||
            PA_Excento !== originalData.PA_Excento ||
            Identidad !== originalData.Identidad ||
            FEC_Creacion !== originalData.FEC_Creacion ||
            ULTI_Visita !== originalData.ULTI_Visita ||
            Receta !== originalData.Receta ||
            NUM_Integrantes !== originalData.NUM_Integrantes ||
            Direccion !== originalData.Direccion ||
            NUM_Telefono !== originalData.NUM_Telefono;

        if (hasChanges) {
            //Si se ha cambiado al menos un dato se procede a realizar el cambio
            openConfirmModal(); 
        } else {
            //Si no se ha detectado al menos un cambio se muestra un mensaje
            setMessage('No se han realizado cambios en el Expediente.');
            openModal();
        }
    };

    // Efecto para ejecutar las validaciones y cargar datos al montar el componente
    useEffect(() => {
        const fetchData = async () => {
            try {
                await validarAccesoModulo(); // Valida el acceso al módulo
                await validarPermisoModulo(); // Valida el permiso del usuario
                setLoading(false);
            } catch (error) {
                console.error('Error durante la carga de datos:', error);
            }
        };
        fetchData();
    }, [validarAccesoModulo, validarPermisoModulo]);

    useEffect(() => {
        const getExpedienteByID = async () => {
            try {
                const response = await axios.get(URI + ID_Expediente, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });
                const data = response.data;
                setNUM_Expediente(data.NUM_Expediente || '');
                setPA_Primer_Nombre(data.PA_Primer_Nombre || '');
                setPA_Segundo_Nombre(data.PA_Segundo_Nombre || '');
                setPA_Primer_Apellido(data.PA_Primer_Apellido || '');
                setPA_Segundo_Apellido(data.PA_Segundo_Apellido || '');
                setDESC_Consulta(data.DESC_Consulta || '');
                setFEC_Nacimiento(data.FEC_Nacimiento || '');
                setPA_Excento(data.PA_Excento || '');
                setIdentidad(data.Identidad || '');
                setFEC_Creacion(data.FEC_Creacion || '');
                setULTI_Visita(data.ULTI_Visita || '');
                setReceta(data.Receta || '');
                setNUM_Integrantes(data.NUM_Integrantes || '');
                setDireccion(data.Direccion || '');
                setNUM_Telefono(data.NUM_Telefono || '');
                setOriginalData(data);
            } catch (error) {
                setMessage('¡Ocurrió un error y no se pudo actualizar el expediente!, intentalo de nuevo');
            }
        };

        getExpedienteByID();
    }, [ID_Expediente]);

    const update = async (e) => {
        e.preventDefault();
        closeConfirmModal(); // Cerrar el modal de confirmación
    
        // Formateo de fechas
        const formattedFEC_Nacimiento = new Date(FEC_Nacimiento).toISOString().split('T')[0];
        const formattedFEC_Creacion = new Date(FEC_Creacion).toISOString().split('T')[0];
        const formattedULTI_Visita = new Date(ULTI_Visita).toISOString().split('T')[0];
    
        try {
            await axios.put(URI + ID_Expediente, {
                NUM_Expediente,
                PA_Primer_Nombre,
                PA_Segundo_Nombre,
                PA_Primer_Apellido,
                PA_Segundo_Apellido,
                DESC_Consulta,
                FEC_Nacimiento: formattedFEC_Nacimiento,
                PA_Excento,
                Identidad,
                FEC_Creacion: formattedFEC_Creacion,
                ULTI_Visita: formattedULTI_Visita,
                Usr_Registro,
                Receta,
                NUM_Integrantes,
                Direccion,
                NUM_Telefono
            }, {
                headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
            });
            setMessage('¡Datos actualizados exitosamente!'); // Establecer el mensaje de éxito
            openModal(); // Abrir el modal de mensaje
        } catch (error) {
            setMessage('¡Ocurrió un error y no se pudo actualizar el expediente!, intentalo de nuevo'); // Establecer el mensaje de error
            openModal(); // Abrir el modal de mensaje
        }
    };
    

    //Procedimiento para cancelar
    const cancel = () => {
        closeModal();
        navigate('/MosArExp');
    };

     // Restaurar un campo especifico al valor original, si el usuario se equivoco y quiere regresar al valor existente
     const restoreField = (fieldName) => {
        switch (fieldName) {
            case 'NUM_Expediente':
                setNUM_Expediente(originalData.NUM_Expediente);
                break;
            case 'PA_Primer_Nombre':
                setPA_Primer_Nombre(originalData.PA_Primer_Nombre);
                break;
            case 'PA_Segundo_Nombre':
                setPA_Segundo_Nombre(originalData.PA_Segundo_Nombre);
                break;
            case 'PA_Primer_Apellido':
                setPA_Primer_Apellido(originalData.PA_Primer_Apellido);
                break;
            case 'PA_Segundo_Apellido':
                setPA_Segundo_Apellido(originalData.PA_Segundo_Apellido);
                break;
            case 'DESC_Consulta':
                setDESC_Consulta(originalData.DESC_Consulta);
                break;
            case 'FEC_Nacimiento':
                setFEC_Nacimiento(originalData.FEC_Nacimiento);
                break;
            case 'PA_Excento':
                setPA_Excento(originalData.PA_Excento);
                break;
            case 'Identidad':
                setIdentidad(originalData.Identidad);
                break;
            case 'FEC_Creacion':
                setFEC_Creacion(originalData.FEC_Creacion);
                break;
            case 'ULTI_Visita':
                setULTI_Visita(originalData.ULTI_Visita);
                break;
            case 'Receta':
                setReceta(originalData.Receta);
                break;
            case 'NUM_Integrantes':
                setNUM_Integrantes(originalData.NUM_Integrantes);
                break;
            case 'Direccion':
                setDireccion(originalData.Direccion);
                break;
            case 'NUM_Telefono':
                setNUM_Telefono(originalData.NUM_Telefono);
                break;
            default:
                break;
        }
    };
    // Restaurar todos los campos al valor original
    const restoreAllFields = () => {
        setNUM_Expediente(originalData.NUM_Expediente);
        setPA_Primer_Nombre(originalData.PA_Primer_Nombre);
        setPA_Segundo_Nombre(originalData.PA_Segundo_Nombre);
        setPA_Primer_Apellido(originalData.PA_Primer_Apellido);
        setPA_Segundo_Apellido(originalData.PA_Segundo_Apellido);
        setDESC_Consulta(originalData.DESC_Consulta);
        setFEC_Nacimiento(originalData.FEC_Nacimiento);
        setPA_Excento(originalData.PA_Excento);
        setIdentidad(originalData.Identidad);
        setFEC_Creacion(originalData.FEC_Creacion);
        setULTI_Visita(originalData.ULTI_Visita);
        setReceta(originalData.Receta);
        setNUM_Integrantes(originalData.NUM_Integrantes);
        setDireccion(originalData.Direccion);
        setNUM_Telefono(originalData.NUM_Telefono);
    };

    // Muestra un indicador de carga mientras se obtienen los datos
    if (loading) {
        return (
            <div className="loading-container">
                <p>Cargando datos... Por favor, espera.</p>
            </div>
        );
    }
    // Muestra un mensaje de error en caso de que ocurra
    if (error) {
        return (
            <div className="error-container">
                <p>{error}</p>
                <button className="btn btn-secondary" onClick={cancel}>Volver</button>
            </div>
        );
    }

    return (
        <div>
        <div className="titulo-container">
            <h3>EDITAR UN EXPEDIENTE</h3>
    </div>
        <div className="formulario-container">
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label className="form-label">Numero de expediente:</label>
                    <input
                       value={NUM_Expediente}
                       onChange={(e)=> setNUM_Expediente(e.target.value)}
                       type="bigint"
                       className="form-control"
                    />
                    <button type="button" className="btn btn-info" onClick={() => restoreField('NUM_Expediente')}>
                        Limpiar
                    </button>
                </div>
                <div className="mb-3">
                    <label className="form-label">Primer nombre del paciente:</label>
                    <input
                       value={PA_Primer_Nombre}
                       onChange={(e)=> setPA_Primer_Nombre(e.target.value)}
                       type="text"
                       className="form-control"

                    />
                   <button type="button" className="btn btn-info" onClick={() => restoreField('PA_Primer_Nombre')}>
                        Limpiar
                    </button>
                </div>
                <div className="mb-3">
                    <label className="form-label">Segundo nombre del paciente:</label>
                    <input
                       value={PA_Segundo_Nombre}
                       onChange={(e)=> setPA_Segundo_Nombre(e.target.value)}
                       type="text"
                       className="form-control"

                    />
                    <button type="button" className="btn btn-info" onClick={() => restoreField('PA_Segundo_Nombre')}>
                        Limpiar
                    </button>
                </div>
                <div className="mb-3">
                    <label className="form-label">Primer apellido del paciente:</label>
                    <input
                       value={PA_Primer_Apellido}
                       onChange={(e)=> setPA_Primer_Apellido(e.target.value)}
                       type="text"
                       className="form-control"

                    />
                    <button type="button" className="btn btn-info" onClick={() => restoreField('PA_Primer_Apellido')}>
                        Limpiar
                    </button>
                </div>
                <div className="mb-3">
                    <label className="form-label">Segundo apellido del paciente:</label>
                    <input
                       value={PA_Segundo_Apellido}
                       onChange={(e)=> setPA_Segundo_Apellido(e.target.value)}
                       type="text"
                       className="form-control"

                    />
                    <button type="button" className="btn btn-info" onClick={() => restoreField('PA_Segundo_Apellido')}>
                        Limpiar
                    </button>
                </div>
                <div className="mb-3">
                    <label className="form-label">Descripcion de la consulta:</label>
                    <textarea
                       value={DESC_Consulta}
                       onChange={(e)=> setDESC_Consulta(e.target.value)}
                       type="text"
                       className="form-control"

                    />
                 <button type="button" className="btn btn-info" onClick={() => restoreField('DESC_Consulta')}>
                        Limpiar
                    </button>
                </div>
                <div className="mb-3">
                    <label className="form-label">Fecha de nacimiento del paciente:</label>
                    <input
                       value={FEC_Nacimiento}
                       onChange={(e)=> setFEC_Nacimiento(e.target.value)}
                       type="date"
                       className="form-control"

                    />
                    <button type="button" className="btn btn-info" onClick={() => restoreField('FEC_Nacimiento')}>
                        Limpiar
                    </button>
                </div>
                <div className="mb-3">
                    <label className="form-label">Paciente Excento:</label>
                    <input
                       value={PA_Excento}
                       onChange={(e)=> setPA_Excento(e.target.value)}
                       type="text"
                       className="form-control"

                    />
                   <button type="button" className="btn btn-info" onClick={() => restoreField('PA_Excento')}>
                        Limpiar
                    </button>
                </div>
                <div className="mb-3">
                    <label className="form-label">Numero de identidad del paciente:</label>
                    <input
                       value={Identidad}
                       onChange={(e)=> setIdentidad(e.target.value)}
                       type="text"
                       className="form-control"

                    />
                    <button type="button" className="btn btn-info" onClick={() => restoreField('Identidad')}>
                        Limpiar
                    </button>
                </div>
                <div className="mb-3">
                    <label className="form-label">Fecha de creacion del expediente:</label>
                    <input
                       value={FEC_Creacion}
                       onChange={(e)=> setFEC_Creacion(e.target.value)}
                       type="date"
                       className="form-control"

                    />
                    <button type="button" className="btn btn-info" onClick={() => restoreField('FEC_Creacion')}>
                        Limpiar
                    </button>
                </div>
                <div className="mb-3">
                    <label className="form-label">Fecha de la ultima visita del paciente:</label>
                    <input
                       value={ULTI_Visita}
                       onChange={(e)=> setULTI_Visita(e.target.value)}
                       type="date"
                       className="form-control"

                    />
                    <button type="button" className="btn btn-info" onClick={() => restoreField('ULTI_Visita')}>
                        Limpiar
                    </button>
                </div>
                <div className="mb-3">
                    <label className="form-label">Receta dada al paciente:</label>
                    <textarea
                       value={Receta}
                       onChange={(e)=> setReceta(e.target.value)}
                       type="text"
                       className="form-control"

                    />
                   <button type="button" className="btn btn-info" onClick={() => restoreField('Receta')}>
                        Limpiar
                    </button>
                </div>
                <div className="mb-3">
                    <label className="form-label">Numero de integrante en el expediente:</label>
                    <input
                       value={NUM_Integrantes}
                       onChange={(e)=> setNUM_Integrantes(e.target.value)}
                       type="text"
                       className="form-control"

                    />
                   <button type="button" className="btn btn-info" onClick={() => restoreField('NUM_Integrantes')}>
                        Limpiar
                    </button>
                </div>
                <div className="mb-3">
                    <label className="form-label">Dirección del domicilio del paciente:</label>
                    <input
                       value={Direccion}
                       onChange={(e)=> setDireccion(e.target.value)}
                       type="text"
                       className="form-control"

                    />
                    <button type="button" className="btn btn-info" onClick={() => restoreField('Direccion')}>
                        Limpiar
                    </button>
                </div>
                <div className="mb-3">
                    <label className="form-label">Numero de telefono del paciente:</label>
                    <input
                       value={NUM_Telefono}
                       onChange={(e)=> setNUM_Telefono(e.target.value)}
                       type="text"
                       className="form-control"

                    />
                    <button type="button" className="btn btn-info" onClick={() => restoreField('NUM_Telefono')}>
                        Limpiar
                    </button>
                </div>
                <button type="submit" className="btn btn-success" > Guardar</button>
                <button type="button" className="btn btn-primary" onClick={restoreAllFields}> Restaurar</button>
                <button type="button" className="btn btn-danger" onClick={cancel}>Cancelar</button>
            </form>
            {/* Modal de confirmación */}
            <Modal
                isOpen={confirmModalIsOpen}
                onRequestClose={closeConfirmModal}
                contentLabel="Confirmación Modal"
                className="custom-modal"
                overlayClassName="custom-overlay"
            >
                <div className="modal-content">
                  <h2>¿Estás seguro de que deseas guardar los cambios?</h2>
                  <div className="btn-container">
                        <button onClick={update} className="btn btn-success">Sí, Guardar <i className="fa-solid fa-check"></i> </button>
                        <button onClick={closeConfirmModal} className="btn btn-danger">No, Regresar <i className="fa-solid fa-xmark"></i> </button>
                    </div>
                </div>
            </Modal>

            {/* Modal de mensaje */}
            <Modal
                 isOpen={modalIsOpen}
                 onRequestClose={handleModalClose}
                 contentLabel="Mensaje Modal"
                 className="custom-modal"
                 overlayClassName="custom-overlay"
            >
                   <div className="modal-content">
                      <h2>{message}</h2>
                        <div className="btn-container">
                            <button onClick={handleModalClose} className="btn btn-primary">Aceptar</button>
                        </div>
                    </div>
                 </Modal>
        </div>
    </div>
    )
}
export default CompEditExpediente
import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Modal from 'react-modal';
import '../../../Estilos/StyleFormulario.css';

const URI = 'https://cisrubenandino.com/ap/ad_accps/';
const URI_ROLES = 'https://cisrubenandino.com/ap/ad_rol/';
const URI_MODULOS_LIBRES = 'https://cisrubenandino.com/ap/mod/lb-modulo/';
const VALIDAR_PERMISO_URI = 'https://cisrubenandino.com/ap/validar-Ia-';
const VALIDAR_MODULO_URI = 'https://cisrubenandino.com/ap/validar-mod-Ap';

const CompCreateAcceso = () => {
  const Navigate = useNavigate()
  const [roles, setRoles] = useState([]);
  const [modulos, setModulos] = useState([]);
  const [formData, setFormData] = useState({
    ID_Rol: '',
    ID_Modulo: '',
    Seleccionar: '',
    Insertar: '',
    Actualizar: '',
    Eliminar: '',
    Usr_Registro: localStorage.getItem('username') || '', //Usuario logeado
});

// Estado para errores
const [errors, setErrors] = useState({});
const [warnings, setWarnings] = useState({});
const [error, setError] = useState(null);
const [loading, setLoading] = useState(true);

// Estado para mensajes de modal
const [modalIsOpen, setModalIsOpen] = useState(false);
const [modalMessage, setModalMessage] = useState('');
const [redirectAfterClose, setRedirectAfterClose] = useState(false);

// Funciónes del modal
const openModal = (message, redirect = false) => {
  setModalMessage(message);
  setModalIsOpen(true);
  setRedirectAfterClose(redirect);
};
const closeModal = () => {
  setModalIsOpen(false);
  if (redirectAfterClose) {
    Navigate('/AdmAPS');
}
};

// Función para validar que se tenga acceso al modulo
const validarAccesoModulo = useCallback(async () => {
  try {
      const res = await axios.get(VALIDAR_MODULO_URI, {
          headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
      });

      if (res.status !== 200 || !res.data.acceso) {
          throw new Error(res.data.mensaje || 'Acceso denegado.');
      }
  } catch (error) {
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
          setError('No tienes autorizacion para acceder a este apartado. Si crees que se trata de un error, comunicaselo al administrador.');
      } else {
          setError('No ha sido posible validar el acceso. Contacta con el administrador.');
      }
      setLoading(false);
      throw error;
  }
}, []);

// Función para validar permisos del usuario
const validarPermiso = useCallback(async () => {
  try {
    const res = await axios.get(VALIDAR_PERMISO_URI, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    });
    if (res.status !== 200 || !res.data.acceso) {
      throw new Error(res.data.mensaje || 'Permiso denegado.');
    }
  } catch (error) {
    setError('No tienes autorización para realizar esta acción. Si crees que se trata de un error, comunícalo al administrador.');
    setLoading(false);
    throw error;
  }
}, []);

// UseEffect para validar acceso, permisos y cargar datos
useEffect(() => {
  const fetchData = async () => {
    try {
      await validarAccesoModulo(); // Valida el acceso al módulo
      await validarPermiso(); // Valida el permiso del usuario
    } catch (error) {
      console.error('Error durante la carga de datos:', error);
    } finally {
      setLoading(false);
    }
  };
  fetchData();
}, [validarAccesoModulo, validarPermiso]);

// Cargar roles cuando el componente se monta
useEffect(() => {
  const fetchRoles = async () => {
    try {
      const rolesResponse = await axios.get(URI_ROLES, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
      });
      setRoles(rolesResponse.data);
    } catch (error) {
      console.error('Error al cargar roles:', error);
    }
  };
  fetchRoles();
}, []);

useEffect(() => {
  if (formData.ID_Rol) {
    const fetchModulosLibres = async () => {
      try {
        const modulosResponse = await axios.get(`${URI_MODULOS_LIBRES}${formData.ID_Rol}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
        });
        if (Array.isArray(modulosResponse.data)) {
          setModulos(modulosResponse.data);
        } else {
          setModulos([]);
        }
      } catch (error) {
        console.error('Error al cargar módulos:', error);
        setModulos([]);
      }
    };
    fetchModulosLibres();
  } else {
    setModulos([]); // Limpiar módulos si no se ha seleccionado un rol
  }
}, [formData.ID_Rol]);

// Función para manejar los cambios en los campos de entrada
const handleChange = (e) => {
    const { name, value } = e.target;
    // Actualizar formData con el valor del campo que ha cambiado
    setFormData((prevData) => ({
        ...prevData,
        [name]: value
    }));
    // Llamar a la función validateField para validar el campo que acaba de cambiar
    validateField(name, value);
};
// Función para validar un campo específico
 const validateField = (name, value) => {
    let error = '';
    let warning = '';

    switch (name) {
        case 'ID_Rol':
            if (!value.trim()) {
                error = 'El Rol objetivo es obligatorio';
            }
            break;
        case 'ID_Modulo':
            if (!value.trim()) {
                error = 'El Módulo es obligatorio.';
            }
            break;
        default:
            if (['Seleccionar', 'Insertar', 'Actualizar', 'Eliminar'].includes(name) && !value.trim()) {
                warning = `No se ha habilitado el permiso de ${name.toLowerCase()}. Verifica si es necesario.`;
            }
            break;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
    }));
    setWarnings((prevWarnings) => ({
      ...prevWarnings,
      [name]: warning,
    }));
  };

// Función para validar todos los campos antes de enviar el formulario
const validateAllFields = () => {
  Object.keys(formData).forEach((field) => validateField(field, formData[field]));
  return !Object.values(errors).some((error) => error !== '');
};

// Función para limpiar todos los campos y errores
const clearInputs = () => {
  setFormData({
      ID_Rol: '',
      ID_Modulo: '',
      Seleccionar: '',
      Insertar: '',
      Actualizar: '',
      Eliminar: '',
      Usr_Registro: localStorage.getItem('username') || '',
  });
  setErrors({});
  setWarnings({});
};

// Función para cancelar y volver a la vista anterior
const handleCancel = () => {
  clearInputs();
  Navigate('/AdmAPS');
};

// Lista de campos obligatorios
const requiredFields = [
    'ID_Rol',
    'ID_Modulo'
];

// Función para validar los campos obligatorios
const validateRequiredFields = () => {
    let hasError = false;
    const newErrors = {};

    requiredFields.forEach((field) => {
        const value = formData[field];

        if (!value.trim()) {
            // Si el campo está vacío, actualiza el estado de errores con un mensaje de error
            newErrors[field] = `El campo es obligatorio.`;
            hasError = true;
        }
    });

    setErrors((prevErrors) => ({
        ...prevErrors,
        ...newErrors
    }));

    return !hasError;
};

// Procedimiento para guardar los datos del formulario
const crear = async (e) => {
  e.preventDefault();

  // Validar el acceso al módulo
await validarAccesoModulo();

// Validar el permiso del usuario para realizar la acción de creación
await validarPermiso();
  
// Validar los campos obligatorios
const areRequiredFieldsValid = validateRequiredFields();

const allFieldsFilled = requiredFields.every(
    (field) => formData[field] && formData[field].trim() !== ''
);
// Verificar que los campos obligatorios estén llenos
if (!allFieldsFilled) {
    openModal('Los datos son insuficientes. Por favor, completa todos los campos obligatorios para continuar.');
    return;
}
if (!areRequiredFieldsValid) {
    openModal('Los campos obligatorios deben completarse antes de poder continuar.');
    return;
}
  // Validar todos los campos
  const isValid = validateAllFields();
  if (!isValid) {
      openModal('Se han detectado uno o mas errores, por favor corrigelos antes de poder continuar.');
      return;
  }
  try {
      await axios.post(URI, formData, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    });
      openModal('¡Se ha asignado el acceso y permisos correctamente!', true);
      clearInputs();
  } catch (error) {
      console.error('Error:', error);
      openModal(error.message || '¡Ocurrió un error y no se han podido registrar los datos, inténtalo de nuevo! Si el problema persiste, por favor contacta con el administrador.');
  }
};

// Muestra un indicador de carga mientras se obtienen los datos
if (loading) {
  return (
      <div className="loading-container">
          <p>Cargando datos... Por favor, espera.</p>
      </div>
  );
}

// Muestra un mensaje de error en caso de que ocurra
if (error) {
  return (
      <div className="error-container">
          <p>{error}</p>
          <button className="btn btn-secondary" onClick={handleCancel}>Volver a inicio</button>
      </div>
  );
}

return (
    <div>
      <div className="titulo-container">
        <h3>AÑADIR ACCESO Y PERMISOS</h3>
      </div>
      <div className="formulario-container">
        <form onSubmit={crear}>
          <div className="mb-3">
            <label className="form-label">Rol a asignar</label>
            <select
                name="ID_Rol"
                value={formData.ID_Rol}
                onChange={handleChange}
                className={`form-control ${errors.ID_Rol ? 'is-invalid' : warnings.ID_Rol ? 'is-warning' : ''}`}
            >
            <option value="">Selecciona un rol →</option>
                {roles.map((rol) => (
                    <option key={rol.ID_Rol} value={rol.ID_Rol}>
                        {rol.NOM_Rol}  {/* Mostrar el nombre del rol */}
                    </option>
                ))}
            </select>
                {errors.ID_Rol && <div className="error-feedback">{errors.ID_Rol}</div>}
                {warnings.ID_Rol && <div className="warning-feedback">{warnings.ID_Rol}</div>}
        </div>
          <div className="mb-3">
          <label className="form-label">Módulo a Autorizar</label>
          {formData.ID_Rol ? (
            modulos.length === 0 ? (
              <p>El rol ya tiene acceso a todos los modulos, ve al apartado de editar para hacer cambios.</p>
            ) : (
              <select
                name="ID_Modulo"
                value={formData.ID_Modulo}
                onChange={handleChange}
                className={`form-control ${errors.ID_Modulo ? 'is-invalid' : warnings.ID_Modulo ? 'is-warning' : ''}`}
              >
                <option value="">Selecciona un módulo →</option>
                {modulos.map((modulo) => (
                  <option key={modulo.ID_Modulo} value={modulo.ID_Modulo}>
                    {modulo.NOM_Modulo}
                  </option>
                ))}
              </select>
            )
          ) : (
            <p>Selecciona un rol para ver los módulos disponibles.</p>
          )}
          {errors.ID_Modulo && <div className="error-feedback">{errors.ID_Modulo}</div>}
          {warnings.ID_Modulo && <div className="warning-feedback">{warnings.ID_Modulo}</div>}
        </div>
          <div className="mb-3">
            <label className="form-label">Permiso de Seleccionar</label>
            <select
              name="Seleccionar"
              value={formData.Seleccionar}
              onChange={handleChange}
              className={`form-control ${errors.Seleccionar ? 'is-invalid' : warnings.Seleccionar ? 'is-warning' : ''}`}
            >
              <option value="0">Selecciona una opción →</option>
              <option value="1">Sí</option>
              <option value="0">No</option>
            </select>
            {errors.Seleccionar && <div className="error-feedback">{errors.Seleccionar}</div>}
            {warnings.Seleccionar && <div className="warning-feedback">{warnings.Seleccionar}</div>}
          </div>

          <div className="mb-3">
            <label className="form-label">Permiso de Insertar</label>
            <select
              name="Insertar"
              value={formData.Insertar}
              onChange={handleChange}
              className={`form-control ${errors.Insertar ? 'is-invalid' : warnings.Insertar ? 'is-warning' : ''}`}
            >
              <option value="0">Selecciona una opción →</option>
              <option value="1">Sí</option>
              <option value="0">No</option>
            </select>
            {errors.Insertar && <div className="error-feedback">{errors.Insertar}</div>}
            {warnings.Insertar && <div className="warning-feedback">{warnings.Insertar}</div>}
          </div>

          <div className="mb-3">
            <label className="form-label">Permiso de Actualizar</label>
            <select
              name="Actualizar"
              value={formData.Actualizar}
              onChange={handleChange}
              className={`form-control ${errors.Actualizar ? 'is-invalid' : warnings.Actualizar ? 'is-warning' : ''}`}
            >
              <option value="0">Selecciona una opción →</option>
              <option value="1">Sí</option>
              <option value="0">No</option>
            </select>
            {errors.Actualizar && <div className="error-feedback">{errors.Actualizar}</div>}
            {warnings.Actualizar && <div className="warning-feedback">{warnings.Actualizar}</div>}
          </div>

          <div className="mb-3">
            <label className="form-label">Permiso de Eliminar</label>
            <select
              name="Eliminar"
              value={formData.Eliminar}
              onChange={handleChange}
              className={`form-control ${errors.Eliminar ? 'is-invalid' : warnings.Eliminar ? 'is-warning' : ''}`}
            >
              <option value="0">Selecciona una opción →</option>
              <option value="1">Sí</option>
              <option value="0">No</option>
            </select>
            {errors.Eliminar && <div className="error-feedback">{errors.Eliminar}</div>}
            {warnings.Eliminar && <div className="warning-feedback">{warnings.Eliminar}</div>}
          </div>
          <button type="submit" className="btn btn-success"> Guardar Acceso <i className="fa-solid fa-gears"></i> </button>
            <button type="button" className="btn btn-primary" onClick={clearInputs}>Limpiar los datos <i className="fa-solid fa-paint-roller"></i></button>
            <button type="button" className="btn btn-danger" onClick={handleCancel}>Cancelar y regresar <i className="fa-solid fa-rotate-left"></i></button>
        </form>
      </div>

      {/* Modal para mostrar mensajes */}
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} className="custom-modal" overlayClassName="custom-modal-overlay">
        <div className="modal-content">
          <h3>{modalMessage}</h3>
          <button className="btn btn-primary" onClick={closeModal}>Cerrar</button>
        </div>
      </Modal>
    </div>
  );
};
                
export default CompCreateAcceso
import '../../Estilos/StyleTableDatos.css';
import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import {useNavigate} from 'react-router-dom';
import { Link } from "react-router-dom";
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css'; // Estilos de DataTables para Bootstrap 5
import $ from 'jquery'; // Importa jQuery
import 'datatables.net-bs5'; // Importa DataTables para Bootstrap 5

const URI = 'https://cisrubenandino.com/ap/control_expedientes/';
const VALIDAR_MODULO_URI = 'https://cisrubenandino.com/ap/validar-mod-Arc';
const VALIDAR_PERMISO_URI = 'https://cisrubenandino.com/ap/validar-Sar-';

const CompShowExpedientes = () => {
    const [expedientes, setExpedientes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    // Función para validar que se tenga acceso al modulo
    const validarAccesoModulo = useCallback(async () => {
        try {
            const res = await axios.get(VALIDAR_MODULO_URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (res.status !== 200 || !res.data.acceso) {
                throw new Error(res.data.mensaje || 'Acceso denegado.');
            }
        } catch (error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                setError('No tienes autorizacion para acceder a este apartado. Si crees que se trata de un error, comunicaselo al administrador.');
            } else {
                setError('No ha sido posible validar el acceso. Contacta con el administrador.');
            }
            setLoading(false);
            throw error;
        }
    }, []);

    // Función para validar el permiso necesario
    const validarPermiso = useCallback(async () => {
        try {
            const res = await axios.get(VALIDAR_PERMISO_URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (res.status !== 200 || !res.data.acceso) {
                setError('No tienes autorización para acceder a este apartado. Si crees que se trata de un error, comunícaselo al administrador.');
            }
        } catch (error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                setError('No se cuenta con el acceso completo para acceder a este apartado.');
            } else {
                setError('No ha sido posible validar el acceso. Contacta con el administrador.');
            }
            setLoading(false);
            throw error;
        }
    }, []);

    const getExpedientes = useCallback(async () => {
        try {
            const res = await axios.get(URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            if (Array.isArray(res.data) && res.data.length > 0) {
                const expedientesFormatted = res.data.map((expediente) => ({
                    ...expediente,
                    FEC_Nacimiento: formatDate(expediente.FEC_Nacimiento),
                    FEC_Creacion: formatDate(expediente.FEC_Creacion),
                    ULTI_Visita: formatDate(expediente.ULTI_Visita),
                }));
                setExpedientes(expedientesFormatted);
            } else {
                setExpedientes([]);
            }
        } catch (error) {
            setError("No ha sido posible encontrar los datos. Por favor vuelve a intentar, si el problema persiste, contacta con el administrador.");
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await validarAccesoModulo(); // Valida el acceso al módulo
                await validarPermiso(); // Valida el permiso del usuario
                await getExpedientes(); // Obtiene los datos de los usuarios si se tiene acceso
            } catch (error) {
                console.error("Ha ocurrido un problema", error);
            }
        };
        fetchData();
    }, [validarAccesoModulo, validarPermiso, getExpedientes]);

    useEffect(() => {
        if ($.fn.DataTable.isDataTable('#dataTableExp')) {
            $('#dataTableExp').DataTable().destroy();
        }
        
        if (expedientes.length > 0) {
            $('#dataTableExp').DataTable();
        }
    }, [expedientes]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        date.setDate(date.getDate() + 1);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    };

    const deleteExpediente = async (ID_Expediente) => {
        try {
            // Validar el permiso del usuario para la acción de "eliminación"
            const res = await axios.get('https://cisrubenandino.com/ap/validar-Ear-', {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
    
            if (res.status !== 200 || !res.data.acceso) {
                window.alert('No tienes el permiso necesario para realizar esta acción.');
                return;
            }
    
            // Si el usuario tiene permiso, preguntar si desea eliminar
            const confirmDelete = window.confirm("¿Está seguro de que desea eliminar este expediente?");
            
            if (confirmDelete) {
                try {
                    await axios.delete(`${URI}${ID_Expediente}`, {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        }
                    });
                    getExpedientes(); // Refresca la lista después de la eliminación
                } catch (error) {
                    console.error('Error al eliminar el dato:', error);
                    window.alert('Hubo un problema al intentar eliminar el expediente. Por favor, inténtelo de nuevo.');
                }
            }
        } catch (error) {
            // Manejo específico del error 403
            if (error.response && error.response.status === 403) {
                window.alert('No tienes el permiso necesario para realizar esta acción.');
            } else {
                console.error('Error al validar permiso:', error);
                window.alert('Hubo un problema al verificar los permisos. Por favor, inténtelo de nuevo más tarde.');
            }
        }
    };

    // Función para cancelar y regresar a la página anterior
    const cancel = () => {
        navigate('/Inicio');
    };

    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };

     // Muestra un indicador de carga mientras se obtienen los datos
if (loading) {
    return (
        <div className="loading-container">
            <p>Cargando datos... Por favor, espera.</p>
        </div>
    );
}

// Muestra un mensaje de error en caso de que ocurra
if (error) {
    return (
        <div className="error-container">
            <p>{error}</p>
            <button className="btn btn-secondary" onClick={cancel}>Volver a inicio</button>
        </div>
    );
}

    return (
        <div className="main-container">
            <h2 className="text-center mb-4">Administración De Expedientes</h2> {/* Título de la página */}
                        <div className="btn-container">
                            <Link to="/ArExpNw" className="btn btn-primary mb-3">
                                Crear un nuevo Expediente <i className="fa-solid fa-file-circle-plus"></i>
                            </Link>
                         </div>
                            <div className="table-container">
                                <table id="dataTableExp" className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th className="text-center">N# EXP</th>
                                            <th className="text-center">Primer Nombre</th>
                                            <th className="text-center">Segundo Nombre</th>
                                            <th className="text-center">Primer Apellido</th>
                                            <th className="text-center">Segundo Apellido</th>
                                            <th className="text-center">Consulta</th>
                                            <th className="text-center">Nacimiento</th>
                                            <th className="text-center">Excento</th>
                                            <th className="text-center">DNI</th>
                                            <th className="text-center">Creacion</th>
                                            <th className="text-center">Ultima Visita</th>
                                            <th className="text-center">Receta</th>
                                            <th className="text-center">N# Integrante</th>
                                            <th className="text-center">Dirección</th>
                                            <th className="text-center">Teléfono</th>
                                            <th className="text-center">Opciones</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {expedientes.length === 0 ? (
                                            <tr>
                                                <td colSpan="16" className="text-center">No hay expedientes para mostrar, no existe o no está creado.</td>
                                            </tr>
                                        ) : (
                                            expedientes.map((expediente) => (
                                                <tr key={expediente.ID_Expediente}>
                                                    <td className="text-center"> {expediente.NUM_Expediente} </td>
                                                    <td className="text-center"> {expediente.PA_Primer_Nombre} </td>
                                                    <td className="text-center"> {expediente.PA_Segundo_Nombre || <i>ND</i>} </td>
                                                    <td className="text-center"> {expediente.PA_Primer_Apellido} </td>
                                                    <td className="text-center"> {expediente.PA_Segundo_Apellido || <i>ND</i>} </td>
                                                    <td title={expediente.DESC_Consulta} className="text-center text-truncated">
                                                        {truncateText(expediente.DESC_Consulta, 25)}
                                                    </td>
                                                    <td className="text-center"> {expediente.FEC_Nacimiento} </td>
                                                    <td className="text-center"> {expediente.PA_Excento || <i>ND</i>} </td>
                                                    <td className="text-center"> {expediente.Identidad || <i>ND</i>} </td>
                                                    <td className="text-center"> {expediente.FEC_Creacion} </td>
                                                    <td className="text-center"> {expediente.ULTI_Visita} </td>
                                                    <td title={expediente.Receta} className="text-center text-truncated">
                                                        {truncateText(expediente.Receta, 25)}
                                                    </td>
                                                    <td className="text-center"> {expediente.NUM_Integrantes|| <i>ND</i>}</td>
                                                    <td className="text-center"> {expediente.Direccion} </td>
                                                    <td className="text-center"> {expediente.NUM_Telefono || <i>ND</i>} </td>
                                                    <td className="text-center">
                                                        <Link to={`/ArExpEtC/${expediente.ID_Expediente}`} className="btn btn-info">
                                                            Editar <i className="fa-solid fa-pen-to-square"></i>
                                                        </Link>
                                                        <button onClick={() => deleteExpediente(expediente.ID_Expediente)} className="btn btn-danger">
                                                            Borrar <i className="fa-solid fa-trash"></i>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    );
                };

export default CompShowExpedientes;

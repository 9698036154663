import React from 'react';
import { BrowserRouter, Route, Routes} from 'react-router-dom';
import SideBar from './componentes/SideBar';
import './Estilos/SideBar.css';

//import de componentes relacionados al inicio de sesión: inicio, login etc 
import Inicio from './componentes/Inicio';
import Footer from './componentes/Footer';
import Header from './componentes/Header';
//import Dashboard from './componentes/Dashboard';
import Login from './componentes/Login';
import InicioNoAutorizado from './componentes/InicioNoAutorizado';
import RutaProtegida from './componentes/RutaProtegidas';
import RutaInexistente from './componentes/RutaInexistente';
//-------------------------------------------------------------------------------------------------------------

//imports de mantenimiento o Administracion
//Usuario
import CompShowUsuario from './componentes/ModuloAdminMantenimiento/ModuloUsuario/ShowUsuario';
import CompCreateUsuario from './componentes/ModuloAdminMantenimiento/ModuloUsuario/CreateUsuario';
import CompEditUsuario from './componentes/ModuloAdminMantenimiento/ModuloUsuario/EditUsuario';

//import de persona
import CompShowPersona from './componentes/ModuloAdminMantenimiento/ModuloPersona/ShowPersona';
import CompCreatePersona from './componentes/ModuloAdminMantenimiento/ModuloPersona/CreatePersona';
import CompEditPersona from './componentes/ModuloAdminMantenimiento/ModuloPersona/EditPersona';

//import de roles
import CompShowRoles from './componentes/ModuloAdminMantenimiento/ModuloRol/ShowRol';
import CompCreateRol from './componentes/ModuloAdminMantenimiento/ModuloRol/CreateRol';
import CompEditRoles from './componentes/ModuloAdminMantenimiento/ModuloRol/EditRol';

//import de Acceso y permisos
import CompShowAccesos from './componentes/ModuloAdminMantenimiento/ModuloAccesoPermiso/ShowAccesoPermiso';
import CompCreateAcceso from './componentes/ModuloAdminMantenimiento/ModuloAccesoPermiso/CreateAccesoPermiso';
import CompEditAcceso from './componentes/ModuloAdminMantenimiento/ModuloAccesoPermiso/EditAccesoPermiso';
//------------------------------------------------------------------------------------------------------------

//imports modulos administrativos: parametros de software y parametros de empresa
//Parametros de software
import CompShowParametrosSoftware from './componentes/ModuloParametrosSoftware/ShowParametrosSoftware';
import CompCreateParametrosSoftware from './componentes/ModuloParametrosSoftware/CreateParametrosSoftware';
import CompEditParametrosSoftware from './componentes/ModuloParametrosSoftware/EditParametrosSoftware';

//Parametros de empresa
import CompShowEmpresaParametros from './componentes/ModuloParametrosEmpresa/ShowEmpresaParametros';
import CompCreateEmpresaParametros from './componentes/ModuloParametrosEmpresa/CreateEmpresaParametros';
import CompEditEmpresaParametros from './componentes/ModuloParametrosEmpresa/EditEmpresaParametros';
//------------------------------------------------------------------------------------------------------------

//imports Area de administración
//Empleados
import CompShowPersonal from './componentes/ModuloAdministracion/Empleados/ShowPersonal';
import CompCreatePersonal from './componentes/ModuloAdministracion/Empleados/CreatePersonal';
import CompEditPersonal from './componentes/ModuloAdministracion/Empleados/EditPersonal';

//inventario
import CompCreateInventario from './componentes/inventario/CreateInventario';
import CompEditInventario from './componentes/inventario/EditInventario';
import CompShowInventario from './componentes/inventario/ShowInventario';
//-------------------------------------------------------------------------------------------------------------

//import Area archivo
import CompShowExpedientes from './componentes/ModuloArchivo/ShowExpedientes';
import CompCreateExpedientes from './componentes/ModuloArchivo/CreateExpedientes';
import CompEditExpediente from './componentes/ModuloArchivo/EditExpedientes';
//-------------------------------------------------------------------------------------------------------------

//import modulo estadistica
import CompCreateAT2R from './componentes/ModuloEstadistica/CreateAT2R';
import CompShowAT2R from './componentes/ModuloEstadistica/ShowAT2R';
import CompShowedad from './componentes/ModuloEstadistica/ShowRanEdad';
import CompEditAT2R from './componentes/ModuloEstadistica/EditAT2R';
import CompShowLB from './componentes/ModuloEstadistica/ShowLibroDiario';
import CompShowHM from './componentes/ModuloEstadistica/ShowHoraMedico';
//-------------------------------------------------------------------------------------------------------------

//import modulo enfermeria
import CompCreateEnfer from './componentes/ModuloEnfermeria/CreateEnfer';
import CompEditEnfer from './componentes/ModuloEnfermeria/EditEnfer';
import CompShowEnfer from './componentes/ModuloEnfermeria/ShowEnfer';
//-----------------------------------------------------------------------------------------------------------

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* Login */}
        <Route path="/Login" element={<Login />} />

        {/* Areas/Rutas protegidas */}

        <Route path="/Inicio" element={<RutaProtegida element={<div> <SideBar/> <Header /> <Inicio /> <Footer /> </div>} /> }/>
        {/* No utilizado de momento <Route path="/Dashboard" element={<RutaProtegida element={<div> <SideBar/> <Dashboard /> </div>} />} /> */}

        {/* Rutas de Administración o mantenimiento: Usuario */}
        <Route path="/AdmUsers" element={<RutaProtegida element={<div> <SideBar/> <CompShowUsuario /> </div>} />} />
        <Route path="/AdmCreatUser" element={<RutaProtegida element={<div> <SideBar/> <CompCreateUsuario />  </div>} />} />
        <Route path="/AdmEdiUser/:ID_Usuario" element={<RutaProtegida element={<div> <SideBar/> <CompEditUsuario /> </div>} />} />

        {/* Persona */}
        <Route path="/AdmPers" element={<RutaProtegida element={<div> <SideBar/> <CompShowPersona /> </div>} />} />
        <Route path="/AdmRrPs" element={<RutaProtegida element={<div> <SideBar/> <CompCreatePersona /> </div>} />} />
        <Route path="/AdmEditPs/:ID_Persona" element={<RutaProtegida element={<div> <SideBar/> <CompEditPersona /> </div>} />} />

        {/* Roles */}
        <Route path="/AdmRl" element={<RutaProtegida element={<div> <SideBar/> <CompShowRoles /> </div>} />} />
        <Route path="/AdmAgRL" element={<RutaProtegida element={<div> <SideBar/><CompCreateRol /> </div>} />} />
        <Route path="/AdmEditRl/:ID_Rol" element={<RutaProtegida element={<div> <SideBar/><CompEditRoles /> </div>} />} />

        {/* Acceso / permisos */}
        <Route path="/AdmAPS" element={<RutaProtegida element={<div> <SideBar/> <CompShowAccesos /> </div>} />} />
        <Route path="/AdmAPSC" element={<RutaProtegida element={<div> <SideBar/> <CompCreateAcceso /> </div>} />} />
        <Route path="/AdmEdiAPC/:ID_Acceso" element={<RutaProtegida element={<div> <SideBar/> <CompEditAcceso /> </div>} />} />

        {/*Rutas Modulo Estadistica*/}
        <Route path="/MostrarA" element={<RutaProtegida element={<div> <SideBar/> <CompShowAT2R /> </div>} />} />
        <Route path="/CreateA" element={<RutaProtegida element={<div> <SideBar/> <CompCreateAT2R /> </div>} />} />
        <Route path="/EditA/:Id_Reg_Libro" element={<RutaProtegida element={<div> <SideBar/> <CompEditAT2R /> </div>} />} />
        <Route path="/MostrarE" element={<RutaProtegida element={<div> <SideBar/> <CompShowedad /> </div>} />} />
        <Route path="/MostrarLB" element={<RutaProtegida element={<div> <SideBar/> <CompShowLB /> </div>} />} />
        <Route path="/MostrarHM" element={<RutaProtegida element={<div> <SideBar/> <CompShowHM /> </div>} />} />

        {/*Rutas Modulo Archivo*/}
        <Route path="/MosArExp" element={<RutaProtegida element={<div> <SideBar/> <CompShowExpedientes /> </div>} />} />
        <Route path="/ArExpNw" element={<RutaProtegida element={<div> <SideBar/> <CompCreateExpedientes /> </div>} />} />
        <Route path="/ArExpEtC/:ID_Expediente" element={<RutaProtegida element={<div> <SideBar/> <CompEditExpediente /> </div>} />} />

        {/*Rutas Modulo Enfermeria*/}
        <Route path="/MostrarEN" element={<RutaProtegida element={<div> <SideBar/> <CompShowEnfer /> </div>} />} />
        <Route path="/CreateEN" element={<RutaProtegida element={<div> <SideBar/> <CompCreateEnfer /> </div>} />} />
        <Route path="/EditEN/:ID_Producto" element={<RutaProtegida element={<div> <SideBar/> <CompEditEnfer /> </div>} />} />

        {/*Rutas Modulo Administración*/}
        {/*Empleados*/}
        <Route path="/MostrarP" element={<RutaProtegida element={<div> <SideBar/> <CompShowPersonal /> </div>} />} />
        <Route path="/CreateP" element={<RutaProtegida element={<div> <SideBar/> <CompCreatePersonal /> </div>} />} />
        <Route path="/EditP/:ID_CONTROL_PERSONAL" element={<RutaProtegida element={<div> <SideBar/> <CompEditPersonal /> </div>} />} />
        {/*Inventario*/}
        <Route path="/MostrarI" element={<RutaProtegida element={<div> <SideBar/> <CompShowInventario /> </div>} />} />
        <Route path="/CreateI" element={<RutaProtegida element={<div> <SideBar/> <CompCreateInventario /> </div>} />} />
        <Route path="/EditI/:ID_ITEM_CONTROL_INVENTARIO" element={<RutaProtegida element={<div> <SideBar/> <CompEditInventario /> </div>} />} />

        {/*Rutas modulos administrativos*/}
        {/*Parametros de empresa*/}
        <Route path="/MostrarPE" element={<RutaProtegida element={<div> <SideBar/> <CompShowEmpresaParametros /> </div>} />} />
        <Route path="/CreatePE" element={<RutaProtegida element={<div> <SideBar/> <CompCreateEmpresaParametros /> </div>} />} />
        <Route path="/EditPE/:ID_PRTS_Empresa" element={<RutaProtegida element={<div> <SideBar/> <CompEditEmpresaParametros /> </div>} />} />
        {/*Parametros de software*/}
        <Route path="/MostrarPS" element={<RutaProtegida element={<div> <SideBar/> <CompShowParametrosSoftware /> </div>} />} />
        <Route path="/CreatePS" element={<RutaProtegida element={<div> <SideBar/> <CompCreateParametrosSoftware /> </div>} />} />
        <Route path="/EditPS/:ID_Producto" element={<RutaProtegida element={<div> <SideBar/> <CompEditParametrosSoftware /> </div>} />} />

        {/* Ruta para página de acceso denegado */}
        <Route path="/InicioNoAutorizado" element={<InicioNoAutorizado />} />

        {/* Redirección para rutas no existentes */}
        <Route path="*" element={<RutaInexistente />} />
      </Routes>
      
    </BrowserRouter>
  );
};

export default App;
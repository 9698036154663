import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Pagination, CardHeader } from 'react-bootstrap';
import BotonExcel from "./BotonReporteConXLSX.js";
import { validarPermisoEliminar } from "./validarPermisoEliminar.js";

const URI = 'https://cisrubenandino.com/ap/libro_diario/';
const VALIDAR_MODULO_URI = 'https://cisrubenandino.com/ap/validar-mod-at2r';
const VALIDAR_PERMISO_URI = 'https://cisrubenandino.com/ap/validar-Sat2r-';

const CompShowAT2R = () => {
    const [librod, setLibrod] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage] = useState(25);
    const [canDelete, setCanDelete] = useState(false);
    const navigate = useNavigate();

    // Formateo para la fecha y hora
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString('es-HN', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour12: true
        });
    };

    // Función para validar el acceso al módulo
    const validarAccesoModulo = useCallback(async () => {
        try {
            const res = await axios.get(VALIDAR_MODULO_URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (res.status !== 200 || !res.data.acceso) {
                throw new Error(res.data.mensaje || 'Acceso denegado.');
            }
        } catch (error) {
            manejarErroresDeValidacion(error);
            throw error;
        }
    }, []);

    // Función para validar el permiso del usuario
    const validarPermisoUsuario = useCallback(async () => {
        try {
            const res = await axios.get(VALIDAR_PERMISO_URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (res.status !== 200 || !res.data.acceso) {
                window.setError('No tienes autorización para acceder a este apartado. Si crees que se trata de un error, comunícaselo al administrador.');
            }
        } catch (error) {
            manejarErroresDeValidacion(error);
            throw error;
        }
    }, []);

    // Función para desactivar un registro
    const putidRegLibro = async (Id_Reg_Libro) => {
        if (!canDelete) {
            setError('No tienes permiso para eliminar registros.');
            return;
        }
    
        const confirmDelete = window.confirm("¿Está seguro de que desea desactivar este registro?");
        if (confirmDelete) {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    throw new Error('Token no encontrado');
                }
                await axios.patch(`${URI}`, { Id_Reg_Libro: Id_Reg_Libro }, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });
                getLibrod();
            } catch (error) {
                console.error('Error al desactivar el registro:', error);
                manejarErroresDeValidacion(error);
            }
        }
    };

    // Función para manejar errores comunes en la validación
    const manejarErroresDeValidacion = (error) => {
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
            localStorage.removeItem('token');
            localStorage.removeItem('username');
            setError('No ha sido posible validar tus credenciales, vuelve a iniciar sesión.');
            navigate(`/InicioNoAutorizado?error=${encodeURIComponent(error.response.data.message)}`);
        } else {
            setError('No ha sido posible validar el acceso. Contacta con el administrador.');
        }
        setLoading(false);
    };

    // Función para obtener los datos del libro diario
    const getLibrod = useCallback(async () => {
        try {
            const response = await axios.get(URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (Array.isArray(response.data)) {
                const librodFormatted = response.data.map((item) => ({
                    ...item,
                    Fecha: formatDate(item.Fecha),
                    Fec_Atencion: formatDate(item.Fec_Atencion),
                    Fec_Nacimiento: formatDate(item.Fec_Nacimiento)
                }));
                setLibrod(librodFormatted);
            } else {
                setLibrod([]);
            }
        } catch (error) {
            console.error('Error al obtener los datos del libro diario:', error);
            manejarErroresDeValidacion(error);
        } finally {
            setLoading(false);
        }
    }, [navigate]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('token');
                await validarAccesoModulo(); // Valida el acceso al módulo
                await validarPermisoUsuario(); // Valida el permiso del usuario
                setCanDelete(await validarPermisoEliminar(token)); // Valida el permiso de eliminación
                await getLibrod(); // Obtiene los datos si se tiene acceso
            } catch (error) {
                console.error('Error durante la carga de datos:', error);
            }
        };
        fetchData();
    }, [validarAccesoModulo, validarPermisoUsuario, getLibrod]);

    // Paginación
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = Array.isArray(librod) ? librod.slice(indexOfFirstPost, indexOfLastPost) : [];

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Cancelar y regresar
    const cancel = () => {
        navigate(-1);
    };

    // Mostrar carga o errores
    if (loading) {
        return (
            <div className="loading-container">
                <p>Cargando datos... Por favor, espera.</p>
            </div>
        );
    }

    if (error) {
        return (
            <div className="error-container">
                <p>{error}</p>
                <button className="btn btn-secondary" onClick={cancel}>Volver a inicio</button>
            </div>
        );
    }

    return (
        <Container className="pt-5" style={{ maxWidth: '90%', padding: '0' }}>
            <div className="row">
                <div className="col">
                    <CardHeader>
                        <div>
                            <BotonExcel productos={librod} />
                        </div>
                    </CardHeader>
                    <div className="mb-3">
                        <Link to="/CreateA" className="btn btn-primary mt-3 mb-3">
                            Crear una nueva consulta
                            <i className="fa-solid fa-file-circle-plus" style={{ marginRight: '10px', fontSize: '20px' }}></i>
                        </Link>
                    </div>
                    <div className="text-center">
                        <h1 className="mt-4 mb-4">Registro de Consultas</h1>
                    </div>
                    <table className="table table-striped">
                        <thead className="table-primary">
                            <tr>
                                <th>Nombre del médico</th>
                                <th>Fecha de la última atención anterior</th>
                                <th>Fecha de la última atención</th>
                                <th>Tipo de atención</th>
                                <th>Tipo de jornada</th>
                                <th>Género</th>
                                <th>Fecha de nacimiento</th>
                                <th>Rango de edad</th>
                                <th>Tipo de médico</th>
                                <th>Acciones</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentPosts.map((item) => (
                                <tr key={item.Id_Reg_Libro}>
                                    <td>{item.Id_Medico}</td>
                                    <td>{item.Fecha}</td>
                                    <td>{item.Fec_Atencion}</td>
                                    <td>{item.Id_Tip_Atencion}</td>
                                    <td>{item.Id_Jornada}</td>
                                    <td>{item.Genero}</td>
                                    <td>{item.Fec_Nacimiento}</td>
                                    <td>{item.Id_Ran_Edad_Pac}</td>
                                    <td>{item.Id_Tip_Medico}</td>
                                    <td>
                                        <Link to={`/EditA/${item.Id_Reg_Libro}`} className="btn btn-info">Editar <i className="fa-solid fa-pen-to-square"></i></Link>
                                        <button onClick={() => putidRegLibro(item.Id_Reg_Libro)} className="btn btn-danger" disabled={!canDelete}>Eliminar <i className="fa-solid fa-trash"></i></button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <Pagination>
                        <Pagination.Prev onClick={() => paginate(currentPage - 1)} disabled={currentPage === 1} />
                        {librod.length > 0 && [...Array(Math.ceil(librod.length / postsPerPage)).keys()].map(number => (
                            <Pagination.Item key={number} active={number + 1 === currentPage} onClick={() => paginate(number + 1)}>
                                {number + 1}
                            </Pagination.Item>
                        ))}
                        <Pagination.Next onClick={() => paginate(currentPage + 1)} disabled={currentPage === Math.ceil(librod.length / postsPerPage)} />
                    </Pagination>
                </div>
            </div>
        </Container>
    );
};

export default CompShowAT2R;

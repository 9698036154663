import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import { useNavigate, useParams } from 'react-router-dom';
import '../../../Estilos/StyleFormulario.css';

// Configuración del elemento raíz para los modales
Modal.setAppElement('#root');

const URI = 'https://cisrubenandino.com/ap/usuarios/'
const URI_ROLES = 'https://cisrubenandino.com/ap/ad_rol/';
const URI_PERSONAS = 'https://cisrubenandino.com/ap/ad_persona/';
const URI_PREGUNTAS = 'https://cisrubenandino.com/ap/preg/';
const VALIDAR_MODULO_URI = 'https://cisrubenandino.com/ap/validar-mod-U';
const VALIDAR_PERMISO_URI = 'https://cisrubenandino.com/ap/validar-Au-';

const CompEditUsuario = () => {
    // Estados para los datos iniciales
    const [NOM_Persona, setNOM_Persona] = useState('');
    const [roles, setRoles] = useState('');
    const [preguntas, setPreguntas] = useState([]);
    // Estados para los datos para la edicion
    const [NOM_Usuario, setNOM_Usuario] = useState('');
    const [Contrasena, setContrasena] = useState('');
    const [ID_Rol, setID_Rol] = useState('');
    const [ID_Pregunta_1, setID_Pregunta_1] = useState('');
    const [ID_Pregunta_2, setID_Pregunta_2] = useState('');
    const [ID_Pregunta_3, setID_Pregunta_3] = useState('');
    const [RESP_Seguridad_1, setRESP_Seguridad_1] = useState('');
    const [RESP_Seguridad_2, setRESP_Seguridad_2] = useState('');
    const [RESP_Seguridad_3, setRESP_Seguridad_3] = useState('');
    const [Correo, setCorreo] = useState('');
    const [Usr_Registro] = useState(localStorage.getItem('username') || ''); //Obtiene el usuario que inicio sesión
    // Estados para manejo de carga y errores
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    // Navegación y parámetros de la URL
    const navigate = useNavigate();
    const { ID_Usuario } = useParams();
    // Estados para mensajes y modales
    const [message, setMessage] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
    const [originalData, setOriginalData] = useState(null);

    // Funciones para controlar los modales
    const openModal = () => {
        setModalIsOpen(true);
    };
    const closeModal = () => {
        setModalIsOpen(false);
    };
    const openConfirmModal = () => {
        setConfirmModalIsOpen(true);
    };
    const closeConfirmModal = () => {
        setConfirmModalIsOpen(false);
    };
    const handleModalClose = () => {
        closeModal();
        if (message === '¡Datos actualizados exitosamente!') {
            navigate('/AdmUsers'); // Regresa a la página de usuarios después de una actualización exitosa
        }
    };

    // Función para validar que se tenga acceso al módulo
    const validarAccesoModulo = useCallback(async () => {
        try {
            const res = await axios.get(VALIDAR_MODULO_URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (res.status !== 200 || !res.data.acceso) {
                throw new Error(res.data.mensaje || 'Acceso denegado.');
            }
        } catch (error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                setError('No tienes autorización para acceder a este apartado. Si crees que se trata de un error, comunícaselo al administrador.');
            } else {
                setError('No ha sido posible validar el acceso. Contacta con el administrador.');
            }
            setLoading(false);
            throw error;
        }
    }, []);

    // Función para validar el permiso necesario
    const validarPermisoModulo = useCallback(async () => {
        try {
            const res = await axios.get(VALIDAR_PERMISO_URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (res.status !== 200 || !res.data.acceso) {
                throw new Error('No tienes autorización para acceder a este apartado.');
            }
        } catch (error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                setError('No se cuenta con el acceso completo para acceder a este apartado.');
            } else {
                setError('No ha sido posible validar el acceso. Contacta con el administrador.');
            }
            setLoading(false);
            throw error;
        }
    }, []);

    // Verifica si se han realizado cambios en los campos para evitar updates vacios
    const handleSubmit = (e) => {
        e.preventDefault();
        const hasChanges =
            NOM_Usuario !== originalData.NOM_Usuario ||
            Contrasena !== "" ||
            ID_Rol !== originalData.ID_Rol ||
            Correo !== originalData.Correo ||
            ID_Pregunta_1 !== originalData.ID_Pregunta_1 ||
            ID_Pregunta_2 !== originalData.ID_Pregunta_2 ||
            ID_Pregunta_3 !== originalData.ID_Pregunta_3 ||
            RESP_Seguridad_1 !== "" ||
            RESP_Seguridad_2 !== "" ||
            RESP_Seguridad_3 !== "";

        if (hasChanges) {
            openConfirmModal(); 
        } else {
            setMessage('No se han realizado cambios.');
            openModal();
        }
    };

    // Función para obtener los datos del usuario por ID
    const getUsuarioByID = useCallback(async () => {
        try {
            const userResponse = await axios.get(URI + ID_Usuario, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            const userData = userResponse.data;

            setNOM_Usuario(userData.NOM_Usuario);
            setID_Rol(userData.ID_Rol); 
            setCorreo(userData.Correo);
            setID_Pregunta_1(userData.ID_Pregunta_1);
            setID_Pregunta_2(userData.ID_Pregunta_2);
            setID_Pregunta_3(userData.ID_Pregunta_3);
            setOriginalData(userData);

            const rolesResponse = await axios.get(URI_ROLES, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            const personasResponse = await axios.get(URI_PERSONAS + userData.ID_Persona, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            const preguntasResponse = await axios.get(URI_PREGUNTAS, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            setRoles(rolesResponse.data);
            setPreguntas(preguntasResponse.data);
            setNOM_Persona(personasResponse.data.NOM_Persona || '');

            setLoading(false);

        } catch (error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                localStorage.removeItem('token');
                setError('No ha sido posible validar tus credenciales, vuelve a iniciar sesión.');
                navigate(`/InicioNoAutorizado?error=${encodeURIComponent(error.response.data.message)}`);
            } else {
                setError('¡Ocurrió un error y no se pudo recuperar los datos! Inténtalo de nuevo.');
            }
            setLoading(false);
            throw error;
        }
    }, [ID_Usuario, navigate]);

    // Efecto para obtener los datos del usuario al cargar el componente
    useEffect(() => {
        const getUsuarioByID = async () => {
            try {
                // Cargar datos del usuario
                const userResponse = await axios.get(URI + ID_Usuario, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });
                const userData = userResponse.data;
                // Asignación de los valores obtenidos a los estados correspondientes
                setNOM_Usuario(userData.NOM_Usuario);
                setID_Rol(userData.ID_Rol); 
                setCorreo(userData.Correo);
                setOriginalData(userData);
                setID_Pregunta_1(userData.ID_Pregunta_1);
                setID_Pregunta_2(userData.ID_Pregunta_2);
                setID_Pregunta_3(userData.ID_Pregunta_3);
                
                // Cargar roles y personas
                const rolesResponse = await axios.get(URI_ROLES, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });
                const personasResponse = await axios.get(URI_PERSONAS + userData.ID_Persona, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });

                const preguntasResponse = await axios.get(URI_PREGUNTAS,{
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });
                
                setRoles(rolesResponse.data);
                setNOM_Persona(personasResponse.data.NOM_Persona || '');
                setPreguntas(preguntasResponse.data);
                
                setLoading(false); // Indica que la carga ha finalizado
            } catch (error) {
                setError('¡Ocurrió un error y no se pudo recuperar los datos! Inténtalo de nuevo.');
                setLoading(false);
                openModal();  // Abre el modal para mostrar el mensaje de error
            }
        };

        getUsuarioByID();
    }, [ID_Usuario]);

    // Efecto para ejecutar las validaciones y cargar datos al montar el componente
    useEffect(() => {
        const fetchData = async () => {
            try {
                await validarAccesoModulo(); // Valida el acceso al módulo
                await validarPermisoModulo(); // Valida el permiso del usuario
                await getUsuarioByID(); // Obtiene los datos del usuario si se tiene acceso
                setLoading(false);
            } catch (error) {
                console.error('Error durante la carga de datos:', error);
            }
        };
        fetchData();
    }, [validarAccesoModulo, validarPermisoModulo, getUsuarioByID]);

    // Función para filtrar las preguntas seleccionadas
    const getFilteredPreguntas = (currentField) => {
        const selectedIds = [
            ID_Pregunta_1,
            ID_Pregunta_2,
            ID_Pregunta_3
        ].filter(id => id && id !== currentField); // Excluye el ID del campo actual

        return preguntas.filter(pregunta => !selectedIds.includes(pregunta.ID_Pregunta.toString()));
    };

    // Función para actualizar los datos en la API
    const update = async () => {
        closeConfirmModal(); // Cierra el modal de confirmación
        try {
            await axios.put(URI + ID_Usuario, {
                NOM_Usuario,
                Contrasena: Contrasena || undefined, // Enviar `undefined` si la contraseña está vacía para no modificarla,
                ID_Rol,
                Correo,
                ID_Pregunta_1,
                RESP_Seguridad_1:RESP_Seguridad_1 || undefined, // lo mismo para evitar cambiar la respuesta de seguridad si no se proporciona
                ID_Pregunta_2,
                RESP_Seguridad_2:RESP_Seguridad_2 || undefined,
                ID_Pregunta_3,
                RESP_Seguridad_3:RESP_Seguridad_3 || undefined,
                Usr_Registro
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            setMessage('¡Datos actualizados exitosamente!');
            openModal();
        } catch (error) {
            setMessage('¡Ocurrió un error y no se pudo actualizar los datos! Inténtalo de nuevo.');
            openModal();
        }
    };

    // Función para cancelar y regresar a la página anterior
    const cancel = () => {
        navigate('/AdmUsers');
    };

    // Restaurar un campo específico al valor original
    const restoreField = (fieldName) => {
        switch (fieldName) {
            case 'NOM_Usuario':
                setNOM_Usuario(originalData.NOM_Usuario);
                break;
            case 'ID_Rol':
                setID_Rol(originalData.ID_Rol);
                break;
            case 'Correo':
                setCorreo(originalData.Correo);
                break;
            case 'ID_Pregunta_1':
                setID_Pregunta_1(originalData.ID_Pregunta_1);
                break;
            case 'ID_Pregunta_2':
                setID_Pregunta_2(originalData.ID_Pregunta_2);
                break;
            case 'ID_Pregunta_3':
                setID_Pregunta_3(originalData.ID_Pregunta_3);
                break;
            default:
                break;
        }
    };

    // Restaurar todos los campos a sus valores originales
    const restoreAllFields = () => {
        setNOM_Usuario(originalData.NOM_Usuario);
        setID_Rol(originalData.ID_Rol);
        setCorreo(originalData.Correo);
        setID_Pregunta_1(originalData.ID_Pregunta_1);
        setID_Pregunta_2(originalData.ID_Pregunta_2);
        setID_Pregunta_3(originalData.ID_Pregunta_3);
        
    };

    // Muestra un indicador de carga mientras se obtienen los datos
    if (loading) {
        return (
            <div className="loading-container">
                <p>Cargando datos... Por favor, espera.</p>
            </div>
        );
    }
    // Muestra un mensaje de error en caso de que ocurra
    if (error) {
        return (
            <div className="error-container">
                <p>{error}</p>
                <button className="btn btn-secondary" onClick={cancel}>Volver</button>
            </div>
        );
    }

    return (
        <div>
        <div className="titulo-container">
            <h3>Editar Datos Basicos del Usuario</h3>
        </div>
        <div className="formulario-container">
            <form onSubmit={handleSubmit}>
               <div className="mb-3">
                    <label className="form-label">Persona</label>
                    <input
                        type="text"
                        value={NOM_Persona}
                        readOnly
                        className="form-control"
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">Nombre de usuario</label>
                    <input
                       value={NOM_Usuario}
                       onChange={(e)=> setNOM_Usuario(e.target.value)}
                       type="text"
                       className="form-control"
                    />
                    <button type="button" className="btn btn-info" onClick={() => restoreField('NOM_Usuario')}>
                        Restaurar
                    </button>
                </div>
                <div className="mb-3">
                    <label className="form-label">Cambiar contraseña</label>
                    <input
                       value={Contrasena}
                       onChange={(e)=> setContrasena(e.target.value)}
                       type="text"
                       placeholder="Ingresa la contraseña"
                       className="form-control"
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">Cambiar Rol (seleccione un rol →)</label>
                    <select
                        value={ID_Rol}
                        onChange={(e)=> setID_Rol(e.target.value)}
                        className="form-control"
                    >
                    <option value="">Selecciona un rol →</option>
                            {roles.map((rol) => (
                                <option key={rol.ID_Rol} value={rol.ID_Rol}>
                                    {rol.NOM_Rol}  {/* Mostrar el nombre del rol */}
                                </option>
                            ))}
                    </select>
                    <button type="button" className="btn btn-info mt-1" onClick={() => restoreField('ID_Rol')}>
                        Restaurar
                    </button>
                </div>
                <div className="mb-3">
                    <label className="form-label">Cambiar Correo Electrónico</label>
                    <input
                       value={Correo}
                       onChange={(e)=> setCorreo(e.target.value)}
                       type="text"
                       className="form-control"
                    />
                    <button type="button" className="btn btn-info" onClick={() => restoreField('Correo')}>
                        Restaurar
                    </button>
                </div>
                <div className="mb-3">
                        <label className="form-label">Pregunta de Seguridad 1</label>
                        <select
                            value={ID_Pregunta_1}
                            className='form-control'
                            onChange={(e) => setID_Pregunta_1(e.target.value)}
                        >
                            <option value="">Selecciona una pregunta →</option>
                            {getFilteredPreguntas(ID_Pregunta_1).map(pregunta => (
                                <option key={pregunta.ID_Pregunta} value={pregunta.ID_Pregunta}>
                                    {pregunta.PREG_Seguridad} {/* Mostrar la pregunta */}
                                </option>
                            ))}
                        </select>
                        <button type="button" className="btn btn-info" onClick={() => restoreField('ID_Pregunta_1')}>
                            Restaurar
                        </button>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Respuesta de Seguridad 1</label>
                        <input
                            value={RESP_Seguridad_1}
                            onChange={(e) => setRESP_Seguridad_1(e.target.value)}
                            type="text"
                            placeholder="Ingresa la respuesta a la pregunta seleccionada (Se debe seleccionar o cambiar la pregunta)"
                            className='form-control'
                            disabled={!ID_Pregunta_1} // Deshabilita el input si no hay pregunta seleccionada
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Pregunta de Seguridad 2</label>
                        <select
                            value={ID_Pregunta_2}
                            onChange={(e) => setID_Pregunta_2(e.target.value)}
                            className='form-control'
                        >
                            <option value="">Selecciona una pregunta →</option>
                            {getFilteredPreguntas(ID_Pregunta_2).map(pregunta => (
                                <option key={pregunta.ID_Pregunta} value={pregunta.ID_Pregunta}>
                                    {pregunta.PREG_Seguridad} {/* Mostrar la pregunta */}
                                </option>
                            ))}
                        </select>
                        <button type="button" className="btn btn-info" onClick={() => restoreField('ID_Pregunta_2')}>
                            Restaurar
                        </button>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Respuesta de Seguridad 2</label>
                        <input
                            value={RESP_Seguridad_2}
                            onChange={(e) => setRESP_Seguridad_2(e.target.value)}
                            type="text"
                            placeholder="Ingresa la respuesta a la pregunta seleccionada (Se debe seleccionar o cambiar la pregunta)"
                            className='form-control'
                            disabled={!ID_Pregunta_2} // Deshabilita el input si no hay pregunta seleccionada
                        />
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Pregunta de Seguridad 3</label>
                        <select
                            value={ID_Pregunta_3}
                            onChange={(e) => setID_Pregunta_3(e.target.value)}
                            className='form-control'
                        >
                            <option value="">Selecciona una pregunta →</option>
                            {getFilteredPreguntas(ID_Pregunta_3).map(pregunta => (
                                <option key={pregunta.ID_Pregunta} value={pregunta.ID_Pregunta}>
                                    {pregunta.PREG_Seguridad} {/* Mostrar la pregunta */}
                                </option>
                            ))}
                        </select>
                        <button type="button" className="btn btn-info" onClick={() => restoreField('ID_Pregunta_3')}>
                            Restaurar
                        </button>
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Respuesta de Seguridad 3</label>
                        <input
                            value={RESP_Seguridad_3}
                            onChange={(e) => setRESP_Seguridad_3(e.target.value)}
                            type="text"
                            placeholder="Ingresa la respuesta a la pregunta seleccionada (Se debe seleccionar o cambiar la pregunta)"
                            className='form-control'
                            disabled={!ID_Pregunta_3} // Deshabilita el input si no hay pregunta seleccionada
                        />
                    </div>
                    {/* Botones de acción */}
                    <button type="submit" className="btn btn-success">Guardar Cambios <i className="fa-solid fa-pen"></i></button>
                    <button type="button" className="btn btn-primary" onClick={restoreAllFields}>Restaurar Todo <i className="fa-solid fa-rotate-right"></i></button>
                    <button type="button" className="btn btn-danger" onClick={cancel}>Cancelar y regresar <i className="fa-solid fa-rotate-left"></i></button>
                </form>
            </div>
            {/* Modal de confirmación */}
            <Modal
                isOpen={confirmModalIsOpen}
                onRequestClose={closeConfirmModal}
                contentLabel="Confirmación Modal"
                className="custom-modal"
                overlayClassName="custom-overlay"
            >
                <div className="modal-content">
                  <h2>¿Estás seguro de que deseas guardar los cambios?</h2>
                  <div className="btn-container">
                        <button onClick={update} className="btn btn-success">Sí, Guardar <i className="fa-solid fa-check"></i> </button>
                        <button onClick={closeConfirmModal} className="btn btn-danger">No, Regresar <i className="fa-solid fa-xmark"></i> </button>
                    </div>
                </div>
            </Modal>

            {/* Modal de mensaje */}
            <Modal
                 isOpen={modalIsOpen}
                 onRequestClose={handleModalClose}
                 contentLabel="Mensaje Modal"
                 className="custom-modal"
                 overlayClassName="custom-overlay"
            >
                   <div className="modal-content">
                      <h2>{message}</h2>
                        <div className="btn-container">
                            <button onClick={handleModalClose} className="btn btn-primary">Aceptar</button>
                        </div>
                    </div>
                 </Modal>
            </div>
    );
};

export default CompEditUsuario
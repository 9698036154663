import React from 'react';
import '../Estilos/Footer.css';

function Footer() {
  return (
    <footer className="footer-container">
      <div className="footer-links">
        <a href="https://m.facebook.com/people/CIS-Dr-Rub%C3%A9n-Andino-Aguilar/100068355048162/" target="_blank" rel="noopener noreferrer">
          Cis. Ruben Andino Aguilar
        </a>
        <a href="https://www.facebook.com/RegionSanitariaMetropolitanaDC64/?locale=es_LA" target="_blank" rel="noopener noreferrer">
          Region sanitaria metropolitana
        </a>
        <a href="https://www.unah.edu.hn/" target="_blank" rel="noopener noreferrer">
          Universidad Nacional Autónoma de Honduras
        </a>
      </div>
      <p>Todos los derechos reservados</p>
    </footer>
  );
}

export default Footer;


import axios from "axios";
import { useState,  useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Modal from 'react-modal';
import '../../Estilos/StyleFormulario.css';



const URI = 'https://cisrubenandino.com/ap/enfermerias/'
const VALIDAR_PERMISO_URI = 'https://cisrubenandino.com/ap/validar-enI-';
const VALIDAR_MODULO_URI = 'https://cisrubenandino.com/ap/validar-mod-e';
 
const CompCreateEnfer = () => { 
     
    const Navigate = useNavigate()
    const [formData, setFormData] = useState({
      NOM_Producto: '',
      Especificacion: '',
      Tamano: '',
      Capacidad: '',
      Tipo: '',
      Cantidad: '',
      Precio: '',
      Dosificacion_1: '',
      Dosificacion_2: '',
      Dosificacion_3: '',
      Lote: '', 
      FEC_Vencimiento: new Date().toISOString().split('T')[0], // Fecha actual
      Aplicadas: '',
      Perdidas: '',
      Descartadas: '',
      CAN_Usada: '',
      Usr_Registro: localStorage.getItem('username') || '',
  });
  // Estado para errores
const [errors, setErrors] = useState({});
const [warnings, setWarnings] = useState({});
const [error, setError] = useState(null);
const [loading, setLoading] = useState(true);
// Estado para mensajes de modal
const [modalIsOpen, setModalIsOpen] = useState(false);
const [modalMessage, setModalMessage] = useState('');
const [redirectAfterClose, setRedirectAfterClose] = useState(false);

// Funciónes del modal
const openModal = (message, redirect = false) => {
    setModalMessage(message);
    setModalIsOpen(true);
    setRedirectAfterClose(redirect);
  };
  const closeModal = () => {
    setModalIsOpen(false);
    if (redirectAfterClose) {
      Navigate('/Inicio');
  }
  };
  

    // Validar acceso al módulo
    const validarAccesoModulo = useCallback(async () => {
        try {
            const res = await axios.get(VALIDAR_MODULO_URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
    
            if (res.status !== 200 || !res.data.acceso) {
                throw new Error(res.data.mensaje || 'Acceso denegado.');
            }
        } catch (error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                setError('No tienes autorización para acceder a este apartado. Si crees que se trata de un error, comunícaselo al administrador.');
            } else {
                setError('No ha sido posible validar el acceso. Contacta con el administrador.');
            }
            setLoading(false);
            throw error;
        }
    }, []);

    // Validar permisos del usuario
    const validarPermisoUsuario = useCallback(async () => {
        try {
            const res = await axios.get(VALIDAR_PERMISO_URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (res.status !== 200 || !res.data.acceso) {
                throw new Error(res.data.mensaje || 'Permiso denegado.');
            }
        } catch (error) {
          setError('No tienes autorización para realizar esta acción. Si crees que se trata de un error, comunícalo al administrador.');
          setLoading(false);
          throw error;
        }
    }, []);
     

    useEffect(() => {
        const validarAccesos = async () => {
            try {
                await validarAccesoModulo();
                await validarPermisoUsuario();
                setLoading(false);
            } catch (err) {
                setLoading(false);
            }
        };

        validarAccesos();
    }, [validarAccesoModulo, validarPermisoUsuario]);

 
// Función para manejar los cambios en los campos de entrada
const handleChange = (e) => {
    const { name, value } = e.target;
    // Actualizar formData con el valor del campo que ha cambiado
    setFormData((prevData) => ({
        ...prevData,
        [name]: value
    }));
    // Llamar a la función validateField para validar el campo que acaba de cambiar
    validateField(name, value);
};
// Función para validar un campo específico
const validateField = (name, value) => {
    let error = ''; // Inicializa el mensaje de error
    let warning = ''; // Inicializa el mensaje de advertencia

    // Expresiones regulares comunes
    
    const lettersAndSingleSpacePattern = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ]+(\s[a-zA-ZáéíóúÁÉÍÓÚñÑ]+)*$/;
    const forbiddenCharacter = /[^a-zA-Z0-9\s,.-]/;
    switch (name) {
        case 'NOM_Producto':
        if (!value.trim()) {
            error = 'El nombre del producto es obligatorio.';
        } else {
            // Longitud máxima
            if (value.length > 100) {
                error = 'El nombre del producto no puede tener mas de 100 letras.';
            }
            // Validar caracteres no permitidos
            const forbiddenCharacters = /[^a-zA-Z0-9\s,.-]/;
            if (forbiddenCharacters.test(value)) {
                error = 'El nombre del producto contiene caracteres no permitidos.';
            }
            // Verificar uso de espacios en blanco
            const leadingOrTrailingSpaces = /^\s+|\s+$/;
            if (leadingOrTrailingSpaces.test(value)) {
                error = 'El nombre del producto no debe tener espacios en blanco al inicio o al final.';
            }
            const multipleSpaces = /\s{2,}/;
            if (multipleSpaces.test(value)) {
                error = 'El nombre del producto no debe contener múltiples espacios en blanco consecutivos.';
            }
            else if (!lettersAndSingleSpacePattern.test(value)) {
                error = 'Solo pueden haber letras y un solo espacio entre palabras.';
            }
        } 
          break; 
        case 'Especificacion':
            if (!value.trim()) {
                error = 'La especificacion del producto es obligatorio.';
          } else {
              // Verificar si hay un espacio al principio o al final
              if (/^\s/.test(value) || /\s$/.test(value)) {
                  error = 'El campo no puede comenzar o terminar con un espacio. Por favor, elimina los espacios.';
              } else if (/\s{2,}/.test(value)) {
                  error = 'El campo no puede contener múltiples espacios consecutivos. Por favor, elimina los espacios extra.';
              } else if (value.length > 200) {
                  error = 'El campo no debe superar los 200 caracteres. Reduce la cantidad de palabras.';
              } else if (!lettersAndSingleSpacePattern.test(value)) {
                error = 'Solo pueden haber letras y un solo espacio entre palabras.';
            } else if (forbiddenCharacter.test(value)) {
                error = 'La especificacion contiene caracteres no permitidos.';
            }
          }
            break;
            case 'Tamano':
            if (!value.trim()) {
              error = 'El tamaño del producto es obligatorio.';
          } else {
              // Verificar si hay un espacio al principio o al final
              if (/^\s/.test(value) || /\s$/.test(value)) {
                  error = 'El campo Tamaño no puede comenzar o terminar con un espacio. Por favor, elimina los espacios.';
              } else if (/\s{2,}/.test(value)) {
                  error = 'El campo Tamaño no puede contener múltiples espacios consecutivos. Por favor, elimina los espacios extra.';
              } else if (forbiddenCharacter.test(value)) {
                  error = 'El tamaño del producto contiene caracteres no permitidos.';
                } else if (value.length > 100) {
                    error = 'El campo no debe superar los 100 caracteres. Reduce la cantidad de palabras.';
                  
              }
          }
            break;

            case 'Capacidad':
            if (!value.trim()) {
              error = 'La capacidad del producto es obligatorio.';
          } else {
              // Verificar si hay un espacio al principio o al final
              if (/^\s/.test(value) || /\s$/.test(value)) {
                  error = 'El campo Capacidad no puede comenzar o terminar con un espacio. Por favor, elimina los espacios.';
              } else if (/\s{2,}/.test(value)) {
                  error = 'El campo Capacidad no puede contener múltiples espacios consecutivos. Por favor, elimina los espacios extra.';
              } else if (value.length > 20) {
                  error = 'El campo Capacidad no debe superar los 20 caracteres.';
              } else  if (forbiddenCharacter.test(value)) {
                error = 'La capacidad del producto contiene caracteres no permitidos.';
             }
          }
            break;

            case 'Tipo':
            if (!value.trim()) {
                error = 'El tipo del producto es obligatorio.';
          } else {
              // Verificar si hay un espacio al principio o al final
              if (/^\s/.test(value) || /\s$/.test(value)) {
                  error = 'El campo no puede comenzar o terminar con un espacio. Por favor, elimina los espacios.';
              } else if (/\s{2,}/.test(value)) {
                  error = 'El campo no puede contener múltiples espacios consecutivos. Por favor, elimina los espacios extra.';
              } else if (value.length > 100) {
                  error = 'El campo no debe superar los 100 caracteres. Reduce la cantidad de palabras.';
              } else  if (forbiddenCharacter.test(value)) {
                error = 'El tipo del producto contiene caracteres no permitidos.';
             }
          }
            break;
        
            case 'Cantidad':
                if (!value.trim()) {
                  error = 'La cantidad del producto es obligatorio.';
              } else {
                  // Verificar si hay un espacio al principio o al final
                  if (/^\s/.test(value) || /\s$/.test(value)) {
                      error = 'El campo Cantidad no puede comenzar o terminar con un espacio. Por favor, elimina los espacios.';
                  } else if (/\s{2,}/.test(value)) {
                      error = 'El campo Cantidad no puede contener múltiples espacios consecutivos. Por favor, elimina los espacios extra.';
                  } else if (!/^\d+$/.test(value)) {
                      error = 'El campo solo puede contener números.';
                  } else if (value.length > 20) {
                      error = 'El campo Cantidad no debe superar los 20 caracteres.';
                  } else  if (forbiddenCharacter.test(value)) {
                    error = 'La cantidad del producto contiene caracteres no permitidos.';
                 }
              }
                break;

                case 'Precio':
                    if (!value.trim()) {
                        error = 'El precio del producto es obligatorio.';
                  } else {
                      // Verificar si hay un espacio al principio o al final
                      if (/^\s/.test(value) || /\s$/.test(value)) {
                          error = 'El campo Precio no puede comenzar o terminar con un espacio. Por favor, elimina los espacios.';
                      } else if (/\s{2,}/.test(value)) {
                          error = 'El campo Precio no puede contener múltiples espacios consecutivos. Por favor, elimina los espacios extra.';
                      } else if (!/^\d+$/.test(value)) {
                          error = 'El campo solo puede contener números.';
                      } else if (value.length > 10) {
                          error = 'El campo Precio no debe superar los 20 caracteres.';
                      } else if (forbiddenCharacter.test(value)) {
                          error = 'El precio del producto contiene caracteres no permitidos.';
                  }
                }
                    break;

        case 'Dosificacion_1':
                        if (!value.trim()) {
                          error = 'Primera Dosificacion es obligatorio.';
                      }
                      const forbiddenCharacters = /[^a-zA-Z0-9\s,.-]/;
            if (forbiddenCharacters.test(value)) {
                error = 'La dosificacion contiene caracteres no permitidos.';
            }
                      break;
        case 'Dosificacion_2':
                        if (!value.trim()) {
                            error = 'Segunda dosificacion es obligatorio.';
                      }
                       if (forbiddenCharacter.test(value)) {
                          error = 'La dosificacion contiene caracteres no permitidos.';
                       }
                      break;
        case 'Dosificacion_3':
                    if (!value.trim()) {
                        error = 'Tercera dosificacion es obligatorio.';
                  }  
                  if (forbiddenCharacter.test(value)) {
                    error = 'La dosificacion contiene caracteres no permitidos.';
                 }
                       
                  break;

        case 'Lote':
            if (!value.trim()) {
                error = 'El lote es obligatorio.';
          } else {
              // Verificar si hay un espacio al principio o al final
              if (/^\s/.test(value) || /\s$/.test(value)) {
                  error = 'El campo no puede comenzar o terminar con un espacio. Por favor, elimina los espacios.';
              } else if (/\s{2,}/.test(value)) {
                  error = 'El campo no puede contener múltiples espacios consecutivos. Por favor, elimina los espacios extra.';
              } else if (value.length > 200) {
                  error = 'El campo no debe superar los 200 caracteres. Reduce la cantidad de palabras.';
              } else if (forbiddenCharacter.test(value)) {
                error = 'El lote del producto contiene caracteres no permitidos.';
             }
          }
            break;

        case 'FEC_Vencimiento':
            if (value.trim() !== '') {
              const inputDate = new Date(value);
              const currentDate = new Date();
              // Redondear las fechas para eliminar horas, minutos y segundos
            inputDate.setHours(0, 0, 0, 0);
            currentDate.setHours(0, 0, 0, 0);
            // Crear fechas de tres días antes y tres días después de la fecha actual
            const oneDaysBefore = new Date(currentDate);
            oneDaysBefore.setDate(currentDate.getDate() - 1);
            const oneDaysAfter = new Date(currentDate);
            oneDaysAfter.setDate(currentDate.getDate() + 3); 
            // Verificar si la fecha de creación está dentro del rango permitido
            if (inputDate < oneDaysBefore || inputDate < oneDaysAfter) {
                error = 'La fecha de creación no debe estar muy adelantada o muy retrasada a la fecha actual. Por favor, valide la fecha correcta.';
           
              
               }
          }
          break;        
        
        case 'Aplicadas':
            if (!value.trim()) {
                error = 'La cantidad aplicada es obligatorio.';
          } else {
              // Verificar si hay un espacio al principio o al final
              if (/^\s/.test(value) || /\s$/.test(value)) {
                  error = 'El campo no puede comenzar o terminar con un espacio. Por favor, elimina los espacios.';
              } else if (/\s{2,}/.test(value)) {
                  error = 'El campo no puede contener múltiples espacios consecutivos. Por favor, elimina los espacios extra.';
                } else if (!/^\d+$/.test(value)) {
                    error = 'El campo solo puede contener números.';
              } else  if (forbiddenCharacter.test(value)) {
                error = 'La cantidad del producto contiene caracteres no permitidos.';
             }
          }
            break;

            case 'Perdidas':
            if (!value.trim()) {
                error = 'La cantidad perdida es obligatorio.';
          } else {
              // Verificar si hay un espacio al principio o al final
              if (/^\s/.test(value) || /\s$/.test(value)) {
                  error = 'El campo no puede comenzar o terminar con un espacio. Por favor, elimina los espacios.';
              } else if (/\s{2,}/.test(value)) {
                  error = 'El campo no puede contener múltiples espacios consecutivos. Por favor, elimina los espacios extra.';
              } else if (!/^\d+$/.test(value)) {
                          error = 'El campo solo puede contener números.';
              } else  if (forbiddenCharacter.test(value)) {
                error = 'Este campo contiene caracteres no permitidos.';
             }
          }
            break;

            case 'Descartadas':
            if (!value.trim()) {
                error = 'La cantidad descartada es obligatorio.';
          } else {
              // Verificar si hay un espacio al principio o al final
              if (/^\s/.test(value) || /\s$/.test(value)) {
                  error = 'El campo no puede comenzar o terminar con un espacio. Por favor, elimina los espacios.';
              } else if (/\s{2,}/.test(value)) {
                  error = 'El campo no puede contener múltiples espacios consecutivos. Por favor, elimina los espacios extra.';
                } else if (!/^\d+$/.test(value)) {
                    error = 'El campo solo puede contener números.';
              } else  if (forbiddenCharacter.test(value)) {
                error = 'Este campo contiene caracteres no permitidos.';
             }
          }
            break;

            case 'CAN_Usada':
            if (!value.trim()) {
                error = 'La cantidad usada es obligatorio.';
          } else {
              // Verificar si hay un espacio al principio o al final
              if (/^\s/.test(value) || /\s$/.test(value)) {
                  error = 'El campo no puede comenzar o terminar con un espacio. Por favor, elimina los espacios.';
              } else if (/\s{2,}/.test(value)) {
                  error = 'El campo no puede contener múltiples espacios consecutivos. Por favor, elimina los espacios extra.';
                } else if (!/^\d+$/.test(value)) {
                    error = 'El campo solo puede contener números.';
              } else  if (forbiddenCharacter.test(value)) {
                error = 'Este campo contiene caracteres no permitidos.';
             }
          }
            break;

    }
    // Actualiza el objeto de estado de errores y advertencia con el resultado de la validación
  setErrors((prevErrors) => ({
    ...prevErrors,
    [name]: error,
}));
setWarnings((prevWarnings) => ({
  ...prevWarnings,
  [name]: warning,
}));
};

// Función para validar todos los campos antes de enviar el formulario
const validateAllFields = () => {
    Object.keys(formData).forEach((field) => validateField(field, formData[field]));
    return !Object.values(errors).some((error) => error !== '');
  };
    // Procedimiento para limpiar los campos de entrada
    const clearInputs = () => {
        setFormData({
         setNOM_Producto: '',
         setEspecificacion: '',
         setTamano: '',
         setCapacidad: '',
         setTipo: '',
         setCantidad: '',
         setPrecio: '',
         setDosificacion_1: '',
         setDosificacion_2: '',
         setDosificacion_3: '',
         setLote: '',
         setFEC_Vencimiento: new Date().toISOString().split('T')[0],
         setAplicadas: '',
         setPerdidas: '',
         setDescartadas: '',
         setCAN_Usada:'',
         Usr_Registro: localStorage.getItem('username') || ''
        
    });
    setErrors({});
  setWarnings({});
};
    

    // Procedimiento para limpiar y regresar
    const handleCancel = () => {
        clearInputs(); 
        Navigate(-1); 
    };

    
// Lista de campos obligatorios
const requiredFields = [
    'NOM_Producto',     
    'Especificacion',
    'Tamano',
    'Capacidad',
    'Tipo',
    'Cantidad',
    'Precio',
    'Dosificacion_1',
    'Dosificacion_2',
    'Dosificacion_3',
    'Lote',
    'FEC_Vencimiento',
    'Aplicadas',
    'Perdidas',
    'Descartadas',
    'CAN_Usada'
];

// Función para validar los campos obligatorios
const validateRequiredFields = () => {
    let hasError = false;
    const newErrors = {};

    requiredFields.forEach((field) => {
        const value = formData[field];

        if (!value.trim()) {
            // Si el campo está vacío, actualiza el estado de errores con un mensaje de error
            newErrors[field] = `El campo es obligatorio.`;
            hasError = true;
        }
    });

    setErrors((prevErrors) => ({
        ...prevErrors,
        ...newErrors
    }));

    return !hasError;
};
    
    //Procedimiento Guardar
    const crear = async (e) => {
        e.preventDefault();

         // Validar el acceso al módulo
await validarAccesoModulo();

       // Validar el permiso del usuario para realizar la acción de creación
await validarPermisoUsuario();

        // Validar los campos obligatorios
const areRequiredFieldsValid = validateRequiredFields();

const allFieldsFilled = requiredFields.every(
    (field) => formData[field] && formData[field].trim() !== ''
);
// Verificar que los campos obligatorios estén llenos
if (!allFieldsFilled) {
    openModal('Los datos son insuficientes o no existen. Por favor, completa al menos todos los campos obligatorios para continuar.');
    return;
}
if (!areRequiredFieldsValid) {
    openModal('Los campos obligatorios deben completarse antes de poder guardar el expediente.');
    return;
}
  // Validar todos los campos
  const isValid = validateAllFields();
  if (!isValid) {
      openModal('Se han detectado uno o mas errores, por favor corrigelos antes de poder continuar.');
      return;
  }


  try {
    // Realizar la petición POST a la API
    await axios.post(URI, formData, {
      headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    });
    openModal('¡Expediente creado exitosamente!', true);
    clearInputs();

            setTimeout(() => {
                Navigate('/MostrarEN');
            }, 2000); // Tiempo para redirigir después de mostrar el mensaje

        } catch (error) {
            console.error('Error:', error);
            openModal('¡Ocurrió un error y no se ha podido crear el expediente, intentalo de nuevo!. Si el problema persiste, por favor contacta con el administrador');
            
        }
    };

    if (loading) {
        return <div>Cargando...</div>;
    }

    if (error) {
        return <div style={{ color: 'red' }}>{error}</div>;
    }


    return (
        <div>
             <div className="titulo-container">
            <h3>Registrar un nuevo Producto</h3>
            </div>
            <div className="formulario-container">
            <form onSubmit={crear}>
                <div className="mb-3">
                    <label className="form-label">Nombre de Producto</label>
                    <input
                       name="NOM_Producto"
                       value={formData.NOM_Producto}
                       onChange={handleChange}
                       type="text"
                       placeholder="Escribe aquí el nombre del producto"
                       className={`form-control ${errors.NOM_Producto ? 'is-invalid' : ''}`}
                       />
                       {errors.NOM_Producto && (
                           <div className="error-feedback">{errors.NOM_Producto}</div>
                        )}

                </div>
                <div className="mb-3">
                    <label className="form-label">Especificacion</label>
                    <input
                    name="Especificacion"
                       value={formData.Especificacion}
                       onChange={handleChange}
                       type="text"
                       placeholder="Informacion detallada del producto"
                       className={`form-control ${errors.Especificacion ? 'is-invalid' : ''}`}
                       />
                       {errors.Especificacion && (
                           <div className="error-feedback">{errors.Especificacion}</div>
                        )}

                
                </div>
                <div className="mb-3">
                    <label className="form-label">Tamaño</label>
                    <input
                       name="Tamano"
                       value={formData.Tamano}
                       onChange={handleChange}
                       type="text"
                       placeholder="Escribe aqui el tamaño del producto "
                       className={`form-control ${errors.Tamano ? 'is-invalid' : warnings.Tamano ? 'is-warning' :''}`}
                       />
                       {errors.Tamano && (
                           <div className="error-feedback">{errors.Tamano}</div>
                        )}
                        {warnings.Tamano && (
                  <div className="warning-feedback">{warnings.Tamano}</div>
                )}

                </div>
                <div className="mb-3">
                    <label className="form-label">Capacidad</label>
                      <input
                       name="Capacidad"
                       value={formData.Capacidad}
                       onChange={handleChange}
                       type="text"
                       placeholder="Indique la capacidad del producto"
                       className={`form-control ${errors.Capacidad ? 'is-invalid' : ''}`}
                       />
                       {errors.Capacidad && (
                           <div className="error-feedback">{errors.Capacidad}</div>
                        )}               
                </div>
                <div className="mb-3">
                    <label className="form-label">Tipo</label>
                      <input
                       name="Tipo"
                       value={formData.Tipo}
                       onChange={handleChange}
                       type="text"
                       placeholder="Tipo de producto "
                       className={`form-control ${errors.Tipo ? 'is-invalid' : ''}`}
                       />
                       {errors.Tipo && (
                           <div className="error-feedback">{errors.Tipo}</div>
                        )}

                    </div>
                <div className="mb-3">
                    <label className="form-label">Cantidad</label>
                       <input
                       name="Cantidad"
                       value={formData.Cantidad}
                       onChange={handleChange}
                       type="tinyint"
                       placeholder="Cantidad obtenida del producto"
                       className={`form-control ${errors.Cantidad ? 'is-invalid' : ''}`}
                       />
                       {errors.Cantidad && (
                           <div className="error-feedback">{errors.Cantidad}</div>
                        )}
                </div>

                <div className="mb-3">
                    <label className="form-label">Precio</label>
                    <input
                       name="Precio"
                       value={formData.Precio}
                       onChange={handleChange}
                       type="tinyint"
                       placeholder="Precio del producto"

                       className={`form-control ${errors.Precio ? 'is-invalid' : warnings.Precio ? 'is-warning' :''}`}
                       />
                       {errors.Precio && (
                           <div className="error-feedback">{errors.Precio}</div>
                        )}
                        {warnings.Precio && (
                  <div className="warning-feedback">{warnings.Precio}</div>
                )}
                </div>

                <div className="mb-3">
                    <label className="form-label">Dosificacion_1</label>
                    <input
                       name="Dosificacion_1"
                       value={formData.Dosificacion_1}
                       onChange={handleChange}
                       type="text"
                       placeholder="Indique la primera dosificacion"
                       className={`form-control ${errors.Dosificacion_1 ? 'is-invalid' : ''}`}
                       />
                       {errors.Dosificacion_1 && (
                           <div className="error-feedback">{errors.Dosificacion_1}</div>
                        )}
                    
                </div>
                <div className="mb-3">
                    <label className="form-label">Dosificacion_2</label>
                    <input
                       name="Dosificacion_2"
                       value={formData.Dosificacion_2}
                       onChange={handleChange}
                       type="text"
                       placeholder="Indique la segunda dosificacion"

                       className={`form-control ${errors.Dosificacion_2 ? 'is-invalid' : warnings.Dosificacion_2 ? 'is-warning' :''}`}
                       />
                       {errors.Dosificacion_2 && (
                           <div className="error-feedback">{errors.Dosificacion_2}</div>
                        )}
                        {warnings.Dosificacion_2 && (
                  <div className="warning-feedback">{warnings.Dosificacion_2}</div>
                )}
                </div>
                <div className="mb-3">
                    <label className="form-label">Dosificacion_3</label>
                    <input
                       name="Dosificacion_3"
                       value={formData.Dosificacion_3}
                       onChange={handleChange}
                       type="text"
                       placeholder="Indique la tercera dosificacion"
                       className={`form-control ${errors.Dosificacion_3 ? 'is-invalid' : warnings.Dosificacion_3 ? 'is-warning' :''}`}
                       />
                       {errors.Dosificacion_3 && (
                           <div className="error-feedback">{errors.Dosificacion_3}</div>
                        )}
                        {warnings.Dosificacion_3 && (
                  <div className="warning-feedback">{warnings.Dosificacion_3}</div>
                )}
                    
                </div>
                <div className="mb-3">
                    <label className="form-label">Lote</label>
                    <input
                       name="Lote"
                       value={formData.Lote}
                       onChange={handleChange}
                       type="text"
                       placeholder="Indique el numero de lote"
                    
                       className={`form-control ${errors.Lote ? 'is-invalid' : ''}`}
                       />
                       {errors.Lote && (
                           <div className="error-feedback">{errors.Lote}</div>
                        )}
                </div>
                <div className="mb-3">
                    <label className="form-label">Fecha de Vencimiento</label>
                    <input
                       name="FEC_Vencimiento"
                       value={formData.FEC_Vencimiento}
                       onChange={handleChange}
                       type="date"
                       placeholder="Indique su fecha de vencimiento"

                       className={`form-control ${errors.FEC_Vencimiento ? 'is-invalid' : ''}`}
                       />
                       {errors.FEC_Vencimiento && (
                           <div className="error-feedback">{errors.FEC_Vencimiento}</div>
                        )}
                </div>
                <div className="mb-3">
                    <label className="form-label">Aplicadas</label>
                    <input
                       name="Aplicadas"
                       value={formData.Aplicadas}
                       onChange={handleChange}
                       type="tinyint"
                       placeholder="Indique la cantidad aplicada"

                       className={`form-control ${errors.Aplicadas ? 'is-invalid' : ''}`}
                       />
                       {errors.Aplicadas && (
                           <div className="error-feedback">{errors.Aplicadas}</div>
                        )}
                </div>
                <div className="mb-3">
                    <label className="form-label">Perdidas</label>
                    <input
                       name="Perdidas"
                       value={formData.Perdidas}
                       onChange={handleChange}
                       type="tinyint"
                       placeholder="Indique la cantidad perdida"

                       className={`form-control ${errors.Perdidas ? 'is-invalid' : ''}`}
                       />
                       {errors.Perdidas && (
                           <div className="error-feedback">{errors.Perdidas}</div>
                        )}
                </div>
                <div className="mb-3">
                    <label className="form-label">Descartadas</label>
                    <input
                       name="Descartadas"
                       value={formData.Descartadas}
                       onChange={handleChange}
                       type="tinyint"
                       placeholder="Indique la cantidad descartada"

                       className={`form-control ${errors.Descartadas ? 'is-invalid' : ''}`}
                       />
                       {errors.Descartadas && (
                           <div className="error-feedback">{errors.Descartadas}</div>
                        )}
                </div>
                <div className="mb-3">
                    <label className="form-label">Cantidad Usada</label>
                    <input
                       name="CAN_Usada"
                       value={formData.CAN_Usada}
                       onChange={handleChange}
                       type="tinyint"
                       placeholder="Indique la cantidad usada"
                       className={`form-control ${errors.CAN_Usada ? 'is-invalid' : ''}`}
                       />
                       {errors.CAN_Usada && (
                           <div className="error-feedback">{errors.CAN_Usada}</div>
                        )}
                    
                </div>
                
                <button type="submit" className="btn btn-success"> Guardar</button>
                <button type="button" className="btn btn-primary" onClick={clearInputs}>Limpiar</button>
                <button type="button" className="btn btn-danger" onClick={handleCancel}>Cancelar</button>
            </form>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Mensaje Modal"
            >
                <h2>{modalMessage}</h2>
                <button onClick={closeModal}>Cerrar</button>
            </Modal>
            </div>
        </div>
    );

};

export default CompCreateEnfer

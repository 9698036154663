import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import { useNavigate, useParams } from 'react-router-dom';
import '../../Estilos/StyleFormulario.css';

const URI = 'https://cisrubenandino.com/ap/parametros_software/';
const VALIDAR_MODULO_URI = 'https://cisrubenandino.com/ap/validar-mod-PS';
const VALIDAR_PERMISO_URI = 'https://cisrubenandino.com/ap/validar-PSA-';

const CompEditParametrosSoftware = () => {
    const [NOM_Producto, setNNOM_Producto] = useState('');
    const [Descripcion, setDescripcion] = useState('');
    const [Version, setVersion] = useState('');
    const [REQ_Sistema, setREQ_Sistema] = useState('');
    const [Licencia, setLicencia] = useState('');
    const [Plataforma, setPlataforma] = useState('');
    const [Categoria, setCategoria] = useState('');
    const [NOM_Desarrollador, setNOM_Desarrollador] = useState('');
    const [Usr_Registro, setUsr_Registro] = useState(localStorage.getItem('username') || '');
    const [message, setMessage] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
    const [originalData, setOriginalData] = useState(null);
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const { ID_Producto } = useParams();

    const [loading, setLoading] = useState(true);

    // Función para validar que se tenga acceso al módulo
    const validarAccesoModulo = useCallback(async () => {
        try {
            const res = await axios.get(VALIDAR_MODULO_URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (res.status !== 200 || !res.data.acceso) {
                throw new Error(res.data.mensaje || 'Acceso denegado.');
            }
        } catch (error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                setErrors('No tienes autorización para acceder a este apartado. Si crees que se trata de un error, comunícaselo al administrador.');
            } else {
                setErrors('No ha sido posible validar el acceso. Contacta con el administrador.');
            }
            setLoading(false);
            throw error;
        }
    }, []);

    // Función para validar el permiso necesario
    const validarPermisoUsuario = useCallback(async () => {
        try {
            const res = await axios.get(VALIDAR_PERMISO_URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (res.status !== 200 || !res.data.acceso) {
                throw new Error('No tienes autorización para acceder a este apartado.');
            }
        } catch (error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                setErrors('No se cuenta con el acceso completo para acceder a este apartado.');
            } else {
                setErrors('No ha sido posible validar el acceso. Contacta con el administrador.');
            }
            setLoading(false);
            throw error;
        }
    }, []);

    useEffect(() => {
        const getParametrosSoftwareByID = async () => {
            try {
                const response = await axios.get(URI + ID_Producto, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });
                const data = response.data;
                setNNOM_Producto(data.NOM_Producto);
                setDescripcion(data.Descripcion);
                setVersion(data.Version);
                setREQ_Sistema(data.REQ_Sistema);
                setLicencia(data.Licencia);
                setPlataforma(data.Plataforma);
                setCategoria(data.Categoria);
                setNOM_Desarrollador(data.NOM_Desarrollador);
                setUsr_Registro(data.Usr_Registro);
                setOriginalData(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        getParametrosSoftwareByID();
    }, [ID_Producto]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await validarAccesoModulo(); // Valida el acceso al módulo
                await validarPermisoUsuario(); // Valida el permiso del usuario
                
                setLoading(false);
            } catch (error) {
                console.error('Error durante la carga de datos:', error);
            }
        };
        fetchData();
    }, [validarAccesoModulo, validarPermisoUsuario]);


    const validateInputs = () => {
        let errorsObj = {};

        if (!NOM_Producto.trim()) {
            errorsObj.NOM_Producto = 'El nombre del producto es requerido';
        } else if (!/^[a-zA-Z0-9\s]+$/.test(NOM_Producto)) {
            errorsObj.NOM_Producto = 'El nombre del producto no debe contener caracteres especiales';
        }

        if (!Descripcion.trim()) {
            errorsObj.Descripcion = 'La descripción del producto es requerida';
        } else if (Descripcion.trim().length > 100) {
            errorsObj.Descripcion = 'La descripción no puede exceder los 100 caracteres';
        }

        if (!Version.trim()) {
            errorsObj.Version = 'La versión del producto es requerida';
        } else if (!/^\d+(\.\d+){2}$/.test(Version.trim())) {
            errorsObj.Version = 'El formato de la versión debe ser X.X.X (por ejemplo, 1.2.3)';
        } else if (Version.trim().length > 20) {
            errorsObj.Version = 'La versión del producto no puede exceder los 20 caracteres';
        }

        if (!REQ_Sistema.trim()) {
            errorsObj.REQ_Sistema = 'Los requisitos del sistema son requeridos';
        } else if (REQ_Sistema.trim().length > 200) {
            errorsObj.REQ_Sistema = 'Los requisitos del sistema no pueden exceder los 200 caracteres';
        }

        if (!Licencia.trim()) {
            errorsObj.Licencia = 'La licencia del producto es requerida';
        } else if (Licencia.trim().length > 20) {
            errorsObj.Licencia = 'La licencia no puede exceder los 20 caracteres';
        }

        if (!Plataforma.trim()) {
            errorsObj.Plataforma = 'La plataforma del producto es requerida';
        } else if (Plataforma.trim().length > 50) {
            errorsObj.Plataforma = 'La plataforma no puede exceder los 50 caracteres';
        }

        if (!Categoria.trim()) {
            errorsObj.Categoria = 'La categoría del producto es requerida';
        } else if (Categoria.trim().length > 50) {
            errorsObj.Categoria = 'La categoría no puede exceder los 50 caracteres';
        }

        if (!NOM_Desarrollador.trim()) {
            errorsObj.NOM_Desarrollador = 'El nombre del desarrollador es requerido';
        } else if (!/^[a-zA-Z\s]+$/.test(NOM_Desarrollador)) {
            errorsObj.NOM_Desarrollador = 'El nombre del desarrollador solo debe contener letras';
        } else if (NOM_Desarrollador.trim().length > 50) {
            errorsObj.NOM_Desarrollador = 'El nombre del desarrollador no puede exceder los 50 caracteres';
        }

        if (!Usr_Registro.trim()) {
            errorsObj.Usr_Registro = 'El usuario del producto es requerido';
        } else if (Usr_Registro.trim().length > 50) {
            errorsObj.Usr_Registro = 'El usuario no puede exceder los 50 caracteres';
        }

        setErrors(errorsObj);
        return Object.keys(errorsObj).length === 0;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const hasChanges = NOM_Producto !== originalData.NOM_Producto ||
            Descripcion !== originalData.Descripcion ||
            Version !== originalData.Version ||
            REQ_Sistema !== originalData.REQ_Sistema ||
            Licencia !== originalData.Licencia ||
            Plataforma !== originalData.Plataforma ||
            Categoria !== originalData.Categoria ||
            NOM_Desarrollador !== originalData.NOM_Desarrollador ||
            Usr_Registro !== originalData.Usr_Registro;

        if (hasChanges) {
            setConfirmModalIsOpen(true);
        } else {
            setMessage('No se han realizado cambios en el Parámetro.');
            setModalIsOpen(true);
        }
    };

    const update = async (e) => {
        e.preventDefault();
        setConfirmModalIsOpen(false);
    
        if (validateInputs()) {
            try {
                await axios.put(URI + ID_Producto, {
                    NOM_Producto,
                    Descripcion,
                    Version,
                    REQ_Sistema,
                    Licencia,
                    Plataforma,
                    Categoria,
                    NOM_Desarrollador,
                    Usr_Registro,
                }, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });
                setMessage('¡Parámetro actualizado exitosamente!');
            } catch (error) {
                setMessage('¡Ocurrió un error y no se pudo actualizar el parámetro!, inténtalo de nuevo');
            }
            setModalIsOpen(true);
        }
    };

    const restoreAllFields = () => {
        if (originalData) {
            setNNOM_Producto(originalData.NOM_Producto);
            setDescripcion(originalData.Descripcion);
            setVersion(originalData.Version);
            setREQ_Sistema(originalData.REQ_Sistema);
            setLicencia(originalData.Licencia);
            setPlataforma(originalData.Plataforma);
            setCategoria(originalData.Categoria);
            setNOM_Desarrollador(originalData.NOM_Desarrollador);
            setUsr_Registro(originalData.Usr_Registro);
        }
    };

    const cancel = () => {
        navigate('/MostrarPS');
    };

    return (
        <div className="main-container d-flex justify-content-center align-items-center" style={{ minHeight: "100vh" }}>
            <div className="card p-4 w-75">
                <h3 className="text-center mb-4">Actualizar Parámetro del Software</h3>
                <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                        <label className="form-label">Nombre del Producto</label>
                        <input
                            value={NOM_Producto}
                            onChange={(e) => setNNOM_Producto(e.target.value)}
                            type="text"
                            className={`form-control ${errors.NOM_Producto ? 'is-invalid' : ''}`}
                        />
                        {errors.NOM_Producto && <div className="invalid-feedback">{errors.NOM_Producto}</div>}
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Descripción</label>
                        <textarea
                            value={Descripcion}
                            onChange={(e) => setDescripcion(e.target.value)}
                            className={`form-control ${errors.Descripcion ? 'is-invalid' : ''}`}
                        />
                        {errors.Descripcion && <div className="invalid-feedback">{errors.Descripcion}</div>}
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Versión</label>
                        <input
                            value={Version}
                            onChange={(e) => setVersion(e.target.value)}
                            type="text"
                            className={`form-control ${errors.Version ? 'is-invalid' : ''}`}
                        />
                        {errors.Version && <div className="invalid-feedback">{errors.Version}</div>}
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Requisitos del Sistema</label>
                        <textarea
                            value={REQ_Sistema}
                            onChange={(e) => setREQ_Sistema(e.target.value)}
                            className={`form-control ${errors.REQ_Sistema ? 'is-invalid' : ''}`}
                        />
                        {errors.REQ_Sistema && <div className="invalid-feedback">{errors.REQ_Sistema}</div>}
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Licencia</label>
                        <input
                            value={Licencia}
                            onChange={(e) => setLicencia(e.target.value)}
                            type="text"
                            className={`form-control ${errors.Licencia ? 'is-invalid' : ''}`}
                        />
                        {errors.Licencia && <div className="invalid-feedback">{errors.Licencia}</div>}
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Plataforma</label>
                        <input
                            value={Plataforma}
                            onChange={(e) => setPlataforma(e.target.value)}
                            type="text"
                            className={`form-control ${errors.Plataforma ? 'is-invalid' : ''}`}
                        />
                        {errors.Plataforma && <div className="invalid-feedback">{errors.Plataforma}</div>}
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Categoría</label>
                        <input
                            value={Categoria}
                            onChange={(e) => setCategoria(e.target.value)}
                            type="text"
                            className={`form-control ${errors.Categoria ? 'is-invalid' : ''}`}
                        />
                        {errors.Categoria && <div className="invalid-feedback">{errors.Categoria}</div>}
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Nombre del Desarrollador</label>
                        <input
                            value={NOM_Desarrollador}
                            onChange={(e) => setNOM_Desarrollador(e.target.value)}
                            type="text"
                            className={`form-control ${errors.NOM_Desarrollador ? 'is-invalid' : ''}`}
                        />
                        {errors.NOM_Desarrollador && <div className="invalid-feedback">{errors.NOM_Desarrollador}</div>}
                    </div>
                    <div className="d-flex justify-content-center">
                        <button type="submit" className="btn btn-primary">Actualizar</button>
                        <button type="button" className="btn btn-secondary ms-2" onClick={restoreAllFields}>Restaurar</button>
                        <button type="button" className="btn btn-danger ms-2" onClick={cancel}>Cancelar</button>
                    </div>
                </form>
                <Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)}>
                    <h2>Mensaje</h2>
                    <p>{message}</p>
                    <button onClick={() => setModalIsOpen(false)}>Cerrar</button>
                </Modal>
                <Modal isOpen={confirmModalIsOpen} onRequestClose={() => setConfirmModalIsOpen(false)}>
                    <h2>Confirmar Actualización</h2>
                    <p>¿Estás seguro que deseas actualizar el parámetro?</p>
                    <div className="d-flex justify-content-center">
                        <button className="btn btn-primary" onClick={update}>Sí</button>
                        <button className="btn btn-secondary ms-2" onClick={() => setConfirmModalIsOpen(false)}>No</button>
                    </div>
                </Modal>
            </div>
        </div>
    );
};

export default CompEditParametrosSoftware;


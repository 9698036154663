import React, { useEffect, useState, useCallback  } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import { useNavigate, useParams } from 'react-router-dom';
import '../../Estilos/StyleFormulario.css';

const URI = 'https://cisrubenandino.com/ap/control_inventario/';
const VALIDAR_MODULO_URI = 'https://cisrubenandino.com/ap/validar-mod-I';
const VALIDAR_PERMISO_URI = 'https://cisrubenandino.com/ap/validar-AI-';


const CompEditInventario = () => {  // Recibe el nombre de usuario como una prop
    const [NUM_FIC_CONTROL_INVENTARIO, setNUM_FIC_CONTROL_INVENTARIO] = useState('');
    const [DESCRIPCION, setDESCRIPCION] = useState('');
    const [MARCA, setMARCA] = useState('');
    const [NUM_SER_CONTROL_INVENTARIO, setNUM_SER_CONTROL_INVENTARIO] = useState('');
    const [NUM_INV_ANT_CONTROL_INVENTARIO, setNUM_INV_ANT_CONTROL_INVENTARIO] = useState('');
    const [COLOR, setCOLOR] = useState('');
    const [COST_ADQ_CONTROL_INVENTARIO, setCOST_ADQ_CONTROL_INVENTARIO] = useState('');
    const [USR_REGISTRO] = useState(localStorage.getItem('username') || '');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const { ID_ITEM_CONTROL_INVENTARIO } = useParams();
    const [message, setMessage] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
    const [originalData, setOriginalData] = useState(null);

    // Funciones para el control de los modales
    const openModal = () => {
        setModalIsOpen(true);
    };
    const closeModal = () => {
        setModalIsOpen(false);
    };
    const openConfirmModal = () => {
        setConfirmModalIsOpen(true);
    };
    const closeConfirmModal = () => {
        setConfirmModalIsOpen(false);
    };
    const handleModalClose = () => {
        closeModal();
    };


    // Función para validar que se tenga acceso al módulo
    const validarAccesoModulo = useCallback(async () => {
        try {
            const res = await axios.get(VALIDAR_MODULO_URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (res.status !== 200 || !res.data.acceso) {
                throw new Error(res.data.mensaje || 'Acceso denegado.');
            }
        } catch (error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                setError('No tienes autorización para acceder a este apartado. Si crees que se trata de un error, comunícaselo al administrador.');
            } else {
                setError('No ha sido posible validar el acceso. Contacta con el administrador.');
            }
            setLoading(false);
            throw error;
        }
    }, []);


    // Función para validar el permiso necesario
    const validarPermisoUsuario = useCallback(async () => {
        try {
            const res = await axios.get(VALIDAR_PERMISO_URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (res.status !== 200 || !res.data.acceso) {
                throw new Error('No tienes autorización para acceder a este apartado.');
            }
        } catch (error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                setError('No se cuenta con el acceso completo para acceder a este apartado.');
            } else {
                setError('No ha sido posible validar el acceso. Contacta con el administrador.');
            }
            setLoading(false);
            throw error;
        }
    }, []);


     //Comprobar si se ha realizado al menos un cambio en un campo
    const handleSubmit = (e) => {
        e.preventDefault();
        const hasChanges =
            NUM_FIC_CONTROL_INVENTARIO !== originalData.NUM_FIC_CONTROL_INVENTARIO ||
            DESCRIPCION !== originalData.DESCRIPCION ||
            MARCA !== originalData.MARCA ||
            NUM_SER_CONTROL_INVENTARIO !== originalData.NUM_SER_CONTROL_INVENTARIO ||
            NUM_INV_ANT_CONTROL_INVENTARIO !== originalData.NUM_INV_ANT_CONTROL_INVENTARIO ||
            COLOR !== originalData.COLOR ||
            COST_ADQ_CONTROL_INVENTARIO !== originalData.COST_ADQ_CONTROL_INVENTARIO;

        if (hasChanges) {
            //Si se ha cambiado al menos un dato se procede a realizar el cambio
            openConfirmModal(); 
        } else {
            //Si no se ha detectado al menos un cambio se muestra un mensaje
            setMessage('No se han realizado cambios en el Inventario.');
            openModal();
        }
    };


    useEffect(() => {
        const getInventarioByID = async () => {
            try {
                const userResponse = await axios.get(URI + ID_ITEM_CONTROL_INVENTARIO, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });
                const userData = userResponse.data;
                setNUM_FIC_CONTROL_INVENTARIO(userData.NUM_FIC_CONTROL_INVENTARIO);
                setDESCRIPCION(userData.DESCRIPCION);
                setMARCA(userData.MARCA);
                setNUM_SER_CONTROL_INVENTARIO(userData.NUM_SER_CONTROL_INVENTARIO);
                setNUM_INV_ANT_CONTROL_INVENTARIO(userData.NUM_INV_ANT_CONTROL_INVENTARIO);
                setCOLOR(userData.COLOR);
                setCOST_ADQ_CONTROL_INVENTARIO(userData.COST_ADQ_CONTROL_INVENTARIO);
                setOriginalData(userData);
            } catch (error) {
                setMessage('¡Ocurrió un error y no se pudo actualizar el inventario!, intentalo de nuevo');
            }

        };

        getInventarioByID();
    }, [ID_ITEM_CONTROL_INVENTARIO, navigate]);

    // Efecto para ejecutar las validaciones y cargar datos al montar el componente
    useEffect(() => {
        const fetchData = async () => {
            try {
                await validarAccesoModulo(); // Valida el acceso al módulo
                await validarPermisoUsuario(); // Valida el permiso del usuario
                
                setLoading(false);
            } catch (error) {
                console.error('Error durante la carga de datos:', error);
            }
        };
        fetchData();
    }, [validarAccesoModulo, validarPermisoUsuario]);



    const update = async (e) => {
        e.preventDefault();
        closeConfirmModal(); // Cerrar el modal de confirmación
 
            
        try {
            await axios.put(URI + ID_ITEM_CONTROL_INVENTARIO, {
                NUM_FIC_CONTROL_INVENTARIO,
                DESCRIPCION,
                MARCA,
                NUM_SER_CONTROL_INVENTARIO,
                NUM_INV_ANT_CONTROL_INVENTARIO,
                COLOR,
                COST_ADQ_CONTROL_INVENTARIO,
                USR_REGISTRO,
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }    


            });
            setMessage('¡Inventario actualizado exitosamente!'); // Establecer el mensaje de éxito
            openModal(); // Abrir el modal de mensaje
        } catch (error) {
            setMessage('¡Ocurrió un error y no se pudo actualizar el inventario!, intentalo de nuevo'); // Establecer el mensaje de error
            openModal(); // Abrir el modal de mensaje
        }
    };

    //Procedimiento para cancelar
    const cancel = () => {
        closeModal();
        navigate('/MostrarI');
    };

   // Restaurar un campo especifico al valor original, si el usuario se equivoco y quiere regresar al valor existente
     const restoreField = (fieldName) => {
        switch (fieldName) {
            case 'NUM_FIC_CONTROL_INVENTARIO':
                setNUM_FIC_CONTROL_INVENTARIO(originalData.NUM_FIC_CONTROL_INVENTARIO);
                break;
            case 'DESCRIPCION':
                setDESCRIPCION(originalData.DESCRIPCION);
                break;
            case 'MARCA':
                setMARCA(originalData.MARCA);
                break;
            case 'NUM_SER_CONTROL_INVENTARIO':
                setNUM_SER_CONTROL_INVENTARIO(originalData.NUM_SER_CONTROL_INVENTARIO);
                break;
            case 'NUM_INV_ANT_CONTROL_INVENTARIO':
                setNUM_INV_ANT_CONTROL_INVENTARIO(originalData.NUM_INV_ANT_CONTROL_INVENTARIO);
                break;
            case 'COLOR':
                setCOLOR(originalData.COLOR);
                break;
            case 'COST_ADQ_CONTROL_INVENTARIO':
                setCOST_ADQ_CONTROL_INVENTARIO(originalData.COST_ADQ_CONTROL_INVENTARIO);
                break;
            default:
                break;
        }
    };

     // Restaurar todos los campos al valor original
    const restoreAllFields = () => {
        setNUM_FIC_CONTROL_INVENTARIO(originalData.NUM_FIC_CONTROL_INVENTARIO);
        setDESCRIPCION(originalData.DESCRIPCION);
        setMARCA(originalData.MARCA);
        setNUM_SER_CONTROL_INVENTARIO(originalData.NUM_SER_CONTROL_INVENTARIO);
        setNUM_INV_ANT_CONTROL_INVENTARIO(originalData.NUM_INV_ANT_CONTROL_INVENTARIO);
        setCOLOR(originalData.COLOR);
        setCOST_ADQ_CONTROL_INVENTARIO(originalData.COST_ADQ_CONTROL_INVENTARIO);
    };

     // Muestra un indicador de carga mientras se obtienen los datos
     if (loading) {
        return (
            <div className="loading-container">
                <p>Cargando datos... Por favor, espera.</p>
            </div>
        );
    }
    // Muestra un mensaje de error en caso de que ocurra
    if (error) {
        return (
            <div className="error-container">
                <p>{error}</p>
                <button className="btn btn-secondary" onClick={cancel}>Volver</button>
            </div>
        );
    }
   
    return (
        <div>
        <div className="titulo-container">
            <h3>EDITAR UN INVENTARIO</h3>
    </div>
        <div className="formulario-container">
            <form onSubmit={handleSubmit}>
                <div className="mb-3">
                    <label className="form-label">Numero de ficha:</label>
                    <input
                       value={NUM_FIC_CONTROL_INVENTARIO}
                       onChange={(e)=> setNUM_FIC_CONTROL_INVENTARIO(e.target.value)}
                       type="bigint"
                       className="form-control"
                    />
                    <button type="button" className="btn btn-info" onClick={() => restoreField('NUM_FIC_CONTROL_INVENTARIO')}>
                        Limpiar
                    </button>
                </div>
               <div className="mb-3">
                    <label className="form-label">Descripcion del producto:</label>
                    <textarea
                       value={DESCRIPCION}
                       onChange={(e)=> setDESCRIPCION(e.target.value)}
                       type="text"
                       className="form-control"

                    />
                 <button type="button" className="btn btn-info" onClick={() => restoreField('DESCRIPCION')}>
                        Limpiar
                    </button>
                </div>
                <div className="mb-3">
                    <label className="form-label">Marca del producto:</label>
                    <textarea
                       value={MARCA}
                       onChange={(e)=> setMARCA(e.target.value)}
                       type="text"
                       className="form-control"

                    />
                 <button type="button" className="btn btn-info" onClick={() => restoreField('MARCA')}>
                        Limpiar
                    </button>
                </div>
                <div className="mb-3">
                    <label className="form-label">Numero de serie:</label>
                    <input
                       value={NUM_SER_CONTROL_INVENTARIO}
                       onChange={(e)=> setNUM_SER_CONTROL_INVENTARIO(e.target.value)}
                       type="bigint"
                       className="form-control"
                    />
                    <button type="button" className="btn btn-info" onClick={() => restoreField('NUM_SER_CONTROL_INVENTARIO')}>
                        Limpiar
                    </button>
                </div>
                <div className="mb-3">
                    <label className="form-label">Numero de inventario anterior:</label>
                    <input
                       value={NUM_INV_ANT_CONTROL_INVENTARIO}
                       onChange={(e)=> setNUM_INV_ANT_CONTROL_INVENTARIO(e.target.value)}
                       type="bigint"
                       className="form-control"
                    />
                    <button type="button" className="btn btn-info" onClick={() => restoreField('NUM_INV_ANT_CONTROL_INVENTARIO')}>
                        Limpiar
                    </button>
                </div>
                <div className="mb-3">
                    <label className="form-label">Color del producto:</label>
                    <textarea
                       value={COLOR}
                       onChange={(e)=> setCOLOR(e.target.value)}
                       type="text"
                       className="form-control"

                    />
                 <button type="button" className="btn btn-info" onClick={() => restoreField('COLOR')}>
                        Limpiar
                    </button>
                </div>
                <div className="mb-3">
                    <label className="form-label">Costo adquirido del producto:</label>
                    <input
                       value={COST_ADQ_CONTROL_INVENTARIO}
                       onChange={(e)=> setCOST_ADQ_CONTROL_INVENTARIO(e.target.value)}
                       type="decimal"
                       className="form-control"
                    />
                    <button type="button" className="btn btn-info" onClick={() => restoreField('COST_ADQ_CONTROL_INVENTARIO')}>
                        Limpiar
                    </button>
                </div>
                <button type="button" className="btn btn-success" onClick={handleSubmit} > Guardar</button>
                <button type="button" className="btn btn-primary" onClick={restoreAllFields}> Restaurar</button>
                <button type="button" className="btn btn-danger" onClick={cancel}>Cancelar</button>
            </form>
            <Modal
                 isOpen={confirmModalIsOpen}
                 onRequestClose={closeConfirmModal}
                 contentLabel="Confirmación Modal"
            >
                <h2>¿Estás seguro de que deseas actualizar el Inventario?</h2>
                <button onClick={update}>Si, Actualizar</button>
                <button onClick={closeConfirmModal}>No, regresar</button>
            </Modal>
            {/* Modal de mensaje */}
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={cancel}
                contentLabel="Mensaje Modal"
            >
                <h2>{message}</h2>
                <button onClick={handleModalClose}>Aceptar</button>
            </Modal>
        </div>
    </div>
    )
}
export default CompEditInventario
import '../../../Estilos/StyleTableDatos.css'; // Asegúrate de que el CSS compartido esté importado
import axios from "axios";
import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import {useNavigate} from 'react-router-dom';
import $ from 'jquery';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css'; // Estilos de DataTables
import 'datatables.net-bs5'; // Importa DataTables

const URI = 'https://cisrubenandino.com/ap/ad_persona/';
const VALIDAR_MODULO_URI = 'https://cisrubenandino.com/ap/validar-mod-P';
const VALIDAR_PERMISO_URI = 'https://cisrubenandino.com/ap/validar-Sp-';

const ShowPersona = () => {
    const [personas, setPersonas] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    // Función para validar que se tenga acceso al modulo
    const validarAccesoModulo = useCallback(async () => {
        try {
            const res = await axios.get(VALIDAR_MODULO_URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (res.status !== 200 || !res.data.acceso) {
                throw new Error(res.data.mensaje || 'Acceso denegado.');
            }
        } catch (error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                setError('No tienes autorizacion para acceder a este apartado. Si crees que se trata de un error, comunicaselo al administrador.');
            } else {
                setError('No ha sido posible validar el acceso. Contacta con el administrador.');
            }
            setLoading(false);
            throw error;
        }
    }, []);

    // Función para validar el permiso necesario
    const validarPermisoPersona = useCallback(async () => {
        try {
            const res = await axios.get(VALIDAR_PERMISO_URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (res.status !== 200 || !res.data.acceso) {
                setError('No tienes autorización para acceder a este apartado. Si crees que se trata de un error, comunícaselo al administrador.');
            }
        } catch (error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                setError('No se cuenta con el acceso completo para acceder a este apartado.');
            } else {
                setError('No ha sido posible validar el acceso. Contacta con el administrador.');
            }
            setLoading(false);
            throw error;
        }
    }, []);

    const getPersonas = useCallback(async () => {
        try {
            const res = await axios.get(URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
    
            if (Array.isArray(res.data)) {
                setPersonas(res.data);
            } else {
                setPersonas([]); // No hay personas para mostrar
            }
        } catch (error) {
            if (error.response) {
                if (error.response.status === 401 || error.response.status === 403) {
                    // Redirige a la página de InicioNoAutorizado
                    localStorage.removeItem('token'); // Elimina/limpia
                    localStorage.removeItem('username'); // Elimina/limpia
                    setError('No ha sido posible validar tus credenciales, vuelve a iniciar sesión.');
                    navigate(`/InicioNoAutorizado?error=${encodeURIComponent(error.response.data.message)}`);
                } else {
                    // Otro tipo de error
                    setError('No ha sido posible encontrar los datos. Por favor vuelve a intentar, si el problema persiste, contacta con el administrador.');
                }
            } else {
                // Error sin respuesta del servidor (puede ser de red o otro detalle)
                setError('No ha sido posible verificar los datos en este momento. Por favor, contacta con el administrador.');
            }
        } finally {
            setLoading(false);
        }
    }, [navigate]);

    useEffect(() => {
        const validarAccesosYObtenerDatos = async () => {
            try {
                await validarAccesoModulo();
                await validarPermisoPersona();
                await getPersonas();
            } catch (error) {
                console.error("Ha ocurrido un problema", error);
            }
        };

        validarAccesosYObtenerDatos();
    }, [validarAccesoModulo, validarPermisoPersona, getPersonas]);

    useEffect(() => {
        if ($.fn.DataTable.isDataTable('#dataTablePersona')) {
            $('#dataTablePersona').DataTable().destroy();
        }

        if (personas.length > 0) {
            $('#dataTablePersona').DataTable();
        }
    }, [personas]);

    const deletePersona = async (ID_Persona) => {
        try {
            // Validar el permiso del usuario para la acción de "eliminación"
            const res = await axios.get('https://cisrubenandino.com/ap/validar-Ep-', {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
    
            if (res.status !== 200 || !res.data.acceso) {
                window.alert('No tienes el permiso necesario para realizar esta acción.');
                return;
            }
    
            // Si el usuario tiene permiso, preguntar si desea eliminar
            const confirmDelete = window.confirm("¿Está seguro de que desea Eliminar esta persona?");
            
            if (confirmDelete) {
                try {
                    await axios.delete(`${URI}${ID_Persona}`, {
                        headers: {
                            'Authorization': `Bearer ${localStorage.getItem('token')}`
                        }
                    });
                    getPersonas(); // Refresca la lista después de la eliminación
                } catch (error) {
                    console.error('Error al Eliminar el dato:', error);
                    window.alert('Hubo un problema al intentar Eliminar a la persona. Por favor, inténtelo de nuevo.');
                }
            }
        } catch (error) {
            // Manejo específico del error 403
            if (error.response && error.response.status === 403) {
                window.alert('No tienes el permiso necesario para realizar esta acción.');
            } else {
                console.error('Error al validar permiso:', error);
                window.alert('Hubo un problema al verificar los permisos. Por favor, inténtelo de nuevo más tarde.');
            }
        }
    };

    // Función para cancelar y regresar a la página anterior
    const cancel = () => {
        navigate('/Inicio');
    };

    // Muestra un indicador de carga mientras se obtienen los datos
if (loading) {
    return (
        <div className="loading-container">
            <p>Cargando datos... Por favor, espera.</p>
        </div>
    );
}

// Muestra un mensaje de error en caso de que ocurra
if (error) {
    return (
        <div className="error-container">
            <p>{error}</p>
            <button className="btn btn-secondary" onClick={cancel}>Volver a inicio</button>
        </div>
    );
}

    return (
        <div className="main-container">
            <h2 className="text-center mb-4">Administración De Personas</h2> {/* Título de la página */}
                    <div className="btn-container">
                        <Link to="/AdmRrPs" className="btn btn-primary mb-3">
                            Registrar una nueva Persona <i className="fa-solid fa-user-plus"></i>
                        </Link>
                    </div>
                    <div className="table-container">
                        <table id="dataTablePersona" className="table table-striped">
                            <thead>
                                <tr>
                                    <th className="text-center">Nombre</th>
                                    <th className="text-center">DNI</th>
                                    <th className="text-center">Sexo</th>
                                    <th className="text-center">Estado Civil</th>
                                    <th className="text-center">Edad</th>
                                    <th className="text-center">Tipo de Persona</th>
                                    <th className="text-center">Estado</th>
                                    <th className="text-center">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {personas.length === 0 ? (
                                    <tr>
                                        <td colSpan="8" className="text-center">No hay datos para mostrar.</td>
                                    </tr>
                                ) : (
                                    personas.map((persona) => (
                                        <tr key={persona.ID_Persona}>
                                            <td className="text-center">{persona.NOM_Persona}</td>
                                            <td className="text-center">{persona.NUM_Identidad}</td>
                                            <td className="text-center">{persona.SEX_Persona}</td>
                                            <td className="text-center">{persona.IND_Civil}</td>
                                            <td className="text-center">{persona.Edad}</td>
                                            <td className="text-center">{persona.TIP_Persona}</td>
                                            <td className="text-center">{persona.Estado === 1 ? "Activo" :  "Inactivo" }</td>
                                            <td className="text-center">
                                            <Link to={`/AdmEditPs/${persona.ID_Persona}`} className="btn btn-info">
                                                    Editar <i className="fa-solid fa-pen-to-square"></i>
                                                </Link>
                                                <button onClick={() => deletePersona(persona.ID_Persona)} className="btn btn-danger">
                                                    Eliminar <i className="fa-solid fa-trash"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>
            </div>
        );
    };

export default ShowPersona;
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

const URI = 'https://cisrubenandino.com/ap/validar-token';

const RutaInexistente = () => {
  const [autenticado, setAutenticado] = useState(null);

  useEffect(() => {
    const verificarToken = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setAutenticado(false);
        return;
      }

      try {
        const response = await fetch(URI, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });

        if (response.ok) {
          const data = await response.json();
          setAutenticado(data.valid);
        } else {
          setAutenticado(false);
        }
      } catch (error) {
        console.error('Error de autenticación.');
        setAutenticado(false);
      }
    };

    verificarToken();
  }, []);

  if (autenticado === null) {
    return <div>Cargando...</div>;
  }

  if (!autenticado) {
    return <Navigate to="/Login" replace />;
  } else {
    return <Navigate to="/Inicio" replace />;
  }
};

export default RutaInexistente;
import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Modal from 'react-modal';
import Swal from "sweetalert2";

const URI = 'https://cisrubenandino.com/ap/libro_diario/';
const VALIDAR_PERMISO_URI = 'https://cisrubenandino.com/ap/validar-Iat2r-';
const VALIDAR_MODULO_URI = 'https://cisrubenandino.com/ap/validar-mod-at2r';

const CompCreateAT2R = () => {
    const [Id_Medico, setId_Medico] = useState('');
    const [Fecha, setFecha] = useState('');
    const [Fec_Atencion, setFec_Atencion] = useState('');
    const [Id_Tip_Atencion, setId_Tip_Atencion] = useState('');
    const [Id_jornada, setId_jornada] = useState('');
    const [Genero, setGenero] = useState('');
    const [Fec_Nacimiento, setFec_Nacimiento] = useState('');
    const [Id_Tip_Medico, setId_Tip_Medico] = useState('');
    const [Usr_Registro, setUsr_Registro] = useState('');
    const [message, setMessage] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const Navigate = useNavigate();
    const navigate = useNavigate();

    // Validar acceso al módulo
    const validarAccesoModulo = useCallback(async () => {
        try {
            const res = await axios.get(VALIDAR_MODULO_URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
    
            if (res.status !== 200 || !res.data.acceso) {
                throw new Error(res.data.mensaje || 'Acceso denegado.');
            }
        } catch (error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                setError('No tienes autorización para acceder a este apartado. Si crees que se trata de un error, comunícaselo al administrador.');
            } else {
                setError('No ha sido posible validar el acceso. Contacta con el administrador.');
            }
            setLoading(false);
            throw error;
        }
    }, []);
    

    // Validar permisos del usuario
    const validarPermisoUsuario = useCallback(async () => {
        try {
            const res = await axios.get(VALIDAR_PERMISO_URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (res.status !== 200 || !res.data.acceso) {
                throw new Error(res.data.mensaje || 'Permiso denegado.');
            }
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'No cuentas con los permisos suficiente para este apartado. Contacta con el administrador.',
                confirmButtonText: 'Aceptar'
            }).then(() => {
                navigate('/MostrarA');
        });
    }
    }, [Navigate]);

    // Función para manejar la verificación del token
    const handleRequest = async () => {
        try {
            const response = await axios.get(VALIDAR_MODULO_URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        const validarAccesos = async () => {
            try {
                await validarAccesoModulo();
                await validarPermisoUsuario();
                setLoading(false);
            } catch (err) {
                setLoading(false);
            }
        };

        validarAccesos();
    }, [validarAccesoModulo, validarPermisoUsuario]);

    useEffect(() => {
        const username = localStorage.getItem('username') || '';
        setUsr_Registro(username);

        // Ejecutar la verificación del token
        handleRequest();
    }, []);

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const clearInputs = () => {
        setId_Medico('');
        setFecha('');
        setFec_Atencion('');
        setId_Tip_Atencion('');
        setId_jornada('');
        setGenero('');
        setFec_Nacimiento('');
        setId_Tip_Medico('');
        setUsr_Registro(localStorage.getItem('username') || '');
    };

    const handleCancel = () => {
        clearInputs();
        Navigate(-1);
    };

    const store = async (e) => {
        e.preventDefault();
    
        // Validaciones
        if (!Id_Medico || !Fecha || !Fec_Atencion || !Id_Tip_Atencion || !Id_jornada || !Genero || !Fec_Nacimiento || !Id_Tip_Medico || !Usr_Registro) {
            Swal.fire({
                icon: 'error',
                title: '¡Campos incompletos!',
                text: 'Por favor, completa todos los campos.',
                timer: 2000,
                showConfirmButton: false,
                position: 'center',
                customClass: {
                    popup: 'animate__animated animate__fadeInDown'
                }
            });
            return;
        }
    
        // Validar formato de fechas (opcional)

        if (new Date(Fecha) >= new Date()) {
            Swal.fire({
                icon: 'error',
                title: '¡Fecha de la ultima atencion anterior inválida!',
                text: 'La fecha de la ultima atencion anterior no puede ser posterior a la fecha actual.',
                timer: 2000,
                showConfirmButton: false,
                position: 'center',
                customClass: {
                    popup: 'animate__animated animate__fadeInDown'
                }
            });
            return;
        }

        if (new Date(Fec_Atencion) >= new Date()) {
            Swal.fire({
                icon: 'error',
                title: '¡Fecha de la ultima atencion inválida!',
                text: 'La fecha de la ultima atencion no puede ser posterior a la fecha actual.',
                timer: 2000,
                showConfirmButton: false,
                position: 'center',
                customClass: {
                    popup: 'animate__animated animate__fadeInDown'
                }
            });
            return;
        }

        if (new Date(Fec_Nacimiento) >= new Date()) {
            Swal.fire({
                icon: 'error',
                title: '¡Fecha de nacimiento inválida!',
                text: 'La fecha de nacimiento no puede ser posterior a la fecha actual.',
                timer: 2000,
                showConfirmButton: false,
                position: 'center',
                customClass: {
                    popup: 'animate__animated animate__fadeInDown'
                }
            });
            return;
        }
    
        try {
            await axios.post(URI, {
                Id_Medico: Id_Medico,
                Fecha: Fecha,
                Fec_Atencion: Fec_Atencion,
                Id_Tip_Atencion: Id_Tip_Atencion,
                Id_jornada: Id_jornada,
                Genero: Genero,
                Fec_Nacimiento: Fec_Nacimiento,
                Id_Tip_Medico: Id_Tip_Medico,
                Usr_Registro: Usr_Registro,
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            Swal.fire({
                icon: 'success',
                title: '¡Registro creado!',
                text: 'El registro se ha creado exitosamente.',
                timer: 2000,
                showConfirmButton: false,
                position: 'center',
                customClass: {
                    popup: 'animate__animated animate__fadeInDown'
                }
            });
    
            clearInputs();
    
            setTimeout(() => {
                Navigate('/MostrarA');
            }, 2000);
    
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: '¡Error!',
                text: 'Ocurrió un error al crear el registro.',
                timer: 2000,
                showConfirmButton: false,
                position: 'center',
                customClass: {
                    popup: 'animate__animated animate__shakeX'
                }
            });
            console.error('Error:', error);
        }
    };
    
    
    const medicosList = [
        { id_medico: 1, Medico: "EDUARDO COELLO" },
        { id_medico: 2, Medico: "LUIS LOPEZ" },
        { id_medico: 3, Medico: "FERNANDO MIRANDA" },
        { id_medico: 4, Medico: "SANDRA MOLINA" },
        { id_medico: 5, Medico: "KARLA CRUZ" },
        { id_medico: 6, Medico: "CARLOS MARTINEZ" },
        { id_medico: 7, Medico: "JOSE UMAZOR" },
        { id_medico: 8, Medico: "NINETH MEJIA" },
        { id_medico: 9, Medico: "EFRAIN FONSECA" },
        { id_medico: 10, Medico: "REBECA MONCADA" },
        { id_medico: 11, Medico: "DIEGO FERRUFINO" },
        { id_medico: 12, Medico: "WARNER MARROQUIN" },
        { id_medico: 13, Medico: "VEIRA JACO" },
        { id_medico: 14, Medico: "VANESSA FIGUEROA" },
        { id_medico: 15, Medico: "RORA AMADOR" },
        { id_medico: 16, Medico: "GLORIA ORELLANA" },
        { id_medico: 17, Medico: "DAYSI CARBAJAL" },
        { id_medico: 18, Medico: "CESAR MARCONY" },
        { id_medico: 19, Medico: "HAROL ARGUELLO" },
        { id_medico: 20, Medico: "ALEJANDRA SABILLON" },
        { id_medico: 21, Medico: "YANARITH LOPEZ" },
        { id_medico: 22, Medico: "DANIELA CASTILLO" },
        { id_medico: 23, Medico: "DANELIA VALERIANO" },
        { id_medico: 24, Medico: "NESTOR MATAMORS" },
        { id_medico: 25, Medico: "ALEJANDRA HERNANDEZ" },
        { id_medico: 26, Medico: "MIGUEL SARMIENTO" },
        { id_medico: 27, Medico: "JOSE GARMENDIA" }
    ];

    const handleSelectChange = (e) => {
        setId_Medico(e.target.value);
    };
    

    if (loading) {
        return <div>Cargando...</div>;
    }

    if (error) {
        return <div style={{ color: 'red' }}>{error}</div>;
    }

    return (
        <div style={{ marginLeft: '8%' }}>
            <h3>Registrar una nueva consulta</h3>
            <form onSubmit={store}>
                <div className="mb-3">
                    <label className="form-label">Nombre del Médico</label>
                    <select
                        value={Id_Medico}
                        onChange={handleSelectChange}
                        className="form-select"
                    >
                        <option value="">Selecciona un médico</option>
                        {medicosList.map(medico => (
                            <option key={medico.id_medico} value={medico.id_medico}>
                                {`${medico.id_medico} - ${medico.Medico}`}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="mb-3">
                    <label className="form-label">Fecha de la última atención anterior</label>
                    <input
                        value={Fecha}
                        onChange={(e) => setFecha(e.target.value)}
                        type="date"
                        className="form-control"
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">Fecha de la última atención</label>
                    <input
                        value={Fec_Atencion}
                        onChange={(e) => setFec_Atencion(e.target.value)}
                        type="date"
                        className="form-control"
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">Tipo de atención</label>
                    <select
                        value={Id_Tip_Atencion}
                        onChange={(e) => setId_Tip_Atencion(e.target.value)}
                        className="form-select"
                    >
                        <option value="">Seleccionar tipo de atención</option>
                        <option value="1">General</option>
                        <option value="2">Especialista</option>
                    </select>
                </div>
                <div className="mb-3">
                    <label className="form-label">Tipo de jornada</label>
                    <select
                        value={Id_jornada}
                        onChange={(e) => setId_jornada(e.target.value)}
                        className="form-select"
                    >
                        <option value="">Seleccionar tipo de jornada</option>
                        <option value="1">Matutino</option>
                        <option value="2">Vespertino</option>
                        <option value="3">Fin de semana</option>
                    </select>
                </div>
                <div className="mb-3">
                    <label className="form-label">Género</label>
                    <select
                        value={Genero}
                        onChange={(e) => setGenero(e.target.value)}
                        className="form-select"
                    >
                        <option value="">Seleccionar género</option>
                        <option value="Masculino">Masculino</option>
                        <option value="Femenino">Femenino</option>
                    </select>
                </div>
                <div className="mb-3">
                    <label className="form-label">Fecha de nacimiento del paciente</label>
                    <input
                        value={Fec_Nacimiento}
                        onChange={(e) => setFec_Nacimiento(e.target.value)}
                        type="date"
                        className="form-control"
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">Tipo de médico</label>
                    <select
                        value={Id_Tip_Medico}
                        onChange={(e) => setId_Tip_Medico(e.target.value)}
                        className="form-select"
                    >
                        <option value="">Seleccionar tipo de médico</option>
                        <option value="1">Médico General</option>
                        <option value="2">Ginecólogo</option>
                    </select>
                </div>
                <button type="submit" className="btn btn-success">Guardar</button>
                <button type="button" className="btn btn-primary" onClick={clearInputs}>Limpiar los datos <i className="fa-solid fa-paint-roller"></i></button>
                <button type="button" className="btn btn-danger" onClick={handleCancel}>Cancelar y regresar <i className="fa-solid fa-rotate-left"></i></button>
            </form>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                contentLabel="Mensaje Modal"
            >
                <h2>{message}</h2>
                <button onClick={closeModal}>Cerrar</button>
            </Modal>
        </div>
    );
}

export default CompCreateAT2R;

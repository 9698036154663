import '../../Estilos/StyleTableDatos.css'; // Asegúrate de que la ruta sea correcta
import axios from "axios";
import React, { useState, useEffect, useCallback } from "react";
import {useNavigate} from 'react-router-dom';
import { Link } from "react-router-dom";
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css'; // Estilos de DataTables para Bootstrap 5
import $ from 'jquery'; // Importa jQuery
import 'datatables.net-bs5'; // Importa DataTables para Bootstrap 5

const URI = 'https://cisrubenandino.com/ap/parametros_software/';
const VALIDAR_MODULO_URI = 'https://cisrubenandino.com/ap/validar-mod-PS';
const VALIDAR_PERMISO_URI = 'https://cisrubenandino.com/ap/validar-PSS-';

const CompShowParametrosSoftware = () => {
    const [software, setParametrosSoftware] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    // Formateo para la fecha y hora
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString('es-HN', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true // Para formato 12 horas, false = 24 
        });
    };

     // Función para validar que se tenga acceso al modulo
     const validarAccesoModulo = useCallback(async () => {
        try {
            const res = await axios.get(VALIDAR_MODULO_URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (res.status !== 200 || !res.data.acceso) {
                throw new Error(res.data.mensaje || 'Acceso denegado.');
            }
        } catch (error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                setError('No tienes autorizacion para acceder a este apartado. Si crees que se trata de un error, comunicaselo al administrador.');
            } else {
                setError('No ha sido posible validar el acceso. Contacta con el administrador.');
            }
            setLoading(false);
            throw error;
        }
    }, []);

    // Función para validar el permiso necesario
    const validarPermisoParametrosSoftware = useCallback(async () => {
        try {
            const res = await axios.get(VALIDAR_PERMISO_URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (res.status !== 200 || !res.data.acceso) {
                setError('No tienes autorización para acceder a este apartado. Si crees que se trata de un error, comunícaselo al administrador.');
            }
        } catch (error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                setError('No se cuenta con el acceso completo para acceder a este apartado.');
            } else {
                setError('No ha sido posible validar el acceso. Contacta con el administrador.');
            }
            setLoading(false);
            throw error;
        }
    }, []);
    
    const getParametrosSoftware = useCallback(async () => {
        try {
            const res = await axios.get(URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
                if (Array.isArray(res.data)) {
                    const parametrosSoftwareFormatted = res.data.map((parametros_software) => ({
                        ...parametros_software,
                        ULTI_Sesion: formatDate(parametros_software.ULTI_Sesion),
                    }));
                    setParametrosSoftware(parametrosSoftwareFormatted);
                } else {
                    setParametrosSoftware([]); // No hay parametros para mostrar
                }
            } catch (error) {
                // Manejo de error para los códigos de estado 401 y 403
            if (error.response) {
                if (error.response.status === 401 || error.response.status === 403) {
                    // Redirige a la página de InicioNoAutorizado
                    localStorage.removeItem('token'); // Elimina el token
                    localStorage.removeItem('username'); // Elimina el nombre de usuario
                    setError('No ha sido posible validar tus credenciales, vuelve a iniciar sesión.');
                    navigate(`/InicioNoAutorizado?error=${encodeURIComponent(error.response.data.message)}`);
                } else {
                    // Otro tipo de error
                    setError('No ha sido posible encontrar los datos. Por favor vuelve a intentar, si el problema persiste, contacta con el administrador.');
                }
            } else {
                // Error sin respuesta del servidor (puede ser de red o otro detalle)
                setError('No ha sido posible verificar los datos en este momento. Por favor, contacta con el administrador.');
            }
            } finally {
                setLoading(false);
        }
    }, [navigate]); 
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                await validarAccesoModulo(); // Valida el acceso al módulo
                await validarPermisoParametrosSoftware(); // Valida el permiso del usuario
                await getParametrosSoftware(); // Obtiene los datos de los usuarios si se tiene acceso
            } catch (error) {
                console.error('Error durante la carga de datos:', error);
            }
        };
        fetchData();
    }, [validarAccesoModulo, validarPermisoParametrosSoftware, getParametrosSoftware]);

    useEffect(() => {
        if ($.fn.DataTable.isDataTable('#dataTableSoftware')) {
            $('#dataTableSoftware').DataTable().destroy();
        }
        $('#dataTableSoftware').DataTable();
    }, [software]);

    const deleteSoftware = async (ID_Producto) => {
        const confirmDelete = window.confirm("¿Está seguro de que desea eliminar este parametro?");
        
        if (confirmDelete) {
            try {
                await axios.delete(`${URI}${ID_Producto}`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });
                window.location.reload();
            } catch (error) {
                if (error.response && error.response.status === 403) {
                    setError('No tienes permiso para eliminar este parámetro. Contacta con el administrador.');
                } else {
                    console.error('Error al eliminar parametro:', error);
                    setError('Error al eliminar el parámetro. Por favor, intenta nuevamente.');
                }
            }
        }
    };

    return (
        <div className="main-container">
            <h1 className="text-center mb-4">Parámetros de Software</h1>
            {loading ? (
                <p>Cargando...</p>
            ) : error ? (
                <p>{error}</p>
            ) : (
                <div className="row">
                    <div className="text-start">
                        <Link to="/createPS" className="btn btn-primary mb-3">
                            Crear un nuevo Parametro <i className="fa-solid fa-file-circle-plus"></i>
                        </Link>
                        <div className="table-container">
                            <table id="dataTableSoftware" className="table table-striped">
                                <thead className="table">
                                    <tr>
                                        <th className="text-center">Nombre del Producto</th>
                                        <th className="text-center">Descripción</th>
                                        <th className="text-center">Versión del producto</th>
                                        <th className="text-center">Requerimientos del sistema</th>
                                        <th className="text-center">Licencia del producto</th>
                                        <th className="text-center">Plataforma</th>
                                        <th className="text-center">Categoría</th>
                                        <th className="text-center">Nombre del Desarrollador</th>
                                        <th className="text-center">Opciones</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {software.length === 0 ? (
                                        <tr>
                                            <td colSpan="9">No hay parametros para mostrar, no existe o no está creado.</td>
                                        </tr>
                                    ) : (
                                        software.map((parametro) => (
                                            <tr key={parametro.ID_Producto}>
                                                <td className="text-center"> {parametro.NOM_Producto} </td>
                                                <td className="text-center"> {parametro.Descripcion} </td>
                                                <td className="text-center"> {parametro.Version} </td>
                                                <td className="text-center"> {parametro.REQ_Sistema} </td>
                                                <td className="text-center"> {parametro.Licencia} </td>
                                                <td className="text-center"> {parametro.Plataforma} </td>
                                                <td className="text-center"> {parametro.Categoria} </td>
                                                <td className="text-center"> {parametro.NOM_Desarrollador} </td>
                                                <td className="text-center">
                                                    <Link to={`/EditPS/${parametro.ID_Producto}`} className="btn btn-info">
                                                        Editar <i className="fa-solid fa-pen-to-square"></i>
                                                    </Link>
                                                    <button onClick={() => deleteSoftware(parametro.ID_Producto)} className="btn btn-danger">
                                                        Borrar <i className="fa-solid fa-trash"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CompShowParametrosSoftware;

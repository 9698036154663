import '../../Estilos/StyleTableDatos.css';
import React, { useState, useEffect, useCallback } from "react";
import {useNavigate} from 'react-router-dom';
import axios from "axios";
import { Link } from "react-router-dom";
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css'; // Estilos de DataTables
import $ from 'jquery'; // Importa jQuery
import 'datatables.net-bs5'; // Importa DataTables

const URI = 'https://cisrubenandino.com/ap/control_inventario/';
const VALIDAR_MODULO_URI = 'https://cisrubenandino.com/ap/validar-mod-I';
const VALIDAR_PERMISO_URI = 'https://cisrubenandino.com/ap/validar-SI-';

const CompShowInventario = () => {
    const [inventario, setInventario] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    // Función para validar que se tenga acceso al modulo
    const validarAccesoModulo = useCallback(async () => {
        try {
            const res = await axios.get(VALIDAR_MODULO_URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (res.status !== 200 || !res.data.acceso) {
                throw new Error(res.data.mensaje || 'Acceso denegado.');
            }
        } catch (error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                setError('No tienes autorizacion para acceder a este apartado. Si crees que se trata de un error, comunicaselo al administrador.');
            } else {
                setError('No ha sido posible validar el acceso. Contacta con el administrador.');
            }
            setLoading(false);
            throw error;
        }
    }, []);

    // Función para validar el permiso necesario
    const validarPermisoControlInventarioRoutes = useCallback(async () => {
        try {
            const res = await axios.get(VALIDAR_PERMISO_URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (res.status !== 200 || !res.data.acceso) {
                setError('No tienes autorización para acceder a este apartado. Si crees que se trata de un error, comunícaselo al administrador.');
            }
        } catch (error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                setError('No se cuenta con el acceso completo para acceder a este apartado.');
            } else {
                setError('No ha sido posible validar el acceso. Contacta con el administrador.');
            }
            setLoading(false);
            throw error;
        }
    }, []);


    const getInventario = useCallback(async () => {
        try {
            const res = await axios.get(URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            setInventario(res.data);
            setLoading(false);
        } catch (error) {
               // Manejo de error para los códigos de estado 401 y 403
        if (error.response) {
            if (error.response.status === 401 || error.response.status === 403) {
                // Redirige a la página de InicioNoAutorizado
                localStorage.removeItem('token'); // Elimina el token
                localStorage.removeItem('username'); // Elimina el nombre de usuario
                setError('No ha sido posible validar tus credenciales, vuelve a iniciar sesión.');
                navigate(`/InicioNoAutorizado?error=${encodeURIComponent(error.response.data.message)}`);
            } else {
                // Otro tipo de error
                setError('No ha sido posible encontrar los datos. Por favor vuelve a intentar, si el problema persiste, contacta con el administrador.');
            }
        } else {
            // Error sin respuesta del servidor (puede ser de red o otro detalle)
            setError('No ha sido posible verificar los datos en este momento. Por favor, contacta con el administrador.');
        }
        } finally {
            setLoading(false);
        }
    }, [navigate]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await validarAccesoModulo(); // Valida el acceso al módulo
                await validarPermisoControlInventarioRoutes(); // Valida el permiso del usuario
                await getInventario(); // Obtiene los datos de los usuarios si se tiene acceso
            } catch (error) {
                console.error("Ha ocurrido un problema", error);
            }
        };
        fetchData();
    }, [validarAccesoModulo, validarPermisoControlInventarioRoutes, getInventario]);

    useEffect(() => {
        getInventario();
    }, [getInventario]);

    useEffect(() => {
        const tableId = '#dataTableInventario';

        if ($.fn.DataTable.isDataTable(tableId)) {
            $(tableId).DataTable().destroy();
        }
        $(tableId).DataTable();
        
    }, [inventario]); // Vuelve a inicializar DataTables cuando cambian los inventarios

    


    const deleteInventario = async (ID_ITEM_CONTROL_INVENTARIO) => {
        const confirmDelete = window.confirm("¿Está seguro de que desea eliminar este inventario?");
        
        if (confirmDelete) {
            try {
                await axios.delete(`${URI}${ID_ITEM_CONTROL_INVENTARIO}`);
                window.location.reload();
            } catch (error) {
                console.error('Error al eliminar inventario:', error);
            }
        }
    };

    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };

    return (
        <div className="main-container">
            {loading ? (
                <p>Cargando...</p>
            ) : error ? (
                <p>{error}</p>
            ) : (
                <div className="row">
                    <div className="col">
                        <Link to="/createI" className="btn btn-primary mb-3">Crear un nuevo Inventario <i className="fa-solid fa-file-circle-plus"></i></Link>
                        <table id="dataTableInventario" className="table table-striped">
                            <thead className="table">
                                <tr>
                                    <th className="text-center">N# FICHA</th>
                                    <th className="text-center">Descripcion</th>
                                    <th className="text-center">Marca</th>
                                    <th className="text-center">Serie</th>
                                    <th className="text-center">Inventario anterior</th>
                                    <th className="text-center">Color</th>
                                    <th className="text-center">Costo</th>
                                    <th className="text-center">Opciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {inventario.length === 0 ? (
                                    <tr>
                                        <td colSpan="8">No hay inventarios para mostrar, no existe o no está creado.</td>
                                    </tr>
                                ) : (
                                    inventario.map((item) => (
                                        <tr key={item.ID_ITEM_CONTROL_INVENTARIO}>
                                            <td className="text-center"> {item.NUM_FIC_CONTROL_INVENTARIO} </td>
                                            <td title={item.DESCRIPCION} className="text-center text-truncated">
                                                {truncateText(item.DESCRIPCION, 25)}
                                            </td>
                                            <td className="text-center"> {item.MARCA} </td>
                                            <td className="text-center"> {item.NUM_SER_CONTROL_INVENTARIO || <i>ND</i>} </td>
                                            <td className="text-center"> {item.NUM_INV_ANT_CONTROL_INVENTARIO || <i>ND</i>} </td>
                                            <td className="text-center"> {item.COLOR} </td>
                                            <td className="text-center"> {item.COST_ADQ_CONTROL_INVENTARIO || <i>ND</i>} </td>
                                            <td className="text-center">
                                                <Link to={`/EditI/${item.ID_ITEM_CONTROL_INVENTARIO}`} className="btn btn-info">Editar <i className="fa-solid fa-pen-to-square"></i></Link>
                                                <button onClick={() => deleteInventario(item.ID_ITEM_CONTROL_INVENTARIO)} className="btn btn-danger">Borrar <i className="fa-solid fa-trash"></i></button>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CompShowInventario;
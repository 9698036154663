import React, { useEffect, useState, useCallback} from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import { useNavigate, useParams } from 'react-router-dom';
import '../../../Estilos/StyleFormulario.css';

// Configuración del elemento raíz para los modales
Modal.setAppElement('#root');

const URI = 'https://cisrubenandino.com/ap/ad_persona/';
const VALIDAR_MODULO_URI = 'https://cisrubenandino.com/ap/validar-mod-P';
const VALIDAR_PERMISO_URI = 'https://cisrubenandino.com/ap/validar-Ap-';

const CompEditPersona = () => {
    //Estado de los datos
    const [NOM_Persona, setNOM_Persona] = useState('');
    const [NUM_Identidad, setNUM_Identidad] = useState('');
    const [SEX_Persona, setSEX_Persona] = useState('');
    const [IND_Civil, setIND_Civil] = useState('');
    const [Edad, setEdad] = useState('');
    const [TIP_Persona, setTIP_Persona] = useState('');
    const [Usr_Registro] = useState(localStorage.getItem('username') || ''); //Recibe el nombre de usuario
    // Navegación y parámetros de la URL
    const navigate = useNavigate();
    const { ID_Persona } = useParams();
    // Estados para manejo de carga y errores
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    // Estados para mensajes y modales
    const [message, setMessage] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
    const [originalData, setOriginalData] = useState(null);

    // Funciones para controlar los modales
    const openModal = () => {
        setModalIsOpen(true);
    };
    const closeModal = () => {
        setModalIsOpen(false);
    };
    const openConfirmModal = () => {
        setConfirmModalIsOpen(true);
    };
    const closeConfirmModal = () => {
        setConfirmModalIsOpen(false);
    };
    const handleModalClose = () => {
        closeModal();
        if (message === '¡Datos actualizados exitosamente!') {
            navigate('/AdmPers'); // Regresa a la página de personas después de una actualización exitosa
        }
    };

    // Función para validar que se tenga acceso al módulo
    const validarAccesoModulo = useCallback(async () => {
        try {
            const res = await axios.get(VALIDAR_MODULO_URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (res.status !== 200 || !res.data.acceso) {
                throw new Error(res.data.mensaje || 'Acceso denegado.');
            }
        } catch (error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                setError('No tienes autorización para acceder a este apartado. Si crees que se trata de un error, comunícaselo al administrador.');
            } else {
                setError('No ha sido posible validar el acceso. Contacta con el administrador.');
            }
            setLoading(false);
            throw error;
        }
    }, []);

    // Función para validar el permiso necesario
    const validarPermisoModulo = useCallback(async () => {
        try {
            const res = await axios.get(VALIDAR_PERMISO_URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (res.status !== 200 || !res.data.acceso) {
                throw new Error('No tienes autorización para acceder a este apartado.');
            }
        } catch (error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                setError('No se cuenta con el acceso completo para acceder a este apartado.');
            } else {
                setError('No ha sido posible validar el acceso. Contacta con el administrador.');
            }
            setLoading(false);
            throw error;
        }
    }, []);

    //Comprobar si se ha realizado al menos un cambio en un campo
    const handleSubmit = (e) => {
        e.preventDefault();
        const hasChanges =
            NOM_Persona !== originalData.NOM_Persona ||
            NUM_Identidad !== originalData.NUM_Identidad ||
            SEX_Persona !== originalData.SEX_Persona ||
            IND_Civil !== originalData.IND_Civil ||
            Edad !== originalData.Edad ||
            TIP_Persona !== originalData.TIP_Persona;

        if (hasChanges) {
            //Si se ha cambiado al menos un dato se procede a realizar el cambio
            openConfirmModal(); 
        } else {
            //Si no se ha detectado al menos un cambio se muestra un mensaje
            setMessage('No se han realizado cambios.');
            openModal();
        }
    };

    // Función para obtener los datos de la persona especifica (si se cuenta con los permisos)
    const getPersonaByID = useCallback(async () => {
        try {
            const personaResponse = await axios.get(URI + ID_Persona, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            const personaData = personaResponse.data;

            setNOM_Persona(personaData.NOM_Persona);
            setNUM_Identidad(personaData.NUM_Identidad); 
            setSEX_Persona(personaData.SEX_Persona);
            setIND_Civil(personaData.IND_Civil);
            setEdad(personaData.Edad);
            setTIP_Persona(personaData.TIP_Persona);
            setOriginalData(personaData);

            setLoading(false);

        } catch (error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                localStorage.removeItem('token');
                setError('No ha sido posible validar tus credenciales, vuelve a iniciar sesión.');
                navigate(`/InicioNoAutorizado?error=${encodeURIComponent(error.response.data.message)}`);
            } else {
                setError('¡Ocurrió un error y no se pudo recuperar los datos! Inténtalo de nuevo.');
            }
            setLoading(false);
            throw error;
        }
    }, [ID_Persona, navigate]);

    // Efecto para obtener los datos de la persona al cargar el componente
    useEffect(() => {
        const getPersonaByID = async () => {
            try {
                const personaResponse = await axios.get(URI + ID_Persona, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });

                const personaData = personaResponse.data;

                setNOM_Persona(personaData.NOM_Persona);
                setNUM_Identidad(personaData.NUM_Identidad);
                setSEX_Persona(personaData.SEX_Persona);
                setIND_Civil(personaData.IND_Civil);
                setEdad(personaData.Edad);
                setTIP_Persona(personaData.TIP_Persona);

                setLoading(false); // Indica que la carga ha finalizado

            } catch (error) {
                setError('¡Ocurrió un error y no se pudo obetener los datos!, intentalo de nuevo');
                setLoading(false);
                openModal();  // Abre el modal para mostrar el mensaje de error
            }
        };
        getPersonaByID();
    }, [ID_Persona]);

    // Efecto para ejecutar las validaciones y cargar datos al montar el componente
    useEffect(() => {
        const fetchData = async () => {
            try {
                await validarAccesoModulo(); // Valida el acceso al módulo
                await validarPermisoModulo(); // Valida el permiso del usuario
                await getPersonaByID(); // Obtiene los datos del usuario si se tiene acceso
                setLoading(false);
            } catch (error) {
                console.error('Error durante la carga de datos:', error);
            }
        };
        fetchData();
    }, [validarAccesoModulo, validarPermisoModulo, getPersonaByID]);


    // Función para actualizar los datos en la API
    const update = async () => {
        closeConfirmModal(); // Cerrar el modal de confirmación
        try {
            await axios.put(URI + ID_Persona, {
                NOM_Persona,
                NUM_Identidad,
                SEX_Persona,
                IND_Civil,
                Edad,
                TIP_Persona,
                Usr_Registro
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            setMessage('¡Datos actualizados exitosamente!'); // Establecer el mensaje de éxito
            openModal(); // Abrir el modal de mensaje
        } catch (error) {
            setMessage('¡Ocurrió un error y no se pudo actualizar los datos!, intentalo de nuevo'); // Establecer el mensaje de error
            openModal(); // Abrir el modal de mensaje
        }
    };

    //Procedimiento para cancelar
    const cancel = () => {
        closeModal();
        navigate('/AdmPers');
    };

     // Restaurar un campo especifico al valor original, si el usuario se equivoco y quiere regresar al valor existente
     const restoreField = (fieldName) => {
        switch (fieldName) {
            case 'NOM_Persona':
                setNOM_Persona(originalData.NOM_Persona);
                break;
            case 'NUM_Identidad':
                setNUM_Identidad(originalData.NUM_Identidad);
                break;
            case 'SEX_Persona':
                setSEX_Persona(originalData.SEX_Persona);
                break;
            case 'IND_Civil':
                setIND_Civil(originalData.IND_Civil);
                break;
            case 'Edad':
                setEdad(originalData.Edad);
                break;
            case 'TIP_Persona':
                setTIP_Persona(originalData.TIP_Persona);
                break;
            default:
                break;
        }
    };

    // Restaurar todos los campos al valor original
    const restoreAllFields = () => {
        setNOM_Persona(originalData.NOM_Persona);
        setNUM_Identidad(originalData.NUM_Identidad);
        setSEX_Persona(originalData.SEX_Persona);
        setIND_Civil(originalData.IND_Civil);
        setEdad(originalData.Edad);
        setTIP_Persona(originalData.TIP_Persona);
    };

    // Muestra un indicador de carga mientras se obtienen los datos
    if (loading) {
        return (
            <div className="loading-container">
                <p>Cargando datos... Por favor, espera.</p>
            </div>
        );
    }
    // Muestra un mensaje de error en caso de que ocurra
    if (error) {
        return (
            <div className="error-container">
                <p>{error}</p>
                <button className="btn btn-secondary" onClick={cancel}>Volver</button>
            </div>
        );
    }

    return (
        <div>
        <div className="titulo-container">
            <h3>EDITAR DATOS DE UNA PERSONA</h3>
    </div>
        <div className="formulario-container">
            <form onSubmit={handleSubmit}>
                {/* Campos editables para los datos */}
                <div className="mb-3">
                    <label className="form-label">Nombre:</label>
                    <input
                       value={NOM_Persona}
                       onChange={(e)=> setNOM_Persona(e.target.value)}
                       type="text"
                       className="form-control"
                    />
                    <button type="button" className="btn btn-info" onClick={() => restoreField('NOM_Persona')}>
                        Restaurar
                    </button>
                </div>
                <div className="mb-3">
                    <label className="form-label">Numero de Identidad:</label>
                    <input
                       value={NUM_Identidad}
                       onChange={(e)=> setNUM_Identidad(e.target.value)}
                       type="text"
                       className="form-control"

                    />
                   <button type="button" className="btn btn-info" onClick={() => restoreField('NUM_Identidad')}>
                        Restaurar
                    </button>
                </div>
                <div className="mb-3">
                    <label className="form-label">Sexo:</label>
                    <select
                       value={SEX_Persona}
                       onChange={(e)=> setSEX_Persona(e.target.value)}
                       type="text"
                       className="form-control"

                    >
                    <option value="">Selecciona una opción →</option>
                    <option value="Masculino">Masculino</option>
                    <option value="Femenino">Femenino</option>
                    </select>
                    <button type="button" className="btn btn-info" onClick={() => restoreField('SEX_Persona')}>
                        Restaurar
                    </button>
                </div>
                <div className="mb-3">
                    <label className="form-label">Estado Civil</label>
                    <select
                        value={IND_Civil}
                        onChange={(e)=> setIND_Civil(e.target.value)}
                        className="form-control"
                    >
                        <option value="">Selecciona una opción →</option>
                        <option value="Soltero">Soltero/a</option>
                        <option value="Casado">Casado/a</option>
                        <option value="Divorciado">Divorciado/a</option>
                        <option value="Viudo">Viudo/a</option>
                    </select>
                    <button type="button" className="btn btn-info mt-1" onClick={() => restoreField('IND_Civil')}>
                        Restaurar
                    </button>
                </div>
                <div className="mb-3">
                    <label className="form-label">Edad:</label>
                    <input
                       value={Edad}
                       onChange={(e)=> setEdad(e.target.value)}
                       type="text"
                       className="form-control"

                    />
                    <button type="button" className="btn btn-info" onClick={() => restoreField('Edad')}>
                        Restaurar
                    </button>
                </div>
                <div className="mb-3">
                    <label className="form-label">Codigo Civil (tipo de persona)</label>
                    <select
                        value={TIP_Persona}
                        onChange={(e)=> setTIP_Persona(e.target.value)}
                        className="form-control"
                    >
                        <option value="">Selecciona una opción →</option>
                        <option value="Natural">Persona Natural</option>
                        <option value="Juridica">Persona Juridica</option>
                    </select>
                    <button type="button" className="btn btn-info mt-1" onClick={() => restoreField('TIP_Persona')}>
                        Restaurar
                    </button>
                </div>
                {/* Botones de acción */}
                <button type="submit" className="btn btn-success" > Guardar Cambios <i className="fa-solid fa-pen"></i> </button>
                <button type="button" className="btn btn-primary" onClick={restoreAllFields}> Restaurar Todo <i className="fa-solid fa-rotate-right"></i></button>
                <button type="button" className="btn btn-danger" onClick={cancel}>Cancelar y regresar <i className="fa-solid fa-rotate-left"></i> </button>
            </form>
            {/* Modal de confirmación */}
            <Modal
                 isOpen={confirmModalIsOpen}
                 onRequestClose={closeConfirmModal}
                 contentLabel="Confirmación Modal"
                 className="custom-modal"
                 overlayClassName="custom-overlay"
            >
                <div className="modal-content">
                  <h2>¿Estás seguro de que deseas guardar los cambios?</h2>
                  <div className="btn-container">
                        <button onClick={update} className="btn btn-success">Sí, Guardar <i className="fa-solid fa-check"></i> </button>
                        <button onClick={closeConfirmModal} className="btn btn-danger">No, Regresar <i className="fa-solid fa-xmark"></i> </button>
                    </div>
                </div>
            </Modal>
            {/* Modal de mensaje */}
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={handleModalClose}
                contentLabel="Mensaje Modal"
                className="custom-modal"
                overlayClassName="custom-overlay"
            >
                <div className="modal-content">
                      <h2>{message}</h2>
                        <div className="btn-container">
                            <button onClick={handleModalClose} className="btn btn-primary">Aceptar</button>
                        </div>
                    </div>
            </Modal>
        </div>
    </div>
    )
}
export default CompEditPersona
import React from 'react';
import Dashboard from './Dashboard';

import '../Estilos/StyleInicio.css';

const Inicio = () => {
  return (
    <div className="inicio-container">
      <div className="dashboard-wrapper">
        <Dashboard />
      </div>
    </div>
  );
};

export default Inicio;

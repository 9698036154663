import React, { useEffect, useState, useCallback  } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import { useNavigate, useParams } from 'react-router-dom';
import '../../../Estilos/StyleFormulario.css';

// Configuración del elemento raíz para los modales
Modal.setAppElement('#root');

// URL base de la API
const URI = 'https://cisrubenandino.com/ap/ad_accps/';
const VALIDAR_MODULO_URI = 'https://cisrubenandino.com/ap/validar-mod-Ap';
const VALIDAR_PERMISO_URI = 'https://cisrubenandino.com/ap/validar-Aap-';

const CompEditAcceso = () => {
    // Estados para los nombres de módulo y rol
    const [NOM_Modulo, setNOM_Modulo] = useState('');
    const [NOM_Rol, setNOM_Rol] = useState('');
    // Estados para los permisos
    const [Seleccionar, setSeleccionar] = useState('');
    const [Insertar, setInsertar] = useState('');
    const [Actualizar, setActualizar] = useState('');
    const [Eliminar, setEliminar] = useState('');
    const [Usr_Registro] = useState(localStorage.getItem('username') || '');


    // Estados para manejo de carga y errores
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Navegación y parámetros de la URL
    const navigate = useNavigate();
    const { ID_Acceso } = useParams();

    // Estados para mensajes y modales
    const [message, setMessage] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
    const [originalData, setOriginalData] = useState(null);

    // Funciones para controlar los modales
    const openModal = () => {
        setModalIsOpen(true);
    };
    const closeModal = () => {
        setModalIsOpen(false);
    };
    const openConfirmModal = () => {
        setConfirmModalIsOpen(true);
    };
    const closeConfirmModal = () => {
        setConfirmModalIsOpen(false);
    };
    const handleModalClose = () => {
        closeModal();
        if (message === '¡Datos actualizados exitosamente!') {
            navigate('/AdmAPS'); // Regresa a la página anterior después de una actualización exitosa
        }
    };

    // Función para validar que se tenga acceso al módulo
    const validarAccesoModulo = useCallback(async () => {
        try {
            const res = await axios.get(VALIDAR_MODULO_URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (res.status !== 200 || !res.data.acceso) {
                throw new Error(res.data.mensaje || 'Acceso denegado.');
            }
        } catch (error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                setError('No tienes autorización para acceder a este apartado. Si crees que se trata de un error, comunícaselo al administrador.');
            } else {
                setError('No ha sido posible validar el acceso. Contacta con el administrador.');
            }
            setLoading(false);
            throw error;
        }
    }, []);

    // Función para validar el permiso necesario
    const validarPermisoModulo = useCallback(async () => {
        try {
            const res = await axios.get(VALIDAR_PERMISO_URI, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });

            if (res.status !== 200 || !res.data.acceso) {
                throw new Error('No tienes autorización para acceder a este apartado.');
            }
        } catch (error) {
            if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                setError('No se cuenta con el acceso completo para acceder a este apartado.');
            } else {
                setError('No ha sido posible validar el acceso. Contacta con el administrador.');
            }
            setLoading(false);
            throw error;
        }
    }, []);

    // Efecto para ejecutar las validaciones y cargar datos al montar el componente
    useEffect(() => {
        const fetchData = async () => {
            try {
                await validarAccesoModulo(); // Valida el acceso al módulo
                await validarPermisoModulo(); // Valida el permiso del usuario
                setLoading(false);
            } catch (error) {
                console.error('Error durante la carga de datos:', error);
            }
        };
        fetchData();
    }, [validarAccesoModulo, validarPermisoModulo]);

    // Efecto para obtener los datos del acceso al cargar el componente
    useEffect(() => {
        const getAcessoByID = async () => {
            try {
                const response = await axios.get(`${URI}by-ac/${ID_Acceso}`, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem('token')}`
                    }
                });
                const data = response.data;
                // Asignación de los valores obtenidos a los estados correspondientes
                setNOM_Rol(data.NOM_Rol || '');
                setNOM_Modulo(data.NOM_Modulo || ''); // Asegura que no sea undefined
                setSeleccionar(data.Seleccionar); 
                setInsertar(data.Insertar);
                setActualizar(data.Actualizar);
                setEliminar(data.Eliminar);
                setOriginalData(data);

                setLoading(false); // Indica que la carga ha finalizado
            } catch (error) {
                setError('¡Ocurrió un error y no se pudo recuperar los datos! Inténtalo de nuevo.');
                setLoading(false);
                openModal();  // Abre el modal para mostrar el mensaje de error
            }
        };

        getAcessoByID();
    }, [ID_Acceso]);


    // Verifica si se han realizado cambios en los campos
    const handleSubmit = (e) => {
        e.preventDefault();
        const hasChanges =
            Seleccionar !== originalData.Seleccionar ||
            Insertar !== originalData.Insertar ||
            Actualizar !== originalData.Actualizar ||
            Eliminar !== originalData.Eliminar;

        if (hasChanges) {
            openConfirmModal(); 
        } else {
            setMessage('No se han realizado cambios.');
            openModal();
        }
    };

    // Función para actualizar los datos en la API
    const update = async () => {
        closeConfirmModal(); // Cierra el modal de confirmación
        try {
            await axios.put(URI + ID_Acceso, {
                Seleccionar,
                Insertar,
                Actualizar,
                Eliminar,
                Usr_Registro
            }, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            setMessage('¡Datos actualizados exitosamente!');
            openModal();
        } catch (error) {
            setMessage('¡Ocurrió un error y no se pudo actualizar los datos! Inténtalo de nuevo.');
            openModal();
        }
    };

    // Función para cancelar y regresar a la página anterior
    const cancel = () => {
        navigate('/AdmAPS');
    };

    // Restaurar un campo específico al valor original
    const restoreField = (fieldName) => {
        switch (fieldName) {
            case 'Seleccionar':
                setSeleccionar(originalData.Seleccionar);
                break;
            case 'Insertar':
                setInsertar(originalData.Insertar);
                break;
            case 'Actualizar':
                setActualizar(originalData.Actualizar);
                break;
            case 'Eliminar':
                setEliminar(originalData.Eliminar);
                break;
            default:
                break;
        }
    };

    // Restaurar todos los campos a sus valores originales
    const restoreAllFields = () => {
        setSeleccionar(originalData.Seleccionar);
        setInsertar(originalData.Insertar);
        setActualizar(originalData.Actualizar);
        setEliminar(originalData.Eliminar);
    };

    // Muestra un indicador de carga mientras se obtienen los datos
    if (loading) {
        return (
            <div className="loading-container">
                <p>Cargando datos... Por favor, espera.</p>
            </div>
        );
    }

    // Muestra un mensaje de error en caso de que ocurra
    if (error) {
        return (
            <div className="error-container">
                <p>{error}</p>
                <button className="btn btn-secondary" onClick={cancel}>Volver</button>
            </div>
        );
    }

    return (
        <div>
        <div className="titulo-container">
            <h3>Editar Permisos</h3>
        </div>
        <div className="formulario-container">
            <form onSubmit={handleSubmit}>
                {/* Campos de solo lectura para mostrar el módulo y el rol */}
                <div className="mb-3">
                    <label className="form-label">Nombre del Rol:</label>
                    <input
                        type="text"
                        className="form-control"
                        value={NOM_Rol}
                        readOnly
                    />
                </div>
                <div className="mb-3">
                    <label className="form-label">Nombre del Modulo:</label>
                    <input
                        type="text"
                        className="form-control"
                        value={NOM_Modulo}
                        readOnly
                    />
                </div>

                {/* Campos editables para los permisos */}
                <div className="mb-3">
                    <label className="form-label">Permiso de Seleccionar</label>
                    <select
                        value={Seleccionar}
                        onChange={(e)=> setSeleccionar(e.target.value)}
                        className="form-control"
                    >
                        <option value="">Seleccione una opción</option>
                        <option value="1">Sí</option>
                        <option value="0">No</option>
                    </select>
                    <button type="button" className="btn btn-info mt-1" onClick={() => restoreField('Seleccionar')}>
                        Restaurar
                    </button>
                </div>
                
                <div className="mb-3">
                    <label className="form-label">Permiso de Insertar</label>
                    <select
                        value={Insertar}
                        onChange={(e)=> setInsertar(e.target.value)}
                        className="form-control"
                    >
                        <option value="">Seleccione una opción</option>
                        <option value="1">Sí</option>
                        <option value="0">No</option>
                    </select>
                    <button type="button" className="btn btn-info mt-1" onClick={() => restoreField('Insertar')}>
                        Restaurar
                    </button>
                </div>
                
                <div className="mb-3">
                    <label className="form-label">Permiso de Actualizar</label>
                    <select
                        value={Actualizar}
                        onChange={(e)=> setActualizar(e.target.value)}
                        className="form-control"
                    >
                        <option value="">Seleccione una opción</option>
                        <option value="1">Sí</option>
                        <option value="0">No</option>
                    </select>
                    <button type="button" className="btn btn-info mt-1" onClick={() => restoreField('Actualizar')}>
                        Restaurar
                    </button>
                </div>
                
                <div className="mb-3">
                    <label className="form-label">Permiso de Eliminar</label>
                    <select
                        value={Eliminar}
                        onChange={(e)=> setEliminar(e.target.value)}
                        className="form-control"
                    >
                        <option value="">Seleccione una opción</option>
                        <option value="1">Sí</option>
                        <option value="0">No</option>
                    </select>
                    <button type="button" className="btn btn-info mt-1" onClick={() => restoreField('Eliminar')}>
                        Restaurar
                    </button>
                </div>
                {/* Botones de acción */}
                    <button type="submit" className="btn btn-success">Guardar Cambios <i className="fa-solid fa-pen"></i> </button>
                    <button type="button" className="btn btn-primary" onClick={restoreAllFields}>Restaurar Todo</button>
                    <button type="button" className="btn btn-danger" onClick={cancel}>Cancelar y regresar <i className="fa-solid fa-rotate-left"></i> </button>
            </form>
            {/* Modal de confirmación */}
            <Modal
                isOpen={confirmModalIsOpen}
                onRequestClose={closeConfirmModal}
                contentLabel="Confirmación Modal"
                className="custom-modal"
                overlayClassName="custom-overlay"
            >
                <div className="modal-content">
                  <h2>¿Estás seguro de que deseas guardar los cambios?</h2>
                  <div className="btn-container">
                        <button onClick={update} className="btn btn-success">Sí</button>
                        <button onClick={closeConfirmModal} className="btn btn-danger">No</button>
                    </div>
                </div>
            </Modal>

            {/* Modal de mensaje */}
            <Modal
                 isOpen={modalIsOpen}
                 onRequestClose={handleModalClose}
                 contentLabel="Mensaje Modal"
                 className="custom-modal"
                 overlayClassName="custom-overlay"
            >
                   <div className="modal-content">
                      <h2>{message}</h2>
                        <div className="btn-container">
                            <button onClick={handleModalClose} className="btn btn-primary">Aceptar</button>
                        </div>
                    </div>
                 </Modal>
            </div>
        </div>
    );
};

export default CompEditAcceso;

import axios from "axios";
import { useState, useEffect, useCallback} from "react";
import { useNavigate } from "react-router-dom";
import Modal from 'react-modal';
import '../../../Estilos/StyleFormulario.css';

const URI = 'https://cisrubenandino.com/ap/ad_persona/';
const VALIDAR_PERMISO_URI = 'https://cisrubenandino.com/ap/validar-Ip-';
const VALIDAR_MODULO_URI = 'https://cisrubenandino.com/ap/validar-mod-P';

const CompCreatePersona = () => {
  const Navigate = useNavigate()
  const [formData, setFormData] = useState({
    NOM_Persona: '',
    NUM_Identidad: '',
    SEX_Persona: '',
    IND_Civil: '',
    Edad: '',
    TIP_Persona: '',
    Usr_Registro: localStorage.getItem('username') || '', //Usuario logeado
});
// Estado para errores
const [errors, setErrors] = useState({});
const [warnings, setWarnings] = useState({});
const [error, setError] = useState(null);
const [loading, setLoading] = useState(true);
// Estado para mensajes de modal
const [modalIsOpen, setModalIsOpen] = useState(false);
const [modalMessage, setModalMessage] = useState('');
const [redirectAfterClose, setRedirectAfterClose] = useState(false);

// Funciónes del modal
const openModal = (message, redirect = false) => {
  setModalMessage(message);
  setModalIsOpen(true);
  setRedirectAfterClose(redirect);
};
const closeModal = () => {
  setModalIsOpen(false);
  if (redirectAfterClose) {
    Navigate('/AdmPers');
 }
};

// Función para validar que se tenga acceso al modulo
const validarAccesoModulo = useCallback(async () => {
    try {
        const res = await axios.get(VALIDAR_MODULO_URI, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        });

        if (res.status !== 200 || !res.data.acceso) {
            throw new Error(res.data.mensaje || 'Acceso denegado.');
        }
    } catch (error) {
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
            setError('No tienes autorizacion para acceder a este apartado. Si crees que se trata de un error, comunicaselo al administrador.');
        } else {
            setError('No ha sido posible validar el acceso. Contacta con el administrador.');
        }
        setLoading(false);
        throw error;
    }
}, []);

// Función para validar permisos del usuario
const validarPermisoPersona = useCallback(async () => {
    try {
      const res = await axios.get(VALIDAR_PERMISO_URI, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (res.status !== 200 || !res.data.acceso) {
        throw new Error(res.data.mensaje || 'Permiso denegado.');
      }
    } catch (error) {
      setError('No tienes autorización para realizar esta acción. Si crees que se trata de un error, comunícalo al administrador.');
      setLoading(false);
      throw error;
    }
  }, []);

  // UseEffect para validar acceso, permisos y cargar datos
    useEffect(() => {
    const fetchData = async () => {
      try {
        await validarAccesoModulo(); // Valida el acceso al módulo
        await validarPermisoPersona(); // Valida el permiso del usuario
      } catch (error) {
        console.error('Error durante la carga de datos:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [validarAccesoModulo, validarPermisoPersona ]);

// Función para manejar los cambios en los campos de entrada
const handleChange = (e) => {
    const { name, value } = e.target;
    // Actualizar formData con el valor del campo que ha cambiado
    setFormData((prevData) => ({
        ...prevData,
        [name]: value
    }));
    // Llamar a la función validateField para validar el campo que acaba de cambiar
    validateField(name, value);
};
// Función para validar un campo específico
const validateField = (name, value) => {
    let error = ''; // Inicializa el mensaje de error
    let warning = ''; // Inicializa el mensaje de advertencia
    // Expresiones regulares comunes
    const onlyNumbersPattern = /^\d+$/;
    const lettersAndSingleSpacePattern = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ]+(\s[a-zA-ZáéíóúÁÉÍÓÚñÑ]+)*$/; //solo permite letras y un espacio entre ellas
    const noLeadingOrTrailingSpaces = /^(?!\s).*.*(?<!\s)$/; //los valores no pueden tener espacios iniciales o finales
    const noMultipleSpaces = /^(?!.*\s{2}).*$/; // Permite asegurar que no haya múltiples espacios consecutivos
  
    switch (name) {
        case 'NOM_Persona':
            if (!value.trim()) {
                error = 'El Nombre de la persona es obligatorio.';
            } else if (value.length > 100) {
                error = 'El Nombre de la persona no debe ser mayo de 100 caracteres';
            } else if (value.length > 40) {
                warning = 'El Nombre de la persona esta siendo considerablemente largo, si es posible, usa uno mas corto';
            } else if (!noLeadingOrTrailingSpaces.test(value)) {
                error = 'El nombre no debe comenzar o terminar con espacios.';
            } else if (!noMultipleSpaces.test(value)) {
                error = 'No puede haber múltiples espacios consecutivos.';
            } else if (!lettersAndSingleSpacePattern.test(value)) {
                error = 'Solo pueden haber letras y un solo espacio entre palabras.';
            } else if (/([a-zA-ZáéíóúÁÉÍÓÚñÑ])\1{2,}/.test(value)) {
                error = 'No puede haber más de 2 caracteres repetidos consecutivamente.';
            }
            break;
        case 'NUM_Identidad':
            if (!value.trim()) {
                error = 'El Numero de Identidad es obligatorio.';
            } else if (value.length > 14) {
                error = 'El DNI no es valido';
            } else if (value.length < 13  ) {
                error = 'El DNI no es valido';
            } else if (!noLeadingOrTrailingSpaces.test(value)) {
                error = 'El DNI no debe comenzar o terminar con espacios.';
            } else if (!noMultipleSpaces.test(value)) {
                error = 'No puede haber múltiples espacios consecutivos.';
            } else if (/([a-zA-ZáéíóúÁÉÍÓÚñÑ])\1{2,}/.test(value)) {
                error = 'No puede haber más de 2 caracteres repetidos consecutivamente.';
            } else if (!onlyNumbersPattern.test(value)) {
                error = 'El DNI solo puede contener números.';
            } 
            break;
        case 'SEX_Persona':
            if (!value.trim()) {
                error = 'El Sexo de la persona es obligatorio.';
            }
            break;
        case 'IND_Civil':
            if (!value.trim()) {
                error = 'El estado civil es obligatorio.';
            }
            break;
        case 'Edad':
            if (!value.trim()) {
                error = 'La edad es obligatoria.';
            } else if (value.length > 3) {
                error = 'La edad no puede tener más de 3 dígitos';
            } else if (parseInt(value, 10) >= 100) {
                error = 'La edad es demasiado grande, no es posible aceptar esa edad';
            } else if (parseInt(value, 10) <= 0) {
                error = 'La edad no puede ser menor o igual a 0';
            } else if (parseInt(value, 10) <= 14) {
                error = 'La edad es muy joven, mínimo igual o mayor a 15';
            } else if (!noLeadingOrTrailingSpaces.test(value)) {
                error = 'El dato no debe comenzar o terminar con espacios.';
            } else if (!noMultipleSpaces.test(value)) {
                error = 'No puede haber múltiples espacios consecutivos.';
            } else if (!onlyNumbersPattern.test(value)) {
                error = 'Solo se puede introducir números.';
            } else if (/^0+\d+/.test(value)) {
                error = 'La edad no puede tener ceros a la izquierda.';
            }
            break;
        case 'TIP_Persona':
            if (!value.trim()) {
                error = 'El Codigo civil de la persona es obligatorio.';
          } 
            break;
        default:
            break;
    }
  // Actualiza el objeto de estado de errores y advertencia con el resultado de la validación
  setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
  }));
  setWarnings((prevWarnings) => ({
    ...prevWarnings,
    [name]: warning,
}));
};

// Función para validar todos los campos antes de enviar el formulario
const validateAllFields = () => {
  Object.keys(formData).forEach((field) => validateField(field, formData[field]));
  return !Object.values(errors).some((error) => error !== '');
};

// Función para limpiar todos los campos y errores
const clearInputs = () => {
  setFormData({
      NOM_Persona: '',
      NUM_Identidad: '',
      SEX_Persona: '',
      IND_Civil: '',
      Edad: '',
      TIP_Persona: '',
      Usr_Registro: localStorage.getItem('username') || '',
  });
  setErrors({});
  setWarnings({});
};

// Función para cancelar y volver a la vista anterior
const handleCancel = () => {
  clearInputs();
  Navigate('/AdmPers');
};

// Lista de campos obligatorios
const requiredFields = [
    'NOM_Persona',
    'NUM_Identidad',
    'SEX_Persona',
    'IND_Civil',
    'Edad',
    'TIP_Persona'
];

// Función para validar los campos obligatorios
const validateRequiredFields = () => {
    let hasError = false;
    const newErrors = {};

    requiredFields.forEach((field) => {
        const value = formData[field];

        if (!value.trim()) {
            // Si el campo está vacío, actualiza el estado de errores con un mensaje de error
            newErrors[field] = `El campo es obligatorio.`;
            hasError = true;
        }
    });

    setErrors((prevErrors) => ({
        ...prevErrors,
        ...newErrors
    }));

    return !hasError;
};

// Procedimiento para guardar los datos del formulario
const crear = async (e) => {
  e.preventDefault();

  try{

// Validar el acceso al módulo
await validarAccesoModulo();

// Validar el permiso del usuario para realizar la acción de creación
await validarPermisoPersona();
  
// Validar los campos obligatorios
const areRequiredFieldsValid = validateRequiredFields();

const allFieldsFilled = requiredFields.every(
    (field) => formData[field] && formData[field].trim() !== ''
);
// Verificar que los campos obligatorios estén llenos
if (!allFieldsFilled) {
    openModal('Los datos son insuficientes. Por favor, completa todos los campos obligatorios para continuar.');
    return;
}
if (!areRequiredFieldsValid) {
    openModal('Los campos obligatorios deben completarse antes de poder continuar.');
    return;
}
  // Validar todos los campos
  const isValid = validateAllFields();
  if (!isValid) {
      openModal('Se han detectado uno o mas errores, por favor corrigelos antes de poder continuar.');
      return;
  }
     // Enviar los datos si todas la validaciones fueron correctas
      await axios.post(URI, formData,{
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      openModal('¡Persona registrada exitosamente!', true);
      clearInputs();
  } catch (error) {
      console.error('Error:', error);
      openModal(error.message || '¡Ocurrió un error y no se han podido registrar los datos, inténtalo de nuevo! Si el problema persiste, por favor contacta con el administrador.');
  }
};

// Muestra un indicador de carga mientras se obtienen los datos
if (loading) {
    return (
        <div className="loading-container">
            <p>Cargando datos... Por favor, espera.</p>
        </div>
    );
}

// Muestra un mensaje de error en caso de que ocurra
if (error) {
    return (
        <div className="error-container">
            <p>{error}</p>
            <button className="btn btn-secondary" onClick={handleCancel}>Volver a inicio</button>
        </div>
    );
}

return (
    <div>
        <div className="titulo-container">
            <h3>REGISTRAR UNA NUEVA PERSONA</h3>
    </div>
  <div className="formulario-container">
      <form onSubmit={crear}>
          <div className="mb-3">
              <label className="form-label">Nombre de la Persona</label>
              <input
                    name="NOM_Persona" // Añade el atributo name
                    value={formData.NOM_Persona}
                    onChange={handleChange}
                    type="text"
                    placeholder="Ejemplo: Juan pedro, Albert Nuñez, Carlos Alberto Lopez Cruz (Este campo es obligatorio)"
                    className={`form-control ${errors.NOM_Persona ? 'is-invalid' : warnings.NOM_Persona ? 'is-warning' : ''}`}
              />
              {errors.NOM_Persona && (
                  <div className="error-feedback">{errors.NOM_Persona}</div>
              )}
              {warnings.NOM_Persona && (
                  <div className="warning-feedback">{warnings.NOM_Persona}</div>
                )}
          </div>
          <div className="mb-3">
              <label className="form-label">Numero de identidad</label>
              <input
                  name="NUM_Identidad" // Añade el atributo name
                  value={formData.NUM_Identidad}
                  onChange={handleChange}
                  type="text"
                  placeholder="Numero de identidad sin espacios, ejemplo: 0801199045501 (Este campo es obligatorio)"
                  className={`form-control ${errors.NUM_Identidad ? 'is-invalid' : warnings.NUM_Identidad ? 'is-warning' : ''}`}
              />
              {errors.NUM_Identidad && (
                  <div className="error-feedback">{errors.NUM_Identidad}</div>
              )}
              {warnings.NUM_Identidad && (
                  <div className="warning-feedback">{warnings.NUM_Identidad}</div>
                )}
          </div>
          <div className="mb-3">
              <label className="form-label">Sexo</label>
              <select
                  name="SEX_Persona"
                  value={formData.SEX_Persona}
                  onChange={handleChange}
                  className={`form-control ${errors.SEX_Persona ? 'is-invalid' : warnings.SEX_Persona ? 'is-warning' : ''}`}
              >
                  <option value="">Selecciona una opción →</option>
                  <option value="Masculino">Masculino</option>
                  <option value="Femenino">Femenino</option>
              </select>
              {errors.SEX_Persona && <div className="invalid-feedback">{errors.SEX_Persona}</div>}
              {warnings.SEX_Persona && <div className="warning-feedback">{warnings.SEX_Persona}</div>}
          </div>
          <div className="mb-3">
              <label className="form-label">Estado Civil </label>
              <select
                  name="IND_Civil"
                  value={formData.IND_Civil}
                  onChange={handleChange}
                  className={`form-control ${errors.IND_Civil ? 'is-invalid' : warnings.IND_Civil ? 'is-warning' : ''}`}
              >
                   <option value="">Selecciona una opción →</option>
                   <option value="Soltero">Soltero/a</option>
                   <option value="Casado">Casado/a</option>
                   <option value="Divorciado">Divorciado/a</option>
                   <option value="Viudo">Viudo/a</option>
            </select>
                {errors.IND_Civil && <div className="invalid-feedback">{errors.IND_Civil}</div>}
                {warnings.IND_Civil && <div className="warning-feedback">{warnings.IND_Civil}</div>}
     </div>
          <div className="mb-3">
              <label className="form-label">Edad</label>
              <input
                  name="Edad" // Añade el atributo name
                  value={formData.Edad}
                  onChange={handleChange}
                  type="text"
                  placeholder="Ejemplo: 20, 38, 26 (Este campo es obligatorio)"
                  className={`form-control ${errors.Edad ? 'is-invalid' : warnings.Edad ? 'is-warning' : ''}`}
              />
              {errors.Edad && (
                  <div className="error-feedback">{errors.Edad}</div>
              )}
              {warnings.Edad && (
                  <div className="warning-feedback">{warnings.Edad}</div>
                )}
          </div>
          <div className="mb-3">
              <label className="form-label">Código Civil (Tipo de persona)</label>
              <select
                  name="TIP_Persona"
                  value={formData.TIP_Persona}
                  onChange={handleChange}
                  className={`form-control ${errors.TIP_Persona ? 'is-invalid' : warnings.TIP_Persona ? 'is-warning' : ''}`}
              >
                   <option value="">Selecciona una opción →</option>
                   <option value="Natural">Persona Natural</option>
                   <option value="Juridica">Persona Juridica</option>
            </select>
                {errors.TIP_Persona && <div className="invalid-feedback">{errors.TIP_Persona}</div>}
                {warnings.TIP_Persona && <div className="warning-feedback">{warnings.TIP_Persona}</div>}
     </div>
          <button type="submit" className="btn btn-success"> Registrar Persona <i className="fa-solid fa-user-plus"></i> </button>
          <button type="button" className="btn btn-primary" onClick={clearInputs}>Limpiar los datos <i className="fa-solid fa-paint-roller"></i></button>
          <button type="button" className="btn btn-danger" onClick={handleCancel}>Cancelar y regresar <i className="fa-solid fa-rotate-left"></i></button>
      </form>
      </div>
      {/* Modal para mostrar mensajes */}
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} className="custom-modal" overlayClassName="custom-modal-overlay">
        <div className="modal-content">
          <h3>{modalMessage}</h3>
          <button className="btn btn-primary" onClick={closeModal}>Cerrar</button>
        </div>
      </Modal>
      </div>
   );
};
                
export default CompCreatePersona
import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

const URI = 'https://cisrubenandino.com/ap/validar-token';

const RutaProtegida = ({ element }) => {
  const [autenticado, setAutenticado] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    const verificarToken = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        setAutenticado(false);
        setError('No se ha proporcionado credenciales, Inicia sesion.');
        return;
      }

      try {
        const response = await fetch(URI, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
          }
        });

        if (response.ok) {
          const data = await response.json();

          if (data.valid) {
            setAutenticado(true);
          } else {
            setAutenticado(false);
          setError('La sesion ya no es valida, por lo que no se ha podido verificar tus datos, por favor vuelve a iniciar sesión.');
          localStorage.removeItem('token');
          localStorage.removeItem('username');
          }
        } else {
          setAutenticado(false);
          setError('No se ha podido verificar tus datos, por favor vuelve a iniciar sesión.');
          localStorage.removeItem('token');
          localStorage.removeItem('username');
        }
      } catch (error) {
        setAutenticado(false);
        setError('Un error inesperado no ha permitido poder continuar, por favor vuelve a intentar iniciar sesión, si esto continua, contacta con el administrador.');
        localStorage.removeItem('token');
        localStorage.removeItem('username');
      }
    };

    verificarToken();
  }, []);


  if (autenticado === null) {
    return <div>Cargando...</div>;
  }

  if (!autenticado) {
    return <Navigate to={`/InicioNoAutorizado?error=${encodeURIComponent(error)}`} />;
  }

  return element;
  
};

export default RutaProtegida;
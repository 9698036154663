import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Modal from 'react-modal';
import '../../Estilos/StyleFormulario.css';

const URI = 'https://cisrubenandino.com/ap/control_expedientes/';
const VALIDAR_PERMISO_URI = 'https://cisrubenandino.com/ap/validar-Iar-';
const VALIDAR_MODULO_URI = 'https://cisrubenandino.com/ap/validar-mod-Arc';

const CompCreateExpediente = () => {
  const Navigate = useNavigate()
  const [formData, setFormData] = useState({
    NUM_Expediente: '',
    PA_Primer_Nombre: '',
    PA_Segundo_Nombre: '',
    PA_Primer_Apellido: '',
    PA_Segundo_Apellido: '',
    DESC_Consulta: '',
    FEC_Nacimiento: new Date().toISOString().split('T')[0], // Fecha actual
    PA_Excento: '',
    Identidad: '',
    FEC_Creacion: new Date().toISOString().split('T')[0], // Fecha actual
    ULTI_Visita: new Date().toISOString().split('T')[0], // Fecha actual
    Usr_Registro: localStorage.getItem('username') || '', //Usuario logeado
    Receta: '',
    NUM_Integrantes: '',
    Direccion: '',
    NUM_Telefono: ''
});
// Estado para errores
const [errors, setErrors] = useState({});
const [warnings, setWarnings] = useState({});
const [error, setError] = useState(null);
const [loading, setLoading] = useState(true);
// Estado para mensajes de modal
const [modalIsOpen, setModalIsOpen] = useState(false);
const [modalMessage, setModalMessage] = useState('');
const [redirectAfterClose, setRedirectAfterClose] = useState(false);

// Funciónes del modal
const openModal = (message, redirect = false) => {
    setModalMessage(message);
    setModalIsOpen(true);
    setRedirectAfterClose(redirect);
  };
  const closeModal = () => {
    setModalIsOpen(false);
    if (redirectAfterClose) {
      Navigate('/MosArExp');
  }
  };

  // Función para validar que se tenga acceso al modulo
const validarAccesoModulo = useCallback(async () => {
    try {
        const res = await axios.get(VALIDAR_MODULO_URI, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        });

        if (res.status !== 200 || !res.data.acceso) {
            throw new Error(res.data.mensaje || 'Acceso denegado.');
        }
    } catch (error) {
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
            setError('No tienes autorizacion para acceder a este apartado. Si crees que se trata de un error, comunicaselo al administrador.');
        } else {
            setError('No ha sido posible validar el acceso. Contacta con el administrador.');
        }
        setLoading(false);
        throw error;
    }
}, []);

// Función para validar permisos del usuario
const validarPermiso = useCallback(async () => {
    try {
      const res = await axios.get(VALIDAR_PERMISO_URI, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (res.status !== 200 || !res.data.acceso) {
        throw new Error(res.data.mensaje || 'Permiso denegado.');
      }
    } catch (error) {
      setError('No tienes autorización para realizar esta acción. Si crees que se trata de un error, comunícalo al administrador.');
      setLoading(false);
      throw error;
    }
  }, []);

  // UseEffect para validar acceso, permisos y cargar datos
useEffect(() => {
    const fetchData = async () => {
      try {
        await validarAccesoModulo(); // Valida el acceso al módulo
        await validarPermiso(); // Valida el permiso del usuario
      } catch (error) {
        console.error('Error durante la carga de datos:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [validarAccesoModulo, validarPermiso]);

// Función para manejar los cambios en los campos de entrada
const handleChange = (e) => {
    const { name, value } = e.target;
    // Actualizar formData con el valor del campo que ha cambiado
    setFormData((prevData) => ({
        ...prevData,
        [name]: value
    }));
    // Llamar a la función validateField para validar el campo que acaba de cambiar
    validateField(name, value);
};
// Función para validar un campo específico
const validateField = (name, value) => {
    let error = ''; // Inicializa el mensaje de error
    let warning = ''; // Inicializa el mensaje de advertencia

    // Expresiones regulares comunes
    const onlyNumbersPattern = /^\d+$/;
    const lettersAndSingleSpacePattern = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ]+(\s[a-zA-ZáéíóúÁÉÍÓÚñÑ]+)*$/;
    const noLeadingOrTrailingSpaces = /^(?!\s).*.*(?<!\s)$/; // Actualiza esta expresión para permitir que los valores no tengan espacios iniciales o finales
    const noMultipleSpaces = /^(?!.*\s{2}).*$/; // Permite asegurar que no haya múltiples espacios consecutivos
  
    switch (name) {
        case 'NUM_Expediente':
            if (!value.trim()) {
                error = 'El número de expediente es obligatorio.';
            } else if (!onlyNumbersPattern.test(value)) {
                error = 'El número de expediente solo puede contener números.';
            } else if (parseInt(value, 10) < 1) {
                error = 'El número de expediente debe ser mayor o igual a 1.';
            } else if (value.length > 20) {
                error = 'El número de expediente no debe exceder los 20 dígitos.';
            }
            break;
        case 'PA_Primer_Nombre':
        case 'PA_Segundo_Nombre':
        case 'PA_Primer_Apellido':
        case 'PA_Segundo_Apellido':
            // Verifica si el valor es vacío
            if (!value.trim()) {
                error = 'Este campo es obligatorio.';
            } else {
                if (!noLeadingOrTrailingSpaces.test(value)) {
                    error = 'El dato no debe comenzar o terminar con espacios.';
                }
                else if (!noMultipleSpaces.test(value)) {
                    error = 'No puede haber múltiples espacios consecutivos.';
                }
                else if (!lettersAndSingleSpacePattern.test(value)) {
                    error = 'Solo pueden haber letras y un solo espacio entre palabras.';
                }
                else if (value.length > 20) {
                    error = 'No pueden haber mas de 20 letras.';
                }
                else if (/([a-zA-ZáéíóúÁÉÍÓÚñÑ])\1{2,}/.test(value)) {
                    error = 'No puede haber más de 2 caracteres repetidos consecutivamente.';
                }
            }
            break;
        case 'DESC_Consulta':
            if (!value.trim()) {
                warning = 'No se han detectado datos, verificar si este campo debe estar vacío.';
          } else {
              // Verificar si hay un espacio al principio o al final
              if (/^\s/.test(value) || /\s$/.test(value)) {
                  error = 'El campo no puede comenzar o terminar con un espacio. Por favor, elimina los espacios.';
              } else if (/\s{2,}/.test(value)) {
                  error = 'El campo no puede contener múltiples espacios consecutivos. Por favor, elimina los espacios extra.';
              } else if (value.length > 200) {
                  error = 'El campo no debe superar los 200 caracteres. Reduce la cantidad de palabras.';
              }
          }
            break;
        case 'FEC_Nacimiento':
            if (value.trim() !== '') {
              const inputDate = new Date(value);
              const currentDate = new Date();
              // Verificar si la fecha es posterior a la fecha actual
              if (inputDate > currentDate) {
                  error = 'La fecha de nacimiento no puede ser posterior a la fecha actual.';
              }
              // Verificar si la fecha es demasiado lejana en el pasado (por ejemplo, antes del año 1900)
              else if (inputDate < new Date('1900-01-01')) {
                  error = 'La fecha de nacimiento no puede ser demasiado lejana en el pasado, anterior al 01 de enero de 1900';
              }
          }
          break;
        case 'PA_Excento':
            if (!value.trim()) {
                warning = 'No se han detectado datos, verificar si este campo debe estar vacío.';
            } else if (value.length > 20) {
              error = 'El campo no debe superar los 20 caracteres. Reduce la cantidad de palabras.';
          } else if (/\s{2,}/.test(value)) {
              error = 'El campo no puede contener múltiples espacios consecutivos. Por favor, elimina los espacios extra.';
          }
            break;
        case 'Identidad':
            if (!value.trim()) {
              warning = 'No se han detectado datos, verificar si este campo debe estar vacío.';
          } else {
              // Verificar si hay un espacio al principio o al final
              if (/^\s/.test(value) || /\s$/.test(value)) {
                  error = 'El campo Identidad no puede comenzar o terminar con un espacio. Por favor, elimina los espacios.';
              } else if (/\s{2,}/.test(value)) {
                  error = 'El campo Identidad no puede contener múltiples espacios consecutivos. Por favor, elimina los espacios extra.';
              } else if (!/^\d+$/.test(value)) {
                  error = 'El campo solo puede contener números.';
              } else if (value.length > 20) {
                  error = 'El campo Identidad no debe superar los 20 caracteres.';
              }
          }
            break;
        case 'FEC_Creacion':
        if (value.trim() !== '') {
            const inputDate = new Date(value);
            const currentDate = new Date();
            // Redondear las fechas para eliminar horas, minutos y segundos
            inputDate.setHours(0, 0, 0, 0);
            currentDate.setHours(0, 0, 0, 0);
            // Crear fechas de tres días antes y tres días después de la fecha actual
            const threeDaysBefore = new Date(currentDate);
            threeDaysBefore.setDate(currentDate.getDate() - 3);
            const threeDaysAfter = new Date(currentDate);
            threeDaysAfter.setDate(currentDate.getDate() + 3);
            // Verificar si la fecha de creación está dentro del rango permitido
            if (inputDate < threeDaysBefore || inputDate > threeDaysAfter) {
                error = 'La fecha de creación no debe estar muy adelantada o muy retrasada a la fecha actual. Por favor, valide la fecha correcta.';
            }
        }
        break;
        case 'ULTI_Visita':
            if (value.trim() !== '') {
            const inputDate = new Date(value);
            const currentDate = new Date();
            // Redondear las fechas para eliminar horas, minutos y segundos
            inputDate.setHours(0, 0, 0, 0);
            currentDate.setHours(0, 0, 0, 0);
            // Crear fechas de dos días antes y dos días después de la fecha actual
            const twoDaysBefore = new Date(currentDate);
            twoDaysBefore.setDate(currentDate.getDate() - 2);
            const twoDaysAfter = new Date(currentDate);
            twoDaysAfter.setDate(currentDate.getDate() + 2);
            // Verificar si la última visita está dentro del rango permitido
            if (inputDate < twoDaysBefore || inputDate > twoDaysAfter) {
                error = 'La fecha de la última visita debe ser la fecha actual, se permite ajustar la fecha dias antes o despues en caso de ser necesario. Por favor, valide la fecha correcta.';
            }
        }
         break;
        case 'Receta':
            if (!value.trim()) {
                warning = 'No se han detectado datos, verificar si este campo debe estar vacío.';
          } else {
              // Verificar si hay un espacio al principio o al final
              if (/^\s/.test(value) || /\s$/.test(value)) {
                  error = 'El campo no puede comenzar o terminar con un espacio. Por favor, elimina los espacios.';
              } else if (/\s{2,}/.test(value)) {
                  error = 'El campo no puede contener múltiples espacios consecutivos. Por favor, elimina los espacios extra.';
              } else if (value.length > 200) {
                  error = 'El campo no debe superar los 200 caracteres. Reduce la cantidad de palabras.';
              }
          }
            break;
        case 'NUM_Integrantes':
            if (!value.trim()) {
                warning = 'No se han detectado datos, verificar si este campo debe estar vacío.';
            } else if (!/^\d+$/.test(value)) {
                error = 'El número de expediente solo puede contener números.';
            } else if (parseInt(value, 10) < 1) {
              error = 'No se permiten numeros menores de 1.';
            } else if (parseInt(value, 10) > Number.MAX_SAFE_INTEGER) {
              error = 'El número de expediente excede el límite permitido.';
            } else if (value.length > 20) {
              error = 'El número de expediente no debe exceder los 20 dígitos.';
            } 
             break;
        case 'Direccion':
        if (!value.trim()) {
            error = 'La dirección es obligatoria.';
        } else {
            // Longitud máxima
            if (value.length > 200) {
                error = 'La dirección no puede tener mas de 200 letras.';
            }
            // Validar caracteres no permitidos
            const forbiddenCharacters = /[^a-zA-Z0-9\s,.-]/;
            if (forbiddenCharacters.test(value)) {
                error = 'La dirección contiene caracteres no permitidos.';
            }
            // Verificar uso de espacios en blanco
            const leadingOrTrailingSpaces = /^\s+|\s+$/;
            if (leadingOrTrailingSpaces.test(value)) {
                error = 'La dirección no debe tener espacios en blanco al inicio o al final.';
            }
            const multipleSpaces = /\s{2,}/;
            if (multipleSpaces.test(value)) {
                error = 'La dirección no debe contener múltiples espacios en blanco consecutivos.';
            }
        } 
        break;
        case 'NUM_Telefono':
            if (!value.trim()) {
                 warning = 'No se han detectado datos, verificar si este campo debe estar vacío.';
            } else {
                // Validar longitud del número de teléfono (10 dígitos, por ejemplo)
                const phoneNumberPattern = /^\d{8}$/;
                if (!phoneNumberPattern.test(value)) {
                    error = 'El número de teléfono permitido es de 8 digitos Ej: 92028065, no se toman en cuenta numeros extranjeros.';
                }
                // Validar caracteres permitidos (solo dígitos)
                const validCharactersPattern = /^[0-9]+$/;
                if (!validCharactersPattern.test(value)) {
                    error = 'El número de teléfono solo puede contener numeros.';
                }
            }
            break;
        default:
            break;
    }
  // Actualiza el objeto de estado de errores y advertencia con el resultado de la validación
  setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
  }));
  setWarnings((prevWarnings) => ({
    ...prevWarnings,
    [name]: warning,
}));
};

// Función para validar todos los campos antes de enviar el formulario
const validateAllFields = () => {
  Object.keys(formData).forEach((field) => validateField(field, formData[field]));
  return !Object.values(errors).some((error) => error !== '');
};

// Función para limpiar todos los campos y errores
const clearInputs = () => {
  setFormData({
      NUM_Expediente: '',
      PA_Primer_Nombre: '',
      PA_Segundo_Nombre: '',
      PA_Primer_Apellido: '',
      PA_Segundo_Apellido: '',
      DESC_Consulta: '',
      FEC_Nacimiento: new Date().toISOString().split('T')[0],
      PA_Excento: '',
      Identidad: '',
      FEC_Creacion: new Date().toISOString().split('T')[0],
      ULTI_Visita: new Date().toISOString().split('T')[0],
      Usr_Registro: localStorage.getItem('username') || '',
      Receta: '',
      NUM_Integrantes: '',
      Direccion: '',
      NUM_Telefono: ''
  });
  setErrors({});
  setWarnings({});
};

// Función para cancelar y volver a la vista anterior
const handleCancel = () => {
  clearInputs();
  Navigate('/MosArExp');
};

// Lista de campos obligatorios
const requiredFields = [
    'NUM_Expediente',
    'PA_Primer_Nombre',
    'PA_Primer_Apellido',
    'Direccion'
];

// Función para validar los campos obligatorios
const validateRequiredFields = () => {
    let hasError = false;
    const newErrors = {};

    requiredFields.forEach((field) => {
        const value = formData[field];

        if (!value.trim()) {
            // Si el campo está vacío, actualiza el estado de errores con un mensaje de error
            newErrors[field] = `El campo es obligatorio.`;
            hasError = true;
        }
    });

    setErrors((prevErrors) => ({
        ...prevErrors,
        ...newErrors
    }));

    return !hasError;
};

// Procedimiento para guardar los datos del formulario
const crear = async (e) => {
  e.preventDefault();

  // Validar el acceso al módulo
await validarAccesoModulo();

// Validar el permiso del usuario para realizar la acción de creación
await validarPermiso();
  
// Validar los campos obligatorios
const areRequiredFieldsValid = validateRequiredFields();

const allFieldsFilled = requiredFields.every(
    (field) => formData[field] && formData[field].trim() !== ''
);
// Verificar que los campos obligatorios estén llenos
if (!allFieldsFilled) {
    openModal('Los datos son insuficientes o no existen. Por favor, completa al menos todos los campos obligatorios para continuar.');
    return;
}
if (!areRequiredFieldsValid) {
    openModal('Los campos obligatorios deben completarse antes de poder guardar el expediente.');
    return;
}
  // Validar todos los campos
  const isValid = validateAllFields();
  if (!isValid) {
      openModal('Se han detectado uno o mas errores, por favor corrigelos antes de poder continuar.');
      return;
  }
  try {
      // Realizar la petición POST a la API
      await axios.post(URI, formData, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      openModal('¡Expediente creado exitosamente!', true);
      clearInputs();
  } catch (error) {
      console.error('Error:', error);
      openModal('¡Ocurrió un error y no se ha podido crear el expediente, intentalo de nuevo!. Si el problema persiste, por favor contacta con el administrador');
  }
};

// Muestra un indicador de carga mientras se obtienen los datos
if (loading) {
    return (
        <div className="loading-container">
            <p>Cargando datos... Por favor, espera.</p>
        </div>
    );
}

// Muestra un mensaje de error en caso de que ocurra
if (error) {
    return (
        <div className="error-container">
            <p>{error}</p>
            <button className="btn btn-secondary" onClick={handleCancel}>Volver a inicio</button>
        </div>
    );
}

return (
    <div>
        <div className="titulo-container">
            <h3>REGISTRAR UN NUEVO EXPEDIENTE</h3>
    </div>
  <div className="formulario-container">
      <form onSubmit={crear}>
          <div className="mb-3">
              <label className="form-label">Numero de expediente</label>
              <input
                    name="NUM_Expediente" // Añade el atributo name
                    value={formData.NUM_Expediente}
                    onChange={handleChange}
                    type="number"
                    placeholder="Ejemplo: 1, 5, 6, 10. (Este campo es obligatorio)"
                    className={`form-control ${errors.NUM_Expediente ? 'is-invalid' : ''}`}
              />
              {errors.NUM_Expediente && (
                  <div className="error-feedback">{errors.NUM_Expediente}</div>
              )}
          </div>
          <div className="mb-3">
              <label className="form-label">Primer nombre del paciente</label>
              <input
                  name="PA_Primer_Nombre" // Añade el atributo name
                  value={formData.PA_Primer_Nombre}
                  onChange={handleChange}
                  type="text"
                  placeholder="Ejemplo: Alicia, Pedro, Carlos, Juan Carlos. (Este campo es obligatorio)"
                  className={`form-control ${errors.PA_Primer_Nombre ? 'is-invalid' : ''}`}
              />
              {errors.PA_Primer_Nombre && (
                  <div className="error-feedback">{errors.PA_Primer_Nombre}</div>
              )}
          </div>
          <div className="mb-3">
              <label className="form-label">Segundo nombre del paciente</label>
              <input
                  name="PA_Segundo_Nombre" // Añade el atributo name
                  value={formData.PA_Segundo_Nombre}
                  onChange={handleChange}
                  type="text"
                  placeholder="Ejemplo: Alexander, Alexis, Estela. (Este campo es opcional)"
                  className={`form-control ${errors.PA_Segundo_Nombre ? 'is-invalid' : warnings.PA_Segundo_Nombre ? 'is-warning' : ''}`}
              />
              {errors.PA_Segundo_Nombre && (
                  <div className="error-feedback">{errors.PA_Segundo_Nombre}</div>
              )}
              {warnings.PA_Segundo_Nombre && (
                  <div className="warning-feedback">{warnings.PA_Segundo_Nombre}</div>
                )}
          </div>
          <div className="mb-3">
              <label className="form-label">Primer apellido del paciente </label>
              <input
                  name="PA_Primer_Apellido" // Añade el atributo name
                  value={formData.PA_Primer_Apellido}
                  onChange={handleChange}
                  type="text"
                  placeholder="Ejemplo: Hernandez, Lopez, Cruz. (Este campo es obligatorio)"
                  className={`form-control ${errors.PA_Primer_Apellido ? 'is-invalid' : ''}`}
              />
              {errors.PA_Primer_Apellido && (
                  <div className="error-feedback">{errors.PA_Primer_Apellido}</div>
              )}
          </div>
          <div className="mb-3">
              <label className="form-label">Segundo apellido del paciente</label>
              <input
                  name="PA_Segundo_Apellido" // Añade el atributo name
                  value={formData.PA_Segundo_Apellido}
                  onChange={handleChange}
                  type="text"
                  placeholder="Ejemplo: Hernandez, Torres, Flores. (Este campo es opcional)"
                  className={`form-control ${errors.PA_Segundo_Apellido ? 'is-invalid' : warnings.PA_Segundo_Apellido ? 'is-warning' : ''}`}
              />
              {errors.PA_Segundo_Apellido && (
                  <div className="error-feedback">{errors.PA_Segundo_Apellido}</div>
              )}
              {warnings.PA_Segundo_Apellido && (
                  <div className="warning-feedback">{warnings.PA_Segundo_Apellido}</div>
                )}
          </div>
          <div className="mb-3">
              <label className="form-label">Descripcion completa o resumida de la consulta brindada al paciente</label>
              <textarea
                  name="DESC_Consulta" // Añade el atributo name
                  value={formData.DESC_Consulta}
                  onChange={handleChange}
                  className={`form-control ${errors.DESC_Consulta ? 'is-invalid' : warnings.DESC_Consulta ? 'is-warning' : ''}`}
                  placeholder="Escribe aquí la descripción de la consulta... (Este campo es opcional)"
                  rows={4} // se puede ajustar el número de filas según preferencias
              />
              {errors.DESC_Consulta && (
                  <div className="error-feedback">{errors.DESC_Consulta}</div>
              )}
              {warnings.DESC_Consulta && (
                  <div className="warning-feedback">{warnings.DESC_Consulta}</div>
                )}
          </div>
          <div className="mb-3">
              <label className="form-label">Fecha de nacimiento del paciente</label>
              <input
                  name="FEC_Nacimiento" // Añade el atributo name
                  value={formData.FEC_Nacimiento}
                  onChange={handleChange}
                  type="date"
                  className={`form-control ${errors.FEC_Nacimiento ? 'is-invalid' : ''}`}
              />
              {errors.FEC_Nacimiento && (
                  <div className="error-feedback">{errors.FEC_Nacimiento}</div>
              )}
              <small className="form-text text-muted">
                Por favor, asegúrese de que la fecha de nacimiento sea correcta. En un inicio, esta tendra automaticamente la fecha actual.
            </small>
          </div>
          <div className="mb-3">
              <label className="form-label">Paciente Excento</label>
              <input
                  name="PA_Excento" // Añade el atributo name
                  value={formData.PA_Excento}
                  onChange={handleChange}
                  type="text"
                  placeholder="Ejemplo: Discapacitado, Embarazada, Embarazo, No, Si, EXT. (Este campo es opcional) "
                  className={`form-control ${errors.PA_Excento ? 'is-invalid' : warnings.PA_Excento ? 'is-warning' : ''}`}
              />
              {errors.PA_Excento && (
                  <div className="error-feedback">{errors.PA_Excento}</div>
              )}
              {warnings.PA_Excento && (
                  <div className="warning-feedback">{warnings.PA_Excento}</div>
                )}
          </div>
          <div className="mb-3">
              <label className="form-label">Numero de identidad o partida de nacimiento del paciente</label>
              <input
                  name="Identidad" // Añade el atributo name
                  value={formData.Identidad}
                  onChange={handleChange}
                  type="text"
                  placeholder="Ejemplo: 0801199010703. (Este campo es opcional) "
                  className={`form-control ${errors.Identidad ? 'is-invalid' : warnings.Identidad ? 'is-warning' : ''}`}
              />
              {errors.Identidad && (
                  <div className="error-feedback">{errors.Identidad}</div>
              )}
              {warnings.Identidad && (
                  <div className="warning-feedback">{warnings.Identidad}</div>
                )}
          </div>
          <div className="mb-3">
              <label className="form-label">Fecha de creacion del expediente</label>
              <input
                  name="FEC_Creacion" // Añade el atributo name
                  value={formData.FEC_Creacion}
                  onChange={handleChange}
                  type="date"
                  className={`form-control ${errors.FEC_Creacion ? 'is-invalid' : ''}`}
              />
              {errors.FEC_Creacion && (
                  <div className="error-feedback">{errors.FEC_Creacion}</div>
              )}

            <small className="form-text text-muted">
             En un inicio, esta tendra automaticamente la fecha actual. Si la fecha esta adelantada o retrasada, ingrese la fecha correcta.
                </small>
          </div>
          <div className="mb-3">
              <label className="form-label">Fecha de la ultima visita del paciente al Centro de Salud</label>
              <input
                  name="ULTI_Visita" // Añade el atributo name
                  value={formData.ULTI_Visita}
                  onChange={handleChange}
                  type="date"
                  className={`form-control ${errors.ULTI_Visita ? 'is-invalid' : ''}`}
              />
              {errors.ULTI_Visita && (
                  <div className="error-feedback">{errors.ULTI_Visita}</div>
              )}

            <small className="form-text text-muted">
               En un inicio, esta tendra automaticamente la fecha actual. Si la fecha esta adelantada o retrasada, ingrese la fecha correcta.
                </small>
          </div>
          <div className="mb-3">
              <label className="form-label">Ultima receta completa o resumida dada al paciente</label>
              <textarea
                  name="Receta" // Añade el atributo name
                  value={formData.Receta}
                  onChange={handleChange}
                  className={`form-control ${errors.Receta ? 'is-invalid' : warnings.Receta ? 'is-warning' : ''}`}
                  placeholder="Escribe aquí la receta proporcionada al paciente... (Este campo es opcional)"
                  rows={4} // se puede ajustar el número de filas según preferencias
              />
              {errors.Receta && (
                  <div className="error-feedback">{errors.Receta}</div>
              )}
              {warnings.Receta && (
                  <div className="warning-feedback">{warnings.Receta}</div>
                )}
          </div>
          <div className="mb-3">
              <label className="form-label">Numero de integrante en el expediente</label>
              <input
                  name="NUM_Integrantes" // Añade el atributo name
                  value={formData.NUM_Integrantes}
                  onChange={handleChange}
                  type="text"
                  placeholder="Ejemplo: 1, 3, 4. (Este campo es opcional) "
                  className={`form-control ${errors.NUM_Integrantes ? 'is-invalid' : warnings.NUM_Integrantes ? 'is-warning' : ''}`}
              />
              {errors.NUM_Integrantes && (
                  <div className="error-feedback">{errors.NUM_Integrantes}</div>
              )}
               {warnings.NUM_Integrantes && (
                  <div className="warning-feedback">{warnings.NUM_Integrantes}</div>
                )}
          </div>
          <div className="mb-3">
              <label className="form-label">Dirección del domicilio del paciente</label>
              <input
                  name="Direccion" // Añade el atributo name
                  value={formData.Direccion}
                  onChange={handleChange}
                  type="text"
                  placeholder="Ejemplo: Col. 3 de mayo, Barrio altos de la Cabaña (Este campo es obligatorio) "
                  className={`form-control ${errors.Direccion ? 'is-invalid' : ''}`}
              />
              {errors.Direccion && (
                  <div className="error-feedback">{errors.Direccion}</div>
              )}
          </div>
          <div className="mb-3">
              <label className="form-label">Numero de telefono del paciente</label>
              <input
                  name="NUM_Telefono" // Añade el atributo name
                  value={formData.NUM_Telefono}
                  onChange={handleChange}
                  type="text"
                  placeholder="Ejemplo: 22003480 (Este campo es opcional) "
                  className={`form-control ${errors.NUM_Telefono ? 'is-invalid' : warnings.NUM_Telefono ? 'is-warning' : ''}`}
              />
              {errors.NUM_Telefono && (
                  <div className="error-feedback">{errors.NUM_Telefono}</div>
              )}
              {warnings.NUM_Telefono && (
                  <div className="warning-feedback">{warnings.NUM_Telefono}</div>
                )}
          </div>
          <button type="submit" className="btn btn-success"> Guardar</button>
          <button type="button" className="btn btn-primary" onClick={clearInputs}>Limpiar</button>
          <button type="button" className="btn btn-danger" onClick={handleCancel}>Cancelar</button>
      </form>
      {/* Modal para mostrar mensajes */}
      <Modal isOpen={modalIsOpen} onRequestClose={closeModal} className="custom-modal" overlayClassName="custom-modal-overlay">
        <div className="modal-content">
          <h3>{modalMessage}</h3>
          <button className="btn btn-primary" onClick={closeModal}>Cerrar</button>
        </div>
      </Modal>
  </div>
  </div>
);
};
                
export default CompCreateExpediente
import axios from "axios";
import { useState, useEffect, useCallback  } from "react";
import { useNavigate } from "react-router-dom";
import Modal from 'react-modal';
import '../../../Estilos/StyleFormulario.css';

const URI = 'https://cisrubenandino.com/ap/control_personal/'
const VALIDAR_PERMISO_URI = 'https://cisrubenandino.com/ap/validar-IE-';
const VALIDAR_MODULO_URI = 'https://cisrubenandino.com/ap/validar-mod-E';

const CompCreatePersonal = () => {
  const Navigate = useNavigate()
  const [formData, setFormData] = useState({
    NUM_EMP_CONTROL_PERSONAL: '',
    PRI_NOM_CONTROL_PERSONAL: '',
    SEG_NOM_CONTROL_PERSONAL: '',
    PRI_APE_CONTROL_PERSONAL: '',
    SEG_APE_CONTROL_PERSONAL: '',
    COR_ELEC_CONTROL_PERSONAL: '',
    DIRECCION: '',
    TELEFONO: '',
    FEC_NAC_CONTROL_PERSONAL: new Date().toISOString().split('T')[0], // Fecha actual
    VACACIONES: '',
    CATEGORIA: '',
    FEC_INGRESO: new Date().toISOString().split('T')[0], // Fecha actual
    USR_REGISTRO: localStorage.getItem('username') || '', //Usuario logeado
    NOM_PUESTO: '',
    TIP_SANGRE: ''
});
// Estado para errores
const [errors, setErrors] = useState({});
const [warnings, setWarnings] = useState({});
const [error, setError] = useState(null);
const [loading, setLoading] = useState(true);
// Estado para mensajes de modal
const [modalIsOpen, setModalIsOpen] = useState(false);
const [modalMessage, setModalMessage] = useState('');

// Funciónes del modal
const openModal = (message) => {
  setModalMessage(message);
  setModalIsOpen(true);
};
const closeModal = () => {
  setModalIsOpen(false);
};


// Función para validar que se tenga acceso al modulo
const validarAccesoModulo = useCallback(async () => {
    try {
        const res = await axios.get(VALIDAR_MODULO_URI, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        });

        if (res.status !== 200 || !res.data.acceso) {
            throw new Error(res.data.mensaje || 'Acceso denegado.');
        }
    } catch (error) {
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
            setError('No tienes autorizacion para acceder a este apartado. Si crees que se trata de un error, comunicaselo al administrador.');
        } else {
            setError('No ha sido posible validar el acceso. Contacta con el administrador.');
        }
        setLoading(false);
        throw error;
    }
}, []);

// Función para validar permisos del usuario
const validarPermisoUsuario = useCallback(async () => {
    try {
      const res = await axios.get(VALIDAR_PERMISO_URI, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (res.status !== 200 || !res.data.acceso) {
        throw new Error(res.data.mensaje || 'Permiso denegado.');
      }
    } catch (error) {
      setError('No tienes autorización para realizar esta acción. Si crees que se trata de un error, comunícalo al administrador.');
      setLoading(false);
      throw error;
    }
  }, []);


  useEffect(() => {
    const fetchData = async () => {
      try {
        await validarAccesoModulo(); // Valida el acceso al módulo
        await validarPermisoUsuario(); // Valida el permiso del usuario
      } catch (error) {
        console.error('Error durante la carga de datos:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [validarAccesoModulo, validarPermisoUsuario]);


// Función para manejar los cambios en los campos de entrada
const handleChange = (e) => {
    const { name, value } = e.target;
    // Actualizar formData con el valor del campo que ha cambiado
    setFormData((prevData) => ({
        ...prevData,
        [name]: value
    }));
    // Llamar a la función validateField para validar el campo que acaba de cambiar
    validateField(name, value);
};
// Función para validar un campo específico
const validateField = (name, value) => {
    let error = ''; // Inicializa el mensaje de error
    let warning = ''; // Inicializa el mensaje de advertencia

// Expresiones regulares comunes
    const onlyNumbersPattern = /^\d+$/;
    const lettersAndSingleSpacePattern = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ]+(\s[a-zA-ZáéíóúÁÉÍÓÚñÑ]+)*$/;
    const noLeadingOrTrailingSpaces = /^(?!\s).*.*(?<!\s)$/; // Actualiza esta expresión para permitir que los valores no tengan espacios iniciales o finales
    const noMultipleSpaces = /^(?!.*\s{2}).*$/; // Permite asegurar que no haya múltiples espacios consecutivos
  
    switch (name) {
        case 'NUM_EMP_CONTROL_PERSONAL':
            if (!value.trim()) {
                error = 'El número de empleado es obligatorio.';
            } else if (!onlyNumbersPattern.test(value)) {
                error = 'El número de empleado solo puede contener números.';
            } else if (parseInt(value, 10) < 1) {
                error = 'El número de empleado debe ser mayor o igual a 1.';
            } else if (value.length > 20) {
                error = 'El número de empleado no debe exceder los 20 dígitos.';
            }
            break;
        case 'PRI_NOM_CONTROL_PERSONAL':
        case 'SEG_NOM_CONTROL_PERSONAL':
        case 'PRI_APE_CONTROL_PERSONAL':
        case 'SEG_APE_CONTROL_PERSONAL':
            // Verifica si el valor es vacío
            if (!value.trim()) {
                error = 'Este campo es obligatorio.';
            } else {
                if (!noLeadingOrTrailingSpaces.test(value)) {
                    error = 'El dato no debe comenzar o terminar con espacios.';
                }
                else if (!noMultipleSpaces.test(value)) {
                    error = 'No puede haber múltiples espacios consecutivos.';
                }
                else if (!lettersAndSingleSpacePattern.test(value)) {
                    error = 'Solo pueden haber letras y un solo espacio entre palabras.';
                }
                else if (value.length > 20) {
                    error = 'No pueden haber mas de 20 letras.';
                }
                else if (/([a-zA-ZáéíóúÁÉÍÓÚñÑ])\1{2,}/.test(value)) {
                    error = 'No puede haber más de 2 caracteres repetidos consecutivamente.';
                }
            }
             break;
        case 'COR_ELEC_CONTROL_PERSONAL':
            if (!value.trim()) {
                warning = 'No se han detectado datos, verificar si este campo debe estar vacío.';
          } else {
              // Verificar si hay un espacio al principio o al final
              if (/^\s/.test(value) || /\s$/.test(value)) {
                  error = 'El campo no puede comenzar o terminar con un espacio. Por favor, elimina los espacios.';
              } else if (/\s{2,}/.test(value)) {
                  error = 'El campo no puede contener múltiples espacios consecutivos. Por favor, elimina los espacios extra.';
              } else if (value.length > 200) {
                  error = 'El campo no debe superar los 200 caracteres. Reduce la cantidad de palabras.';
              }
          }
            break;
        case 'DIRECCION':
        if (!value.trim()) {
            error = 'La dirección es obligatoria.';
        } else {
            // Longitud máxima
            if (value.length > 200) {
                error = 'La dirección no puede tener mas de 200 letras.';
            }
            // Validar caracteres no permitidos
            const forbiddenCharacters = /[^a-zA-Z0-9\s,.-]/;
            if (forbiddenCharacters.test(value)) {
                error = 'La dirección contiene caracteres no permitidos.';
            }
            // Verificar uso de espacios en blanco
            const leadingOrTrailingSpaces = /^\s+|\s+$/;
            if (leadingOrTrailingSpaces.test(value)) {
                error = 'La dirección no debe tener espacios en blanco al inicio o al final.';
            }
            const multipleSpaces = /\s{2,}/;
            if (multipleSpaces.test(value)) {
                error = 'La dirección no debe contener múltiples espacios en blanco consecutivos.';
            }
        } 
         break;
        case 'TELEFONO':
            if (!value.trim()) {
                 warning = 'No se han detectado datos, verificar si este campo debe estar vacío.';
            } else {
                // Validar longitud del número de teléfono (10 dígitos, por ejemplo)
                const phoneNumberPattern = /^\d{8}$/;
                if (!phoneNumberPattern.test(value)) {
                    error = 'El número de teléfono permitido es de 8 digitos Ej: 92028065, no se toman en cuenta numeros extranjeros.';
                }
                // Validar caracteres permitidos (solo dígitos)
                const validCharactersPattern = /^[0-9]+$/;
                if (!validCharactersPattern.test(value)) {
                    error = 'El número de teléfono solo puede contener numeros.';
                }
            }
             break;
        case 'FEC_NAC_CONTROL_PERSONAL':
            if (value.trim() !== '') {
              const inputDate = new Date(value);
              const currentDate = new Date();
              // Verificar si la fecha es posterior a la fecha actual
              if (inputDate > currentDate) {
                  error = 'La fecha de nacimiento no puede ser posterior a la fecha actual.';
              }
              // Verificar si la fecha es demasiado lejana en el pasado (por ejemplo, antes del año 1900)
              else if (inputDate < new Date('1900-01-01')) {
                  error = 'La fecha de nacimiento no puede ser demasiado lejana en el pasado, anterior al 01 de enero de 1900';
              }
          }
           break;
        case 'VACACIONES':
        case 'CATEGORIA':
            if (!value.trim()) {
                warning = 'No se han detectado datos, verificar si este campo debe estar vacío.';
          } else {
              // Verificar si hay un espacio al principio o al final
              if (/^\s/.test(value) || /\s$/.test(value)) {
                  error = 'El campo no puede comenzar o terminar con un espacio. Por favor, elimina los espacios.';
              } else if (/\s{2,}/.test(value)) {
                  error = 'El campo no puede contener múltiples espacios consecutivos. Por favor, elimina los espacios extra.';
              } else if (value.length > 200) {
                  error = 'El campo no debe superar los 200 caracteres. Reduce la cantidad de palabras.';
              }
          }
          break;
        case 'FEC_INGRESO':
        if (value.trim() !== '') {
            const inputDate = new Date(value);
            const currentDate = new Date();
            // Redondear las fechas para eliminar horas, minutos y segundos
            inputDate.setHours(0, 0, 0, 0);
            currentDate.setHours(0, 0, 0, 0);
            // Crear fechas de tres días antes y tres días después de la fecha actual
            const threeDaysBefore = new Date(currentDate);
            threeDaysBefore.setDate(currentDate.getDate() - 3);
            const threeDaysAfter = new Date(currentDate);
            threeDaysAfter.setDate(currentDate.getDate() + 3);
            // Verificar si la fecha de creación está dentro del rango permitido
            if (inputDate < threeDaysBefore || inputDate > threeDaysAfter) {
                error = 'La fecha de ingreso no debe estar muy adelantada o muy retrasada a la fecha actual. Por favor, valide la fecha correcta.';
            }
        }
         break;
        
        case 'NOM_PUESTO':
        case 'TIP_SANGRE':
            if (!value.trim()) {
                warning = 'No se han detectado datos, verificar si este campo debe estar vacío.';
          } else {
              // Verificar si hay un espacio al principio o al final
              if (/^\s/.test(value) || /\s$/.test(value)) {
                  error = 'El campo no puede comenzar o terminar con un espacio. Por favor, elimina los espacios.';
              } else if (/\s{2,}/.test(value)) {
                  error = 'El campo no puede contener múltiples espacios consecutivos. Por favor, elimina los espacios extra.';
              } else if (value.length > 200) {
                  error = 'El campo no debe superar los 200 caracteres. Reduce la cantidad de palabras.';
              }
          }
           break;
        default:
            break;
    }
// Actualiza el objeto de estado de errores y advertencia con el resultado de la validación
  setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
  }));
  setWarnings((prevWarnings) => ({
    ...prevWarnings,
    [name]: warning,
}));
};

// Función para validar todos los campos antes de enviar el formulario
const validateAllFields = () => {
  Object.keys(formData).forEach((field) => validateField(field, formData[field]));
  return !Object.values(errors).some((error) => error !== '');
};

// Función para limpiar todos los campos y errores
const clearInputs = () => {
  setFormData({
      NUM_EMP_CONTROL_PERSONAL: '',
      PRI_NOM_CONTROL_PERSONAL: '',
      SEG_NOM_CONTROL_PERSONAL: '',
      PRI_APE_CONTROL_PERSONAL: '',
      SEG_APE_CONTROL_PERSONAL: '',
      COR_ELEC_CONTROL_PERSONAL: '',
      DIRECCION: '',
      TELEFONO: '',
      FEC_NAC_CONTROL_PERSONAL: new Date().toISOString().split('T')[0],
      VACACIONES: '',
      CATEGORIA: '',
      FEC_INGRESO: new Date().toISOString().split('T')[0], 
      USR_REGISTRO: localStorage.getItem('username') || '',
      NOM_PUESTO: '',
      TIP_SANGRE: ''
  });
  setErrors({});
  setWarnings({});
};

// Función para cancelar y volver a la vista anterior
const handleCancel = () => {
  clearInputs();
  Navigate(-1);
};

// Lista de campos obligatorios
const requiredFields = [
    'NUM_EMP_CONTROL_PERSONAL',
    'PRI_NOM_CONTROL_PERSONAL',
    'PRI_APE_CONTROL_PERSONAL',
    'NOM_PUESTO',
    'TELEFONO'
];

// Función para validar los campos obligatorios
const validateRequiredFields = () => {
    let hasError = false;
    const newErrors = {};

    requiredFields.forEach((field) => {
        const value = formData[field];

        if (!value.trim()) {
            // Si el campo está vacío, actualiza el estado de errores con un mensaje de error
            newErrors[field] = `El campo es obligatorio.`;
            hasError = true;
        }
    });

    setErrors((prevErrors) => ({
        ...prevErrors,
        ...newErrors
    }));

    return !hasError;
};

// Procedimiento para guardar los datos del formulario
const crear = async (e) => {
  e.preventDefault();

  // Validar el acceso al módulo
   await validarAccesoModulo();

 // Validar el permiso del usuario para realizar la acción de creación
  await validarPermisoUsuario();
  
  
  // Validar los campos obligatorios
  const areRequiredFieldsValid = validateRequiredFields();

  const allFieldsFilled = requiredFields.every(
   (field)=> formData[field] && formData[field].trim() !==''

);
// Verificar que los campos obligatorios estén llenos
if (!allFieldsFilled) {
    openModal('Los datos son insuficientes o no existen. Por favor, completa al menos todos los campos obligatorios para continuar.');
    return;
}
if (!areRequiredFieldsValid) {
    openModal('Los campos obligatorios deben completarse antes de poder guardar el expediente del personal.');
    return;
}
   // Validar todos los campos
  const isValid = validateAllFields();
  if (!isValid) {
      openModal('Se han detectado uno o mas errores, por favor corrigelos antes de poder continuar.');
      return;
  }
  try {
      // Convertir fechas a formato "yyyy-MM-dd"
      const formattedFEC_NAC_CONTROL_PERSONAL = formData.FEC_NAC_CONTROL_PERSONAL;
      const formattedFEC_INGRESO = formData.FEC_INGRESO;
      // Realizar la petición POST a la API
      await axios.post(URI, formData, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        
          NUM_EMP_CONTROL_PERSONAL: formData.NUM_EMP_CONTROL_PERSONAL,
          PRI_NOM_CONTROL_PERSONAL: formData.PRI_NOM_CONTROL_PERSONAL,
          SEG_NOM_CONTROL_PERSONAL: formData.SEG_NOM_CONTROL_PERSONAL,
          PRI_APE_CONTROL_PERSONAL: formData.PRI_APE_CONTROL_PERSONAL,
          SEG_APE_CONTROL_PERSONAL: formData.SEG_APE_CONTROL_PERSONAL,
          COR_ELEC_CONTROL_PERSONAL: formData.COR_ELEC_CONTROL_PERSONAL,
          DIRECCION: formData.DIRECCION,
          TELEFONO: formData.TELEFONO,
          FEC_NAC_CONTROL_PERSONAL: formattedFEC_NAC_CONTROL_PERSONAL,
          VACACIONES: formData.VACACIONES,
          CATEGORIA: formData.CATEGORIA,
          FEC_INGRESO: formattedFEC_INGRESO,
          USR_REGISTRO: formData.USR_REGISTRO,
          NOM_PUESTO: formData.NOM_PUESTO,
          TIP_SANGRE: formData.TIP_SANGRE,
        }  
      });
      openModal('¡Personal creado exitosamente!');
      clearInputs();
  } catch (error) {
      console.error('Error:', error);
      openModal('¡Ocurrió un error y no se ha podido crear el expediente del personal, intentalo de nuevo!. Si el problema persiste, por favor contacta con el administrador');
  }

};

// Muestra un indicador de carga mientras se obtienen los datos
if (loading) {
    return (
        <div className="loading-container">
            <p>Cargando datos... Por favor, espera.</p>
        </div>
    );
}

// Muestra un mensaje de error en caso de que ocurra
if (error) {
    return (
        <div className="error-container">
            <p>{error}</p>
            <button className="btn btn-secondary" onClick={handleCancel}>Volver a inicio</button>
        </div>
    );
}

return (
    <div>
        <div className="titulo-container">
            <h3>REGISTRAR UN NUEVO PERSONAL</h3>
    </div>
  <div className="formulario-container">
      <form onSubmit={crear}>
          <div className="mb-3">
              <label className="form-label">Numero de empleado</label>
              <input
                    name="NUM_EMP_CONTROL_PERSONAL" // Añade el atributo name
                    value={formData.NUM_EMP_CONTROL_PERSONAL}
                    onChange={handleChange}
                    type="number"
                    placeholder="Ejemplo: 1, 5, 6, 10. (Este campo es obligatorio)"
                    className={`form-control ${errors.NUM_EMP_CONTROL_PERSONAL ? 'is-invalid' : ''}`}
              />
              {errors.NUM_EMP_CONTROL_PERSONAL && (
                  <div className="error-feedback">{errors.NUM_EMP_CONTROL_PERSONAL}</div>
              )}
        </div>
          <div className="mb-3">
              <label className="form-label">Primer nombre del empleado</label>
              <input
                  name="PRI_NOM_CONTROL_PERSONAL" // Añade el atributo name
                  value={formData.PRI_NOM_CONTROL_PERSONAL}
                  onChange={handleChange}
                  type="text"
                  placeholder="Ejemplo: Alicia, Pedro, Carlos, Juan Carlos. (Este campo es obligatorio)"
                  className={`form-control ${errors.PRI_NOM_CONTROL_PERSONAL ? 'is-invalid' : ''}`}
              />
              {errors.PRI_NOM_CONTROL_PERSONAL && (
                  <div className="error-feedback">{errors.PRI_NOM_CONTROL_PERSONAL}</div>
              )}
         </div>
          <div className="mb-3">
              <label className="form-label">Segundo nombre del empleado</label>
              <input
                  name="SEG_NOM_CONTROL_PERSONAL" // Añade el atributo name
                  value={formData.SEG_NOM_CONTROL_PERSONAL}
                  onChange={handleChange}
                  type="text"
                  placeholder="Ejemplo: Alexander, Alexis, Estela. "
                  className={`form-control ${errors.SEG_NOM_CONTROL_PERSONAL ? 'is-invalid' : warnings.SEG_NOM_CONTROL_PERSONAL ? 'is-warning' : ''}`}
              />
              {errors.SEG_NOM_CONTROL_PERSONAL && (
                  <div className="error-feedback">{errors.SEG_NOM_CONTROL_PERSONAL}</div>
              )}
              {warnings.SEG_NOM_CONTROL_PERSONAL && (
                  <div className="warning-feedback">{warnings.SEG_NOM_CONTROL_PERSONAL}</div>
                )}
          </div>
          <div className="mb-3">
              <label className="form-label">Primer apellido del empleado </label>
              <input
                  name="PRI_APE_CONTROL_PERSONAL" // Añade el atributo name
                  value={formData.PRI_APE_CONTROL_PERSONAL}
                  onChange={handleChange}
                  type="text"
                  placeholder="Ejemplo: Hernandez, Lopez, Cruz. (Este campo es obligatorio)"
                  className={`form-control ${errors.PRI_APE_CONTROL_PERSONAL ? 'is-invalid' : ''}`}
              />
              {errors.PRI_APE_CONTROL_PERSONAL && (
                  <div className="error-feedback">{errors.PRI_APE_CONTROL_PERSONAL}</div>
              )}
          </div>
          <div className="mb-3">
              <label className="form-label">Segundo apellido del empleado</label>
              <input
                  name="SEG_APE_CONTROL_PERSONAL" // Añade el atributo name
                  value={formData.SEG_APE_CONTROL_PERSONAL}
                  onChange={handleChange}
                  type="text"
                  placeholder="Ejemplo: Hernandez, Torres, Flores."
                  className={`form-control ${errors.SEG_APE_CONTROL_PERSONAL ? 'is-invalid' : warnings.SEG_APE_CONTROL_PERSONAL ? 'is-warning' : ''}`}
              />
              {errors.SEG_APE_CONTROL_PERSONAL && (
                  <div className="error-feedback">{errors.SEG_APE_CONTROL_PERSONAL}</div>
              )}
              {warnings.SEG_APE_CONTROL_PERSONAL && (
                  <div className="warning-feedback">{warnings.SEG_APE_CONTROL_PERSONAL}</div>
                )}
            </div>
          <div className="mb-3">
              <label className="form-label">correo electronico</label>
              <textarea
                  name="COR_ELEC_CONTROL_PERSONAL" // Añade el atributo name
                  value={formData.COR_ELEC_CONTROL_PERSONAL}
                  onChange={handleChange}
                  className={`form-control ${errors.COR_ELEC_CONTROL_PERSONAL ? 'is-invalid' : warnings.COR_ELEC_CONTROL_PERSONAL ? 'is-warning' : ''}`}
                  placeholder="Escribe escribe el correo electronico."
                  rows={4} // se puede ajustar el número de filas según preferencias
              />
              {errors.COR_ELEC_CONTROL_PERSONAL && (
                  <div className="error-feedback">{errors.COR_ELEC_CONTROL_PERSONAL}</div>
              )}
              {warnings.COR_ELEC_CONTROL_PERSONAL && (
                  <div className="warning-feedback">{warnings.COR_ELEC_CONTROL_PERSONAL}</div>
                )}
          </div>
           <div className="mb-3">
              <label className="form-label">Dirección del domicilio del empleado</label>
              <input
                  name="DIRECCION" // Añade el atributo name
                  value={formData.DIRECCION}
                  onChange={handleChange}
                  type="text"
                  placeholder="Ejemplo: Col. 3 de mayo, Barrio altos de la Cabaña, Col. Quezada "
                  className={`form-control ${errors.DIRECCION ? 'is-invalid' : ''}`}
              />
              {errors.DIRECCION && (
                  <div className="error-feedback">{errors.DIRECCION}</div>
              )}
          </div>
           <div className="mb-3">
              <label className="form-label">Numero de telefono del empleado</label>
              <input
                  name="TELEFONO" // Añade el atributo name
                  value={formData.TELEFONO}
                  onChange={handleChange}
                  type="text"
                  placeholder="Ejemplo: 22003480 (Este campo es obligatorio) "
                  className={`form-control ${errors.TELEFONO ? 'is-invalid' : warnings.TELEFONO ? 'is-warning' : ''}`}
              />
              {errors.TELEFONO && (
                  <div className="error-feedback">{errors.TELEFONO}</div>
              )}
              {warnings.TELEFONO && (
                  <div className="warning-feedback">{warnings.TELEFONO}</div>
                )}
          </div>
          <div className="mb-3">
              <label className="form-label">Fecha de nacimiento del empleado</label>
              <input
                  name="FEC_NAC_CONTROL_PERSONAL" // Añade el atributo name
                  value={formData.FEC_NAC_CONTROL_PERSONAL}
                  onChange={handleChange}
                  type="date"
                  className={`form-control ${errors.FEC_NAC_CONTROL_PERSONAL ? 'is-invalid' : ''}`}
              />
              {errors.FEC_NAC_CONTROL_PERSONAL && (
                  <div className="error-feedback">{errors.FEC_NAC_CONTROL_PERSONAL}</div>
              )}
              <small className="form-text text-muted">
                Por favor, asegúrese de que la fecha de nacimiento sea correcta. En un inicio, esta tendra automaticamente la fecha actual.
            </small>
          </div>
          <div className="mb-3">
              <label className="form-label">vacaciones del empleado</label>
              <textarea
                  name="VACACIONES" // Añade el atributo name
                  value={formData.VACACIONES}
                  onChange={handleChange}
                  className={`form-control ${errors.VACACIONES ? 'is-invalid' : warnings.VACACIONES ? 'is-warning' : ''}`}
                  placeholder="Escribe aquí la descripción de las vacaciones asignadas."
                  rows={4} // se puede ajustar el número de filas según preferencias
              />
              {errors.VACACIONES && (
                  <div className="error-feedback">{errors.VACACIONES}</div>
              )}
              {warnings.VACACIONES && (
                  <div className="warning-feedback">{warnings.VACACIONES}</div>
                )}
          </div>
         <div className="mb-3">
              <label className="form-label">Categoria de trabajo</label>
              <textarea
                  name="CATEGORIA" // Añade el atributo name
                  value={formData.CATEGORIA}
                  onChange={handleChange}
                  className={`form-control ${errors.CATEGORIA ? 'is-invalid' : warnings.CATEGORIA ? 'is-warning' : ''}`}
                  placeholder="Ejemplo: enfermera."
                  rows={4} // se puede ajustar el número de filas según preferencias
              />
              {errors.CATEGORIA && (
                  <div className="error-feedback">{errors.CATEGORIA}</div>
              )}
              {warnings.CATEGORIA && (
                  <div className="warning-feedback">{warnings.CATEGORIA}</div>
                )}
          </div>
         <div className="mb-3">
              <label className="form-label">Fecha de ingreso del empleado</label>
              <input
                  name="FEC_INGRESO" // Añade el atributo name
                  value={formData.FEC_INGRESO}
                  onChange={handleChange}
                  type="date"
                  className={`form-control ${errors.FEC_INGRESO ? 'is-invalid' : ''}`}
              />
              {errors.FEC_INGRESO && (
                  <div className="error-feedback">{errors.FEC_INGRESO}</div>
              )}

            <small className="form-text text-muted">
               En un inicio, esta tendra automaticamente la fecha actual. Si la fecha esta adelantada o retrasada, ingrese la fecha correcta.
                </small>
          </div>
          <div className="mb-3">
              <label className="form-label">Nombre el puesto de trabajo del empleado</label>
              <textarea
                  name="NOM_PUESTO" // Añade el atributo name
                  value={formData.NOM_PUESTO}
                  onChange={handleChange}
                  className={`form-control ${errors.NOM_PUESTO ? 'is-invalid' : warnings.NOM_PUESTO ? 'is-warning' : ''}`}
                  placeholder="Escribe aquí la nombre el puesto de trabajo... (Este campo es obligatorio)"
                  rows={4} // se puede ajustar el número de filas según preferencias
              />
              {errors.NOM_PUESTO && (
                  <div className="error-feedback">{errors.NOM_PUESTO}</div>
              )}
              {warnings.Receta && (
                  <div className="warning-feedback">{warnings.NOM_PUESTO}</div>
                )}
          </div>
          <div className="mb-3">
              <label className="form-label">Tipo de sangre del empleado</label>
              <textarea
                  name="TIP_SANGRE" // Añade el atributo name
                  value={formData.TIP_SANGRE}
                  onChange={handleChange}
                  className={`form-control ${errors.TIP_SANGRE ? 'is-invalid' : warnings.TIP_SANGRE ? 'is-warning' : ''}`}
                  placeholder="Escribe aquí el tipo de sangre."
                  rows={4} // se puede ajustar el número de filas según preferencias
              />
              {errors.TIP_SANGRE && (
                  <div className="error-feedback">{errors.TIP_SANGRE}</div>
              )}
              {warnings.TIP_SANGRE && (
                  <div className="warning-feedback">{warnings.TIP_SANGRE}</div>
                )}
          </div>

         <button type="submit" className="btn btn-success"> Guardar</button>
         <button type="button" className="btn btn-primary" onClick={clearInputs}>Limpiar</button>
          <button type="button" className="btn btn-danger" onClick={handleCancel}>Cancelar</button>
      </form>
      <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Mensaje Modal"
      >
          <h2>{modalMessage}</h2>
          <button onClick={closeModal}>Cerrar</button>
      </Modal>
  </div>
  </div>
);
};
                
export default CompCreatePersonal
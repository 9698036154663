import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import Modal from 'react-modal';
import '../../../Estilos/StyleFormulario.css';

const URI = 'https://cisrubenandino.com/ap/ad_rol/'
const VALIDAR_PERMISO_URI = 'https://cisrubenandino.com/ap/validar-Ir-';
const VALIDAR_MODULO_URI = 'https://cisrubenandino.com/ap/validar-mod-R';

const CompCreateRol = () => {
  const Navigate = useNavigate()
  const [formData, setFormData] = useState({
    NOM_Rol: '',
    Descripcion: '',
    Usr_Registro: localStorage.getItem('username') || '', //Usuario logeado
});

// Estado para errores
const [errors, setErrors] = useState({});
const [warnings, setWarnings] = useState({});
const [error, setError] = useState(null);
const [loading, setLoading] = useState(true);

// Estados del modal
const [modalIsOpen, setModalIsOpen] = useState(false);
const [modalMessage, setModalMessage] = useState('');
const [redirectAfterClose, setRedirectAfterClose] = useState(false);

// Funciónes del modal
const openModal = (message, redirect = false) => {
  setModalMessage(message);
  setModalIsOpen(true);
  setRedirectAfterClose(redirect);
};
const closeModal = () => {
  setModalIsOpen(false);
  if (redirectAfterClose) {
    Navigate('/AdmRl');
}
};

// Función para validar que se tenga acceso al modulo
const validarAccesoModulo = useCallback(async () => {
    try {
        const res = await axios.get(VALIDAR_MODULO_URI, {
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        });

        if (res.status !== 200 || !res.data.acceso) {
            throw new Error(res.data.mensaje || 'Acceso denegado.');
        }
    } catch (error) {
        if (error.response && (error.response.status === 401 || error.response.status === 403)) {
            setError('No tienes autorizacion para acceder a este apartado. Si crees que se trata de un error, comunicaselo al administrador.');
        } else {
            setError('No ha sido posible validar el acceso. Contacta con el administrador.');
        }
        setLoading(false);
        throw error;
    }
}, []);

// Función para validar permisos del usuario
const validarPermiso = useCallback(async () => {
    try {
      const res = await axios.get(VALIDAR_PERMISO_URI, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (res.status !== 200 || !res.data.acceso) {
        throw new Error(res.data.mensaje || 'Permiso denegado.');
      }
    } catch (error) {
      setError('No tienes autorización para realizar esta acción. Si crees que se trata de un error, comunícalo al administrador.');
      setLoading(false);
      throw error;
    }
  }, []);

  // UseEffect para validar acceso, permisos y cargar datos
useEffect(() => {
    const fetchData = async () => {
      try {
        await validarAccesoModulo(); // Valida el acceso al módulo
        await validarPermiso(); // Valida el permiso del usuario
      } catch (error) {
        console.error('Error durante la carga de datos:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [validarAccesoModulo, validarPermiso]);

// Función para manejar los cambios en los campos de entrada
const handleChange = (e) => {
    const { name, value } = e.target;
    // Actualizar formData con el valor del campo que ha cambiado
    setFormData((prevData) => ({
        ...prevData,
        [name]: value
    }));
    // Llamar a la función validateField para validar el campo que acaba de cambiar
    validateField(name, value);
};
// Función para validar un campo específico
const validateField = (name, value) => {
    let error = ''; // Inicializa el mensaje de error
    let warning = ''; // Inicializa el mensaje de advertencia
  
    switch (name) {
        case 'NOM_Rol':
            if (!value.trim()) {
                error = 'El Nombre del Rol es obligatorio.';
            } else if (value.length > 50) {
                error = 'El nombre no puede ser de mas de 50 caracteres';
            } else if (value.length > 30) {
                warning = 'Se recomienda que el nombre no sea mayor de 30 caracteres';
            } else if (/\s{2,}/.test(value)) {
                error = 'El campo no puede contener múltiples espacios consecutivos. Por favor, elimina los espacios extra.';
            } else if (/^\s/.test(value) || /\s$/.test(value)) {
                error = 'El campo no puede comenzar o terminar con un espacio. Por favor, elimina los espacios.';
            } else if (/([a-zA-ZáéíóúÁÉÍÓÚñÑ])\1{2,}/.test(value)) {
                error = 'No puede haber más de 2 caracteres repetidos consecutivamente.';
            } else if (/[^a-zA-ZáéíóúÁÉÍÓÚñÑ\s]/.test(value)) {
                error = 'Solo se pueden usar letras.';
            }
            break;

        case 'Descripcion':
            if (!value.trim()) {
                error = 'La descripcion del rol, no puede estar vacia';
          } else {
              // Verificar si hay un espacio al principio o al final
              if (/^\s/.test(value) || /\s$/.test(value)) {
                  error = 'El campo no puede comenzar o terminar con un espacio. Por favor, elimina los espacios.';
              } else if (/\s{2,}/.test(value)) {
                  error = 'El campo no puede contener múltiples espacios consecutivos. Por favor, elimina los espacios extra.';
              } else if (value.length > 150) {
                  error = 'El campo no debe superar los 150 caracteres. Reduce la cantidad de palabras.';
              } else if (/([a-zA-ZáéíóúÁÉÍÓÚñÑ])\1{2,}/.test(value)) {
                error = 'No puede haber más de 2 caracteres repetidos consecutivamente.';
              } else if (value.length > 100) {
                warning = 'La descripcion supera los 100 caracteres, se recomienda hacer una descripcion mas corta si es posible';
            }  else if (/([a-zA-ZáéíóúÁÉÍÓÚñÑ])\1{2,}/.test(value)) {
                error = 'No puede haber más de 2 caracteres repetidos consecutivamente.';
            }
        }
            break;
        default:
            break;
    }
  // Actualiza el objeto de estado de errores y advertencia con el resultado de la validación
  setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: error,
  }));
  setWarnings((prevWarnings) => ({
    ...prevWarnings,
    [name]: warning,
}));
};

// Función para validar todos los campos antes de enviar el formulario
const validateAllFields = () => {
  Object.keys(formData).forEach((field) => validateField(field, formData[field]));
  return !Object.values(errors).some((error) => error !== '');
};

// Función para limpiar todos los campos y errores
const clearInputs = () => {
  setFormData({
      NOM_Rol: '',
      Descripcion: '',
      Usr_Registro: localStorage.getItem('username') || '',
  });
  setErrors({});
  setWarnings({});
};

// Función para cancelar y volver
const handleCancel = () => {
  clearInputs();
  Navigate('/AdmRl');
};

// Lista de campos obligatorios
const requiredFields = [
    'NOM_Rol',
    'Descripcion'
];

// Función para validar los campos obligatorios
const validateRequiredFields = () => {
    let hasError = false;
    const newErrors = {};

    requiredFields.forEach((field) => {
        const value = formData[field];

        if (!value.trim()) {
            // Si el campo está vacío, actualiza el estado de errores con un mensaje de error
            newErrors[field] = `Este campo es obligatorio para continuar.`;
            hasError = true;
        }
    });

    setErrors((prevErrors) => ({
        ...prevErrors,
        ...newErrors
    }));

    return !hasError;
};

// Procedimiento para guardar los datos del formulario
const crear = async (e) => {
  e.preventDefault();
  
// Validar los campos obligatorios
const areRequiredFieldsValid = validateRequiredFields();

const allFieldsFilled = requiredFields.every(
    (field) => formData[field] && formData[field].trim() !== ''
);
// Verificar que los campos obligatorios estén llenos
if (!allFieldsFilled) {
    openModal('Faltan datos por llenar. Por favor, completa todos los campos obligatorios para continuar.');
    return;
}
if (!areRequiredFieldsValid) {
    openModal('Los campos obligatorios deben completarse antes de registrar el Rol.');
    return;
}
  // Validar todos los campos
  const isValid = validateAllFields();
  if (!isValid) {
      openModal('Se han detectado uno o mas errores, por favor corrigelos antes de poder continuar.');
      return;
  }
  try {
      // Realizar la petición POST a la API
      await axios.post(URI, formData, {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      openModal('¡Se ha Creado el nuevo Rol exitosamente!', true);
      clearInputs();
  } catch (error) {
      console.error('Error:', error);
      openModal('¡Ocurrió un error y no se ha podido crear el Rol, intentalo de nuevo!. Si el problema persiste, por favor contacta con el administrador');
  }
};

// Muestra un indicador de carga mientras se obtienen los datos
if (loading) {
    return (
        <div className="loading-container">
            <p>Cargando datos... Por favor, espera.</p>
        </div>
    );
}

// Muestra un mensaje de error en caso de que ocurra
if (error) {
    return (
        <div className="error-container">
            <p>{error}</p>
            <button className="btn btn-secondary" onClick={handleCancel}>Volver a inicio</button>
        </div>
    );
}

return (
    <div>
        <div className="titulo-container">
            <h3>REGISTRAR UN NUEVO ROL DE USUARIO</h3>
    </div>
  <div className="formulario-container">
      <form onSubmit={crear}>
          <div className="mb-3">
              <label className="form-label">Nombre del Rol</label>
              <input
                    name="NOM_Rol" // Añade el atributo name
                    value={formData.NOM_Rol}
                    onChange={handleChange}
                    type="text"
                    placeholder="Ejemplo: Administrador, Sub Administrador (Este campo es obligatorio)"
                    className={`form-control ${errors.NOM_Rol ? 'is-invalid' : warnings.NOM_Rol ? 'is-warning' : ''}`}
              />
              {errors.NOM_Rol && (
                  <div className="error-feedback">{errors.NOM_Rol}</div>
              )}
              {warnings.NOM_Rol && (
                  <div className="warning-feedback">{warnings.NOM_Rol}</div>
                )}
          </div>
          <div className="mb-3">
              <label className="form-label">Descripción del Rol</label>
              <input
                  name="Descripcion" // Añade el atributo name
                  value={formData.Descripcion}
                  onChange={handleChange}
                  type="text"
                  placeholder="Añadir descripción. Ejemplo: Rol de caracter administrativo con acceso a todos los apartados del sistema... (Este campo es obligatorio)"
                  className={`form-control ${errors.Descripcion ? 'is-invalid' : warnings.Descripcion ? 'is-warning' : ''}`}
              />
              {errors.Descripcion && (
                  <div className="error-feedback">{errors.Descripcion}</div>
              )}
              {warnings.Descripcion && (
                  <div className="warning-feedback">{warnings.Descripcion}</div>
                )}
          </div>
          <button type="submit" className="btn btn-success"> Guardar Rol <i className="fa-solid fa-user-check"></i> </button>
          <button type="button" className="btn btn-primary" onClick={clearInputs}>Limpiar los datos <i className="fa-solid fa-paint-roller"></i> </button>
          <button type="button" className="btn btn-danger" onClick={handleCancel}>Cancelar y Regresar <i className="fa-solid fa-rotate-left"></i> </button>
         </form>
       </div>
            {/* Modal para mostrar mensajes */}
            <Modal isOpen={modalIsOpen} onRequestClose={closeModal} className="custom-modal" overlayClassName="custom-modal-overlay">
                <div className="modal-content">
                <h3>{modalMessage}</h3>
                <button className="btn btn-primary" onClick={closeModal}>Cerrar</button>
                </div>
            </Modal>
        </div>
      );
    };
                
export default CompCreateRol
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FaSignOutAlt } from 'react-icons/fa'; // Importa el icono de cerrar sesión
import '../Estilos/Header.css';

function Header() {
  const navigate = useNavigate();

  const handleCerrarSesion = () => {
    // Remueve el token actual
    localStorage.removeItem('token');
    //Limpia el usuario guardado
    localStorage.removeItem('username');
    // Redirige a la página de inicio de sesión
    navigate('/login');
  };

  return (
    <header className="header-container">
      <h1>CIS Ruben Andino Aguilar</h1>
      <nav>
        <ul>
          <li>Bienvenido</li>
          <li>
            <Link to="/login" className="logout-link" onClick={handleCerrarSesion}>
              <FaSignOutAlt /> Cerrar sesión
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;


import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import axios from 'axios';
import Chart from 'chart.js/auto';
import '../Estilos/Dashboard.css';

const Dashboard = () => {
  const [archivosData, setArchivosData] = useState([40, 60]); // Valores iniciales para el área de Archivos
  const [administracionData, setAdministracionData] = useState([30, 70]); // Valores iniciales para el área de Administración
  const [enfermeriaData, setEnfermeriaData] = useState([50, 50]); // Valores iniciales para el área de Enfermería
  const [estadisticasData, setEstadisticasData] = useState([20, 80]); // Valores iniciales para el área de Estadísticas

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const archivosResponse = await axios.get('https://cisrubenandino.com/archivos_data/');
      const administracionResponse = await axios.get('https://cisrubenandino.com/administracion_data/');
      const enfermeriaResponse = await axios.get('https://cisrubenandino.com/enfermeria_data/');
      const estadisticasResponse = await axios.get('https://cisrubenandino.com/estadisticas_data/');

      setArchivosData([archivosResponse.data.disponibles, archivosResponse.data.noDisponibles]);
      setAdministracionData([administracionResponse.data.disponibles, administracionResponse.data.noDisponibles]);
      setEnfermeriaData([enfermeriaResponse.data.disponibles, enfermeriaResponse.data.noDisponibles]);
      setEstadisticasData([estadisticasResponse.data.disponibles, estadisticasResponse.data.noDisponibles]);
    } catch (error) {
      console.error('Error al obtener datos del backend:', error);
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
      <div style={{ width: '95%', maxWidth: '1200px', textAlign: 'center' }}>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <div style={{ width: '300px' }}>
            <h3>Área de Archivos</h3>
            <Pie data={{ labels: ['Disponibles', 'No Disponibles'], datasets: [{ data: archivosData, backgroundColor: ['#FF6384', '#36A2EB'], hoverBackgroundColor: ['#FF6384', '#36A2EB'] }] }} />
            <p>Total de Registros: {archivosData[0] + archivosData[1]}</p>
          </div>
          <div style={{ width: '300px' }}>
            <h3>Área de Administración</h3>
            <Pie data={{ labels: ['Disponibles', 'No Disponibles'], datasets: [{ data: administracionData, backgroundColor: ['#FF6384', '#36A2EB'], hoverBackgroundColor: ['#FF6384', '#36A2EB'] }] }} />
            <p>Total de Registros: {administracionData[0] + administracionData[1]}</p>
          </div>
          <div style={{ width: '300px' }}>
            <h3>Área de Enfermería</h3>
            <Pie data={{ labels: ['Disponibles', 'No Disponibles'], datasets: [{ data: enfermeriaData, backgroundColor: ['#FF6384', '#36A2EB'], hoverBackgroundColor: ['#FF6384', '#36A2EB'] }] }} />
            <p>Total de Registros: {enfermeriaData[0] + enfermeriaData[1]}</p>
          </div>
          <div style={{ width: '300px' }}>
            <h3>Área de Estadísticas</h3>
            <Pie data={{ labels: ['Disponibles', 'No Disponibles'], datasets: [{ data: estadisticasData, backgroundColor: ['#FF6384', '#36A2EB'], hoverBackgroundColor: ['#FF6384', '#36A2EB'] }] }} />
            <p>Total de Registros: {estadisticasData[0] + estadisticasData[1]}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;